import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';
import { defineMessages } from 'react-intl';

const prefix = 'diversity_term';

const messages = defineMessages({
  introTitle: {
    id: `${prefix}-intro-title`,
    defaultMessage:
      'Agora você já pode dar permissão dos dados de diversidade para outras pessoas!',
  },
  introDescription: {
    id: `${prefix}-intro-description`,
    defaultMessage: 'Você pode fazer isso agora ou quando quiser na seção',
  },
  introDescriptionAccess: {
    id: `${prefix}-intro-description-access`,
    defaultMessage: 'Acesso à plataforma → Perfis de acesso.',
  },
  introDescriptionPermission: {
    id: `${prefix}-intro-description-permission`,
    defaultMessage: 'Saiba mais sobre permissionamento na',
  },
  introDescriptionFAQ: {
    id: `${prefix}-intro-description-faq`,
    defaultMessage: 'Central de Ajuda',
  },

  nextButtonTextContent: {
    id: `${prefix}-button-next`,
    defaultMessage: 'Próximo',
  },
  confirmButtonTextContent: {
    id: `${prefix}-button-confirm`,
    defaultMessage: 'Estou de acordo',
  },
});

export const getMessages = intl => formatMessages(intl, messages);
