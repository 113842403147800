import { EmailTemplateTypeEnum } from '@gupy/enums';
import { buildToast, ToastTypes } from '@gupy/front-commons';
import { call, put, takeLatest } from 'redux-saga/effects';
import EmailTemplateService from '../../../services/EmailTemplate/EmailTemplateService';
import EngageSurveyService from '../../../services/EngageSurvey';
import JobService from '../../../services/Job/JobService';
import * as JobCancelActionTypes from './ActionTypes';

export function* getCancelReasons() {
  try {
    const res = yield call(JobService.getCancelReasons);
    yield put({
      type: JobCancelActionTypes.GET_CANCEL_REASONS_SUCCESS,
      payload: res.body,
    });
  } catch (error) {
    yield put({
      type: JobCancelActionTypes.GET_CANCEL_REASONS_FAIL,
      error,
    });
  }
}

export function* getTemplateEmails() {
  try {
    const response = yield call(EmailTemplateService.getAll);
    const feedbackTemplates = response.body.filter(
      t => t.type === EmailTemplateTypeEnum.badFeedback,
    );
    yield put({
      type: JobCancelActionTypes.GET_TEMPLATE_EMAILS_SUCCESS,
      payload: feedbackTemplates,
    });
  } catch (error) {
    yield put({
      error,
      type: JobCancelActionTypes.GET_TEMPLATE_EMAILS_FAIL,
    });
  }
}

export function* sendEmail({ payload }) {
  try {
    const { from, jobId, messages, templateEmail, engageSurveyId } = payload;
    const response = yield call(
      JobService.getAllApplicationsWithNoFeedback,
      jobId,
    );
    const data = {
      from,
      subject: templateEmail.subject,
      emailBody: templateEmail.body,
      applicationIds: response.body.applications,
      attachments: (templateEmail.attachments || []).map(
        ({ id, name, size, tempName }) => ({ id, name, size, tempName }),
      ),
      engageSurveyId,
    };

    yield call(JobService.sendFeedbackEmails, jobId, data);
    yield put({
      type: JobCancelActionTypes.SEND_EMAIL_SUCCESS,
      toast: buildToast(messages.success, ToastTypes.success),
    });
  } catch (error) {
    const { messages } = payload;
    yield put({
      error,
      type: JobCancelActionTypes.SEND_EMAIL_FAIL,
      toast: buildToast(messages.error, ToastTypes.error),
    });
  }
}

export function* getApplicationsToSendFeedback(action) {
  try {
    const res = yield call(
      JobService.getAllApplicationsWithNoFeedback,
      action.jobId,
    );
    yield put({
      type: JobCancelActionTypes.GET_APPLICATIONS_TO_SEND_EMAIL_SUCCESS,
      payload: res.body.applications,
    });
  } catch (error) {
    yield put({
      type: JobCancelActionTypes.GET_APPLICATIONS_TO_SEND_EMAIL_FAIL,
      error,
    });
  }
}

export function* getBindSurveyCareerPages() {
  try {
    const res = yield call(EngageSurveyService.getSurveyCareerPageBinds);
    yield put({
      type: JobCancelActionTypes.GET_BIND_SURVEY_CAREER_PAGES_SUCCESS,
      payload: res.body,
    });
  } catch (error) {
    yield put({
      type: JobCancelActionTypes.GET_BIND_SURVEY_CAREER_PAGES_FAIL,
      error,
    });
  }
}

function* JobCancelSaga() {
  yield takeLatest(JobCancelActionTypes.GET_CANCEL_REASONS, getCancelReasons);
  yield takeLatest(JobCancelActionTypes.GET_TEMPLATE_EMAILS, getTemplateEmails);
  yield takeLatest(JobCancelActionTypes.SEND_EMAIL, sendEmail);
  yield takeLatest(
    JobCancelActionTypes.GET_APPLICATIONS_TO_SEND_EMAIL,
    getApplicationsToSendFeedback,
  );
  yield takeLatest(
    JobCancelActionTypes.GET_BIND_SURVEY_CAREER_PAGES,
    getBindSurveyCareerPages,
  );
}

export default JobCancelSaga;
