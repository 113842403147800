import { Button, Spinner, Tip } from '@gupy/design-system';
import { SchemaForm } from '@gupy/front-commons';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import uuid from 'uuid';
import './Integrations.scss';
import { BlockedComponent } from '../../components/BlockedComponent';
import Grid from '../../components/Grid/Grid';
import GridContent from '../../components/Grid/GridContent';
import SetupGridSidebarMenu from '../../components/Grid/SetupGridSidebarMenu';
import { WithKeycloak, WithUnleash } from '../../components/Hocs';
import DontShowTipsAgainEnum from '../../constants/DontShowTipsAgainEnum';
import LocalstorageConstants from '../../constants/LocalstorageConstants';
import featuresFlagsEnum from '../Authentication/FeaturesFlagsEnum';
import { adaptJobBoardsCustomIdsViewModelToRequestBody } from './adapters/jobBoardBindingAdapter';
import CustomIdRemoveModal from './components/JobBoardsCustomIds/CustomIdRemoveModal';
import JobBoardsCustomIds from './components/JobBoardsCustomIds/JobBoardsCustomIds';
import { ChildAppModel } from './components/RscLinkedinIntegration/models/ChildAppModel';
import RscLinkedinIntegration from './components/RscLinkedinIntegration/RscLinkedinIntegration';
import RscLinkedinIntegrationChildApps from './components/RscLinkedinIntegration/RscLinkedinIntegrationChildApps';
import RscLinkedinIntegrationCreateChildApp from './components/RscLinkedinIntegration/RscLinkedinIntegrationCreateChildApp';
import rscChildAppStatusEnum from './enums/RscChildAppStatusEnum';
import openRscLinkedinIframeInPopup from './helpers/openRscLinkedinIframeInPopup';
import {
  bindRscLinkedinIntegrationChildAppCareerPages,
  clearCreatedRsclinkedinIntegrationChildApp,
  createRsclinkedinIntegrationChildApp,
  desyncRscLinkedinIntegrationChildApp,
  editRscLinkedinIntegrationChildApp,
  getRscChildApps,
  initIntegrations,
  putRscErrorToast,
  restartSyncRscLinkedinIntegrationChildApp,
  saveIntegrations,
  saveJobBoardsCustomIds,
  updateIntegrationsForm,
} from './IntegrationsAction';
import { getMessages } from './Messages';

const propTypes = {
  initIntegrations: PropTypes.func.isRequired,
  updateIntegrationsForm: PropTypes.func.isRequired,
  saveIntegrations: PropTypes.func.isRequired,
  createRsclinkedinIntegrationChildApp: PropTypes.func.isRequired,
  clearCreatedRsclinkedinIntegrationChildApp: PropTypes.func.isRequired,
  saveJobBoardsCustomIds: PropTypes.func.isRequired,
  getRscChildApps: PropTypes.func.isRequired,
  bindRscLinkedinIntegrationChildAppCareerPages: PropTypes.func.isRequired,
  editRscLinkedinIntegrationChildApp: PropTypes.func.isRequired,
  restartSyncRscLinkedinIntegrationChildApp: PropTypes.func.isRequired,
  desyncRscLinkedinIntegrationChildApp: PropTypes.func.isRequired,
  putRscErrorToast: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
  Integrations: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  match: PropTypes.object.isRequired,
  isFFRscLinkedinIntegrationEnabled: PropTypes.bool.isRequired,
  keycloakCredentials: PropTypes.object,
  Authentication: PropTypes.object.isRequired,
  isSchedulingIntegrationEnabled: PropTypes.bool.isRequired,
  isRepackagingEnabled: PropTypes.bool.isRequired,
};

class Integrations extends Component {
  state = {
    messages: {},
    customIdRemoveModal: {
      isOpen: false,
      jobBoardId: null,
      keyId: null,
    },
    showTip: true,
  };

  componentDidMount() {
    const { intl, isFFRscLinkedinIntegrationEnabled, keycloakCredentials } =
      this.props;

    const { shouldDisplayRscLinkedinIntegration, token } =
      this.getShouldDisplayRscLinkedinIntegration(
        isFFRscLinkedinIntegrationEnabled,
        keycloakCredentials,
      );

    this.props.initIntegrations(
      this.props.match.params.screen,
      shouldDisplayRscLinkedinIntegration,
      token,
    );

    this.setState({ messages: getMessages(intl) });
    this.setState({
      showTip: this.canShowTip(
        DontShowTipsAgainEnum.integrationsJobBoardsCustomIds,
      ),
    });
  }

  componentDidUpdate(props) {
    const { isFFRscLinkedinIntegrationEnabled, keycloakCredentials } =
      this.props;

    const prevScreen = props.match.params.screen;
    const nextScreen = this.props.match.params.screen;

    const { shouldDisplayRscLinkedinIntegration, token } =
      this.getShouldDisplayRscLinkedinIntegration(
        isFFRscLinkedinIntegrationEnabled,
        keycloakCredentials,
      );

    if (prevScreen !== nextScreen && nextScreen) {
      this.props.initIntegrations(
        nextScreen,
        shouldDisplayRscLinkedinIntegration,
        token,
      );
    }
    this.handleChildAppCreated();
  }

  getShouldDisplayRscLinkedinIntegration = (
    isFFRscLinkedinIntegrationEnabled,
    keycloakCredentials,
  ) => {
    const shouldDisplayRscLinkedinIntegration =
      isFFRscLinkedinIntegrationEnabled && !!keycloakCredentials;

    const token = keycloakCredentials
      ? keycloakCredentials.keycloak.token
      : null;

    return { shouldDisplayRscLinkedinIntegration, token };
  };

  getScreenTitle = () =>
    this.state.messages[this.props.match.params.screen] ||
    this.state.messages.default;

  getArrayDeepCopy = arrayOfObjects =>
    JSON.parse(JSON.stringify(arrayOfObjects));

  getJobBoardsCustomIdsIndexes = (
    jobBoardsCustomIdsParam,
    jobBoardIdParam,
    keyIdParam,
  ) => {
    const jobBoardIndex = jobBoardsCustomIdsParam.findIndex(
      ({ jobBoardId }) => jobBoardId === jobBoardIdParam,
    );
    if (jobBoardIndex < 0) return {};
    const keyIdIndex = jobBoardsCustomIdsParam[
      `${jobBoardIndex}`
    ].customIds.findIndex(({ keyId }) => keyId === keyIdParam);
    if (keyIdIndex < 0) return {};
    return { jobBoardIndex, keyIdIndex };
  };

  setCustomIdRemoveModal = customIdRemoveModal => {
    this.setState({ customIdRemoveModal });
  };

  isInvalidJobBoardsCustomIds = () => {
    const { jobBoardsCustomIds, initialJobBoardsCustomIds } =
      this.props.Integrations;

    if (
      !this.isChangedJobBoardsCustomIds(
        jobBoardsCustomIds,
        initialJobBoardsCustomIds,
      )
    ) {
      return false;
    }

    let isInvalid = false;
    jobBoardsCustomIds.forEach(jobBoard =>
      jobBoard.customIds.forEach(customId => {
        if (!customId.customId || customId.careerPageIds.length === 0) {
          isInvalid = true;
        }
      }),
    );
    return isInvalid;
  };

  isChangedJobBoardsCustomIds = (initialJobBoards, currentJobBoards) => {
    const removeUnnecessaryPropertiesToCompare = jobBoards => {
      const adaptedJobBoards = [];
      Object.values(jobBoards).forEach(jobBoard => {
        adaptedJobBoards.push({
          ...jobBoard,
          customIds: jobBoard.customIds.map(
            ({ keyId, displayErrors, ...rest }) => rest,
          ),
        });
      });
      return adaptedJobBoards;
    };

    const adaptedInitialJobBoards = JSON.stringify(
      removeUnnecessaryPropertiesToCompare(initialJobBoards),
    );
    const adaptedCurrentJobBoards = JSON.stringify(
      removeUnnecessaryPropertiesToCompare(currentJobBoards),
    );

    return adaptedInitialJobBoards !== adaptedCurrentJobBoards;
  };

  enableAllDisplayErrors = () => {
    const jobBoardsCustomIds = JSON.parse(
      JSON.stringify(this.props.Integrations.jobBoardsCustomIds),
    );
    const adaptedJobBoards = [];
    Object.values(jobBoardsCustomIds).forEach(jobBoard => {
      adaptedJobBoards.push({
        ...jobBoard,
        customIds: jobBoard.customIds.map(customId => ({
          ...customId,
          displayErrors: true,
        })),
      });
    });
    return this.props.saveJobBoardsCustomIds(adaptedJobBoards);
  };

  handleFormChange = form => {
    this.props.updateIntegrationsForm(form.formData);
  };

  handleFormSubmit = ({ errors, formData }) => {
    if (errors.length !== 0) return;

    if (this.isInvalidJobBoardsCustomIds()) {
      this.enableAllDisplayErrors();
      return;
    }

    const isChangedJobBoardsCustomIds = this.isChangedJobBoardsCustomIds(
      this.props.Integrations.initialJobBoardsCustomIds,
      this.props.Integrations.jobBoardsCustomIds,
    );

    this.props.saveIntegrations(
      formData,
      {
        success: this.state.messages.saveIntegrationsSubmitSuccess,
        error: this.state.messages.saveIntegrationsSubmitError,
      },
      this.props.match.params.screen,
      isChangedJobBoardsCustomIds &&
        adaptJobBoardsCustomIdsViewModelToRequestBody(
          this.props.Integrations.jobBoardsCustomIds,
        ),
    );
  };

  handleChildAppCreateSubmit = () => {
    const {
      keycloakCredentials: {
        keycloak: { token },
      },
    } = this.props;

    this.props.createRsclinkedinIntegrationChildApp(
      {
        success: this.state.messages.saveIntegrationsSubmitSuccess,
        error: this.state.messages.saveIntegrationsSubmitError,
      },
      token,
    );
  };

  handleChildAppCreated = () => {
    const { childAppCreated } = this.props.Integrations;

    if (childAppCreated) {
      openRscLinkedinIframeInPopup({
        id: childAppCreated.id,
        clientId: childAppCreated.clientId,
      });

      this.props.clearCreatedRsclinkedinIntegrationChildApp();
    }
  };

  shouldDisplayCreateChildAppButton = status => {
    const statuses = {
      [rscChildAppStatusEnum.SYNC_PENDING]: false,
      [rscChildAppStatusEnum.READY_TO_CONNECT_WITH_LINKEDIN]: false,
    };

    return statuses[`${status}`] !== undefined ? statuses[`${status}`] : true;
  };

  handleAddJobBoardCustomId = jobBoardIdParam => {
    const jobBoardsCustomIds = this.getArrayDeepCopy(
      this.props.Integrations.jobBoardsCustomIds,
    );
    const jobBoardIndex = jobBoardsCustomIds.findIndex(
      ({ jobBoardId }) => jobBoardId === jobBoardIdParam,
    );
    if (jobBoardIndex < 0) return false;

    jobBoardsCustomIds[`${jobBoardIndex}`].customIds.push({
      keyId: uuid(),
      customId: '',
      careerPageIds: [],
      displayErrors: false,
    });
    return this.props.saveJobBoardsCustomIds(jobBoardsCustomIds);
  };

  handleRemoveJobBoardCustomId = (jobBoardIdParam, keyIdParam) => {
    const jobBoardsCustomIds = this.getArrayDeepCopy(
      this.props.Integrations.jobBoardsCustomIds,
    );
    const { jobBoardIndex, keyIdIndex } = this.getJobBoardsCustomIdsIndexes(
      jobBoardsCustomIds,
      jobBoardIdParam,
      keyIdParam,
    );
    if (jobBoardIndex === undefined || keyIdIndex === undefined) return false;

    jobBoardsCustomIds[`${jobBoardIndex}`].customIds.splice(keyIdIndex, 1);
    return this.props.saveJobBoardsCustomIds(jobBoardsCustomIds);
  };

  handleSetJobBoardCustomIdCareerPages = (
    jobBoardIdParam,
    keyIdParam,
    careerPageIds,
  ) => {
    const jobBoardsCustomIds = this.getArrayDeepCopy(
      this.props.Integrations.jobBoardsCustomIds,
    );
    const { jobBoardIndex, keyIdIndex } = this.getJobBoardsCustomIdsIndexes(
      jobBoardsCustomIds,
      jobBoardIdParam,
      keyIdParam,
    );
    if (jobBoardIndex === undefined || keyIdIndex === undefined) return false;

    jobBoardsCustomIds[`${jobBoardIndex}`].customIds[
      `${keyIdIndex}`
    ].careerPageIds = careerPageIds;
    jobBoardsCustomIds[`${jobBoardIndex}`].customIds[
      `${keyIdIndex}`
    ].displayErrors = true;
    return this.props.saveJobBoardsCustomIds(jobBoardsCustomIds);
  };

  handleSetJobBoardCustomIdOnChange = (
    jobBoardIdParam,
    keyIdParam,
    newCustomIdValue,
  ) => {
    if (!/^\d*$/.test(newCustomIdValue)) return false;
    const jobBoardsCustomIds = this.getArrayDeepCopy(
      this.props.Integrations.jobBoardsCustomIds,
    );
    const { jobBoardIndex, keyIdIndex } = this.getJobBoardsCustomIdsIndexes(
      jobBoardsCustomIds,
      jobBoardIdParam,
      keyIdParam,
    );
    if (jobBoardIndex === undefined || keyIdIndex === undefined) return false;

    jobBoardsCustomIds[`${jobBoardIndex}`].customIds[`${keyIdIndex}`].customId =
      newCustomIdValue;
    jobBoardsCustomIds[`${jobBoardIndex}`].customIds[
      `${keyIdIndex}`
    ].displayErrors = true;
    return this.props.saveJobBoardsCustomIds(jobBoardsCustomIds);
  };

  buildRscChildAppSyncSubmitSuccessToastMessage = (
    rscChildAppSyncSuccessSlice1,
    rscChildAppSyncSuccessSlice2,
    rscChildAppSyncSuccessSlice3,
  ) => (
    <Fragment>
      {rscChildAppSyncSuccessSlice1}
      <br />
      <br />
      {rscChildAppSyncSuccessSlice2}
      <br />
      <br />
      {rscChildAppSyncSuccessSlice3}
    </Fragment>
  );

  handleChildAppSyncSubmit = (
    childAppId,
    childAppName,
    childAppCareerPages,
  ) => {
    const { isFFRscLinkedinIntegrationEnabled, keycloakCredentials } =
      this.props;

    const {
      rscChildAppSyncSuccessSlice1,
      rscChildAppSyncSuccessSlice2,
      rscChildAppSyncSuccessSlice3,
      rscChildAppSyncError,
      rscIntegrationChildAppsLinkedinPageDuplicateError,
    } = this.state.messages;

    const { currentUser } = this.props.Authentication;

    const rscChildAppSyncSuccess =
      this.buildRscChildAppSyncSubmitSuccessToastMessage(
        rscChildAppSyncSuccessSlice1,
        rscChildAppSyncSuccessSlice2,
        rscChildAppSyncSuccessSlice3,
      );

    const { token } = this.getShouldDisplayRscLinkedinIntegration(
      isFFRscLinkedinIntegrationEnabled,
      keycloakCredentials,
    );

    this.props.bindRscLinkedinIntegrationChildAppCareerPages(
      {
        childAppId,
        childAppName,
        childAppCareerPagesIds: childAppCareerPages.map(
          ({ careerPageId }) => careerPageId,
        ),
      },
      {
        language: currentUser.language,
      },
      {
        success: rscChildAppSyncSuccess,
        error: rscChildAppSyncError,
        formValidationErrors: {
          rscIntegrationChildAppsLinkedinPageDuplicateError,
        },
      },
      token,
    );
  };

  handleChildAppEditSubmit = (
    childAppId,
    childAppName,
    childAppCareerPages,
  ) => {
    const { isFFRscLinkedinIntegrationEnabled, keycloakCredentials } =
      this.props;

    const {
      rscChildAppEditSuccess,
      rscChildAppEditError,
      rscIntegrationChildAppsLinkedinPageDuplicateError,
    } = this.state.messages;

    const { token } = this.getShouldDisplayRscLinkedinIntegration(
      isFFRscLinkedinIntegrationEnabled,
      keycloakCredentials,
    );

    this.props.editRscLinkedinIntegrationChildApp(
      {
        childAppId,
        childAppName,
        childAppCareerPagesIds: childAppCareerPages.map(
          ({ careerPageId }) => careerPageId,
        ),
      },
      {
        success: rscChildAppEditSuccess,
        error: rscChildAppEditError,
        formValidationErrors: {
          rscIntegrationChildAppsLinkedinPageDuplicateError,
        },
      },
      token,
    );
  };

  handleChildAppSyncRestartSubmit = (childAppId, childAppName) => {
    const { isFFRscLinkedinIntegrationEnabled, keycloakCredentials } =
      this.props;

    const {
      rscChildAppSyncSuccessSlice1,
      rscChildAppSyncSuccessSlice2,
      rscChildAppSyncSuccessSlice3,
      rscChildAppSyncSuccessSlice4,
      rscChildAppSyncSuccessSlice5,
      rscChildAppSyncError,
    } = this.state.messages;

    const rscChildAppSyncSuccess =
      this.buildRscChildAppSyncSubmitSuccessToastMessage(
        rscChildAppSyncSuccessSlice1,
        rscChildAppSyncSuccessSlice2,
        rscChildAppSyncSuccessSlice3,
        rscChildAppSyncSuccessSlice4,
        rscChildAppSyncSuccessSlice5,
        childAppName,
      );
    const { currentUser } = this.props.Authentication;

    const { token } = this.getShouldDisplayRscLinkedinIntegration(
      isFFRscLinkedinIntegrationEnabled,
      keycloakCredentials,
    );

    this.props.restartSyncRscLinkedinIntegrationChildApp(
      {
        childAppId,
      },
      {
        language: currentUser.language,
      },
      {
        success: rscChildAppSyncSuccess,
        error: rscChildAppSyncError,
      },
      token,
    );
  };

  handleChildAppDesyncSubmit = childAppId => {
    const { isFFRscLinkedinIntegrationEnabled, keycloakCredentials } =
      this.props;

    const { currentUser } = this.props.Authentication;

    const { token } = this.getShouldDisplayRscLinkedinIntegration(
      isFFRscLinkedinIntegrationEnabled,
      keycloakCredentials,
    );

    this.props.desyncRscLinkedinIntegrationChildApp(
      {
        childAppId,
      },
      {
        language: currentUser.language,
      },
      token,
    );
  };

  handleChildAppsPollingRequest = () => {
    const { isFFRscLinkedinIntegrationEnabled, keycloakCredentials } =
      this.props;

    const { token } = this.getShouldDisplayRscLinkedinIntegration(
      isFFRscLinkedinIntegrationEnabled,
      keycloakCredentials,
    );

    this.props.getRscChildApps(
      {
        success: '',
        error: this.state.messages.rscGetChildAppsOnPollingError,
      },
      token,
    );
  };

  getParsedTipsFromStorage = () => {
    const tipsFromStorage = localStorage.getItem(
      LocalstorageConstants.dontShowTipsAgain,
    );
    return JSON.parse(tipsFromStorage || null);
  };

  canShowTip = tip => {
    const parsedTipsFromStorage = this.getParsedTipsFromStorage();

    const tipExistsOnStorage =
      parsedTipsFromStorage &&
      Array.isArray(parsedTipsFromStorage) &&
      parsedTipsFromStorage.includes(tip);

    if (tipExistsOnStorage) return false;
    return true;
  };

  handleDontShowTipAgain = () => {
    const parsedTipsFromStorage = this.getParsedTipsFromStorage();

    const tipsToSaveInStorage = [];
    if (parsedTipsFromStorage && Array.isArray(parsedTipsFromStorage)) {
      tipsToSaveInStorage.concat(parsedTipsFromStorage);
    }
    tipsToSaveInStorage.push(
      DontShowTipsAgainEnum.integrationsJobBoardsCustomIds,
    );

    this.setState({ showTip: false });

    return localStorage.setItem(
      LocalstorageConstants.dontShowTipsAgain,
      JSON.stringify(tipsToSaveInStorage),
    );
  };

  hasNoneChildAppWithSetupStatus = childApps =>
    !childApps.some(childApp => childApp.hasSetupStatus());

  hasAnyCareerPageAvailableToLinkedinRsc = careerPages =>
    careerPages.some(careerPage => careerPage.isAvailable);

  render() {
    const {
      integrations,
      isSaving,
      jsonSchema,
      uiSchema,
      rscLinkedinIntegrationChildApps,
      rscLinkedinIntegrationServiceOnError,
      rscLinkedinIntegrationRestartingChildApps,
    } = this.props.Integrations;

    const { currentUser } = this.props.Authentication;

    const isCurrentUserTypeRoot = currentUser.userType === 'root';

    let { rscLinkedinIntegrationCareerPages } = this.props.Integrations;

    const { screen } = this.props.match.params;
    const {
      permissions,
      intl,
      isFFRscLinkedinIntegrationEnabled,
      keycloakCredentials,
    } = this.props;
    const { showTip, messages } = this.state;

    const screenTitle = this.getScreenTitle();
    const showSchemaForm = jsonSchema && jsonSchema.type && true;
    const isDefaultScreen = screen === 'default';

    const childApps = rscLinkedinIntegrationChildApps.map(
      childApp => new ChildAppModel(childApp, messages),
    );

    const hasChildApps = childApps.length > 0;

    const careerPagesAlreadyUsedinLinkedinRsc = new Set();

    childApps.forEach(({ careerPages }) => {
      careerPages.forEach(({ careerPageId }) =>
        careerPagesAlreadyUsedinLinkedinRsc.add(careerPageId),
      );
    });

    rscLinkedinIntegrationCareerPages = rscLinkedinIntegrationCareerPages.map(
      careerPage => ({
        ...careerPage,
        isAvailable: !careerPagesAlreadyUsedinLinkedinRsc.has(careerPage.id),
      }),
    );

    const isCreateChildAppButtonEnabled =
      this.hasNoneChildAppWithSetupStatus(childApps) &&
      this.hasAnyCareerPageAvailableToLinkedinRsc(
        rscLinkedinIntegrationCareerPages,
      );

    const { shouldDisplayRscLinkedinIntegration, token } =
      this.getShouldDisplayRscLinkedinIntegration(
        isFFRscLinkedinIntegrationEnabled,
        keycloakCredentials,
      );

    const { isRepackagingEnabled } = this.props;
    const { isSchedulingIntegrationEnabled } = this.props;
    const isSchedulingIntegrationScreen = screen === 'interviewEvent';
    const isMessagesLoaded = Object.keys(messages).length;

    const schemaForm = () =>
      isSaving ? (
        <div className="spinner">
          <Spinner color="secondary" />
        </div>
      ) : (
        <Fragment>
          <SchemaForm
            schema={jsonSchema}
            uiSchema={uiSchema}
            formData={integrations}
            showErrorList={false}
            onChange={this.handleFormChange}
            onSubmit={this.handleFormSubmit}
            noHtml5Validate
            className="integrations"
            intl={intl}
          >
            {isDefaultScreen && (
              <Fragment>
                <JobBoardsCustomIds
                  handleAddJobBoardCustomId={this.handleAddJobBoardCustomId}
                  handleSetJobBoardCustomIdCareerPages={
                    this.handleSetJobBoardCustomIdCareerPages
                  }
                  handleSetJobBoardCustomIdOnChange={
                    this.handleSetJobBoardCustomIdOnChange
                  }
                  setCustomIdRemoveModal={this.setCustomIdRemoveModal}
                />
                {showTip && (
                  <Tip
                    className="job-boards-custom-ids__tip"
                    id="tip-info"
                    type="info"
                    showIcon
                    text={
                      <span>
                        {
                          messages.jobBoardsCustomIdsFormConfidentialAndInternalJobsAlert
                        }
                        .{' '}
                        <button
                          tabIndex={0}
                          className="job-boards-custom-ids__dont-show-tip-again"
                          onClick={this.handleDontShowTipAgain}
                        >
                          {
                            messages.jobBoardsCustomIdsFormConfidentialAndInternalJobsAlertHrefText
                          }
                        </button>
                        .
                      </span>
                    }
                  />
                )}
              </Fragment>
            )}
            <div className="toolbar">
              <Button
                className="job-boards-custom-ids__save-btn"
                submit
                disabled={isSaving}
                block
              >
                {this.state.messages.saveIntegrationsSubmitBtn}
              </Button>
            </div>
          </SchemaForm>
          {isDefaultScreen &&
            shouldDisplayRscLinkedinIntegration &&
            !rscLinkedinIntegrationServiceOnError && (
              <RscLinkedinIntegration
                intl={intl}
                getChildApps={this.props.getRscChildApps}
                putRscErrorToast={this.props.putRscErrorToast}
                token={token}
                isCurrentUserTypeRoot={isCurrentUserTypeRoot}
              >
                {hasChildApps && (
                  <RscLinkedinIntegrationChildApps
                    childApps={childApps}
                    rscLinkedinIntegrationCareerPages={
                      rscLinkedinIntegrationCareerPages
                    }
                    handleChildAppSyncSubmit={this.handleChildAppSyncSubmit}
                    handleChildAppEditSubmit={this.handleChildAppEditSubmit}
                    handleChildAppSyncRestartSubmit={
                      this.handleChildAppSyncRestartSubmit
                    }
                    handleChildAppDesyncSubmit={this.handleChildAppDesyncSubmit}
                    handleChildAppsPollingRequest={
                      this.handleChildAppsPollingRequest
                    }
                    restartingChildApps={
                      rscLinkedinIntegrationRestartingChildApps
                    }
                    key={Date.now()}
                  />
                )}

                <RscLinkedinIntegrationCreateChildApp
                  handleChildAppCreateSubmit={this.handleChildAppCreateSubmit}
                  buttonText={messages.rscConnectButton}
                  isCreateChildAppButtonDisabled={
                    !isCreateChildAppButtonEnabled
                  }
                />
              </RscLinkedinIntegration>
            )}
        </Fragment>
      );

    return (
      <Grid>
        <CustomIdRemoveModal
          customIdRemoveModal={this.state.customIdRemoveModal}
          setCustomIdRemoveModal={this.setCustomIdRemoveModal}
          handleRemoveJobBoardCustomId={this.handleRemoveJobBoardCustomId}
        />
        <SetupGridSidebarMenu permissions={permissions} />
        {isMessagesLoaded && (
          <div className="col-sm-9">
            <BlockedComponent
              shouldBlock={
                isRepackagingEnabled &&
                isSchedulingIntegrationScreen &&
                !isSchedulingIntegrationEnabled
              }
              title={messages.scheduleIntegrationRepackagingTitle}
              description={
                <FormattedHTMLMessage
                  id="schedule-integration-repacking-description"
                  defaultMessage="<span>Integre sua conta do Google Calendar ou do Office 365 e gerencie o calendário de entrevistas direto pela Gupy, para continuar otimizando e simplificando seu processo.</span>"
                />
              }
              cardTitle={messages.scheduleIntegrationRepackagingCardTitle}
              btnText={messages.scheduleIntegrationRepackagingKnowMore}
              cardDescription={
                <FormattedHTMLMessage
                  id="repackaging-card-description"
                  defaultMessage="<span>Aprenda com os nossos artigos na nossa Central de Ajuda!</span>"
                />
              }
              currentUser={currentUser}
              gaButtonClickProps={{
                category: 'setup',
                action: 'interview_scheduling_know_more_btn_click',
                label: 'interview_scheduling_know_more_btn',
              }}
              gaKnowMoreLinkProps={{
                category: 'setup',
                action: 'interview_scheduling_know_more_faq_link',
                label: 'interview_scheduling_know_more_faq',
              }}
              knowMoreUrl="https://suporte.gupy.io/s/suporte/article/Campos-customizados-o-que-sao-e-como-utiliza-los-nas-vagas?language=pt_BR"
            >
              <GridContent title={screenTitle} hasSideBar={false}>
                {showSchemaForm ? schemaForm() : <div />}
              </GridContent>
            </BlockedComponent>
          </div>
        )}
      </Grid>
    );
  }
}

Integrations.propTypes = propTypes;

const mapStateToProps = state => ({
  Integrations: state.reducers.Integrations,
  Authentication: state.reducers.Authentication,
});

const IntegrationsWithUnleashAndKeycloak = WithUnleash(
  WithKeycloak(Integrations),
)([
  {
    propName: 'isFFRscLinkedinIntegrationEnabled',
    name: featuresFlagsEnum.rscLinkedinIntegration,
  },
  {
    propName: 'isSchedulingIntegrationEnabled',
    name: featuresFlagsEnum.showSchedulingIntegration,
  },
  {
    propName: 'isRepackagingEnabled',
    name: featuresFlagsEnum.repackaging,
  },
]);

export default withRouter(
  injectIntl(
    connect(mapStateToProps, {
      initIntegrations,
      updateIntegrationsForm,
      saveIntegrations,
      saveJobBoardsCustomIds,
      getRscChildApps,
      bindRscLinkedinIntegrationChildAppCareerPages,
      editRscLinkedinIntegrationChildApp,
      restartSyncRscLinkedinIntegrationChildApp,
      desyncRscLinkedinIntegrationChildApp,
      putRscErrorToast,
      createRsclinkedinIntegrationChildApp,
      clearCreatedRsclinkedinIntegrationChildApp,
    })(IntegrationsWithUnleashAndKeycloak),
  ),
);
