import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const defaultProps = {
  className: '',
};

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

function Grid({ className, children }) {
  return <div className={classNames('row grid', className)}>{children}</div>;
}

Grid.defaultProps = defaultProps;
Grid.propTypes = propTypes;

export default Grid;
