import { SectionDivider } from '@gupy/design-system';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
};

const isRenderingSomeChild = children =>
  React.Children.toArray(children).some(child => child.props.hasPermission);

const SetupSectionDivider = ({ id, title, children }) =>
  isRenderingSomeChild(children) ? (
    <div className="grid__sidebar__section-divider">
      <SectionDivider id={id} title={title} />
      {children}
    </div>
  ) : null;

SetupSectionDivider.propTypes = propTypes;
SetupSectionDivider.defaultProps = defaultProps;

export default SetupSectionDivider;
