import { ContainerErrorBoundary } from '@gupy/front-commons';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Route as DefaultRoute } from 'react-router-dom';

const propTypes = {
  component: PropTypes.object.isRequired,
};

const defaultProps = {
  layout: Fragment,
};

const Route = ({ component: Component, layout: Layout, ...rest }) => (
  <DefaultRoute
    {...rest}
    render={props => (
      <Layout>
        <ContainerErrorBoundary>
          <Component {...props} />
        </ContainerErrorBoundary>
      </Layout>
    )}
  />
);

Route.propTypes = propTypes;
Route.defaultProps = defaultProps;

export default Route;
