import { call, put, select, takeLatest } from 'redux-saga/effects';
import { WhatsappBusinessCampaignActionTypes } from './Actions';
import Service from './Service';

export const getJobApplication = state => state.reducers.JobApplication;

export function* createCampaign(action) {
  const { campaign } = action;
  const { includeApplicationIds } = yield select(getJobApplication);

  try {
    yield call(Service.createCampaign, {
      ...campaign,
      includeApplicationIds,
    });

    yield put({
      type: WhatsappBusinessCampaignActionTypes.CREATE_CAMPAIGN_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: WhatsappBusinessCampaignActionTypes.CREATE_CAMPAIGN_FAIL,
    });
  }
}

function* WhatsAppBusinessCampaignSaga() {
  yield takeLatest(
    WhatsappBusinessCampaignActionTypes.CREATE_CAMPAIGN,
    createCampaign,
  );
}

export default WhatsAppBusinessCampaignSaga;
