import { Infotip, InfotipTypes } from '@gupy/design-system';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  length: PropTypes.number.isRequired,
};

const SecondaryMenuOverflow = ({ id, children, length }) => {
  if (children.length > length) {
    return (
      <Infotip
        alignment={InfotipTypes.BOTTOM_RIGHT}
        id={`infotip-secondary-menu-${id}`}
        message={children}
        width={300}
      >
        {`${children.substring(0, length)}...`}
      </Infotip>
    );
  }
  return children;
};

SecondaryMenuOverflow.propTypes = propTypes;

export default SecondaryMenuOverflow;
