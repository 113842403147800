import { asyncActionType, createActionTypes } from '@gupy/front-commons';

export default createActionTypes(
  'EmailDomains',
  asyncActionType('LIST_EMAIL_DOMAINS'),
  asyncActionType('CREATE_EMAIL_DOMAIN'),
  asyncActionType('DELETE_EMAIL_DOMAIN'),
  'OPEN_MODAL',
  'CLOSE_MODAL',
);
