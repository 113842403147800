import { asyncActionType, createActionTypes } from '@gupy/front-commons';

const JobOverviewActionTypes = createActionTypes(
  'JobOverview',
  asyncActionType('INIT'),
  asyncActionType('CREATE_JOB_FROM_TEMPLATE'),
);

const initJobOverview = () => ({
  type: JobOverviewActionTypes.INIT,
});

const initJobOverviewSuccess = jobTemplates => {
  const templates = jobTemplates.map(t => ({
    label: t.name,
    value: t.id !== null ? t.id.toString() : null,
  }));

  const result = {
    type: JobOverviewActionTypes.INIT_SUCCESS,
    templates,
  };

  return result;
};

const createJobFromTemplate = (templateId, jobDetailsPath) => ({
  type: JobOverviewActionTypes.CREATE_JOB_FROM_TEMPLATE,
  templateId,
  jobDetailsPath,
});

export {
  initJobOverview,
  initJobOverviewSuccess,
  createJobFromTemplate,
  JobOverviewActionTypes,
};
