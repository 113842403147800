import { combineReducers } from 'redux';
import * as JobCancelActionTypes from './ActionTypes';

export const cancelReasons = (state = [], action) => {
  switch (action.type) {
    case JobCancelActionTypes.GET_CANCEL_REASONS_SUCCESS: {
      return [...action.payload];
    }
    case JobCancelActionTypes.GET_CANCEL_REASONS_FAIL:
      return [];
    default:
      return state;
  }
};

export const isLoading = (state = false, action) => {
  switch (action.type) {
    case JobCancelActionTypes.GET_CANCEL_REASONS_SUCCESS:
    case JobCancelActionTypes.GET_CANCEL_REASONS_FAIL:
    case JobCancelActionTypes.SEND_EMAIL_SUCCESS:
    case JobCancelActionTypes.SEND_EMAIL_FAIL:
    case JobCancelActionTypes.GET_BIND_SURVEY_CAREER_PAGES_SUCCESS:
    case JobCancelActionTypes.GET_BIND_SURVEY_CAREER_PAGES_FAIL: {
      return false;
    }
    case JobCancelActionTypes.GET_CANCEL_REASONS:
    case JobCancelActionTypes.SEND_EMAIL:
    case JobCancelActionTypes.GET_BIND_SURVEY_CAREER_PAGES:
      return true;
    default:
      return state;
  }
};

export const templateEmails = (state = [], action) => {
  switch (action.type) {
    case JobCancelActionTypes.GET_TEMPLATE_EMAILS_SUCCESS: {
      return [...action.payload];
    }
    case JobCancelActionTypes.GET_TEMPLATE_EMAILS_FAIL:
      return [];
    default:
      return state;
  }
};

export const applicationsToSendFeedback = (state = [], action) => {
  switch (action.type) {
    case JobCancelActionTypes.GET_APPLICATIONS_TO_SEND_EMAIL_SUCCESS: {
      return [...action.payload];
    }
    case JobCancelActionTypes.GET_APPLICATIONS_TO_SEND_EMAIL_FAIL:
      return [];
    default:
      return state;
  }
};

export const surveys = (state = [], action) => {
  switch (action.type) {
    case JobCancelActionTypes.GET_BIND_SURVEY_CAREER_PAGES_SUCCESS: {
      return [...action.payload];
    }
    case JobCancelActionTypes.GET_BIND_SURVEY_CAREER_PAGES_FAIL:
      return [];
    default:
      return state;
  }
};

export default combineReducers({
  cancelReasons,
  isLoading,
  templateEmails,
  applicationsToSendFeedback,
  surveys,
});
