import { Spinner } from '@gupy/design-system';
import React from 'react';
import rscChildAppStatusEnum from '../../../enums/RscChildAppStatusEnum';

export class ChildAppModel {
  constructor(
    {
      id,
      name,
      status,
      careerPages,
      clientId,
      clientKey,
      formValidationErrors,
      nameWithFormValidationErrors,
      careerPagesWithFormValidationErrors,
      hasDeleteEmail,
    },
    messages,
  ) {
    this.id = id;
    this.name = name;
    this.status = status;
    this.careerPages = careerPages;
    this.clientId = clientId;
    this.clientKey = clientKey;
    this.formValidationErrors = formValidationErrors;
    this.nameWithFormValidationErrors = nameWithFormValidationErrors;
    this.careerPagesWithFormValidationErrors =
      careerPagesWithFormValidationErrors;
    this.hasDeleteEmail = hasDeleteEmail;
    this.messages = messages;
  }

  isDeleteInLinkedinButtonVisible = () => {
    const statuses = {
      [rscChildAppStatusEnum.DESYNC_PREPARING]: true,
      [rscChildAppStatusEnum.DESYNC_PENDING]: true,
      [rscChildAppStatusEnum.DESYNC_SUCCESS]: true,
      [rscChildAppStatusEnum.DESYNC_FAIL]: true,
    };

    return statuses[this.status] || false;
  };

  isDeleteInLinkedinButtonEnabled = () => {
    const statuses = {
      [rscChildAppStatusEnum.DESYNC_SUCCESS]: true,
    };

    return statuses[this.status] || false;
  };

  isEditButtonEnabled = () => {
    const statuses = {
      [rscChildAppStatusEnum.READY_TO_SYNC_DATA]: true,
      [rscChildAppStatusEnum.SYNC_PENDING]: false,
      [rscChildAppStatusEnum.SYNC_SUCCESS]: true,
      [rscChildAppStatusEnum.SYNC_FAIL]: false,
    };

    return statuses[this.status] !== undefined ? statuses[this.status] : false;
  };

  isDeleteButtonEnabled = () => {
    const statuses = {
      [rscChildAppStatusEnum.READY_TO_SYNC_DATA]: false,
      [rscChildAppStatusEnum.SYNC_PENDING]: false,
      [rscChildAppStatusEnum.SYNC_SUCCESS]: true,
      [rscChildAppStatusEnum.SYNC_FAIL]: true,
    };

    return statuses[this.status] !== undefined ? statuses[this.status] : false;
  };

  isEditModeDefault = () => {
    const statuses = {
      [rscChildAppStatusEnum.READY_TO_SYNC_DATA]: true,
      [rscChildAppStatusEnum.SYNC_PENDING]: false,
      [rscChildAppStatusEnum.SYNC_SUCCESS]: false,
      [rscChildAppStatusEnum.SYNC_FAIL]: false,
    };

    return statuses[this.status] !== undefined ? statuses[this.status] : false;
  };

  getStatusLabel = () => {
    const statuses = {
      [rscChildAppStatusEnum.READY_TO_SYNC_DATA]:
        this.messages.rscIntegrationChildAppsStatusReadyToSyncData,
      [rscChildAppStatusEnum.SYNC_PENDING]:
        this.messages.rscIntegrationChildAppsStatusSyncPending,
      [rscChildAppStatusEnum.SYNC_SUCCESS]:
        this.messages.rscIntegrationChildAppsStatusSyncSuccess,
      [rscChildAppStatusEnum.SYNC_FAIL]:
        this.messages.rscIntegrationChildAppsStatusSyncFail,
      [rscChildAppStatusEnum.DESYNC_PREPARING]:
        this.messages.rscIntegrationChildAppsStatusDesyncPending,
      [rscChildAppStatusEnum.DESYNC_PENDING]:
        this.messages.rscIntegrationChildAppsStatusDesyncPending,
      [rscChildAppStatusEnum.DESYNC_SUCCESS]:
        this.messages.rscIntegrationChildAppsStatusDesyncSuccess,
      [rscChildAppStatusEnum.DESYNC_FAIL]:
        this.messages.rscIntegrationChildAppsStatusDesyncFail,
    };

    return statuses[this.status] !== undefined ? statuses[this.status] : '';
  };

  getStatusColor = () => {
    const statuses = {
      [rscChildAppStatusEnum.READY_TO_SYNC_DATA]: 'gray',
      [rscChildAppStatusEnum.SYNC_PENDING]: 'blue',
      [rscChildAppStatusEnum.SYNC_SUCCESS]: 'success',
      [rscChildAppStatusEnum.SYNC_FAIL]: 'danger',
      [rscChildAppStatusEnum.DESYNC_PREPARING]: 'blue',
      [rscChildAppStatusEnum.DESYNC_PENDING]: 'blue',
      [rscChildAppStatusEnum.DESYNC_SUCCESS]: 'success',
      [rscChildAppStatusEnum.DESYNC_FAIL]: 'danger',
    };

    return statuses[this.status] !== undefined ? statuses[this.status] : 'gray';
  };

  getStatusIcon = () => {
    const statuses = {
      [rscChildAppStatusEnum.SYNC_PENDING]: <Spinner size={14} />,
    };

    return statuses[this.status] !== undefined ? statuses[this.status] : null;
  };

  getTipData = () => {
    const statuses = {
      [rscChildAppStatusEnum.SYNC_FAIL]: {
        type: 'danger',
        showIcon: true,
        text: this.messages.rscIntegrationChildAppsStatusSyncFailTipText,
        actionText:
          this.messages.rscIntegrationChildAppsStatusSyncFailTipActionText,
        actionHandler: 'handleChildAppSyncRestart',
      },
      [rscChildAppStatusEnum.DESYNC_FAIL]: {
        type: 'danger',
        showIcon: true,
        text: this.messages.rscIntegrationChildAppsStatusDesyncFailTipText,
        actionText:
          this.messages.rscIntegrationChildAppsStatusDesyncFailTipActionText,
        actionHandler: 'handleChildAppDesyncRestart',
      },
    };

    return statuses[this.status] !== undefined ? statuses[this.status] : null;
  };

  shouldShowTip = () => !!this.getTipData();

  canStatusToBeUpdatedInServerSide = () => {
    const statuses = [rscChildAppStatusEnum.ENABLING_LINKEDIN_FEATURES];

    return statuses.includes(this.status);
  };

  getFormValidationErrorForField = fieldName =>
    this.formValidationErrors[fieldName] !== undefined
      ? this.formValidationErrors[fieldName]
      : null;

  hasFormValidationErrors = () =>
    Object.keys(this.formValidationErrors).length > 0;

  hasSetupStatus = () =>
    this.status === rscChildAppStatusEnum.READY_TO_CONNECT_WITH_LINKEDIN ||
    this.status === rscChildAppStatusEnum.ENABLING_LINKEDIN_FEATURES ||
    this.status === rscChildAppStatusEnum.READY_TO_SYNC_DATA;
}
