import PropTypes from 'prop-types';
import React, { Fragment, useRef } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { getMessages } from '../../Messages';

const propTypes = {
  intl: intlShape.isRequired,
  isOpen: PropTypes.func.isRequired,
  setTimeInsights: PropTypes.func,
  handleTypeInsights: PropTypes.func,
};
const typeInsights = {
  TTF: 'ttf',
  TTP: 'ttp',
  TTA: 'tta',
  TTH: 'tth',

  filterTypeInsights(chave) {
    return this[chave];
  },
};

const InsightsComponent = ({
  isOpen,
  handleInsightsClick,
  intl,
  setTimeInsights,
  handleTypeInsights,
}) => {
  const messagesEndRef = useRef(null);
  const message = getMessages(intl);

  const handleSendQuestionAboutJobPostingTime = async () => {
    const question = {
      sender: 'user-message_input',
      text: message.aboutJobPostingTime,
      whatInsights: message.questionAboutJobPostingTime,
    };
    handleInsightsClick([question]);

    const responseMessage = {
      sender: 'bot-message_response_insights',
      text: message.textResponseAboutJobPostingTime,
    };
    handleInsightsClick([question, responseMessage]);

    setTimeInsights(true);

    const metric = typeInsights.filterTypeInsights('TTP');
    handleTypeInsights(metric, question);
  };

  const handleSendQuestionFirstApplication = async () => {
    const question = {
      sender: 'user-message_input',
      text: message.firstApplication,
      whatInsights: message.questionFirstApplication,
    };
    handleInsightsClick([question]);

    const botMessage = {
      sender: 'bot-message_response_insights',
      text: message.textResponseFirstApplication,
    };
    handleInsightsClick([question, botMessage]);

    setTimeInsights(true);

    const metric = typeInsights.filterTypeInsights('TTA');
    handleTypeInsights(metric, question);
  };

  const handleSendQuestionHiringTimeVacancy = async () => {
    const question = {
      sender: 'user-message_input',
      text: message.hiringTimeVacancy,
      whatInsights: message.questionHiringTimeVacancy,
    };
    handleInsightsClick([question]);

    const botMessage = {
      sender: 'bot-message_response_insights',
      text: message.textResponseHiringTimeVacancy,
    };
    handleInsightsClick([question, botMessage]);

    setTimeInsights(true);

    const metric = typeInsights.filterTypeInsights('TTH');
    handleTypeInsights(metric, question);
  };

  const handleSendQuestionClosingTimeVacancy = async () => {
    const question = {
      sender: 'user-message_input',
      text: message.closingTimeVacancy,
      whatInsights: message.questionClosingTimeVacancy,
    };
    handleInsightsClick([question]);

    const botMessage = {
      sender: 'bot-message_response_insights',
      text: message.textResponseClosingTimeVacancy,
    };
    handleInsightsClick([question, botMessage]);

    setTimeInsights(true);

    const metric = typeInsights.filterTypeInsights('TTF');
    handleTypeInsights(metric, question);
  };

  return (
    isOpen && (
      <Fragment>
        <div className="message_suggestion">
          <button
            aria-label={message.aboutJobPostingTime}
            className="user-message_suggestion"
            onClick={handleSendQuestionAboutJobPostingTime}
          >
            {message.aboutJobPostingTime}
          </button>

          <button
            aria-label={message.firstApplication}
            className="user-message_suggestion"
            onClick={handleSendQuestionFirstApplication}
          >
            {message.firstApplication}
          </button>

          <button
            aria-label={message.hiringTimeVacancy}
            className="user-message_suggestion"
            onClick={handleSendQuestionHiringTimeVacancy}
          >
            {message.hiringTimeVacancy}
          </button>

          <button
            aria-label={message.closingTimeVacancy}
            className="user-message_suggestion"
            onClick={handleSendQuestionClosingTimeVacancy}
          >
            {message.closingTimeVacancy}
          </button>
        </div>
        <div ref={messagesEndRef} />
      </Fragment>
    )
  );
};
InsightsComponent.propTypes = propTypes;
export default injectIntl(InsightsComponent);
