import { GoogleAnalyticsHelper } from '@gupy/front-helpers';
import React from 'react';
import PrivacyDocument from './PrivacyDocument';
import {
  updatePrivacyConsent,
  updateTermsConsent,
} from './TermsAndPrivacyService';
import TermsDocument from './TermsDocument';

const getTermsAndPrivacy = ({ messages, openConfirmDialog }) => {
  const handleTermsConfirmClick = () => {
    GoogleAnalyticsHelper.sendEvent({
      category: 'TERMS_AND_PRIVACY',
      action: 'AGREE_TERMS',
    });
    updateTermsConsent();
  };

  const handlePrivacyConfirmClick = () => {
    GoogleAnalyticsHelper.sendEvent({
      category: 'TERMS_AND_PRIVACY',
      action: 'AGREE_PRIVACY',
    });
    updatePrivacyConsent();
  };

  return {
    terms: {
      label: messages.termsLabel,
      onClick: () => {
        GoogleAnalyticsHelper.sendEvent({
          category: 'TERMS_AND_PRIVACY',
          action: 'OPEN_TERMS',
        });
        openConfirmDialog({
          title: <strong>{messages.termsTitle}</strong>,
          message: <TermsDocument />,
          confirmButtonText: messages.termsConfirmButtonText,
          onConfirmClick: handleTermsConfirmClick,
        });
      },
    },
    privacy: {
      label: messages.privacyLabel,
      onClick: () => {
        GoogleAnalyticsHelper.sendEvent({
          category: 'TERMS_AND_PRIVACY',
          action: 'OPEN_PRIVACY',
        });
        openConfirmDialog({
          title: <strong>{messages.privacyTitle}</strong>,
          message: <PrivacyDocument />,
          confirmButtonText: messages.privacyConfirmButtonText,
          onConfirmClick: handlePrivacyConfirmClick,
        });
      },
    },
  };
};

export default getTermsAndPrivacy;
