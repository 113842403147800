import { asyncActionType, createActionTypes } from '@gupy/front-commons';
import PartnerTypes from '../../../../candidates/src/constants/PartnerTypes';
import JobPublicationType from '../../constants/Job/JobPublicationType/JobPublicationType';
import UrlHelper from '../../helpers/UrlHelper';
import JobPermissionsManager from '../../managers/JobPermissionsManager';

export const JobShareActionTypes = createActionTypes(
  'JobShare',
  'CHANGE_FORM',
  asyncActionType('SEND_EMAIL'),
  asyncActionType('INIT'),
  asyncActionType('PATCH_PUBLICATION_CHANNEL'),
  asyncActionType('GET_JOB_BOARDS'),
  asyncActionType('UPDATE_PUBLISHING_CHANNELS'),
);

const replaceMessageWithJobData = (
  htmlMessage,
  companyName,
  jobName,
  shareUrl,
) => {
  let message = htmlMessage.replace('{companyName}', companyName);
  message = message.replace('{jobName}', jobName);
  message = message.replace('{shareUrl}', shareUrl);
  message = message.replace('{shareUrl}', shareUrl);
  return message;
};

export const changeForm = modelForm => ({
  type: JobShareActionTypes.CHANGE_FORM,
  modelForm,
});

export const clearForm = () => ({
  type: JobShareActionTypes.CLEAR_FORM,
});

export const patchProfile = payload => ({
  type: JobShareActionTypes.PATCH_PUBLICATION_CHANNEL,
  payload,
});

export const sendEmail = (payload, messages) => ({
  type: JobShareActionTypes.SEND_EMAIL,
  payload,
  messages,
});

export const getJobBoards = () => ({
  type: JobShareActionTypes.GET_JOB_BOARDS,
});

export const init = (jobId, htmlMessage) => ({
  type: JobShareActionTypes.INIT,
  jobId,
  htmlMessage,
});

const getShareUrls = ({ careerPageSubdomain, jobId, jobPublicationType }) => {
  const buildJobUrlParams = {
    location: window.location,
    careerPageSubdomain,
    jobId,
  };
  let shareUrl = UrlHelper.buildJobUrlWithSource({
    ...buildJobUrlParams,
    source: PartnerTypes.gupyPublicPage,
  });

  if (jobPublicationType === JobPublicationType.internal) {
    shareUrl = UrlHelper.buildInternalJobUrl(buildJobUrlParams);
  }

  const shareUrlWithFacebookSource = UrlHelper.buildJobUrlWithSource({
    ...buildJobUrlParams,
    source: PartnerTypes.sharingFacebook,
  });
  const shareUrlWithLinkedinSource = UrlHelper.buildJobUrlWithSource({
    ...buildJobUrlParams,
    source: PartnerTypes.sharingLinkedin,
  });
  const shareUrlWithTwitterSource = UrlHelper.buildJobUrlWithSource({
    ...buildJobUrlParams,
    source: PartnerTypes.sharingTwitter,
  });
  const shareUrlWithWhatsappSource = UrlHelper.buildJobUrlWithSource({
    ...buildJobUrlParams,
    source: PartnerTypes.sharingWhatsapp,
  });

  return {
    shareUrl,
    shareUrlWithFacebookSource,
    shareUrlWithLinkedinSource,
    shareUrlWithTwitterSource,
    shareUrlWithWhatsappSource,
  };
};

export const initSuccess = (
  boards,
  profile,
  htmlMessage,
  job,
  careerPageSubdomain,
) => {
  const companyName = job.careerPage.name || profile.name;
  const {
    shareUrl,
    shareUrlWithFacebookSource,
    shareUrlWithLinkedinSource,
    shareUrlWithTwitterSource,
    shareUrlWithWhatsappSource,
  } = getShareUrls({
    careerPageSubdomain,
    jobId: job.id,
    jobPublicationType: job.publicationType,
  });

  return {
    type: JobShareActionTypes.INIT_SUCCESS,
    boards,
    profile,
    htmlMessage: replaceMessageWithJobData(
      htmlMessage,
      companyName,
      job.name,
      shareUrl,
    ),
    job: JobPermissionsManager.adaptJobPermissions(job),
    shareUrl,
    shareUrlWithFacebookSource,
    shareUrlWithLinkedinSource,
    shareUrlWithTwitterSource,
    shareUrlWithWhatsappSource,
  };
};

export const updatePublishingChannels = payload => ({
  type: JobShareActionTypes.UPDATE_PUBLISHING_CHANNELS,
  payload,
});
