import { call, put, takeLatest } from 'redux-saga/effects';
import Service from '../../services/ApiAccessPolicy/Service';
import * as actions from './Action';
import {
  API_ACCESS_POLICY_DELETE,
  API_ACCESS_POLICY_LIST,
  API_ACCESS_POLICY_LIST_PERMISSION,
  API_ACCESS_POLICY_LIST_ROUTES,
  POST_API_ACCESS_PERMISSIONS,
} from './ActionTypes';

export function* getApiAccessPolicyList() {
  try {
    const response = yield call(Service.getApiAccessPolicyList);
    const policies = response.body || [];
    yield put(actions.getApiAccessPolicyListSuccess({ policies }));
  } catch (error) {
    yield put(actions.getApiAccessPolicyListFail(error));
  }
}

export function* deleteApiAccessPolicy(action) {
  const { payload } = action;
  try {
    yield call(Service.deleteApiAccessPolicy, payload.id);
    yield put(actions.getApiAccessPolicyList());
    yield put(actions.deleteApiAccessPolicySuccess());
  } catch (error) {
    yield put(actions.deleteApiAccessPolicyFail(error));
  }
}

export function* getApiAccessRoutesList() {
  try {
    const response = yield call(Service.listApiAccessRoutes);
    const routes = response.body || [];
    yield put(actions.getApiPolicyRoutesListSuccess({ routes }));
  } catch (error) {
    yield put(actions.getApiPolicyRoutesListFail(error));
  }
}

export function* getApiAccessPermissionList() {
  try {
    const response = yield call(Service.listApiAccessRoutes);
    const permissions = response.body || [];
    yield put(actions.apiAccessPolicyListPermissionSuccess({ permissions }));
  } catch (error) {
    yield put(actions.apiAccessPolicyListPermissionFail(error));
  }
}

export function* postApiAccessPermissions(action) {
  const { payload } = action;
  try {
    const response = yield call(Service.postApiAccessPolicy, payload);
    const token = response.body.token || [];
    yield put(actions.postApiAccessPolicyPermissionsSuccess({ token }));
  } catch (error) {
    yield put(actions.postApiAccessPolicyPermissionsFail(error));
  }
}

function* ApiAccessPolicySaga() {
  yield takeLatest(API_ACCESS_POLICY_LIST, getApiAccessPolicyList);
  yield takeLatest(API_ACCESS_POLICY_DELETE, deleteApiAccessPolicy);
  yield takeLatest(API_ACCESS_POLICY_LIST_ROUTES, getApiAccessRoutesList);
  yield takeLatest(
    API_ACCESS_POLICY_LIST_PERMISSION,
    getApiAccessPermissionList,
  );
  yield takeLatest(POST_API_ACCESS_PERMISSIONS, postApiAccessPermissions);
}

export default ApiAccessPolicySaga;
