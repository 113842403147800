import { asyncActionType, createActionTypes } from '@gupy/front-commons';
import dayjs from 'dayjs';

const HiringInformationActionTypes = createActionTypes(
  'HiringInformation',
  'CHANGE_FORM',
  'OPEN_HIRING_INFORMATION_MODAL',
  'CLOSE_HIRING_INFORMATION_MODAL',
  asyncActionType('GET_JOB_OFFERS'),
  asyncActionType('GET_HIRING_TYPES'),
  asyncActionType('GET_VACANCY_CODES'),
);

const openHiringInformationModal = application => {
  const {
    hiringDate,
    jobVacancyCodeId,
    salaryCurrencyType,
    salary,
    hiringCustomFields,
    _metadata,
  } = application;
  const adaptedApplication = {
    ...hiringCustomFields,
    applicationId: application.id,
    candidate: { id: application.candidate.id },
    hiringDate: hiringDate ? dayjs(hiringDate) : undefined,
    hiringType: application.hiringType,
    jobVacancyCodeId: jobVacancyCodeId
      ? jobVacancyCodeId.toString()
      : undefined,
    salaryCurrencyType: salaryCurrencyType || undefined,
    salary: salary || undefined,
    metadata: _metadata || undefined,
  };

  return {
    type: HiringInformationActionTypes.OPEN_HIRING_INFORMATION_MODAL,
    payload: {
      application: adaptedApplication,
    },
  };
};

const closeHiringInformationModal = () => ({
  type: HiringInformationActionTypes.CLOSE_HIRING_INFORMATION_MODAL,
});

const changeForm = modelForm => ({
  type: HiringInformationActionTypes.CHANGE_FORM,
  modelForm,
});

const getHiringTypes = () => ({
  type: HiringInformationActionTypes.GET_HIRING_TYPES,
});

const getVacancyCodes = (jobId, applicationId) => ({
  type: HiringInformationActionTypes.GET_VACANCY_CODES,
  jobId,
  applicationId,
});

const getJobOffers = () => ({
  type: HiringInformationActionTypes.GET_JOB_OFFERS,
});

export {
  changeForm,
  getJobOffers,
  getHiringTypes,
  getVacancyCodes,
  openHiringInformationModal,
  closeHiringInformationModal,
  HiringInformationActionTypes,
};
