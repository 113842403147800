import { asyncActionType, createActionTypes } from '@gupy/front-commons';

const GupyBadgeFeedbackInsightsActionTypes = createActionTypes(
  'GupyBadgeFeedbackInsights',
  asyncActionType('INIT_GUPY_BADGE_FEEDBACK_JOB_INSIGHTS'),
  asyncActionType('GET_JOB_POSTINGS_WITHOUT_FEEDBACK'),
  asyncActionType('GET_INACTIVE_JOB_POSTINGS'),
  asyncActionType('NEXT_PAGE_JOB_POSTINGS_WITHOUT_FEEDBACK'),
  asyncActionType('NEXT_PAGE_INACTIVE_JOB_POSTINGS'),
);

const initGupyBadgeFeedbackJobInsights = () => ({
  type: GupyBadgeFeedbackInsightsActionTypes.INIT_GUPY_BADGE_FEEDBACK_JOB_INSIGHTS,
});

const getJobPostingsWithoutFeedback = () => ({
  type: GupyBadgeFeedbackInsightsActionTypes.GET_JOB_POSTINGS_WITHOUT_FEEDBACK,
});

const getInactiveJobPostings = () => ({
  type: GupyBadgeFeedbackInsightsActionTypes.GET_INACTIVE_JOB_POSTINGS,
});

const nextPageJobPostingsWithoutFeedback = page => ({
  type: GupyBadgeFeedbackInsightsActionTypes.NEXT_PAGE_JOB_POSTINGS_WITHOUT_FEEDBACK,
  page,
});

const nextPageInactiveJobPostings = page => ({
  type: GupyBadgeFeedbackInsightsActionTypes.NEXT_PAGE_INACTIVE_JOB_POSTINGS,
  page,
});

export {
  GupyBadgeFeedbackInsightsActionTypes,
  initGupyBadgeFeedbackJobInsights,
  getJobPostingsWithoutFeedback,
  getInactiveJobPostings,
  nextPageJobPostingsWithoutFeedback,
  nextPageInactiveJobPostings,
};
