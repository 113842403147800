import {
  deleteJobTemplate,
  initJobTemplates,
  JobTemplatesActionTypes,
  updateSearch,
} from './JobTemplatesAction';
import JobTemplatesContainer from './JobTemplatesContainer';
import JobTemplatesReducer from './JobTemplatesReducer';
import JobTemplatesSaga from './JobTemplatesSaga';
import JobTemplatesService from './JobTemplatesService';

const JobTemplatesAction = {
  updateSearch,
  initJobTemplates,
  deleteJobTemplate,
  JobTemplatesActionTypes,
};

export {
  JobTemplatesContainer,
  JobTemplatesAction,
  JobTemplatesReducer,
  JobTemplatesSaga,
  JobTemplatesService,
};
