import { combineReducers } from 'redux';
import * as ActionTypes from './ActionTypes';
import {
  checkPermission,
  groupPermissions,
  isAllPermissionsChecked,
  toggleCollapse,
  toggleCollapseVersion,
} from './adapters';

const apiAccessPolicyInitialState = {
  policies: [],
  isFetchingApiAccessPolicy: false,
  permissionToDelete: null,
};
const apiAccessRoutesInitialState = {
  routes: [],
  isFetchingApiAccessRoutes: false,
};
const apiAccessPermissionInitialState = {
  permissions: {},
  isFetchingApiAccessPermissions: false,
  token: null,
  isAllSelected: false,
  description: '',
};
export const apiAccessPolicy = (
  state = apiAccessPolicyInitialState,
  action,
) => {
  switch (action.type) {
    case ActionTypes.API_ACCESS_POLICY_LIST:
      return {
        ...state,
        isFetchingApiAccessPolicy: true,
      };
    case ActionTypes.API_ACCESS_POLICY_LIST_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isFetchingApiAccessPolicy: false,
      };
    }
    case ActionTypes.API_ACCESS_POLICY_LIST_FAIL:
      return {
        ...state,
        error: action.error,
        isFetchingApiAccessPolicy: false,
      };
    case ActionTypes.API_ACCESS_POLICY_DELETE_MODAL:
      return {
        ...state,
        ...action.payload,
      };
    case ActionTypes.API_ACCESS_POLICY_DELETE_SUCCESS:
      return {
        ...state,
        permissionToDelete: null,
      };
    default:
      return state;
  }
};

export const apiAccessRoutes = (
  state = apiAccessRoutesInitialState,
  action,
) => {
  switch (action.type) {
    case ActionTypes.API_ACCESS_POLICY_LIST_ROUTES:
      return {
        ...state,
        isFetchingApiAccessRoutes: true,
      };
    case ActionTypes.API_ACCESS_POLICY_LIST_ROUTES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetchingApiAccessRoutes: false,
      };
    case ActionTypes.API_ACCESS_POLICY_LIST_ROUTES_FAIL:
      return {
        ...state,
        error: action.error,
        isFetchingApiAccessRoutes: false,
      };
    default:
      return state;
  }
};

export const apiAccessPermissions = (
  state = apiAccessPermissionInitialState,
  action,
) => {
  switch (action.type) {
    case ActionTypes.API_ACCESS_POLICY_LIST_PERMISSION:
      return {
        ...state,
        isFetchingApiAccessPermissions: true,
      };
    case ActionTypes.API_ACCESS_POLICY_LIST_PERMISSION_SUCCESS:
      return {
        ...state,
        permissions: groupPermissions(action.payload.permissions),
        isFetchingApiAccessPermissions: false,
      };
    case ActionTypes.API_ACCESS_POLICY_LIST_PERMISSION_FAIL:
      return {
        ...state,
        error: action.error,
        isFetchingApiAccessPermissions: false,
      };
    case ActionTypes.API_ACCESS_POLICY_TOGGLE_PERMISSIONS:
      return {
        ...state,
        permissions: checkPermission(
          state.permissions,
          {
            version: action.payload.permission.version,
            group: action.payload.permission.group,
            pattern: action.payload.permission.pattern,
            permission: action.payload.permission.permission,
          },
          action.payload.checked,
        ),
      };
    case ActionTypes.API_ACCESS_POLICY_TOGGLE_HEADER_PERMISSIONS:
      return {
        ...state,
        ...action.payload,
      };
    case ActionTypes.API_ACCESS_POLICY_SET_POLICY:
      return {
        ...state,
        ...action.payload,
        isAllSelected: isAllPermissionsChecked(state.permissions),
      };
    case ActionTypes.API_ACCESS_POLICY_TOGGLE_CATEGORY:
      return {
        ...state,
        permissions: toggleCollapse(
          state.permissions,
          action.payload.version,
          action.payload.categoryName,
        ),
      };
    case ActionTypes.API_ACCESS_POLICY_TOGGLE_VERSIONS:
      return {
        ...state,
        permissions: toggleCollapseVersion(
          state.permissions,
          action.payload.version,
        ),
      };
    case ActionTypes.POST_API_ACCESS_PERMISSIONS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        description: '',
      };
    case ActionTypes.POST_API_ACCESS_PERMISSIONS_FAIL:
      return {
        ...state,
        token: null,
      };
    case ActionTypes.API_ACCESS_PERMISSIONS_CLEAR:
      return {
        ...state,
        token: null,
        description: '',
      };
    case ActionTypes.API_ACCESS_PERMISSIONS_UPDATE_DESCRIPTION:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default combineReducers({
  apiAccessPolicy,
  apiAccessRoutes,
  apiAccessPermissions,
});
