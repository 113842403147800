import { put, takeLatest } from 'redux-saga/effects';
import CustomTestServices from '../../../../../external/CustomTest/redux/TestCorrection/TestCorrectionServices';
import JobService from '../../../../../services/Job/JobService';
import {
  CandidateTestsActionTypes,
  getApplicationDetailsSuccess,
  getCustomTestsResultsSuccess,
  getProfileTestResultSuccess,
  initCandidateTestsSuccess,
} from './CandidateTestsAction';

function* initCandidateTests(action) {
  const { jobId, application, jobSteps } = action;

  try {
    const testsResponse = yield JobService.getCandidateTests(
      jobId,
      application.id,
    );

    const { video, providerTestsSummary } = testsResponse.body;

    const hasExternalProviderTest = jobSteps.some(
      step => !!step.testProviderId,
    );
    let multipleTestsResult = null;
    if (hasExternalProviderTest) {
      try {
        const response = yield JobService.getProviderTestResult(
          jobId,
          application.id,
        );
        multipleTestsResult = response.body;
      } catch (error) {
        multipleTestsResult = null;
      }
    }

    yield put(
      initCandidateTestsSuccess({
        video,
        jobSteps,
        multipleTestsResult,
        providerTestsSummary,
        applicationId: application.id,
      }),
    );
  } catch (error) {
    yield put({
      type: CandidateTestsActionTypes.INIT_CANDIDATE_TESTS_FAIL,
      error,
    });
  }
}

function* getProfileTestResult(action) {
  const { jobId, application, jobSteps } = action;

  try {
    const profileTestResponse = yield JobService.getProfileTestResult(
      jobId,
      application.id,
    );
    yield put(
      getProfileTestResultSuccess({
        profileTestResult: profileTestResponse.body,
        jobSteps,
      }),
    );
  } catch (error) {
    yield put({
      type: CandidateTestsActionTypes.GET_PROFILE_TEST_RESULT_FAIL,
    });
  }
}

function* getCustomTestsResults(action) {
  const { jobId, application } = action;

  try {
    const customTestsResponse = yield CustomTestServices.list(
      application.id,
      jobId,
    );
    yield put(
      getCustomTestsResultsSuccess({
        customTestsResult: customTestsResponse.body.data,
        application,
      }),
    );
  } catch (error) {
    yield put({
      type: CandidateTestsActionTypes.GET_CUSTOM_TESTS_RESULTS_FAIL,
      error,
    });
  }
}

function* getApplicationDetails(action) {
  try {
    const { jobId, applicationId } = action;
    const applicationResponse = yield JobService.getApplication(
      jobId,
      applicationId,
      {},
      null,
    );
    const { application } = applicationResponse.body;
    yield put(getApplicationDetailsSuccess(application));
  } catch (error) {
    yield put({
      type: CandidateTestsActionTypes.GET_APPLICATION_DETAILS_FAIL,
      error,
    });
  }
}

function* CandidateTestsSaga() {
  yield takeLatest(
    CandidateTestsActionTypes.INIT_CANDIDATE_TESTS,
    initCandidateTests,
  );
  yield takeLatest(
    CandidateTestsActionTypes.INIT_CANDIDATE_TESTS,
    getCustomTestsResults,
  );
  yield takeLatest(
    CandidateTestsActionTypes.GET_PROFILE_TEST_RESULT,
    getProfileTestResult,
  );
  yield takeLatest(
    CandidateTestsActionTypes.GET_APPLICATION_DETAILS,
    getApplicationDetails,
  );
}

export default CandidateTestsSaga;
