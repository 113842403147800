import { useFlag } from '@unleash/proxy-client-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import featuresFlagsEnum from '../Authentication/FeaturesFlagsEnum';
import {
  getCompanyUserDiversityLegalTerm,
  saveCompanyUserDiversityLegalTerm,
} from './Actions';
import { steps } from './constants';
import { getMessages } from './Messages';
import DiversityLegalTermsModal from './modal/Modal';
import { selectUserConsentPermission } from './Selectors';

let isdiversityCompaniesEnable;

const propTypes = {
  intl: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  userConsentPermission: PropTypes.shape({
    isOwner: PropTypes.bool,
    hasPermissions: PropTypes.bool,
  }).isRequired,
  getCompanyUserDiversityLegalTermAction: PropTypes.func.isRequired,
  saveCompanyUserDiversityLegalTermAction: PropTypes.func.isRequired,
};

const DiversityLegalTermsContainer = ({
  intl,
  isOpen,
  userConsentPermission,
  getCompanyUserDiversityLegalTermAction,
  saveCompanyUserDiversityLegalTermAction,
}) => {
  isdiversityCompaniesEnable = useFlag(featuresFlagsEnum.diversityCompanies);
  const initialStep = userConsentPermission.isOwner
    ? steps.intro
    : steps.signedTerm;
  const messages = getMessages(intl);

  useEffect(() => {
    getCompanyUserDiversityLegalTermAction();
  }, []);

  const handleModalClose = () => {
    saveCompanyUserDiversityLegalTermAction();
  };

  if (isdiversityCompaniesEnable && isOpen) {
    return (
      <DiversityLegalTermsModal
        messages={messages}
        locale={intl.locale}
        initialStep={initialStep}
        onClose={handleModalClose}
      />
    );
  }

  return null;
};

DiversityLegalTermsContainer.propTypes = propTypes;

const mapStateToProps = state => ({
  userConsentPermission: selectUserConsentPermission(
    state,
    isdiversityCompaniesEnable,
  ),
  isOpen: state.reducers.DiversityLegalTerms.userShouldView,
});

const mapDispatchToProps = dispatch => ({
  getCompanyUserDiversityLegalTermAction: () =>
    dispatch(getCompanyUserDiversityLegalTerm()),
  saveCompanyUserDiversityLegalTermAction: () =>
    dispatch(saveCompanyUserDiversityLegalTerm()),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DiversityLegalTermsContainer),
);
