import { call, put, takeLatest } from 'redux-saga/effects';
import JobApplicationActionTypes from '../../constants/Job/JobApplication/JobApplicationActionTypes';
import InterviewEventService from '../../services/InterviewEvent/InterviewEventService';
import {
  clearInterviewEventList,
  getByInterviewerIdFail,
  getByInterviewerIdSuccess,
  InterviewEventActionTypes,
  startFetchingInterviewEventList,
} from './InterviewEventAction';

export function* getByInterviewerId(action) {
  try {
    yield put(startFetchingInterviewEventList());
    const { interviewerId, timezone, timetable } = action;
    const response = yield call(InterviewEventService.getByInterviewerId, {
      interviewerId,
      timezone,
      timetable,
    });
    const interviewEvents = response.body || [];

    yield put(getByInterviewerIdSuccess(interviewEvents));
  } catch (error) {
    if (error.statusCode === 404) {
      yield put(clearInterviewEventList());
    } else {
      yield put(getByInterviewerIdFail());
    }
  }
}

function* InterviewEventSaga() {
  yield takeLatest(
    InterviewEventActionTypes.GET_BY_INTERVIEWER_ID,
    getByInterviewerId,
  );
  yield takeLatest(
    JobApplicationActionTypes.CURRICULUM_CHANGE_APPLICATION_STEP_SUCCESS,
    getByInterviewerId,
  );
}

export default InterviewEventSaga;
