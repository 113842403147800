import { combineReducers } from 'redux';
import { JobShareActionTypes } from './Actions';

const initialEmailForm = {
  from: '',
  destinations: '',
  subject: '',
  htmlMessage: '',
};

const isSending = (state = false, action) => {
  switch (action.type) {
    case JobShareActionTypes.SEND_EMAIL:
    case JobShareActionTypes.UPDATE_PUBLISHING_CHANNELS:
      return true;
    case JobShareActionTypes.UPDATE_PUBLISHING_CHANNELS_SUCCESS:
    case JobShareActionTypes.UPDATE_PUBLISHING_CHANNELS_FAIL:
    case JobShareActionTypes.SEND_EMAIL_SUCCESS:
    case JobShareActionTypes.SEND_EMAIL_FAIL:
      return false;
    default:
      return state;
  }
};

export const jobBoards = (state = [], action) => {
  switch (action.type) {
    case JobShareActionTypes.INIT_SUCCESS:
    case JobShareActionTypes.GET_JOB_BOARDS_SUCCESS:
      return action.boards;
    default:
      return state;
  }
};

export const profile = (state = {}, action) => {
  switch (action.type) {
    case JobShareActionTypes.INIT_SUCCESS:
      return action.profile;
    default:
      return state;
  }
};

export const job = (state = {}, action) => {
  switch (action.type) {
    case JobShareActionTypes.INIT:
      return {};
    case JobShareActionTypes.INIT_SUCCESS:
      return action.job;
    case JobShareActionTypes.UPDATE_PUBLISHING_CHANNELS_SUCCESS:
      return { ...state, jobBoards: action.jobBoards };
    default:
      return state;
  }
};

export const isLoading = (state = false, action) => {
  switch (action.type) {
    case JobShareActionTypes.INIT:
      return true;
    case JobShareActionTypes.INIT_SUCCESS:
    case JobShareActionTypes.INIT_FAIL:
      return false;
    default:
      return state;
  }
};

export const emailForm = (state = initialEmailForm, action) => {
  switch (action.type) {
    case JobShareActionTypes.SEND_EMAIL_SUCCESS:
    case JobShareActionTypes.INIT:
      return initialEmailForm;
    case JobShareActionTypes.CHANGE_FORM:
      return Object.assign({}, state, action.modelForm);
    case JobShareActionTypes.INIT_SUCCESS:
      return Object.assign({}, state, { htmlMessage: action.htmlMessage });
    default:
      return state;
  }
};

const validation = (state = {}, action) => {
  switch (action.type) {
    case JobShareActionTypes.SEND_EMAIL:
    case JobShareActionTypes.UPDATE_PUBLISHING_CHANNELS_SUCCESS:
    case JobShareActionTypes.INIT:
      return {};
    case JobShareActionTypes.SEND_EMAIL_FAIL:
      return action.validation;
    default:
      return state;
  }
};

export const shareUrl = (state = {}, action) => {
  switch (action.type) {
    case JobShareActionTypes.INIT:
    case JobShareActionTypes.INIT_FAIL:
      return {};
    case JobShareActionTypes.INIT_SUCCESS:
      return {
        default: action.shareUrl,
        withFacebookSource: action.shareUrlWithFacebookSource,
        withLinkedinSource: action.shareUrlWithLinkedinSource,
        withTwitterSource: action.shareUrlWithTwitterSource,
        withWhatsappSource: action.shareUrlWithWhatsappSource,
      };
    default:
      return state;
  }
};

export default combineReducers({
  emailForm,
  isSending,
  validation,
  jobBoards,
  profile,
  isLoading,
  job,
  shareUrl,
});
