import { buildToast, SchemaFormHelper, ToastTypes } from '@gupy/front-commons';
import * as types from './ActionTypes';
import JobCustomFormTemplateModalValidator from './components/JobCustomFormTemplateModalValidator';

const fieldTypes = {
  shortText: 'text',
  number: 'number',
  boolean: 'boolean',
  date: 'date',
  time: 'time',
  select: 'select',
  multiSelect: 'multiSelect',
  attachment: 'attachment',
  conditional: 'conditional',
};

const getSchema = () => ({
  type: types.GET,
});

const saveCompanySchema = ({ schema, successMessage, failMessage }) => ({
  type: types.SAVE_COMPANY_SCHEMA,
  schema,
  successMessage,
  failMessage,
});

const addFieldAndGetSchema = ({ schema, fieldToAdd }) => {
  const schemaFormHelper = new SchemaFormHelper(schema);

  switch (fieldToAdd.fieldType) {
    case fieldTypes.shortText:
      schemaFormHelper.upsertTextField({
        key: fieldToAdd.fieldKey,
        title: fieldToAdd.fieldName,
        required: fieldToAdd.fieldMandatory,
      });
      break;
    case fieldTypes.number:
      schemaFormHelper.upsertNumberField({
        key: fieldToAdd.fieldKey,
        title: fieldToAdd.fieldName,
        required: fieldToAdd.fieldMandatory,
      });
      break;
    case fieldTypes.boolean:
      schemaFormHelper.upsertBooleanField({
        key: fieldToAdd.fieldKey,
        title: fieldToAdd.fieldName,
        required: fieldToAdd.fieldMandatory,
      });
      break;
    case fieldTypes.date:
      schemaFormHelper.upsertDateField({
        key: fieldToAdd.fieldKey,
        title: fieldToAdd.fieldName,
        required: fieldToAdd.fieldMandatory,
      });
      break;
    case fieldTypes.time:
      schemaFormHelper.upsertTimeField({
        key: fieldToAdd.fieldKey,
        title: fieldToAdd.fieldName,
        required: fieldToAdd.fieldMandatory,
      });
      break;
    case fieldTypes.select:
      schemaFormHelper.upsertSelectField({
        key: fieldToAdd.fieldKey,
        title: fieldToAdd.fieldName,
        required: fieldToAdd.fieldMandatory,
        items: fieldToAdd.fieldSelectItems,
      });
      break;
    case fieldTypes.multiSelect:
      schemaFormHelper.upsertMultiSelectField({
        key: fieldToAdd.fieldKey,
        title: fieldToAdd.fieldName,
        required: fieldToAdd.fieldMandatory,
        items: fieldToAdd.fieldSelectItems,
      });
      break;
    case fieldTypes.attachment:
      schemaFormHelper.upsertAttachmentField({
        key: fieldToAdd.fieldKey,
        title: fieldToAdd.fieldName,
        required: fieldToAdd.fieldMandatory,
      });
      break;
    case fieldTypes.conditional:
      schemaFormHelper.upsertConditionalField({
        key: fieldToAdd.fieldKey,
        title: fieldToAdd.fieldName,
        required: fieldToAdd.fieldMandatory,
        items: fieldToAdd.fieldSelectItems,
        children: fieldToAdd.children,
      });
      break;
    default:
      throw new Error('Type not mapped');
  }

  return schemaFormHelper.toSchema();
};

const saveCompanySchemaSuccess = successMessage => ({
  type: types.SAVE_COMPANY_SCHEMA_SUCCESS,
  toast: buildToast(successMessage, ToastTypes.success, { autoClose: 5000 }),
});

const saveCompanySchemaFail = failMessage => ({
  type: types.SAVE_COMPANY_SCHEMA_FAIL,
  toast: buildToast(failMessage, ToastTypes.error, { autoClose: 5000 }),
});

const openModal = field => ({
  type: types.OPEN_MODAL,
  field: field || null,
});

const closeModal = () => ({
  type: types.CLOSE_MODAL,
});

const saveCompanySchemaMemory = ({ schema }) => ({
  type: types.SAVE_COMPANY_SCHEMA_MEMORY,
  schema,
});

const saveCompanyItem = ({ items }) => ({
  type: types.SAVE_COMPANY_ITEMS,
  items,
});

const addFieldToCompanySchemaMemory = ({ schema, fieldToAdd }) => {
  const form = new JobCustomFormTemplateModalValidator();
  form.populate(fieldToAdd);
  const validation = form.validateModel();

  return validation
    ? {
        type: types.SAVE_COMPANY_SCHEMA_INVALID,
        validation,
      }
    : saveCompanySchemaMemory({
        schema: addFieldAndGetSchema({ schema, fieldToAdd }),
      });
};

const deleteCompanySchemaFieldMemory = ({ schema, field }) => {
  const schemaFormHelper = new SchemaFormHelper(schema);
  schemaFormHelper.deleteField(field);
  return saveCompanySchemaMemory({
    schema: schemaFormHelper.toSchema(),
  });
};

export {
  getSchema,
  addFieldToCompanySchemaMemory,
  openModal,
  closeModal,
  saveCompanySchema,
  saveCompanySchemaSuccess,
  saveCompanySchemaFail,
  deleteCompanySchemaFieldMemory,
  saveCompanyItem,
};
