import { asyncActionType, createActionTypes } from '@gupy/front-commons';

const JobSummaryActionTypes = createActionTypes(
  'JobSummary',
  asyncActionType('ADD_APPROVER'),
  asyncActionType('CLOSE_JOB'),
  asyncActionType('GET_ALL_APPROVERS'),
  asyncActionType('GET_ALL_JOBS_BY_APPROVER_ID'),
  asyncActionType('INIT_JOB_SUMMARY'),
  asyncActionType('PATCH_APPROVE_JOB'),
  asyncActionType('PATCH_DISAPPROVE_JOB'),
  asyncActionType('REMOVE_APPROVER_BULK'),
  asyncActionType('REMOVE_APPROVER'),
  asyncActionType('REPLACE_APPROVER_BULK'),
  asyncActionType('REPLACE_APPROVER'),
  'CHANGE_APPROVE_JOB_FORM_MODEL',
  'OPEN_APPROVE_JOB_MODAL',
  'CLOSE_APPROVE_JOB_MODAL',
  'CHANGE_DISAPPROVE_JOB_FORM_MODEL',
  'OPEN_DISAPPROVE_JOB_MODAL',
  'CLOSE_DISAPPROVE_JOB_MODAL',
);

export { JobSummaryActionTypes };
