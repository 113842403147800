import { Dialog, P } from '@gupy/design-system';
import PropTypes from 'prop-types';
import React from 'react';
import './TokenManagementWithoutOtherAccessPermissionsDialog.scss';

const propTypes = {
  messages: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

const TokenManagementWithoutOtherAccessPermissionsDialog = ({
  messages,
  isModalOpen,
  handleCancel,
}) => (
  <Dialog
    id="token-management-without-other-access-permissions-dialog"
    maxWidth="xs"
    onClose={handleCancel}
    open={isModalOpen}
    className="token-management-without-other-access-permissions-dialog"
    displayBlockButtons
    footer={{
      primaryButton: {
        label:
          messages.tokenManagementWithoutOtherAccessPermissionsConfirmButton,
        action: handleCancel,
      },
    }}
  >
    <p className="token-management-without-other-access-permissions-dialog__title">
      {messages.tokenManagementWithoutOtherAccessPermissionsTitle}
    </p>

    <p className="token-management-without-other-access-permissions-dialog__paragraph">
      {messages.tokenManagementWithoutOtherAccessPermissionsParagraph}
    </p>

    <P>{messages.tokenManagementWithoutOtherAccessPermissionsParagraphFinal}</P>
  </Dialog>
);

TokenManagementWithoutOtherAccessPermissionsDialog.propTypes = propTypes;

export default TokenManagementWithoutOtherAccessPermissionsDialog;
