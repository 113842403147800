import { Button, Dropdown, TextArea, Tip } from '@gupy/design-system';
import CardCollapse from '@gupy/design-system/CardCollapse/CardCollapse';
import { JobDeletionReason } from '@gupy/enums';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { StepEnum } from '../JobDeletionAfterPublicationModal';

const propTypes = {
  messages: PropTypes.object.isRequired,
  setStep: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  selectedReason: PropTypes.string,
  deleteReasonText: PropTypes.string.isRequired,
  setSelectedReason: PropTypes.func.isRequired,
  setDeleteReasonText: PropTypes.func.isRequired,
  hasReason: PropTypes.bool.isRequired,
};

const ReasonStep = ({
  messages,
  setStep,
  expanded,
  selectedReason,
  deleteReasonText,
  setSelectedReason,
  setDeleteReasonText,
  hasReason,
}) => {
  const jobDeletionReasons = [
    { label: messages.testJobReason, value: JobDeletionReason.testJob },
    {
      label: messages.jobWithErrorReason,
      value: JobDeletionReason.jobWithError,
    },
    {
      label: messages.strategicDecisionReason,
      value: JobDeletionReason.strategicDecision,
    },
    { label: messages.oldJobReason, value: JobDeletionReason.oldJob },
    { label: messages.otherReason, value: JobDeletionReason.other },
  ];

  const handleChange = () => {
    setStep(StepEnum.reason);
  };

  const proceedToNextStep = () => {
    setStep(StepEnum.feedback);
  };

  const handleCurrentColor = () => {
    const gupyColorSteel = '#8C8C8C';
    const colorSuccess = '#13ce8b';
    if (hasReason) {
      return colorSuccess;
    }
    return gupyColorSteel;
  };

  return (
    <div className="job-deletion-reason">
      <CardCollapse
        id="job-deletion-reason-expansion-panel"
        title={messages.reasonStepTitle}
        description=""
        icon="CheckCircle"
        iconColor={handleCurrentColor()}
        expanded={expanded}
        onCollapse={handleChange}
      >
        <div className="job-deletion-reason__container">
          <Dropdown
            id="job-deletion-reason-dropdown"
            data-testid="job-deletion-reason-dropdown"
            label={messages.chooseReasonLabel}
            options={jobDeletionReasons}
            selectedOptionValue={selectedReason}
            nonSelectText={messages.chooseReasonPlaceholder}
            onChange={value => {
              setSelectedReason(value);
            }}
            required
            clearBtnAriaLabel={messages.clearSelectedReason}
          />
          {selectedReason === JobDeletionReason.other && (
            <div className="job-deletion-reason__textarea-container">
              <TextArea
                id="job-deletion-reason-textarea"
                name="job-deletion-reason-textarea"
                data-testid="job-deletion-reason-textarea"
                className="job-deletion-reason__textarea"
                value={deleteReasonText}
                label={messages.writeReasonLabel}
                onChange={event => setDeleteReasonText(event.target.value)}
                placeholder={messages.describeReasonPlaceholder}
                required
              />
            </div>
          )}
          <Tip
            type="warning"
            className="job-deletion__tip"
            text={
              <FormattedHTMLMessage
                id="job-deletion-reason-tip"
                defaultMessage="<strong>Atenção</strong>: definir o motivo de exclusão é importante para futuras análises sobre as vagas excluídas pela empresa."
              />
            }
            showIcon
          />
          <Button
            size="md"
            type="outlined"
            onClick={proceedToNextStep}
            className="job-deletion__button"
            disabled={!hasReason}
            data-testid="job-deletion-reason-button"
          >
            {messages.reasonProceedButton}
          </Button>
        </div>
      </CardCollapse>
    </div>
  );
};

ReasonStep.propTypes = propTypes;

export default ReasonStep;
