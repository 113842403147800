import { defineMessages } from 'react-intl';

const customTestQuestionTypesEnum = {
  text: 'text',
  textarea: 'textarea',
  choices: 'choices',
  multipleChoices: 'multipleChoices',
};

const messages = defineMessages({
  text: {
    id: 'short_aswer',
    defaultMessage: 'Resposta curta',
  },
  textarea: {
    id: 'textarea',
    defaultMessage: 'Parágrafo',
  },
  choices: {
    id: 'multiple_choices',
    defaultMessage: 'Múltipla escolha',
  },
  multipleChoices: {
    id: 'select_box',
    defaultMessage: 'Caixa de seleção',
  },
});

const getQuestionTypesLabel = intl => ({
  text: intl.formatMessage(messages.text),
  textarea: intl.formatMessage(messages.textarea),
  choices: intl.formatMessage(messages.choices),
  multipleChoices: intl.formatMessage(messages.multipleChoices),
});

const getCustomTestQuestionTypesData = intl =>
  Object.keys(customTestQuestionTypesEnum).map(key => ({
    value: customTestQuestionTypesEnum[key],
    label: getQuestionTypesLabel(intl)[key],
  }));

export {
  customTestQuestionTypesEnum,
  getQuestionTypesLabel,
  getCustomTestQuestionTypesData,
};
