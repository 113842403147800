import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from './ActionTypes';
import * as service from './Service';

export function* saveFormSchema(action) {
  const { payload } = action;

  try {
    const result = yield call(service.saveSchemaForm, payload);
    yield put({
      type: types.SAVE_CUSTOM_FORM_SCHEMA_SUCCESS,
      payload: result,
    });
  } catch (err) {
    yield put({
      type: types.SAVE_CUSTOM_FORM_SCHEMA_FAILURE,
    });
  }
}

function* getCustomFormList(action) {
  const { payload } = action;
  try {
    const formList = yield call(
      service.getCustomFormList,
      payload.jobId,
      payload.jobStepId,
    );
    yield put({
      type: types.GET_CUSTOM_FORM_LIST_SCHEMA_SUCCESS,
      payload: formList.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_CUSTOM_FORM_LIST_SCHEMA_FAILURE,
      payload: error,
    });
  }
}

function* CustomFormsSaga() {
  yield takeLatest(types.SAVE_CUSTOM_FORM_SCHEMA, saveFormSchema);
  yield takeLatest(types.GET_CUSTOM_FORM_LIST_SCHEMA, getCustomFormList);
}

export default CustomFormsSaga;
