import api from '../../api/DarthVaderClient';

const path = '/job-offer/company';

export default {
  post({ candidate, job, ...payload }) {
    return api.post(`${path}/job/${job.id}/candidate/${candidate.id}`, payload);
  },

  get({ candidate, job }) {
    return api.get(`${path}/job/${job.id}/candidate/${candidate.id}`);
  },

  getByJobId({ jobId }) {
    return api.get(`${path}/job/${jobId}`);
  },

  delete(id) {
    return api.delete(`/job-offer/${id}/company`);
  },

  cancel(id) {
    return api.patch(`/job-offer/${id}/cancel`);
  },

  send({ offerId, senderType, bcc }) {
    const payload = {
      offerId,
      senderType,
      ...(bcc !== undefined && bcc !== '' && { bcc }),
    };
    return api.post(`/job-offer/${offerId}/send`, payload);
  },
};
