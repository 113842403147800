import { inboundClient as api } from '../../api/InboundClient';

export const getSegmentedLists = async () => {
  const res = await api.get('/api/segmented-list?limit=1000');
  return res.body;
};

export const postTalentsToLists = async data => {
  const res = await api.post('/api/segmented-list/contacts/add', { ...data });
  return res.body;
};
