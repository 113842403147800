export const CategoryGAEnum = {
  jobApplicationsBackToJobs: 'JOB_APPLICATIONS_BACK_TO_JOBS',
  jobApplicationsToolbar: 'JOB_APPLICATIONS_TOOLBAR',
  jobApplicationsAddCandidate: 'JOB_APPLICATIONS_ADD_CANDIDATE',
  jobApplicationsCandidatesTable: 'JOB_APPLICATIONS_CANDIDATES_TABLE',
  jobApplicationsHiringData: 'JOB_APPLICATIONS_HIRING_DATA',
  jobApplicationsActionBar: 'JOB_APPLICATIONS_ACTION_BAR',
  jobApplicationsAddTag: 'JOB_APPLICATIONS_ADD_TAG',
  jobApplicationsSendEmail: 'JOB_APPLICATIONS_SEND_EMAIL',
  jobApplicationsRelocateCandidate: 'JOB_APPLICATIONS_RELOCATE_CANDIDATE',
  jobApplicationsSendWppBusiness: 'JOB_APPLICATIONS_SEND_WPP_BUSINESS',
  jobApplicationsSendToCrm: 'JOB_APPLICATIONS_SEND_TO_CRM',
  jobApplicationsDisqualifyCandidate: 'JOB_APPLICATIONS_DISQUALIFY_CANDIDATE',
};

export const ActionGAEnum = {
  jobApplicationsBackToJobs: 'job_applications_back-to-jobs',
  jobApplicationsOpenToAddCandidate: 'job_applications_open-to-add-candidate',
  jobApplicationsStartAddCandidate: 'job_applications_start-add-candidate',
  jobApplicationsConfirmAddCandidate: 'job_applications_confirm-add-candidate',
  jobApplicationsCancelAddCandidate: 'job_applications_cancel-add-candidate',
  jobApplicationsShareJob: 'job_applications_share-job',
  jobApplicationsSeeJob: 'job_applications_see-job',
  jobApplicationsJobMetrics: 'job_applications_job-metrics',
  jobApplicationsJobSummary: 'job_applications_job-summary',
  jobApplicationsJobStep: 'job_applications_job-step',
  jobApplicationsFavoriteCandidate: 'job_applications_favorite-candidate',
  jobApplicationsCandidateHistory: 'job_applications_candidate-history',
  jobApplicationsOpenHiringData: 'job_applications_open-hiring-data',
  jobApplicationsSaveHiringData: 'job_applications_save-hiring-data',
  jobApplicationsCancelHiringData: 'job_applications_cancel-hiring-data',
  jobApplicationsActionbarOpenToAddTag:
    'job_applications_actionbar_open-to-add-tag',
  jobApplicationsActionbarAddTag: 'job_applications_actionbar_add-tag',
  jobApplicationsActionbarSaveAddTag: 'job_applications_actionbar_save-add-tag',
  jobApplicationsActionbarCancelAddTag:
    'job_applications_actionbar_cancel-add-tag',
  jobApplicationsActionbarOpenToSendEmail:
    'job_applications_actionbar_open-to-send-email',
  jobApplicationsActionbarConfirmSendEmail:
    'job_applications_actionbar_confirm-send-email',
  jobApplicationsActionbarCancelSendEmail:
    'job_applications_actionbar_cancel-send-email',
  jobApplicationsActionbarSaveTemplateSendEmail:
    'job_applications_actionbar_save-template-send-email',
  jobApplicationsActionbarOpenToRelocateCandidate:
    'job_applications_actionbar_open-to-relocate-candidate',
  jobApplicationsActionbarConfirmRelocateCandidate:
    'job_applications_actionbar_confirm-relocate-candidate',
  jobApplicationsActionbarCancelRelocateCandidate:
    'job_applications_actionbar_cancel-relocate-candidate',
  jobApplicationsActionbarOpenToSendWppBusiness:
    'job_applications_actionbar_open-to-send-wpp-business',
  jobApplicationsActionbarConfirmSendWppBusiness:
    'job_applications_actionbar_confirm-send-wpp-business',
  jobApplicationsActionbarCancelSendWppBusiness:
    'job_applications_actionbar_cancel-send-wpp-business',
  jobApplicationsActionbarOpenToSendToCrm:
    'job_applications_actionbar_open-to-send-to-crm',
  jobApplicationsActionbarConfirmSendToCrm:
    'job_applications_actionbar_confirm-send-to-crm',
  jobApplicationsActionbarCancelSendToCrm:
    'job_applications_actionbar_cancel-send-to-crm',
  jobApplicationsActionbarMoveCandidate:
    'job_applications_actionbar_move-candidate',
  jobApplicationsActionbarOpenToDisqualifyCandidate:
    'job_applications_actionbar_open-to-disqualify-candidate',
  jobApplicationsActionbarConfirmDisqualifyCandidate:
    'job_applications_actionbar_confirm-disqualify-candidate',
  jobApplicationsActionbarCancelDisqualifyCandidate:
    'job_applications_actionbar_cancel-disqualify-candidate',
};
