import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  draft: {
    id: 'job_status_draft',
    defaultMessage: 'Rascunho',
  },
  waiting_approval: {
    id: 'job_status_waiting_approval',
    defaultMessage: 'Em aprovação',
  },
  approved: {
    id: 'job_status_approved',
    defaultMessage: 'Aprovada',
  },
  disapproved: {
    id: 'job_status_disapproved',
    defaultMessage: 'Reprovada',
  },
  published: {
    id: 'job_status_published',
    defaultMessage: 'Publicada',
  },
  frozen: {
    id: 'job_status_frozen',
    defaultMessage: 'Congelada',
  },
  closed: {
    id: 'job_status_closed',
    defaultMessage: 'Encerrada',
  },
  canceled: {
    id: 'job-status-canceled',
    defaultMessage: 'Cancelada',
  },
  template: {
    id: 'job_status_template',
    defaultMessage: 'Template',
  },
  linkAnotherTemplate: {
    id: 'email_template_link_another',
    defaultMessage: 'Vincular outro template',
  },
  unlinkJobDisabled: {
    id: 'email_template_unlink_job_disabled',
    defaultMessage:
      'Você não tem permissão de edição desta vaga. Solicite à pessoa responsável.',
  },

  linkNewTemplate: {
    id: 'email_template_link_new_template',
    defaultMessage: 'Vincular novo template',
  },

  unlinkJob: {
    id: 'email_template_unlink_job',
    defaultMessage: 'Desvincular vaga',
  },
  saveButton: {
    id: 'save',
    defaultMessage: 'Salvar',
  },
  cancelButton: {
    id: 'cancel',
    defaultMessage: 'Cancelar',
  },
  emailTemplatesPageGridContentTitle: {
    id: 'email_templates_page_grid_content_title',
    defaultMessage:
      'Busque e gerencie os templates de e-mail criados em sua empresa',
  },

  emailTemplateSettingsTab: {
    id: 'email_template_settings_tab',
    defaultMessage: 'Configurações do template',
  },

  emailTemplateAssociatedJobsTab: {
    id: 'email_template_associated_jobs_tab',
    defaultMessage: 'Vagas vinculadas',
  },

  associatedJobsDescription: {
    id: 'associated_jobs_description',
    defaultMessage:
      'Você pode revisar as vagas e etapas vinculadas a este template de e-mail, vincular novos templates ou desvincular se for necessário.',
  },

  noAssociatedJobs: {
    id: 'no_associated_jobs',
    defaultMessage: 'Não existem vagas vinculadas a este template de e-mail.',
  },

  noAssociatedJobsDescription: {
    id: 'no_associated_jobs_description',
    defaultMessage:
      'As vagas e etapas vinculadas a este template de e-mail aparecerão aqui. Você poderá revisar, vincular novos templates ou desvincular se for necessário.',
  },

  emailTemplateName: {
    id: 'email_template_name',
    defaultMessage: 'Nome do template de e-mail',
  },
  emailType: {
    id: 'email_type',
    defaultMessage: 'Tipo de e-mail',
  },

  noResultsFound: {
    id: 'no_results_found',
    defaultMessage: 'Nenhum resultado encontrado',
  },
  emailTemplateSubject: {
    id: 'email_template_subject',
    defaultMessage: 'Assunto',
  },
  turnTemplateVisibleToggle: {
    id: 'wish_turn_this_template_visible_for_everyone_in_your_team',
    defaultMessage: 'Visível a todos os usuários da empresa',
  },
  success: {
    id: 'email_template_saved_success',
    defaultMessage: 'Template de e-mail salvo com sucesso',
  },
  noEmailTemplateCreatedYet: {
    id: 'no_email_template_created_yet',
    defaultMessage: 'Nenhum template de e-mail disponível',
  },
  noEmailTemplateFoundTitle: {
    id: 'no_email_template_found',
    defaultMessage: 'Nenhum template de e-mail encontrado',
  },
  noEmailTemplateFoundDescription: {
    id: 'no_email_template_found_description',
    defaultMessage: 'Reveja os termos usados e tente uma nova busca.',
  },
  noEmailTemplateCreatedYetDescription: {
    id: 'no_email_template_created_yet_description',
    defaultMessage:
      'Os templates de e-mail te ajudam a ter uma comunicação mais eficiente com os seus candidatos(as).',
  },
  noEmailTemplateCreatedYetAdmissionOnlyDescription: {
    id: 'no_email_template_created_yet_admission_only_description',
    defaultMessage:
      'Os templates de e-mail te ajudam a ter uma comunicação mais eficiente com as pessoas pré-colaboradoras',
  },
  createEmailTemplateButton: {
    id: 'create_email_template_button',
    defaultMessage: 'Criar template de e-mail',
  },
  allowReplyLabel: {
    id: 'allow_reply_label',
    defaultMessage: 'Permitir resposta do(a)s candidato(a)s para esse e-mail',
  },
  allowReplyAdmissionOnlyLabel: {
    id: 'allow_reply_admission_only_label',
    defaultMessage: 'Permitir resposta de pré-colaboradores para esse e-mail',
  },
  allowReply: {
    id: 'allow_reply',
    defaultMessage: 'Sim, permitir',
  },
  disallowReply: {
    id: 'disallow_reply',
    defaultMessage: 'Não permitir',
  },
  twoPeopleTalking: {
    id: 'two_people_talking',
    defaultMessage: 'Duas pessoas conversando',
  },
  delete: {
    id: 'delete',
    defaultMessage: 'Excluir',
  },
  invalidImageExtension: {
    id: 'image_extension_error',
    defaultMessage:
      'Extensão não permitida! Por favor, selecione uma imagem .jpg .jpeg .gif ou .png.',
  },
  invalidImageSize: {
    id: 'image_size_error',
    defaultMessage:
      'Sua imagem não foi carregada! Selecione uma imagem com até 5Mb e tente novamente.',
  },
  jobName: {
    id: 'email_template_associated_job_name',
    defaultMessage: 'Nome da vaga',
  },
  jobStep: {
    id: 'email_template_associated_job_step',
    defaultMessage: 'Etapa',
  },
  jobCreator: {
    id: 'email_template_associated_job_creator',
    defaultMessage: 'Responsável',
  },
  jobStatus: {
    id: 'email_template_associated_job_status',
    defaultMessage: 'Status',
  },

  emailTemplateLinkAnotherTemplateTitleModal: {
    id: 'email_template_link_another_template_title_modal',
    defaultMessage: 'Vincular outro template',
  },

  linkAnotherTemplateModalContent: {
    id: 'email_template_link_another_template_modal_content',
    defaultMessage:
      'Você pode vincular um novo template de e-mail para a etapa da vaga',
  },

  linkAnotherTemplateModalDropdownLabel: {
    id: 'email_template_link_another_template_modal_dropdown_label',
    defaultMessage:
      'Ao chegar nesta etapa, as pessoas candidatas devem receber qual mensagem?',
  },

  linkAnotherTemplateModalDropdownText: {
    id: 'email_template_link_another_template_modal_dropdown_text',
    defaultMessage: 'Selecione uma opção',
  },

  emailTemplateUnlinkAssociatedJobTitleModal: {
    id: 'email_template_unlink_associated_job_confirmation_title_modal',
    defaultMessage: 'Tem certeza que deseja desvincular?',
  },

  emailTemplateUnlinkAssociatedJobMessageModal: {
    id: 'email_template_unlink_associated_job_confirmation_message_modal',
    defaultMessage:
      'Se desvincular, a etapa da vaga ficará sem template de e-mail e os candidatos deixarão de receber uma comunicação.',
  },

  emailTemplateUnlinkAssociatedJobMessageLinkModal: {
    id: 'email_template_unlink_associated_job_confirmation_message_link_modal',
    defaultMessage: 'Você pode',
  },

  emailTemplateUnlinkAssociatedJobMessageLink2Modal: {
    id: 'email_template_unlink_associated_job_confirmation_message_link_2_modal',
    defaultMessage: 'desvincular e configurar um novo e-mail aqui',
  },

  emailTemplateUnlinkAssociatedJobMessageLink3Modal: {
    id: 'email_template_unlink_associated_job_confirmation_message_link_3_modal',
    defaultMessage: 'ou clicar no ícone.',
  },

  emailTemplateUnlinkAssociatedJobConfirmButtonTextModal: {
    id: 'email_template_unlink_associated_job_confirmation_modal',
    defaultMessage: 'Desvincular template',
  },

  emailTemplateUnlinkAssociatedJobMessageModalSuccess: {
    id: 'email_template_unlink_associated_job_confirmation_message_modal_success',
    defaultMessage: 'Template e vagas desvinculados com sucesso!',
  },

  emailTemplateLinkAnotherTemplateMessageModalSuccess: {
    id: 'email_template_link_new_template_message_modal_success',
    defaultMessage: 'Novo template vinculado com sucesso!',
  },

  emailSubjectIsTooLong: {
    id: 'email_subject_is_too_long',
    defaultMessage: 'Limite de caracteres atingido',
  },
});

export const getMessages = intl => formatMessages(intl, messages);
