import { buildToast, ToastTypes } from '@gupy/front-commons';
import { put, takeLatest } from 'redux-saga/effects';
import RoleActionTypes from '../../constants/Role/RoleActionTypes';
import RoleForm from '../../forms/Role/RoleForm';
import RoleService from '../../services/Role/RoleService';

function* getRoleSimilarities() {
  const form = new RoleForm();

  try {
    const response = yield RoleService.getRoleSimilarities();

    yield put({
      type: RoleActionTypes.GET_ROLE_SIMILARITIES_SUCCESS,
      roleSimilarities: response.body.data,
    });
  } catch (error) {
    yield put({
      type: RoleActionTypes.GET_ROLE_SIMILARITIES_FAIL,
      validation: form.validateErrorResponse(error),
    });
  }
}

function* getAll() {
  const form = new RoleForm();

  try {
    const response = yield RoleService.getAll();
    yield put({
      type: RoleActionTypes.GET_ALL_SUCCESS,
      roles: response.body.data,
    });
  } catch (error) {
    yield put({
      type: RoleActionTypes.GET_ALL_FAIL,
      validation: form.validateErrorResponse(error),
    });
  }
}

function* post(action) {
  const { payload, messages } = action;
  const form = new RoleForm();

  form.populate(payload);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: RoleActionTypes.POST_FAIL,
      validation,
    });
  } else {
    try {
      yield RoleService.post(payload);

      yield put({
        type: RoleActionTypes.POST_SUCCESS,
        toast: buildToast(messages.success, ToastTypes.success),
      });
    } catch (error) {
      yield put({
        type: RoleActionTypes.POST_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* update(action) {
  const { payload, messages } = action;
  const form = new RoleForm();

  const { id } = payload;
  const newPayload = { ...payload, id: undefined };

  form.populate(newPayload);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: RoleActionTypes.PUT_FAIL,
      validation,
    });
  } else {
    try {
      yield RoleService.put(id, newPayload);

      yield put({
        type: RoleActionTypes.PUT_SUCCESS,
        toast: buildToast(messages.success, ToastTypes.success),
      });
    } catch (error) {
      yield put({
        type: RoleActionTypes.PUT_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* del(action) {
  const { id, messages } = action;

  try {
    const response = yield RoleService.delete(id);
    if (response.statusCode !== 200) {
      throw new Error(response.body.message);
    }
    yield put({
      type: RoleActionTypes.DELETE_SUCCESS,
      toast: buildToast(messages.success, ToastTypes.success),
    });
  } catch (error) {
    const form = new RoleForm();
    yield put({
      type: RoleActionTypes.DELETE_FAIL,
      validation: form.validateErrorResponse(error),
      toast: buildToast(error.body.message, ToastTypes.error),
    });
  }
}

function* RoleSaga() {
  yield takeLatest(RoleActionTypes.GET_ROLE_SIMILARITIES, getRoleSimilarities);
  yield takeLatest(RoleActionTypes.GET_ALL, getAll);
  yield takeLatest(RoleActionTypes.POST, post);
  yield takeLatest(RoleActionTypes.POST_SUCCESS, getAll);
  yield takeLatest(RoleActionTypes.PUT, update);
  yield takeLatest(RoleActionTypes.PUT_SUCCESS, getAll);
  yield takeLatest(RoleActionTypes.DELETE, del);
  yield takeLatest(RoleActionTypes.DELETE_SUCCESS, getAll);
}

export default RoleSaga;
