import { Button, IconEnum } from '@gupy/design-system';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { getMessages } from '../../Messages';
import CustomIdCard from './CustomIdCard';
import './JobBoardPanel.scss';

const JobBoardPanel = ({
  intl,
  jobBoard,
  handleAddJobBoardCustomId,
  handleSetJobBoardCustomIdCareerPages,
  handleSetJobBoardCustomIdOnChange,
  setCustomIdRemoveModal,
}) => {
  const messages = getMessages(intl);
  return (
    <div className="job-board-panel">
      <div className="job-board-panel__title">{jobBoard.name}</div>
      {/*
      TODO: to get the correct sub title message, it would be necessary to create a migration
      */}
      <div className="job-board-panel__sub-title">
        {messages.jobBoardsCustomIdsFormSubTitle}
      </div>
      {jobBoard.customIds.map(
        ({ keyId, customId, displayErrors, careerPageIds }) => (
          <CustomIdCard
            key={keyId}
            keyId={keyId}
            customId={customId}
            displayErrors={displayErrors}
            selectedCareerPages={careerPageIds}
            jobBoardId={jobBoard.jobBoardId}
            handleSetJobBoardCustomIdCareerPages={
              handleSetJobBoardCustomIdCareerPages
            }
            handleSetJobBoardCustomIdOnChange={
              handleSetJobBoardCustomIdOnChange
            }
            setCustomIdRemoveModal={setCustomIdRemoveModal}
          />
        ),
      )}
      <Button
        className={classNames('job-board-panel__add-btn', 'btn')}
        type="text"
        size="lg"
        onClick={() => handleAddJobBoardCustomId(jobBoard.jobBoardId)}
        icon={IconEnum.Add}
        block
      >
        {messages.jobBoardsCustomIdsFormAddCustomIdBtn} {jobBoard.name}
      </Button>
    </div>
  );
};

JobBoardPanel.propTypes = {
  intl: PropTypes.object.isRequired,
  jobBoard: PropTypes.object.isRequired,
  handleAddJobBoardCustomId: PropTypes.func.isRequired,
  handleSetJobBoardCustomIdCareerPages: PropTypes.func.isRequired,
  handleSetJobBoardCustomIdOnChange: PropTypes.func.isRequired,
  setCustomIdRemoveModal: PropTypes.func.isRequired,
};

export default injectIntl(JobBoardPanel);
