import { asyncActionType, createActionTypes } from '@gupy/front-commons';

const JobAdditionalQuestionsActionTypes = createActionTypes(
  'JobAdditionalQuestions',
  'CHANGE_FORM',
  'CHANGE_FORM_ADDITIONAL_QUESTION_OPTION',
  'CLEAR_FORM',
  'EDIT',
  'CANCEL_EDIT',
  'ADD_OPTION',
  'DELETE_OPTION',
  asyncActionType('GET_ALL'),
  asyncActionType('POST'),
  asyncActionType('PUT'),
  asyncActionType('DELETE'),
);

const changeForm = modelForm => ({
  type: JobAdditionalQuestionsActionTypes.CHANGE_FORM,
  modelForm,
});

const addOption = () => ({
  type: JobAdditionalQuestionsActionTypes.ADD_OPTION,
});

const deleteOption = option => ({
  type: JobAdditionalQuestionsActionTypes.DELETE_OPTION,
  option,
});

const changeAdditionalQuestionOption = option => ({
  type: JobAdditionalQuestionsActionTypes.CHANGE_FORM_ADDITIONAL_QUESTION_OPTION,
  option,
});

const clearForm = () => ({
  type: JobAdditionalQuestionsActionTypes.CLEAR_FORM,
});

const postAdditionalQuestion = payload => ({
  type: JobAdditionalQuestionsActionTypes.POST,
  payload,
});

const putAdditionalQuestion = payload => ({
  type: JobAdditionalQuestionsActionTypes.PUT,
  payload,
});

const deleteAdditionalQuestion = (jobId, id, stepId) => ({
  type: JobAdditionalQuestionsActionTypes.DELETE,
  jobId,
  id,
  stepId,
});

const editAdditionalQuestion = modelForm => ({
  type: JobAdditionalQuestionsActionTypes.EDIT,
  modelForm,
});

const cancelEditAdditionalQuestion = () => ({
  type: JobAdditionalQuestionsActionTypes.CANCEL_EDIT,
});

const getAllAdditionalQuestions = (jobId, jobStepId) => ({
  type: JobAdditionalQuestionsActionTypes.GET_ALL,
  jobId,
  jobStepId,
});

export {
  JobAdditionalQuestionsActionTypes,
  changeForm,
  addOption,
  deleteOption,
  changeAdditionalQuestionOption,
  clearForm,
  postAdditionalQuestion,
  putAdditionalQuestion,
  deleteAdditionalQuestion,
  editAdditionalQuestion,
  cancelEditAdditionalQuestion,
  getAllAdditionalQuestions,
};
