import { buildToast, ToastTypes } from '@gupy/front-commons';
import ApprovalWorkflowFormValidator from '../../../containers/ApprovalWorkflow/ApprovalWorkflowFormValidator';
import * as types from './ApprovalWorkflowActionTypes';

export const clearForm = () => ({
  type: types.CLEAR_FORM,
});

export const changeForm = modelForm => ({
  type: types.CHANGE_FORM,
  modelForm,
});

export const fetchApprovalWorkflowsRequest = () => ({
  type: types.FETCH_APPROVAL_WORKFLOWS_REQUEST,
});

export const fetchApprovalWorkflowRequest = approvalWorkflowId => ({
  type: types.FETCH_APPROVAL_WORKFLOW_REQUEST,
  payload: approvalWorkflowId,
});

export const fetchApprovalWorkflowsSuccess = response => ({
  type: types.FETCH_APPROVAL_WORKFLOWS_SUCCESS,
  payload: response.body,
});

export const fetchApprovalWorkflowSuccess = response => ({
  type: types.FETCH_APPROVAL_WORKFLOW_SUCCESS,
  payload: response.body,
});

export const fetchApprovalWorkflowsFailure = () => ({
  type: types.FETCH_APPROVAL_WORKFLOWS_FAILURE,
});

export const fetchApprovalWorkflowFailure = () => ({
  type: types.FETCH_APPROVAL_WORKFLOW_FAILURE,
});

export const fetchApproversRequest = () => ({
  type: types.FETCH_APPROVERS_REQUEST,
});

export const fetchApproversSuccess = response => ({
  type: types.FETCH_APPROVERS_SUCCESS,
  payload: response.body.data,
});

export const fetchApproversFailure = () => ({
  type: types.FETCH_APPROVERS_FAILURE,
});

export const saveApprovalWorkflowRequest = ({
  id,
  name,
  approverIds,
  requestMethod,
  messages,
}) => {
  const form = new ApprovalWorkflowFormValidator();

  form.populate({ name, approverIds, requestMethod });
  const validation = form.validateModel();

  return validation
    ? {
        type: id
          ? types.UPDATE_APPROVAL_WORKFLOW_FAILURE
          : types.SAVE_APPROVAL_WORKFLOW_FAILURE,
        validation,
      }
    : {
        type: id
          ? types.UPDATE_APPROVAL_WORKFLOW_REQUEST
          : types.SAVE_APPROVAL_WORKFLOW_REQUEST,
        payload: { id, name, approverIds, requestMethod },
        messages,
      };
};

export const saveApprovalWorkflowSuccess = messages => ({
  type: types.SAVE_APPROVAL_WORKFLOW_SUCCESS,
  toast: buildToast(messages.success, ToastTypes.success),
});

export const saveApprovalWorkflowFailure = failureMessage => ({
  type: types.SAVE_APPROVAL_WORKFLOW_FAILURE,
  toast: buildToast(failureMessage, ToastTypes.error),
});

export const deleteApprovalWorkflowRequest = ({ workflow, messages }) => ({
  type: types.DELETE_APPROVAL_WORKFLOW_REQUEST,
  workflow: { id: workflow.id, companyId: workflow.companyId },
  messages,
});

export const deleteApprovalWorkflowSuccess = ({ id, messages }) => ({
  type: types.DELETE_APPROVAL_WORKFLOW_SUCCESS,
  workflowId: id,
  toast: buildToast(messages.success, ToastTypes.success),
});

export const deleteApprovalWorkflowFailure = messages => ({
  type: types.DELETE_APPROVAL_WORKFLOW_FAILURE,
  toast: buildToast(messages.failure, ToastTypes.error),
});

export const updateApprovalWorkflowSuccess = messages => ({
  type: types.UPDATE_APPROVAL_WORKFLOW_SUCCESS,
  toast: buildToast(messages.success, ToastTypes.success),
});

export const updateApprovalWorkflowFailure = failureMessage => ({
  type: types.UPDATE_APPROVAL_WORKFLOW_FAILURE,
  toast: buildToast(failureMessage, ToastTypes.error),
});
