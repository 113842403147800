import { combineReducers } from 'redux';
import AddressActionTypes from '../../constants/Address/AddressActionTypes';

// TODO: Should be removed once address context is migrated to container context (@atrat/@otm)
// Why: There is no need to have Address outside of each use (subsidiary, workplace, filters)

export const addressSuggestions = (state = [], action) => {
  switch (action.type) {
    case AddressActionTypes.CHANGE_FORM_ADDRESS_SUGGESTIONS_SUCCESS: {
      if (!action.suggestions) {
        return [];
      }

      return action.suggestions.body.slice(0, 5).map(item => ({
        description: item.description,
        placeId: item.place_id,
      }));
    }
    case AddressActionTypes.CHANGE_FORM_ADDRESS_CLEAR_SUGGESTIONS:
      return [];
    default:
      return state;
  }
};

const initAddressModel = {
  addressCountry: '',
  addressCountryShortName: '',
  addressCity: '',
  addressStreet: '',
  addressStateShortName: '',
  addressState: '',
  addressZipCode: '',
  addressNumber: '',
  addressLongitude: null,
  addressLatitude: null,
};

const addressModel = (state = initAddressModel, action) => {
  switch (action.type) {
    case AddressActionTypes.INIT:
    case AddressActionTypes.CLOSE_ADDRESS_MODAL:
      return initAddressModel;
    case AddressActionTypes.CHANGE_FORM_ADDRESS_MODAL:
      return {
        ...state,
        ...action.modelForm,
      };
    default:
      return state;
  }
};

const addressValidation = (state = {}, action) => {
  switch (action.type) {
    case AddressActionTypes.INIT:
    case AddressActionTypes.CLOSE_ADDRESS_MODAL:
    case AddressActionTypes.SAVE_ADDRESS_MODAL_SUCCESS:
      return {};
    case AddressActionTypes.SAVE_ADDRESS_MODAL_FAIL:
      return action.validation;
    default:
      return state;
  }
};

const countries = (state = [], action) => {
  switch (action.type) {
    case AddressActionTypes.GET_COUNTRIES_SUCCESS:
      return action.countries.map(country => ({
        value: country.code,
        label: country.name,
      }));
    default:
      return state;
  }
};

const isAddressModalOpen = (state = false, action) => {
  switch (action.type) {
    case AddressActionTypes.OPEN_ADDRESS_MODAL:
      return true;
    case AddressActionTypes.CLOSE_ADDRESS_MODAL:
    case AddressActionTypes.SAVE_ADDRESS_MODAL_SUCCESS:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  addressSuggestions,
  addressModel,
  addressValidation,
  countries,
  isAddressModalOpen,
});
