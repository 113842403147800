import { Button, Dialog } from '@gupy/design-system';
import { JobDeletionReason } from '@gupy/enums';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as actions from '../JobDeletionAction';
import * as selectors from '../Selectors';
import './JobDeletionAfterPublicationModal.scss';
import ConfirmStep from './components/ConfirmStep';
import FeedbackStep from './components/FeedbackStep';
import ReasonStep from './components/ReasonStep';

const propTypes = {
  job: PropTypes.object.isRequired,
  onModalClose: PropTypes.func.isRequired,
  messages: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onConfirmButtonClick: PropTypes.func.isRequired,
  applicationsToSendFeedback: PropTypes.array,
  requestGetApplicationsToSendFeedback: PropTypes.func.isRequired,
};

export const StepEnum = {
  reason: 'reason',
  feedback: 'feedback',
  confirm: 'confirm',
};
Object.freeze(StepEnum);

const JobDeletionAfterPublicationModal = ({
  job,
  onModalClose,
  messages,
  isOpen,
  onConfirmButtonClick,
  applicationsToSendFeedback,
  requestGetApplicationsToSendFeedback,
}) => {
  useEffect(() => {
    requestGetApplicationsToSendFeedback(job.id);
  }, []);

  const [step, setStep] = useState(StepEnum.reason);

  const [selectedReason, setSelectedReason] = useState(null);
  const [deleteReasonText, setDeleteReasonText] = useState('');
  const reasonIsOther = selectedReason === JobDeletionReason.other;
  const hasReason = reasonIsOther
    ? !!deleteReasonText.trim().length
    : !!selectedReason;

  const [selectedTemplate, setSelectedTemplate] = useState({});
  const hasFeedbacksToSend =
    applicationsToSendFeedback && applicationsToSendFeedback.length > 0;
  const feedbackStepIsCompleted = !hasFeedbacksToSend || !!selectedTemplate.id;

  const [confirmDeletion, setConfirmDeletion] = useState(false);

  const handleSetStep = stepClicked => {
    if (stepClicked === StepEnum.feedback && hasReason) {
      return setStep(StepEnum.feedback);
    }

    if (
      stepClicked === StepEnum.confirm &&
      hasReason &&
      feedbackStepIsCompleted
    ) {
      return setStep(StepEnum.confirm);
    }

    return undefined;
  };

  const handleConfirm = () => {
    const deleteJobPayload = {
      reason: selectedReason,
      observation: deleteReasonText,
      template: selectedTemplate,
    };
    onConfirmButtonClick(job.id, deleteJobPayload);
  };

  const { name, code } = job;
  const formattedCode = code ? `${code} - ` : '';

  const canConfirmDeletion =
    hasReason && feedbackStepIsCompleted && confirmDeletion;

  return (
    <div data-testid="job-deletion-modal-final-steps">
      <Dialog
        id="job-deletion-modal-final-steps"
        open={isOpen}
        title={messages.deleteJob}
        onClose={onModalClose}
      >
        <div className="job-deletion-modal__container">
          <div className="job-deletion-modal__info-title">
            <FormattedHTMLMessage
              id="job_deletion_modal_info_title"
              defaultMessage="Você está excluindo a vaga <strong>{formattedCode}{name}</strong>"
              values={{
                name,
                formattedCode,
              }}
            />
          </div>
          <ReasonStep
            messages={messages}
            setStep={setStep}
            expanded={step === StepEnum.reason}
            selectedReason={selectedReason}
            deleteReasonText={deleteReasonText}
            setSelectedReason={setSelectedReason}
            setDeleteReasonText={setDeleteReasonText}
            hasReason={hasReason}
          />
          <FeedbackStep
            messages={messages}
            setStep={handleSetStep}
            job={job}
            expanded={step === StepEnum.feedback}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            hasFeedbacksToSend={hasFeedbacksToSend}
            feedbackStepIsCompleted={feedbackStepIsCompleted}
          />
          <ConfirmStep
            messages={messages}
            setStep={handleSetStep}
            expanded={step === StepEnum.confirm}
            confirmDeletion={confirmDeletion}
            setConfirmDeletion={setConfirmDeletion}
          />
        </div>
        <div className="job-deletion-modal__actions">
          <Button
            type="text"
            size="md"
            onClick={onModalClose}
            className="job-deletion-modal__actions--cancel"
            data-testid="job-deletion-modal-actions-cancel"
          >
            {messages.cancel}
          </Button>
          <Button
            size="md"
            onClick={handleConfirm}
            className="job-deletion-modal__actions--submit"
            data-testid="job-deletion-modal-actions-submit"
            disabled={!canConfirmDeletion}
          >
            {messages.confirmJobPostDeletion}
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

JobDeletionAfterPublicationModal.propTypes = propTypes;

const mapStateToProps = state => ({
  applicationsToSendFeedback: selectors.getApplicationsToSendFeedback(state),
});

const mapDispatchToProps = dispatch => ({
  requestGetApplicationsToSendFeedback: jobId =>
    dispatch(actions.getApplicationsToSendFeedback(jobId)),
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(JobDeletionAfterPublicationModal),
);
