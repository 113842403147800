import { ContainerErrorBoundary } from '@gupy/front-commons';
import PropTypes from 'prop-types';
import React, { Fragment, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import Error403 from '../Error/403';
import featuresFlagsEnum from './FeaturesFlagsEnum';
import PrivateRouteContent from './PrivateRouteContent';
import './PrivateRoute.scss';

export const isAdmissionOnlyAllowedPath = (path = '') => {
  const pathWithoutParam = path.split('?')[0];
  const fixedPath = /[/#]$/.test(pathWithoutParam)
    ? pathWithoutParam.slice(0, -1)
    : pathWithoutParam;

  const admissionOnlyAllowedPaths = [
    '/companies/auth',
    '/companies/auth/callback',
    '/companies/forgot-password',
    '/admission/companies',
    '/companies/community',
    '/companies/signout',
    '/companies/signin',
    '/companies/profile',
    '/companies/setup',
    '/companies/setup/profile',
    '/companies/setup/roles',
    '/companies/setup/departments',
    '/companies/setup/subsidiaries',
    '/companies/setup/user-access-profile',
    '/companies/setup/user-access-profile/new',
    '/companies/setup/users',
    '/companies/setup/admission/documents',
    '/companies/setup/admission/custom-fields',
    '/companies/setup/admission/templates',
    '/companies/setup/admission/templates/new',
    '/companies/setup/email-template',
    '/companies/setup/email-template/new',
    '/companies/setup/admission/workflow',
    '/companies/setup/career-pages',
  ];

  const admissionOnlyAllowedPathsRegex = [
    /\/companies\/define-password\/[\w-]+/,
    /\/companies\/setup\/admission\/documents\/[\w-]+/,
    /\/companies\/setup\/admission\/templates\/[\w-]+/,
    /\/companies\/setup\/admission\/integrations\/[\w-]+/,
    /\/companies\/setup\/admission\/workflow\/edit\/[\w-]+/,
    /\/companies\/setup\/user-access-profile\/[\w-]+/,
    /\/companies\/setup\/email-template\/[\w-]+/,
    /\/admission\/companies\/profile\/[\w-]+/,
    /\/admission\/companies\/profile\/[\w-]+\/[\w-]+\/document\/[\w-]+/,
  ];

  return (
    admissionOnlyAllowedPaths.includes(fixedPath) ||
    admissionOnlyAllowedPathsRegex.some(regex => regex.test(fixedPath))
  );
};

const propTypes = {
  Authentication: PropTypes.object.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  renderPermissions: PropTypes.array,
  layout: PropTypes.any,
};

const defaultProps = {
  renderPermissions: [],
  layout: Fragment,
};

const PrivateRoute = ({
  component: Component,
  Authentication,
  renderPermissions,
  layout: Layout,
  ...rest
}) => {
  const { currentUser } = Authentication;
  const { permissions, featureFlags = [] } = currentUser || {};

  useLayoutEffect(() => {
    const pathname = rest.history.location.pathname || rest.path;

    if (
      featureFlags.includes(featuresFlagsEnum.admissionOnly) &&
      !isAdmissionOnlyAllowedPath(pathname)
    ) {
      window.location.replace('/admission/companies');
    }
  }, []);

  let canRender = true;
  if (permissions && renderPermissions.length) {
    canRender = renderPermissions.reduce(
      (can, renderPermission) => can || permissions[renderPermission],
      false,
    );
  }

  return (
    <Route
      {...rest}
      render={props => (
        <PrivateRouteContent {...props}>
          {canRender ? (
            <Layout permissions={permissions}>
              <ContainerErrorBoundary>
                <Component
                  {...props}
                  permissions={permissions}
                  featureFlags={featureFlags || []}
                />
              </ContainerErrorBoundary>
            </Layout>
          ) : (
            <Error403 />
          )}
        </PrivateRouteContent>
      )}
    />
  );
};

PrivateRoute.propTypes = propTypes;
PrivateRoute.defaultProps = defaultProps;

const mapStateToProps = state => ({
  Authentication: state.reducers.Authentication,
});

export default withRouter(connect(mapStateToProps, {})(PrivateRoute));
