import { call, put, takeLatest } from 'redux-saga/effects';
import history from '../../../../history';
import ActionTypes from './TestCorrectionActionTypes';
import Services from './TestCorrectionServices';

function* list(action) {
  try {
    const { applicationId, jobId } = action;
    const response = yield Services.list(applicationId, jobId);
    yield put({
      type: ActionTypes.LIST_SUCCESS,
      model: response.body.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.LIST_FAIL,
    });
  }
}

function* read(action) {
  const { applicationId, testId } = action;

  try {
    const response = yield Services.getApplicationTestAnswers(
      testId,
      applicationId,
    );
    yield put({
      type: ActionTypes.READ_SUCCESS,
      model: response.body,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.READ_FAIL,
      error,
    });
  }
}

function* saveCorretion(action) {
  try {
    const { applicationId, jobId, testId, payload } = action;
    yield Services.saveCorrection({ applicationId, jobId, testId, payload });
    yield put({
      type: ActionTypes.SAVE_SUCCESS,
    });
    yield call(
      history.push,
      `/companies/jobs/${jobId}/candidates/${applicationId}`,
    );
  } catch (error) {
    yield put({
      type: ActionTypes.SAVE_FAIL,
    });
  }
}

function* CustomTestQuestionSaga() {
  yield takeLatest(ActionTypes.LIST, list);
  yield takeLatest(ActionTypes.READ, read);
  yield takeLatest(ActionTypes.SAVE, saveCorretion);
}

export default CustomTestQuestionSaga;
