import uuid from 'uuid';

const adaptCustomIdResponseToCustomIdViewModel = customIdModel => ({
  ...customIdModel,
  careerPageIds: customIdModel.careerPageIds.map(id => id.toString()),
  keyId: uuid(),
  displayErrors: true,
});

export const adaptResponseToJobBoardsCustomIdsViewModel = jobBoardCustomId => ({
  ...jobBoardCustomId,
  customIds: jobBoardCustomId.customIds.map(
    adaptCustomIdResponseToCustomIdViewModel,
  ),
});

export const adaptJobBoardsCustomIdsViewModelToRequestBody = jobBoards => {
  const adaptedJobBoards = [];
  Object.values(jobBoards).forEach(jobBoard => {
    adaptedJobBoards.push({
      ...jobBoard,
      customIds: jobBoard.customIds.map(
        ({ keyId, displayErrors, careerPageIds, ...rest }) => ({
          ...rest,
          careerPageIds: careerPageIds.map(id => Number(id)),
        }),
      ),
    });
  });
  return adaptedJobBoards;
};
