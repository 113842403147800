import { FileSizeHelper } from '@gupy/front-commons';
import { sanitize } from 'dompurify';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

const propTypes = {
  templateEmail: PropTypes.object.isRequired,
  Authentication: PropTypes.object.isRequired,
};

const TemplateEmailPreview = ({ templateEmail, Authentication }) => {
  const {
    currentUser: {
      company: { id: companyId },
    },
  } = Authentication;
  const pathFilesOnS3 = `https://s3.amazonaws.com/gupy5/production/temp/companies/${companyId}`;

  return (
    <section className={'template-email'} aria-live="polite">
      <p className={'template-email__subject'}>{templateEmail.subject}</p>
      <p
        dangerouslySetInnerHTML={{ __html: sanitize(templateEmail.body) }}
        className={'template-email__body'}
      />
      {templateEmail.attachments && !!templateEmail.attachments.length && (
        <div>
          <p className={'template-email__attachments'}>
            <strong>
              <FormattedMessage id="attachments" defaultMessage="Anexos" />
            </strong>
          </p>
          <ul>
            {templateEmail.attachments.map(attachment => (
              <li key={attachment.tempName}>
                <a
                  className={'template-email__attachment-link'}
                  href={`${pathFilesOnS3}/${attachment.tempName}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {attachment.name}
                  {FileSizeHelper.bytesToKBString(attachment.size)}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </section>
  );
};

TemplateEmailPreview.propTypes = propTypes;

const mapStateToProps = state => ({
  Authentication: state.reducers.Authentication,
});

export default connect(mapStateToProps)(TemplateEmailPreview);
