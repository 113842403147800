export const GUPY_TEST_PROVIDER_ID = '66e0b1e9-2f92-4360-914a-c11cf7a0c9d9';
export const GUPY_PROFILE_TEST_CODE = 'profile';
export const PRIMEIRA_ESCOLHA_PROFILE_PROVIDER_UUID =
  '7d39d295-152b-4f11-b914-000000000001';
export const PRIMEIRA_ESCOLHA_BEHAVIORAL_PROVIDER_UUID =
  '7d39d295-152b-4f11-b914-f29331763602';
export const PRIMEIRA_ESCOLHA_PROFILE_TEST_CODE = '46683';
export const PRIMEIRA_ESCOLHA_BEHAVIORAL_CODE = '23078';
export const ASSESSMENTS_CULTURAL_FIT_V1_CODE = 'culturalFit_V1';
export const ASSESSMENTS_CULTURAL_FIT_V2_CODE = 'culturalFit_V2';
export const ASSESSMENTS_BEHAVIORAL_V2_CODE = 'behavioral_V1';
