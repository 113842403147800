import api from '../../../../api/DarthVaderClient';

export default {
  list(applicationId, jobId) {
    return api.get(
      `/custom-test/companies/jobs/${jobId}/candidates/${applicationId}`,
    );
  },
  read(applicationId, jobId, testId) {
    return api.get(
      `/companies/jobs/${jobId}/candidates/${applicationId}/tests/${testId}`,
    );
  },
  getApplicationTestAnswers(testId, applicationId) {
    return api.get(
      `/custom-test/companies/${testId}/applications/${applicationId}`,
    );
  },
  async saveCorrection({ applicationId, jobId, testId, payload }) {
    const request = () =>
      api.put(
        `/companies/jobs/${jobId}/candidates/${applicationId}/tests/${testId}`,
        payload,
      );
    try {
      return await request();
    } catch (e) {
      return request();
    }
  },
};
