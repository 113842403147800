import api from '../../../../../api/DarthVaderClient';

const routeBase = '/interview-events/companies';

export default {
  getByEmailThreadId: emailThreadId =>
    api.get(
      `${routeBase}/interview-events/by-email-thread?emailThreadId=${emailThreadId}`,
    ),
  createInterviewEvent: payload =>
    api.patch(`${routeBase}/interview-events`, payload),
  cancelInterviewEvent: (candidateId, jobStepId) =>
    api.delete(
      `${routeBase}/interview-events?candidateId=${candidateId}&jobStepId=${jobStepId}`,
    ),
  getInterviewEvent: ({ candidateId, jobStepId }) =>
    api.get(
      `${routeBase}/interview-events?candidateId=${candidateId}&jobStepId=${jobStepId}`,
    ),
  getSuggestSpots: ({ interviewersIds, duration, startDate }) => {
    const interviewersIdsParam = JSON.stringify([].concat(interviewersIds));
    return api.get(
      `${routeBase}/interview-events/suggest-spots?interviewersIds=${interviewersIdsParam}&durationInMinutes=${duration}&start=${startDate.toString()}`,
    );
  },
  getUsersAvailability: ({ interviewersIds, date, startTime, endTime }) => {
    const interviewersIdsParam = JSON.stringify([].concat(interviewersIds));
    return api.get(
      `${routeBase}/interview-events/users-availability?interviewersIds=${interviewersIdsParam}&date=${date}&start=${startTime.toString()}&end=${endTime.toString()}`,
    );
  },
  getPossibleParticipants: jobId =>
    api.get(`${routeBase}/jobs/${jobId}/participants`),
  saveClickWhatsAppLink({ jobId, applicationId, actionType }) {
    return api.post(
      `/companies/jobs/${jobId}/applications/${applicationId}/history`,
      { actionType },
    );
  },
};
