import { AppAlert } from '@gupy/design-system';
import { useFlag } from '@unleash/proxy-client-react';
import axios from 'axios';
import { sanitize } from 'dompurify';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import UserTypeEnum from '../../../constants/UserTypesEnum';
import FeaturesFlagEnum from '../../Authentication/FeaturesFlagsEnum';
import './SystemAlert.scss';

const ALERT_LOCAL_STORAGE_KEY_PREFIX = 'has_dismissed_product_alert';

function getAppAlertMessage(currentUser, i18nMessages) {
  return currentUser &&
    currentUser.language &&
    i18nMessages[currentUser.language]
    ? i18nMessages[currentUser.language]
    : i18nMessages.en;
}

function hasInvalidMessages(alertMessages) {
  return !(
    alertMessages &&
    alertMessages.messages &&
    alertMessages.moreInfoLabels &&
    alertMessages.moreInfoLinks &&
    alertMessages.uuid
  );
}

function isOwner(currentUser) {
  return currentUser.userType === UserTypeEnum.owner;
}

let inMemorySystemAlert;

function setAppAlertMessage(setSystemAlert) {
  axios.get(process.env.REACT_APP_SYSTEM_ALERT_URL).then(response => {
    inMemorySystemAlert = { ...response.data };
    setSystemAlert({ ...response.data });
  });
}

const propTypes = {
  currentUser: PropTypes.object.isRequired,
  dismiss: PropTypes.bool,
  onDismiss: PropTypes.func,
  useInMemoryVariable: PropTypes.bool,
};

const defaultProps = {
  dismiss: false,
  onDismiss: () => {},
  useInMemoryVariable: true,
};

const SystemAlert = ({
  currentUser,
  onDismiss,
  dismiss,
  useInMemoryVariable,
}) => {
  const shouldDisplayProductAlert = useFlag(FeaturesFlagEnum.productAlert);
  const shouldDisplayProductAlertMoreInfo = useFlag(
    FeaturesFlagEnum.productAlertMoreInfo,
  );
  const shouldDisplayAlertOwner = useFlag(FeaturesFlagEnum.productAlertOwner);
  const [hasDismissed, setHasDismissed] = useState(dismiss);
  const [systemAlert, setSystemAlert] = useState(
    useInMemoryVariable ? inMemorySystemAlert : null,
  );

  useEffect(() => {
    if (shouldDisplayProductAlert && !systemAlert) {
      setAppAlertMessage(setSystemAlert);
    }
  }, []);

  if (hasInvalidMessages(systemAlert)) {
    return null;
  }

  const { uuid, messages, moreInfoLabels, moreInfoLinks, status } = systemAlert;
  const productAlertLocalStorageKey = `${ALERT_LOCAL_STORAGE_KEY_PREFIX}_${uuid}`;
  const isMainWindow = window.self === window.top;
  const showAlertReq =
    !localStorage.getItem(productAlertLocalStorageKey) &&
    !hasDismissed &&
    isMainWindow;

  const shouldRenderAppAlert = shouldDisplayProductAlert && showAlertReq;

  const shouldRenderAppAlertMoreInfo =
    shouldRenderAppAlert && shouldDisplayProductAlertMoreInfo;

  const shouldRenderAlertOwner = showAlertReq && isOwner(currentUser);

  const appAlertMessage = getAppAlertMessage(currentUser, messages);
  const appAlertMoreInfoLabel = getAppAlertMessage(currentUser, moreInfoLabels);
  const appAlertMoreInfoLink = getAppAlertMessage(currentUser, moreInfoLinks);

  const handleDismissAppAlert = () => {
    localStorage.setItem(productAlertLocalStorageKey, new Date().getTime());
    setHasDismissed(true);
    onDismiss(true);
  };

  const shouldRender = shouldDisplayAlertOwner
    ? shouldRenderAlertOwner
    : shouldRenderAppAlert;

  return (
    <div className="system-alert">
      {shouldRender ? (
        <React.Fragment>
          <AppAlert
            id="instability-alert"
            onDismiss={handleDismissAppAlert}
            className={
              status ? `instability-alert--${status}` : 'instability-alert'
            }
          >
            <span
              className="system-alert__text-message"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: sanitize(appAlertMessage) }}
            />
            {shouldRenderAppAlertMoreInfo && (
              <a
                className="system-alert__more-info-link"
                target="_blank"
                href={appAlertMoreInfoLink}
              >
                {appAlertMoreInfoLabel}
              </a>
            )}
          </AppAlert>
        </React.Fragment>
      ) : null}
    </div>
  );
};

SystemAlert.propTypes = propTypes;
SystemAlert.defaultProps = defaultProps;

export default SystemAlert;
