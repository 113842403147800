import { DialogInfo } from '@gupy/design-system';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import './GroupTypeKnowMoreDialog.scss';

const propTypes = {
  messages: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

const GroupTypeKnowMoreDialog = ({
  messages,
  isModalOpen,
  handleCloseModal,
}) => {
  const dialogTitle = (
    <span className="knowMoreDialog__title">{messages.knowMoreTitle}</span>
  );

  return (
    <DialogInfo
      title={dialogTitle}
      description={
        <p className="knowMoreDialog">
          {messages.knowMoreDescription}

          <ul className="knowMoreDialog__description-list">
            <li className="knowMoreDialog__description-item knowMoreDialog__description-item--first">
              <FormattedHTMLMessage
                id="know-more-management-description"
                defaultMessage="Pessoas com o perfil de acesso associado ao <strong>grupo do tipo Gestão,</strong> serão exibidas na lista de opções do campo <strong>“Seleção do(a) Gestor(a) responsável pela vaga”</strong> na <strong>Criação da vaga.</strong>"
              />
            </li>

            <li className="knowMoreDialog__description-item">
              <FormattedHTMLMessage
                id="know-more-recruitment-description"
                defaultMessage="Pessoas com o perfil de acesso associado ao <strong>grupo do tipo Recrutamento,</strong> serão exibidas na lista de opções do campo <strong>“Seleção do(a) Recrutador(a) da vaga”</strong> na <strong>Criação da vaga.</strong>"
              />
            </li>

            <li className="knowMoreDialog__description-item">
              <FormattedHTMLMessage
                id="know-more-admission-description"
                // FIXME: get correct copy msg with Design, when it's ready
                defaultMessage="TBA"
              />
            </li>
          </ul>
        </p>
      }
      open={isModalOpen}
      confirmButton={{
        label: messages.okButton,
        action: handleCloseModal,
      }}
    />
  );
};

GroupTypeKnowMoreDialog.propTypes = propTypes;

export default GroupTypeKnowMoreDialog;
