import { put, takeLatest } from 'redux-saga/effects';
import history from '../../history';
import { getAllNotificationsSuccess } from './NotificationsActionsV2';
import * as NotificationsActionTypes from './NotificationsActionTypesV2';
import api from './NotificationsServiceV2';

const {
  getAll: apiGetAll,
  setViewed: apiSetViewed,
  removeNotification: apiRemoveNotification,
} = api;

const isAbsoluteUrlAndMatchesDomain = url => {
  const { origin } = window.location;
  return url.match(origin);
};

const isAbsoluteUrlAndExternalDomain = url =>
  /(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}/.test(
    url,
  );

const defaultAction = url => {
  if (isAbsoluteUrlAndMatchesDomain(url)) {
    history.push(url.replace(origin, ''));
    return;
  }
  if (isAbsoluteUrlAndExternalDomain(url)) {
    window.location.href = url;
    return;
  }
  history.push(`/${url}`);
};

const ctaTypesFunction = {
  DEFAULT: defaultAction,
};

const callAction = ({ type, actionUrl }) => ctaTypesFunction[type](actionUrl);

const callCtas = ctas => ctas.map(callAction);

export function* getAllNotifications({ data: payload }) {
  const { namespace } = payload;

  try {
    const data = yield apiGetAll(namespace);
    yield put(getAllNotificationsSuccess(data));
  } catch (error) {
    yield put({
      type: NotificationsActionTypes.GET_ALL_FAILURE_V2,
      error,
    });
  }
}

export function* viewNotification({
  data: {
    id,
    type,
    data: { ctas },
  },
}) {
  try {
    yield apiSetViewed(id);

    callCtas(ctas);

    yield put({
      type: NotificationsActionTypes.VIEW_SUCCESS_V2,
      data: { id, type },
    });
  } catch (error) {
    yield put({
      type: NotificationsActionTypes.VIEW_FAILURE_V2,
      error,
    });
  }
}

export function* removeNotification({ data: { id, type } }) {
  try {
    yield apiRemoveNotification(id);

    yield put({
      type: NotificationsActionTypes.REMOVE_SUCCESS_V2,
      data: { id, type },
    });
  } catch (error) {
    yield put({
      type: NotificationsActionTypes.REMOVE_FAILURE_V2,
      error,
    });
  }
}

export default function* NotificationsApplySaga() {
  yield takeLatest(
    NotificationsActionTypes.GET_ALL_REQUEST_V2,
    getAllNotifications,
  );
  yield takeLatest(NotificationsActionTypes.VIEW_REQUEST_V2, viewNotification);
  yield takeLatest(
    NotificationsActionTypes.REMOVE_REQUEST_V2,
    removeNotification,
  );
}
