import { asyncActionType, createActionTypes } from '@gupy/front-commons';
import jobStatusEnum from '../../../constants/Job/jobStatusEnum';
import JobPermissionsManager from '../../../managers/JobPermissionsManager';

const JobPictureActionTypes = createActionTypes(
  'JobPicture',
  'CHANGE_FORM',
  'START_UPLOAD_IMAGE',
  'FINISH_UPLOAD_IMAGE',
  asyncActionType('SAVE_MEDIA'),
  asyncActionType('GET'),
);

const changeForm = modelForm => ({
  type: JobPictureActionTypes.CHANGE_FORM,
  modelForm,
});

const saveMedia = (payload, jobPath) => {
  const adaptedPayload = { ...payload };

  if (!adaptedPayload.videoUrl || adaptedPayload.videoUrl.trim() === '') {
    adaptedPayload.videoUrl = null;
  }

  if (
    !adaptedPayload.videoSectionTitle ||
    adaptedPayload.videoSectionTitle.trim() === ''
  ) {
    adaptedPayload.videoSectionTitle = null;
  }

  return {
    type: JobPictureActionTypes.SAVE_MEDIA,
    payload: adaptedPayload,
    jobPath,
  };
};

const getJob = jobId => ({
  type: JobPictureActionTypes.GET,
  jobId,
});

export const getJobSuccess = job => {
  const { draft, waitingApproval, approved, disapproved, template } =
    jobStatusEnum;
  const allowedStatus = [
    draft,
    waitingApproval,
    approved,
    disapproved,
    template,
  ];
  const canEditJobCareerPageSubdomain =
    job.status && allowedStatus.indexOf(job.status) > -1;

  return {
    type: JobPictureActionTypes.GET_SUCCESS,
    modelForm: JobPermissionsManager.adaptJobPermissions(job),
    canEditJobCareerPageSubdomain,
  };
};

const startImageUpload = name => ({
  type: JobPictureActionTypes.START_UPLOAD_IMAGE,
  name,
});

const finishImageUpload = name => ({
  type: JobPictureActionTypes.FINISH_UPLOAD_IMAGE,
  name,
});

export {
  JobPictureActionTypes,
  changeForm,
  saveMedia,
  getJob,
  startImageUpload,
  finishImageUpload,
};
