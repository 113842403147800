import { Dropdown } from '@gupy/design-system';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  templates: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  messages: PropTypes.object.isRequired,
};

const defaultProps = {
  value: undefined,
};

const JobTemplatesDropdown = ({ templates, value, onChange, messages }) => (
  <div className="job-templates-dropdown">
    <Dropdown
      id="job-template"
      placeholder={messages.jobTemplates}
      emptyOptionsText={messages.noJobTemplateFound}
      options={templates}
      selectedOptionValue={value}
      onChange={selectedValue =>
        onChange({
          target: { value: selectedValue },
        })
      }
      searchable
    />
  </div>
);

JobTemplatesDropdown.propTypes = propTypes;
JobTemplatesDropdown.defaultProps = defaultProps;

export default JobTemplatesDropdown;
