import { DialogConfirm } from '@gupy/design-system';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  isOpen: PropTypes.bool,
  options: PropTypes.object.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
};

const defaultProps = {
  cancelButtonText: undefined,
  isOpen: false,
  title: undefined,
};

const AppConfirmDialog = ({
  isOpen,
  options,
  onCancelClick,
  onConfirmClick,
}) => (
  <DialogConfirm
    data-testid="app-confirm-dialog"
    id="confirm-dialog"
    title={options.title}
    description={options.message}
    confirmButton={{
      label: options.confirmButtonText,
      action: () => {
        onConfirmClick();
        if (options.onConfirmClick) options.onConfirmClick();
      },
    }}
    cancelButton={
      options.cancelButtonText
        ? {
            label: options.cancelButtonText,
            action: () => {
              onCancelClick();
              if (options.onCancelClick) options.onCancelClick();
            },
          }
        : null
    }
    open={isOpen}
    onClose={onCancelClick}
  />
);

AppConfirmDialog.propTypes = propTypes;
AppConfirmDialog.defaultProps = defaultProps;

export default AppConfirmDialog;
