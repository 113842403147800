import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  deleteTitle: {
    id: 'job_list_delete_job_confirmation_title',
    defaultMessage: 'Excluir vaga',
  },
  deleteMessage: {
    id: 'job_list_delete_job_confirmation_message',
    defaultMessage: 'Você confirma a exclusão da vaga?',
  },
  deleteConfirmButtonText: {
    id: 'delete',
    defaultMessage: 'Excluir',
  },
  deleteCancelButtonText: {
    id: 'cancel',
    defaultMessage: 'Cancelar',
  },
  deleteSuccess: {
    id: 'job_list_delete_job_success_message',
    defaultMessage: 'Vaga excluída com sucesso.',
  },
  deleteError: {
    id: 'job_list_delete_job_error_message',
    defaultMessage: 'Erro ao excluir vaga.',
  },
  finishSuccess: {
    id: 'job_list_close_job_success_message',
    defaultMessage: 'Vaga encerrada com sucesso.',
  },
  finishError: {
    id: 'job_list_close_job_error_message',
    defaultMessage: 'Erro ao encerrar vaga.',
  },
  title: {
    id: 'job_list_freeze_job_confirmation_title',
    defaultMessage: 'Congelar vaga',
  },
  freezeMessage: {
    id: 'job_list_freeze_job_confirmation_message',
    defaultMessage: 'Você confirma a desativação temporária da vaga?',
  },
  freezeConfirmButtonText: {
    id: 'freeze',
    defaultMessage: 'Congelar',
  },
  freezeCancelButtonText: {
    id: 'cancel',
    defaultMessage: 'Cancelar',
  },
  freezeSuccess: {
    id: 'job_list_freeze_job_success_message',
    defaultMessage: 'Vaga congelada com sucesso.',
  },
  freezeError: {
    id: 'job_list_freeze_job_error_message',
    defaultMessage: 'Erro ao congelar vaga.',
  },
  editJob: {
    id: 'edit_job',
    defaultMessage: 'Editar',
  },
  shareJob: {
    id: 'share_job',
    defaultMessage: 'Compartilhar',
  },
  freeze: {
    id: 'freeze',
    defaultMessage: 'Congelar',
  },
  close: {
    id: 'close',
    defaultMessage: 'Encerrar',
  },
  approve: {
    id: 'approve',
    defaultMessage: 'Aprovar',
  },
  publish: {
    id: 'publish',
    defaultMessage: 'Publicar',
  },
  viewJob: {
    id: 'view_job',
    defaultMessage: 'Visualizar',
  },
  deleteJob: {
    id: 'delete_job',
    defaultMessage: 'Excluir',
  },
  backManageJobs: {
    id: 'back-manage-jobs',
    defaultMessage: 'Voltar para a gestão de vagas',
  },
});

export const getJobActionsMessages = intl => formatMessages(intl, messages);
