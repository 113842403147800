import { useFlag } from '@unleash/proxy-client-react';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import FeaturesFlagsEnum from '../../Authentication/FeaturesFlagsEnum';
import EmptyState from './EmptyState';
import UserAccessProfileCard from './UserAccessProfileCard';
import './UserAccessProfileList.scss';

const propTypes = {
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  onDeleteClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  userAccessProfiles: PropTypes.array,
  featureFlags: PropTypes.arrayOf(PropTypes.string),
  messages: PropTypes.object.isRequired,
};

const UserAccessProfileList = ({
  canEdit,
  canDelete,
  onDeleteClick,
  onEditClick,
  userAccessProfiles,
  featureFlags,
  messages,
}) => {
  const isDiversityDashboardEnable = useFlag(
    FeaturesFlagsEnum.diversityDashboard,
  );
  const isDiversityCompaniesEnable = useFlag(
    FeaturesFlagsEnum.diversityCompanies,
  );

  const shouldShowIllustration = useMemo(
    () => featureFlags && featureFlags.includes(FeaturesFlagsEnum.rebranding),
    [featureFlags],
  );

  const shouldShowDiversityChip = useMemo(
    () =>
      featureFlags && isDiversityDashboardEnable && isDiversityCompaniesEnable,
    [featureFlags],
  );

  const hasAccessProfiles = userAccessProfiles && userAccessProfiles.length > 0;

  return hasAccessProfiles ? (
    <div className="user-access-profile-list">
      {userAccessProfiles.map(profile => (
        <UserAccessProfileCard
          key={profile.id}
          userAccessProfile={profile}
          onDeleteClick={onDeleteClick}
          onEditClick={onEditClick}
          canEdit={canEdit}
          canDelete={canDelete}
          messages={messages}
          shouldShowDiversityChip={shouldShowDiversityChip}
        />
      ))}
    </div>
  ) : (
    <EmptyState
      messages={messages}
      shouldShowIllustration={shouldShowIllustration}
    />
  );
};

UserAccessProfileList.propTypes = propTypes;

export default UserAccessProfileList;
