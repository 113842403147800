import { call, put, takeLatest } from 'redux-saga/effects';
import { JobSummaryActionTypes } from '../../../containers/Job/JobSummary/ActionTypes';
import history from '../../../history';
import {
  deleteApprovalWorkflowFailure,
  deleteApprovalWorkflowSuccess,
  fetchApprovalWorkflowFailure,
  fetchApprovalWorkflowsFailure,
  fetchApprovalWorkflowsSuccess,
  fetchApprovalWorkflowSuccess,
  fetchApproversFailure,
  fetchApproversSuccess,
  saveApprovalWorkflowFailure,
  saveApprovalWorkflowSuccess,
  updateApprovalWorkflowFailure,
  updateApprovalWorkflowSuccess,
} from './ApprovalWorkflowActions';
import * as types from './ApprovalWorkflowActionTypes';
import ApprovalWorkflowService from './ApprovalWorkflowService';

const workflowErrorsEnum = {
  usersNotEligibleForApproval: 'some_users_are_not_eligible_for_job_approval',
};

function* requestApprovalWorkflows() {
  try {
    const response = yield call(ApprovalWorkflowService.getApprovalWorkflows);

    yield put(fetchApprovalWorkflowsSuccess(response));
  } catch (error) {
    yield put(fetchApprovalWorkflowsFailure());
  }
}

function* requestApprovalWorkflow({ payload: approvalWorkflowId }) {
  try {
    const response = yield call(
      ApprovalWorkflowService.getApprovalWorkflow,
      approvalWorkflowId,
    );
    yield put(fetchApprovalWorkflowSuccess(response));
  } catch (error) {
    yield put(fetchApprovalWorkflowFailure());
  }
}

function* requestApprovers() {
  try {
    const response = yield call(ApprovalWorkflowService.getApprovers);
    yield put(fetchApproversSuccess(response));
  } catch (error) {
    yield put(fetchApproversFailure());
  }
}

function* requestWorkflowApprovers({ payload: approvalWorkflow }) {
  try {
    const additionalApprovalsIds = approvalWorkflow
      ? approvalWorkflow.approverIds
      : [];
    const response = yield call(
      ApprovalWorkflowService.getWorkflowApprovers,
      additionalApprovalsIds.join(','),
    );
    yield put(fetchApproversSuccess(response));
  } catch (error) {
    yield put(fetchApproversFailure());
  }
}

function* saveApprovalWorkflow({ payload, messages }) {
  try {
    yield call(ApprovalWorkflowService.saveApprovalWorkflow, payload);

    yield call(history.push, '/companies/setup/approval-workflows');
    yield put(saveApprovalWorkflowSuccess(messages));
  } catch (error) {
    if (error.body.message === workflowErrorsEnum.usersNotEligibleForApproval) {
      yield put(saveApprovalWorkflowFailure(messages.allUsersMustBeApprovers));
      return;
    }

    yield put(saveApprovalWorkflowFailure(messages.failure));
  }
}

function* deleteApprovalWorkflow({ workflow, messages }) {
  try {
    yield call(ApprovalWorkflowService.deleteApprovalWorkflow, workflow);
    yield put(deleteApprovalWorkflowSuccess({ id: workflow.id, messages }));
  } catch (error) {
    yield put(deleteApprovalWorkflowFailure(messages));
  }
}

function* updateApprovalWorkflow({ payload, messages }) {
  try {
    const { id, ...attributes } = payload;
    yield call(ApprovalWorkflowService.updateApprovalWorkflow, id, attributes);

    yield call(history.push, '/companies/setup/approval-workflows');
    yield put(updateApprovalWorkflowSuccess(messages));
  } catch (error) {
    if (error.body.message === workflowErrorsEnum.usersNotEligibleForApproval) {
      yield put(
        updateApprovalWorkflowFailure(messages.allUsersMustBeApprovers),
      );
      return;
    }
    yield put(updateApprovalWorkflowFailure(messages.failure));
  }
}

function* ApprovalWorkflowSagas() {
  yield takeLatest(
    types.FETCH_APPROVAL_WORKFLOWS_REQUEST,
    requestApprovalWorkflows,
  );
  yield takeLatest(
    types.FETCH_APPROVAL_WORKFLOW_REQUEST,
    requestApprovalWorkflow,
  );
  yield takeLatest(types.FETCH_APPROVERS_REQUEST, requestApprovers);
  yield takeLatest(JobSummaryActionTypes.GET_ALL_APPROVERS, requestApprovers);
  yield takeLatest(
    types.FETCH_APPROVAL_WORKFLOW_SUCCESS,
    requestWorkflowApprovers,
  );
  yield takeLatest(types.SAVE_APPROVAL_WORKFLOW_REQUEST, saveApprovalWorkflow);
  yield takeLatest(
    types.UPDATE_APPROVAL_WORKFLOW_REQUEST,
    updateApprovalWorkflow,
  );
  yield takeLatest(
    types.DELETE_APPROVAL_WORKFLOW_REQUEST,
    deleteApprovalWorkflow,
  );
}

export {
  requestApprovalWorkflows,
  requestApprovalWorkflow,
  requestApprovers,
  saveApprovalWorkflow,
  updateApprovalWorkflow,
  deleteApprovalWorkflow,
  ApprovalWorkflowSagas,
};
