import api from '../../../api/DarthVaderClient';

export const saveSchemaForm = async ({ jobId, jobStepId, formSchema }) => {
  const { body } = await api.post(
    `/form-step-activity/company/form/${jobId}/${jobStepId}`,
    formSchema,
  );

  return body;
};

export const getCustomFormList = async (jobId, jobStepId) => {
  const { body } = await api.get(
    `/form-step-activity/company/form/${jobId}/${jobStepId}`,
  );

  return body;
};
