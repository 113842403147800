import { asyncActionType, createActionTypes } from '@gupy/front-commons';

export default createActionTypes(
  'JobStepCustom',
  'CHANGE_FORM',
  'CLEAR_FORM',
  asyncActionType('PATCH'),
  asyncActionType('POST'),
  asyncActionType('GET'),
  asyncActionType('VALIDATE_JOB_STEP_CUSTOM'),
  asyncActionType('GET_TEST_PROVIDERS'),
  asyncActionType('GET_TESTS_FROM_PROVIDER'),
);
