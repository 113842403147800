import { combineReducers } from 'redux';
import * as actionTypes from './ActionTypes';

const initialCompanySize = {
  data: {},
};

export default combineReducers({
  companySize: (state = initialCompanySize, action) => {
    switch (action.type) {
      case actionTypes.GET_COMPANY_SIZE_SUCCESS:
        return { ...state, data: action.payload };
      default:
        return state;
    }
  },
});
