import { FooterButtons, InputGroup, Tip } from '@gupy/design-system';
import { useFlag } from '@unleash/proxy-client-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import FormAlert from '../../components/Form/FormAlert';
import GridContent from '../../components/Grid/GridContent';
import UserTypeEnum from '../../constants/UserTypesEnum';
import FeaturesFlagsEnum from '../Authentication/FeaturesFlagsEnum';
import {
  changeForm as changeFormAction,
  clearForm as clearFormAction,
  getStructureUserAccessProfile as getStructureUserAccessProfileAction,
  getUserAccessProfile as getUserAccessProfileAction,
  postUserAccessProfile as postUserAccessProfileAction,
  putUserAccessProfile as putUserAccessProfileAction,
} from './Actions';
import DiversityProfile from './components/DiversityProfile';
import GroupType from './components/GroupType';
import PermissionDiversityConfirmDialog from './components/PermissionDiversityConfirmDialog';
import PermissionPanel from './components/PermissionPanel';
import TokenManagementWithoutOtherAccessPermissionsDialog from './components/TokenManagementWithoutOtherAccessPermissionsDialog';
import { Branches, TipMessages } from './Enums';
import {
  customHandleChangeTreeBranchs,
  treeGroupCallBacks,
} from './helpers/UserAccessProfileFormContainerHelper';
import { getMessages } from './UserAccessProfileForm.messages';

const propTypes = {
  UserAccessProfile: PropTypes.object.isRequired,
  Authentication: PropTypes.object.isRequired,
  changeForm: PropTypes.func.isRequired,
  clearForm: PropTypes.func.isRequired,
  getUserAccessProfile: PropTypes.func.isRequired,
  getStructureUserAccessProfile: PropTypes.func.isRequired,
  putUserAccessProfile: PropTypes.func.isRequired,
  postUserAccessProfile: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  match: PropTypes.object.isRequired,
  featureFlags: PropTypes.array.isRequired,
};

export const filterByFeatureFlags = (
  permissionGroup,
  featureFlags,
  unleashFeatureFlags,
) => {
  const filteredPermissions = { ...permissionGroup };

  const isDiversityDashboardEnable = unleashFeatureFlags.diversityDashboard;
  const isDiversityCompaniesEnable = unleashFeatureFlags.diversityCompanies;
  const isTalentInboundEnabled = unleashFeatureFlags.talentAcquisitionModule;

  delete filteredPermissions.send_email_to_candidate;

  if (!isDiversityDashboardEnable) {
    delete filteredPermissions.view_diversity_dashboard;
  }
  if (!isDiversityCompaniesEnable) {
    delete filteredPermissions.export_diversity_in_reports;
  }
  if (!isTalentInboundEnabled) {
    delete filteredPermissions.manage_segmented_list;
    delete filteredPermissions.manage_communication_inbound;
    delete filteredPermissions.manage_capture_form;
  }

  return filteredPermissions;
};

const defaultGroupTipState = {
  [Branches.admission]: false,
};

const UserAccessProfileFormContainer = ({
  Authentication,
  UserAccessProfile,
  changeForm,
  clearForm,
  featureFlags,
  getStructureUserAccessProfile,
  getUserAccessProfile,
  intl,
  match,
  postUserAccessProfile,
  putUserAccessProfile,
}) => {
  const [redirect, setRedirect] = useState(null);
  const [
    showTokenManagementWithoutOtherAccessPermissionsModal,
    setShowTokenManagementWithoutOtherAccessPermissionsModal,
  ] = useState(false);
  const [showPermissionDiversityModal, setShowPermissionDiversityModal] =
    useState(false);
  const [showGroupTip, setShowGroupTip] = useState(defaultGroupTipState);
  const [
    isOnlyManagePublicApiAccessTokenPermissionSelected,
    setIsOnlyManagePublicApiAccessTokenPermissionSelected,
  ] = useState(false);

  const messages = getMessages(intl);

  useEffect(() => {
    const { id } = match.params;
    if (id) {
      getUserAccessProfile(id);
    } else {
      clearForm();
      getStructureUserAccessProfile();
    }
  }, []);

  useEffect(() => {
    if (UserAccessProfile.modelForm.struct.tree) {
      const allGroups = Object.values(
        UserAccessProfile.modelForm.struct.tree,
      ).reduce((acc, curr) => Object.assign(acc, curr.groups), {});

      const checkedGroups = Object.entries(allGroups).filter(
        ([, group]) => group.checked,
      );

      const shouldDisplayWarningTip =
        checkedGroups.length === 1 &&
        checkedGroups[0][0] === 'manage_public_api_access_token';

      if (shouldDisplayWarningTip) {
        setIsOnlyManagePublicApiAccessTokenPermissionSelected(true);
      } else {
        setIsOnlyManagePublicApiAccessTokenPermissionSelected(false);
      }
    }
  }, [UserAccessProfile]);

  const unleashFeatureFlags = {
    talentAcquisitionModule: useFlag(FeaturesFlagsEnum.talentAcquisitionModule),
    diversityDashboard: useFlag(FeaturesFlagsEnum.diversityDashboard),
    diversityCompanies: useFlag(FeaturesFlagsEnum.diversityCompanies),
  };

  const handleSetShowGroupTip = groupTip => {
    setShowGroupTip({
      ...showGroupTip,
      ...groupTip,
    });
  };

  const handleShowPermissionDiversityModal = () => {
    setShowPermissionDiversityModal(true);
  };

  const handleHidePermissionDiversityModal = () => {
    setShowPermissionDiversityModal(false);
  };

  const handleShowTokenManagementWithoutOtherAccessPermissionsModal = () => {
    setShowTokenManagementWithoutOtherAccessPermissionsModal(true);
  };

  const handleHideTokenManagementWithoutOtherAccessPermissionsModal = () => {
    setShowTokenManagementWithoutOtherAccessPermissionsModal(false);
  };

  const handleChangeTreeBranch = (branch, newStatus) => {
    const { ...newStruct } = UserAccessProfile.modelForm.struct;

    if (branch !== Branches.admission) {
      newStruct.tree[branch].checked = newStatus;
    }

    Object.keys(newStruct.tree[branch].groups).forEach(group => {
      newStruct.tree[branch].groups[group].checked = newStatus;
    });

    const customHandle = customHandleChangeTreeBranchs[branch];
    if (customHandle) customHandle(newStruct, handleSetShowGroupTip);

    changeForm({
      struct: newStruct,
    });
  };

  const handleChangeTreeGroup = (branch, group, newStatus) => {
    const { ...newStruct } = UserAccessProfile.modelForm.struct;
    const { groups } = newStruct.tree[branch];

    groups[group].checked = newStatus;

    const branchCallBack = treeGroupCallBacks[branch];
    if (branchCallBack) {
      branchCallBack(group, groups, newStatus, handleSetShowGroupTip);
    }

    const isAllGroupsChecked =
      Object.keys(groups).filter(g => groups[g].checked).length ===
      Object.keys(groups).length;

    newStruct.tree[branch].checked = isAllGroupsChecked;

    changeForm({
      struct: newStruct,
    });
  };

  const handleCancelClick = () => {
    setRedirect('/companies/setup/user-access-profile');
  };

  const handleFieldChange = event => {
    const { target } = event;

    changeForm({
      [target.name]: target.value,
    });
  };

  const handleFormSubmit = () => {
    const { modelForm } = UserAccessProfile;

    if (modelForm.id !== null && modelForm.id !== undefined) {
      putUserAccessProfile(modelForm, '/companies/setup/user-access-profile', {
        success: messages.success,
      });
    } else {
      postUserAccessProfile(modelForm, '/companies/setup/user-access-profile', {
        success: messages.success,
      });
    }
  };

  const handleSubmit = () => {
    if (isOnlyManagePublicApiAccessTokenPermissionSelected) {
      handleShowTokenManagementWithoutOtherAccessPermissionsModal();
      return;
    }

    const { struct } = UserAccessProfile.modelForm;
    const diversityAtsBranch = struct.tree.diversity_ats;

    if (!diversityAtsBranch) {
      handleFormSubmit();
      return;
    }

    const checkedPermissionDiversityGroups = Object.values(
      diversityAtsBranch.groups,
    ).filter(group => group.checked).length;

    if (!checkedPermissionDiversityGroups) {
      handleFormSubmit();
    } else {
      handleShowPermissionDiversityModal();
    }
  };

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  const { currentUser } = Authentication;
  const currentUserIsOwner =
    currentUser && currentUser.userType === UserTypeEnum.owner;

  const { validation, isSaving, modelForm } = UserAccessProfile;

  const isDiversityFeatureEnabled = unleashFeatureFlags.diversityCompanies;

  const isAdmissionOnly = featureFlags.includes('admission_only');

  let permissionsNode = [];
  let diversityPanel = null;

  if (modelForm.struct && modelForm.struct.tree) {
    const branches = modelForm.struct.tree;
    const globalization = modelForm.struct.gt;
    const diversityPermissionBranch = branches.diversity_ats;

    permissionsNode = Object.keys(branches)
      .filter(branch => branch !== 'diversity_ats')
      .map(branch => {
        const branchGroups = filterByFeatureFlags(
          branches[branch].groups,
          featureFlags,
          unleashFeatureFlags,
        );

        const branchGroupsLen = Object.keys(branchGroups).length;

        const checkedGroups = Object.keys(branchGroups).filter(
          x => branchGroups[x].checked,
        ).length;

        const branchTipMessage = messages[TipMessages[branch]];

        const indeterminate =
          checkedGroups > 0 && checkedGroups < branchGroupsLen;
        return (
          <PermissionPanel
            branchName={branch}
            branch={branches[branch]}
            globalization={globalization}
            indeterminate={indeterminate}
            handleChangeTreeBranch={handleChangeTreeBranch}
            handleChangeTreeGroup={handleChangeTreeGroup}
            branchGroups={branchGroups}
            showGroupTip={showGroupTip}
            branchTipMessage={branchTipMessage}
            key={branch}
          />
        );
      });

    if (diversityPermissionBranch) {
      const diversityBranchGroups = filterByFeatureFlags(
        diversityPermissionBranch.groups,
        featureFlags,
        unleashFeatureFlags,
      );
      const diversityGroupsLen = diversityBranchGroups.length;
      const diversityCheckedGroups = Object.keys(diversityBranchGroups).filter(
        x => diversityBranchGroups[x].checked,
      ).length;

      const isDiversityIndeterminate =
        diversityCheckedGroups > 0 &&
        diversityCheckedGroups < diversityGroupsLen;

      diversityPanel = (
        <PermissionPanel
          branchName="diversity_ats"
          branch={diversityPermissionBranch}
          globalization={globalization}
          branchGroups={diversityBranchGroups}
          indeterminate={isDiversityIndeterminate}
          handleChangeTreeBranch={handleChangeTreeBranch}
          handleChangeTreeGroup={handleChangeTreeGroup}
          hasInfoTip
          tipMessage={messages.panelTip}
        />
      );
    }
  }

  return (
    <GridContent
      title={
        modelForm.id !== null && modelForm.id !== undefined
          ? messages.userProfile
          : messages.newUserProfile
      }
    >
      <div className="user-access-profile">
        {validation && validation.alert && (
          <FormAlert validation={validation.alert} />
        )}

        <div className="row">
          <div className="col-xs-12 col-sm-5 user-access-profile__profile-title">
            <InputGroup
              id="name"
              name="name"
              label={messages.userAccessProfileName}
              value={modelForm.name}
              onChange={handleFieldChange}
              disabled={isSaving}
              validation={validation.name}
              block
              required
            />
          </div>
        </div>

        {!isAdmissionOnly && (
          <GroupType
            messages={messages}
            value={modelForm.groupType}
            onGroupTypeChange={handleFieldChange}
          />
        )}

        <div className="user-access-profile__permission-nodes">
          {permissionsNode}
        </div>

        {isOnlyManagePublicApiAccessTokenPermissionSelected && (
          <Tip
            className="user-access-profile__tip"
            type="warning"
            text={
              <FormattedHTMLMessage
                id="additional_questions_tip"
                defaultMessage="<b>Atenção</b>: para criar um perfil de acesso com o gerenciamento de tokens de API pública, você deve, minimamente, habilitar outras permissões de acesso que se relacionem com endpoints que precisam ser gerenciados. <a href='https://developers.gupy.io/reference/introduction' target='_blank'>Saiba mais</a>"
              />
            }
            showIcon
          />
        )}

        {isDiversityFeatureEnabled && currentUserIsOwner && (
          <DiversityProfile
            messages={messages}
            diversityPanel={diversityPanel}
          />
        )}

        <div className="user-access-profile__footer">
          <div className="col-xs-12">
            <div className="align-right actions">
              {validation.struct && (
                <span className="error">{validation.struct.message}</span>
              )}

              <FooterButtons
                buttons={[
                  {
                    id: 'cancel-form-email-user-profile',
                    onClick: handleCancelClick,
                    disabled: isSaving,
                    text: messages.cancelButton,
                    type: 'text',
                  },
                  {
                    id: 'save-form-email-user-profile',
                    onClick: handleSubmit,
                    disabled: isSaving,
                    text: messages.saveButton,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      <PermissionDiversityConfirmDialog
        messages={messages}
        isModalOpen={showPermissionDiversityModal}
        handleCancel={handleHidePermissionDiversityModal}
        handleOk={handleFormSubmit}
      />

      <TokenManagementWithoutOtherAccessPermissionsDialog
        handleCancel={
          handleHideTokenManagementWithoutOtherAccessPermissionsModal
        }
        isModalOpen={showTokenManagementWithoutOtherAccessPermissionsModal}
        messages={messages}
      />
    </GridContent>
  );
};

UserAccessProfileFormContainer.propTypes = propTypes;

const mapStateToProps = state => ({
  UserAccessProfile: state.reducers.UserAccessProfile,
  Authentication: state.reducers.Authentication,
});

export default injectIntl(
  connect(mapStateToProps, {
    changeForm: changeFormAction,
    clearForm: clearFormAction,
    getStructureUserAccessProfile: getStructureUserAccessProfileAction,
    getUserAccessProfile: getUserAccessProfileAction,
    postUserAccessProfile: postUserAccessProfileAction,
    putUserAccessProfile: putUserAccessProfileAction,
  })(UserAccessProfileFormContainer),
);
