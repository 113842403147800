import darthVaderApi from '../../api/DarthVaderClient';
import { rscLinkedinIntegrationServiceClient as rscLinkedinIntegrationApi } from '../../api/RscLinkedinIntegrationServiceClient';

export default {
  getCompanyIntegrations(renderScreen) {
    return darthVaderApi.get(`/companies/integrations/${renderScreen}`);
  },
  getCompanyPublicCareerPages() {
    return darthVaderApi.get('/career-pages/list/public');
  },
  getCompanyAllCareerPages() {
    return darthVaderApi.get('/career-pages/list');
  },
  saveCompanyIntegrations(integrationsObject, renderScreen) {
    return darthVaderApi.post(
      `/companies/integrations/${renderScreen}`,
      integrationsObject,
    );
  },
  getJobBoardsCustomIds() {
    return darthVaderApi.get('/job-boards/career-pages/binding');
  },
  saveJobBoardsCustomIds(jobBoardsCustomIds) {
    return darthVaderApi.post('/job-boards/career-pages/binding', {
      data: jobBoardsCustomIds,
    });
  },
  getLinkedinJobBoardId() {
    return darthVaderApi.get('/job-boards?name=Linkedin');
  },
  createRsclinkedinIntegrationChildApp(token) {
    return rscLinkedinIntegrationApi.post(
      '/api/v1/child-app',
      {},
      {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  getRscLinkedinIntegrationChildApps(token) {
    return rscLinkedinIntegrationApi.get(
      '/api/v1/child-app',
      {},
      {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  bindRscLinkedinIntegrationChildAppCareerPages(
    childApp,
    token,
    companyUserLanguage,
  ) {
    const { id, name, careerPagesIds } = childApp;

    return rscLinkedinIntegrationApi.post(
      `/api/v1/child-app/${id}/career-pages`,
      { name, careerPagesIds, companyUserLanguage },
      {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  editRscLinkedinIntegrationChildApp(childApp, token) {
    const { id, name } = childApp;

    return rscLinkedinIntegrationApi.patch(
      `/api/v1/child-app/${id}`,
      { name },
      {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  async restartSyncRscLinkedinIntegrationChildApp(
    childApp,
    token,
    companyUserLanguage,
  ) {
    const { id } = childApp;

    await rscLinkedinIntegrationApi.post(
      `/api/v1/child-app/${id}/restart-sync`,
      {
        companyUserLanguage,
      },
      {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  saveRscLinkedinIntegrationChildAppFeatures(childAppId, payload, token) {
    return rscLinkedinIntegrationApi.post(
      `/api/v1/child-app/${childAppId}/features/enable`,
      payload,
      {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  desyncRscLinkedinIntegrationChildApp(childApp, token, companyUserLanguage) {
    const { id } = childApp;

    return rscLinkedinIntegrationApi.post(
      `/api/v1/child-app/${id}/desync`,
      {
        companyUserLanguage,
      },
      {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  getRscChildAppsWithDeleteEmail(token) {
    return rscLinkedinIntegrationApi.get(
      '/api/v1/child-app/deletion-processes',
      {},
      {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  saveRscChildAppWithDeleteEmail(childAppId, token) {
    return rscLinkedinIntegrationApi.post(
      '/api/v1/child-app/deletion-processes',
      {
        childAppId,
      },
      {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  deleteRscLinkedinIntegrationChildApp(childAppId, token) {
    return rscLinkedinIntegrationApi.delete(
      `/api/v1/child-app/${childAppId}`,
      {},
      {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
};
