import { asyncActionType, createActionTypes } from '@gupy/front-commons';
import RegistrationSchemaHelper from '../helpers/RegistrationSchemaHelper';

const JobRegistrationFormActionTypes = createActionTypes(
  'JobRegistrationForm',
  asyncActionType('GET_REGISTRATION_SCHEMA'),
  asyncActionType('SAVE_REGISTRATION_SCHEMA'),
  'CHANGE_REGISTRATION_SCHEMA',
);

const getRegistrationSchema = jobId => ({
  type: JobRegistrationFormActionTypes.GET_REGISTRATION_SCHEMA,
  jobId,
});

const getRegistrationSchemaSuccess = schema => ({
  type: JobRegistrationFormActionTypes.GET_REGISTRATION_SCHEMA_SUCCESS,
  schema: RegistrationSchemaHelper.adaptPayloadToState(schema),
});

const saveRegistrationSchema = (jobId, state, successCallback) => ({
  type: JobRegistrationFormActionTypes.SAVE_REGISTRATION_SCHEMA,
  jobId,
  payload: RegistrationSchemaHelper.adaptStateToPayload(state),
  successCallback,
});

const changeRegistrationSchema = ({ schema, key, value }) => ({
  type: JobRegistrationFormActionTypes.CHANGE_REGISTRATION_SCHEMA,
  schema,
  key,
  value,
});

export {
  JobRegistrationFormActionTypes,
  getRegistrationSchema,
  getRegistrationSchemaSuccess,
  changeRegistrationSchema,
  saveRegistrationSchema,
};
