import { asyncActionType, createActionTypes } from '@gupy/front-commons';

export const IntegrationsActionTypes = createActionTypes(
  'Integrations',
  asyncActionType('INIT'),
  asyncActionType('INIT_RSC_LINKEDIN_INTEGRATION'),
  asyncActionType('UPDATE_INTEGRATIONS_FORM'),
  asyncActionType('SAVE_INTEGRATIONS'),
  asyncActionType('INIT_INTEGRATIONS_SUCCESS'),
  asyncActionType('INIT_LINKEDIN_RSC_SUCCESS'),
  asyncActionType('GET_RSC_LINKEDIN_INTEGRATION_CHILD_APPS'),
  asyncActionType('CREATE_RSC_LINKEDIN_INTEGRATION_CHILD_APP'),
  asyncActionType('CREATE_RSC_LINKEDIN_INTEGRATION_CHILD_APP_CLEAR'),
  asyncActionType('BIND_RSC_LINKEDIN_INTEGRATION_CHILD_APP_CAREER_PAGES'),
  asyncActionType('EDIT_RSC_LINKEDIN_INTEGRATION_CHILD_APP'),
  asyncActionType('RESTART_SYNC_RSC_LINKEDIN_INTEGRATION_CHILD_APP'),
  asyncActionType('DESYNC_RSC_LINKEDIN_INTEGRATION_CHILD_APP'),
  asyncActionType('SAVE_JOB_BOARDS_CUSTOM_IDS'),
  asyncActionType('PUT_RSC_LINKEDIN_INTEGRATION_ERROR_TOAST'),
  asyncActionType(
    'SET_RSC_LINKEDIN_INTEGRATION_CHILD_APP_FORM_VALIDATION_ERROR',
  ),
);
