import { SectionItem } from '@gupy/design-system';
import PropTypes from 'prop-types';
import React from 'react';
import './SetupGridSidebarMenu.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
  hasPermission: PropTypes.bool,
  shouldBlockFeature: PropTypes.bool,
};

const defaultProps = {
  hasPermission: false,
  shouldBlockFeature: false,
};

const SetupSectionItem = ({
  children,
  link,
  hasPermission,
  shouldBlockFeature,
}) => {
  if (!shouldBlockFeature) {
    if (hasPermission) {
      return <SectionItem link={link}>{children}</SectionItem>;
    }
    return null;
  }
  return (
    <div className="section-item--not-permission">
      <SectionItem link={link}>
        <span className="children-content">{children}</span>
        <img
          src={`${process.env.REACT_APP_ASSETS_URL}/repacking/higher-plan-icon.svg`}
          height="24px"
          width="24px"
          alt="Logo"
        />
      </SectionItem>
    </div>
  );
};

SetupSectionItem.propTypes = propTypes;
SetupSectionItem.defaultProps = defaultProps;

export default SetupSectionItem;
