import { combineReducers } from 'redux';
import * as taskActionTypes from './TaskActionTypes';

export const isLoading = (state = true, action) => {
  switch (action.type) {
    case taskActionTypes.GET_TASKS_SUCCESS:
    case taskActionTypes.GET_TASKS_FAIL:
      return false;
    case taskActionTypes.GET_TASKS:
      return true;
    default:
      return state;
  }
};

export const userTasks = (state = null, action) => {
  switch (action.type) {
    case taskActionTypes.GET_TASKS_SUCCESS:
      return action.userTasks;
    default:
      return state;
  }
};

export default combineReducers({
  userTasks,
  isLoading,
});
