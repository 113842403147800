import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  questionType: PropTypes.string.isRequired,
  actions: PropTypes.array.isRequired,
};

const QuestionItem = ({ id, text, questionType, actions }) => (
  <div className="question-item">
    <div className="question-item__header">
      <p className="question-item__text">{text}</p>
      <span>{questionType}</span>
    </div>
    <div className="question-item__actions">
      {actions.map((action, key) => (
        <button key={key.toString() + id} onClick={action.onClick}>
          <i className={`fa fa-${action.icon}`} />
        </button>
      ))}
    </div>
  </div>
);

QuestionItem.propTypes = propTypes;

export default QuestionItem;
