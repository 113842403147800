import { EmailTemplateTypeEnum } from '@gupy/enums';
import { buildToast, ToastTypes } from '@gupy/front-commons';
import { call, put, takeLatest } from 'redux-saga/effects';
import JobStepTypes from '../../../constants/Job/JobStep/JobStepTypes';
import EmailTemplateService from '../../../services/EmailTemplate/EmailTemplateService';
import EngageSurveyService from '../../../services/EngageSurvey';
import JobService from '../../../services/Job/JobService';
import JobClosureActionTypes from './JobClosureActionTypes';

function* getFinalJobStepId(action) {
  try {
    const response = yield JobService.getJobSteps(
      action.jobId,
      JobStepTypes.final,
    );

    yield put({
      type: JobClosureActionTypes.GET_FINAL_JOB_STEP_ID_SUCCESS,
      finalStepId: response.body[0].id,
    });
  } catch (error) {
    yield put({
      type: JobClosureActionTypes.GET_FINAL_JOB_STEP_ID_FAIL,
    });
  }
}

function* getAllTemplateEmails() {
  try {
    const response = yield EmailTemplateService.getAll();
    const feedbackTemplates = response.body.filter(
      t => t.type === EmailTemplateTypeEnum.badFeedback,
    );
    const responseVariables = yield EmailTemplateService.getVariables();

    yield put({
      type: JobClosureActionTypes.GET_ALL_TEMPLATE_EMAILS_SUCCESS,
      templateEmails: feedbackTemplates,
      markers: responseVariables.body.data,
    });
  } catch (error) {
    yield put({
      type: JobClosureActionTypes.GET_ALL_TEMPLATE_EMAILS_FAIL,
    });
  }
}

function* sendFeedbackEmails(action) {
  const { applicationIds, template, jobId, from, messages, engageSurveyId } =
    action;

  try {
    const payload = {
      from,
      subject: template.subject,
      emailBody: template.body,
      attachments: template.attachments || [],
      applicationIds,
      engageSurveyId,
    };

    yield JobService.sendFeedbackEmails(jobId, payload);

    yield put({
      type: JobClosureActionTypes.SEND_FEEDBACK_EMAILS_SUCCESS,
      toast: messages ? buildToast(messages.success, ToastTypes.success) : null,
    });
  } catch (error) {
    yield put({
      type: JobClosureActionTypes.SEND_FEEDBACK_EMAILS_FAIL,
      toast: messages ? buildToast(messages.error, ToastTypes.error) : null,
    });
  }
}

export function* getBindSurveyCareerPages() {
  try {
    const res = yield call(EngageSurveyService.getSurveyCareerPageBinds);
    yield put({
      type: JobClosureActionTypes.GET_BIND_SURVEY_CAREER_PAGES_SUCCESS,
      payload: res.body,
    });
  } catch (error) {
    yield put({
      type: JobClosureActionTypes.GET_BIND_SURVEY_CAREER_PAGES_FAIL,
      error,
    });
  }
}

function* closeJob(action) {
  const { jobId, isFeedbackSent, successToastMessage } = action;
  try {
    yield JobService.closeJob({
      jobId,
      isFeedbackSent,
    });

    yield put({
      type: JobClosureActionTypes.CLOSE_JOB_SUCCESS,
      toast: successToastMessage
        ? buildToast(successToastMessage, ToastTypes.success)
        : null,
    });
  } catch (error) {
    yield put({
      type: JobClosureActionTypes.CLOSE_JOB_FAIL,
      error,
    });
  }
}

function* JobClosureSaga() {
  yield takeLatest(JobClosureActionTypes.CLOSE_JOB, closeJob);
  yield takeLatest(
    JobClosureActionTypes.GET_FINAL_JOB_STEP_ID,
    getFinalJobStepId,
  );
  yield takeLatest(
    JobClosureActionTypes.GET_ALL_TEMPLATE_EMAILS,
    getAllTemplateEmails,
  );
  yield takeLatest(
    JobClosureActionTypes.SEND_FEEDBACK_EMAILS,
    sendFeedbackEmails,
  );
  yield takeLatest(
    JobClosureActionTypes.GET_BIND_SURVEY_CAREER_PAGES,
    getBindSurveyCareerPages,
  );
}

export default JobClosureSaga;
