import { Spinner } from '@gupy/design-system';
import PropTypes from 'prop-types';
import React from 'react';
import '../RscLinkedinIntegrationChildAppsItem';

const ChildAppReadyEnablingFeatures = ({ messages }) => (
  <tr
    className={
      'job-board-panel-rsc-child-apps__table-row ENABLING_LINKEDIN_FEATURES'
    }
  >
    <td className="actions" colSpan="4">
      <Spinner size={16} />
      {messages.rscIntegrationChildAppEnablingLinkedinFeaturesMessage}
    </td>
  </tr>
);

ChildAppReadyEnablingFeatures.propTypes = {
  messages: PropTypes.object.isRequired,
};

export default ChildAppReadyEnablingFeatures;
