import { buildToast, ToastTypes } from '@gupy/front-commons';
import download from 'downloadjs';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getCompanyBranchSuccess } from './Action';
import * as types from './ActionTypes';
import { formatCompanyBranchToTreeStruct } from './scripts/formater';
import Service from './Service';

export function* getCompanyBranchesData(action) {
  try {
    const { level, branchLabel } = action.payload || {};
    const response = yield call(Service.get, { level, branchLabel });

    yield put(
      getCompanyBranchSuccess({
        items: response.body.data,
        level,
        branchLabel,
      }),
    );
  } catch (error) {
    yield put({
      type: types.GET_COMPANY_BRANCHES_FAIL,
      error,
    });
  }
}

export function* getCSVTemplate() {
  try {
    const response = yield call(Service.getCSVTemplate);
    const csv = response.body;
    yield call(
      download,
      `data:${csv.mimeType};base64,${csv.content}`,
      csv.fileName,
      csv.mimeType,
    );

    yield put({
      type: types.GET_COMPANY_BRANCHES_CSV_TEMPLATE_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.GET_COMPANY_BRANCHES_CSV_TEMPLATE_FAIL,
      error,
    });
  }
}

export function* getCurrentCSV() {
  try {
    const response = yield call(Service.getCurrentCSV);
    const csv = response.body;
    yield call(
      download,
      `data:${csv.mimeType};base64,${csv.content}`,
      csv.fileName,
      csv.mimeType,
    );

    yield put({
      type: types.GET_COMPANY_BRANCHES_CURRENT_CSV_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.GET_COMPANY_BRANCHES_CURRENT_CSV_FAIL,
      error,
    });
  }
}

const formatPatchPayload = form => ({
  id: form.id,
  branchLeafName: form.branchLeafName,
  branchCode: form.branchCode,
  address: {
    addressLine: form.addressLine,
    addressNumber: form.addressNumber,
    addressZipCode: form.addressZipCode,
    addressCity: form.addressCity,
    addressState: form.addressState,
    addressStateShortName: form.addressStateShortName,
    addressStreet: form.addressStreet,
    addressCountry: form.addressCountry,
    addressCountryShortName: form.addressCountryShortName,
    addressLatitude: form.addressLatitude,
    addressLongitude: form.addressLongitude,
  },
});

export function* patchCompanyBranch(action) {
  const { messages, form } = action.payload;

  try {
    yield call(Service.updateCompanyBranches, formatPatchPayload(form));
    const response = yield call(Service.get, {});

    yield put({
      type: types.PATCH_COMPANY_BRANCH_SUCCESS,
      payload: formatCompanyBranchToTreeStruct(response.body.data),
      toast: buildToast(messages.patchSuccess, ToastTypes.success),
    });
  } catch (error) {
    let errorMessage = messages.failToFetchError;

    const errorMessages = {
      duplicated_path: messages.duplicatedPathError,
      duplicated_code: messages.duplicatedCodeError,
      invalid_company_branch_id: messages.failToFetchError,
    };

    if (typeof error === 'object' && error.body && error.body.message) {
      errorMessage = errorMessages[error.body.message];

      if (error.statusCode && error.statusCode === 400) {
        yield put({
          type: types.PATCH_COMPANY_BRANCH_FAIL,
          payload: errorMessage,
        });
        return;
      }
    }

    yield put({
      type: types.PATCH_COMPANY_BRANCH_FAIL,
      toast: buildToast(errorMessage, ToastTypes.error),
    });

    yield put({
      type: types.SET_NODE_MODAL_IS_OPEN,
      payload: false,
    });
  }
}

export function* postCompanyBranchesCSV(action) {
  const { payload } = action;

  try {
    yield call(Service.postCompanyBranchesCSV, payload);

    const response = yield call(Service.get, {});

    yield put({
      type: types.POST_COMPANY_BRANCHES_CSV_SUCCESS,
      payload: formatCompanyBranchToTreeStruct(response.body.data),
    });
  } catch (error) {
    let responseError = [];

    if (error.statusCode && error.statusCode === 400) {
      if (error.body.message === 'validation_error') {
        responseError = error.body.data;
      } else {
        responseError = [{ title: error.body.message }];
      }

      yield put({
        type: types.POST_COMPANY_BRANCHES_CSV_FAIL,
        payload: responseError,
      });
      return;
    }

    yield put({
      type: types.POST_COMPANY_BRANCHES_CSV_FAIL,
      toast: buildToast('Ocorreu um erro', ToastTypes.error),
    });
  }
}

export function* deleteCompanyBranch(action) {
  const { messages } = action.payload;
  try {
    yield call(Service.deleteCompanyBranch, action.payload);
    const response = yield call(Service.get, {});
    yield put({
      type: types.DELETE_COMPANY_BRANCH_SUCCESS,
      payload: formatCompanyBranchToTreeStruct(response.body.data),
      toast: buildToast(messages.leafExcluded, ToastTypes.success),
    });
  } catch (error) {
    if (error.body.message === 'branch_has_associated_children') {
      yield put({
        type: types.DELETE_COMPANY_BRANCH_FAIL,
        toast: buildToast(
          messages.branch_has_associated_children,
          ToastTypes.error,
        ),
      });
    } else {
      yield put({
        type: types.DELETE_COMPANY_BRANCH_FAIL,
        toast: buildToast(messages.branch_in_use, ToastTypes.error),
      });
    }
  }
}

function* SetupSaga() {
  yield takeLatest(types.GET_COMPANY_BRANCHES, getCompanyBranchesData);
  yield takeLatest(types.PATCH_COMPANY_BRANCH, patchCompanyBranch);
  yield takeLatest(types.GET_COMPANY_BRANCHES_CSV_TEMPLATE, getCSVTemplate);
  yield takeLatest(types.GET_COMPANY_BRANCHES_CURRENT_CSV, getCurrentCSV);
  yield takeLatest(types.POST_COMPANY_BRANCHES_CSV, postCompanyBranchesCSV);
  yield takeLatest(types.DELETE_COMPANY_BRANCH, deleteCompanyBranch);
}

export default SetupSaga;
