import { asyncActionType, createActionTypes } from '@gupy/front-commons';

const TimelineActionTypes = createActionTypes(
  'Timeline',
  asyncActionType('GET_TIMELINE'),
);

const getTimeline = applicationId => ({
  type: TimelineActionTypes.GET_TIMELINE,
  applicationId,
});

export { TimelineActionTypes, getTimeline };
