import api from '../../api/DarthVaderClient';

const jobOfferTemplatesPath =
  '/communication/companies/users/current/email-templates';

export default {
  post(payload) {
    return api.post(jobOfferTemplatesPath, payload);
  },

  patch(id, payload) {
    return api.patch(`${jobOfferTemplatesPath}/${id}`, payload);
  },

  delete(id) {
    return api.delete(`${jobOfferTemplatesPath}/${id}`);
  },

  get(id) {
    return api.get(`${jobOfferTemplatesPath}/${id}`);
  },

  getAll({ searchString, publicOnly = false, attributes = [] } = {}) {
    const search = searchString ? `&search=${searchString}` : '';
    return api.get(
      `${jobOfferTemplatesPath}?order=name${search}&publicOnly=${publicOnly}&page=0&perPage=9999&type=job_offer${attributes.length ? `&attributes=${attributes}` : ''}`,
    );
  },

  getVariables() {
    return api.get('/selection-process/company/job-offer/template/variables');
  },
};
