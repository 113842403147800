import { asyncActionType, createActionTypes } from '@gupy/front-commons';

export default createActionTypes(
  'RelocateCandidate',
  asyncActionType('OPEN_RELOCATE_CANDIDATE_MODAL'),
  asyncActionType('CLOSE_RELOCATE_CANDIDATE_MODAL'),
  asyncActionType('CHANGE_CANDIDATES_TO_RELOCATE'),
  asyncActionType('RELOCATE_SINGLE_CANDIDATE'),
  asyncActionType('RELOCATE_CANDIDATES'),
  asyncActionType('RELOCATE_ALL_CANDIDATES'),
  asyncActionType('GET_AVAILABLE_JOBS'),
  'RELOCATE_ALL_CANDIDATES_BY_TASK_MANAGER_SUCCESS',
);
