export const CLEAR_FORM = 'approvalWorkflow/CLEAR_FORM';
export const CHANGE_FORM = 'approvalWorkflow/CHANGE_FORM';

export const FETCH_APPROVAL_WORKFLOWS_REQUEST =
  'approvalWorkflow/FETCH_APPROVAL_WORKFLOWS_REQUEST';
export const FETCH_APPROVAL_WORKFLOWS_SUCCESS =
  'approvalWorkflow/FETCH_APPROVAL_WORKFLOWS_SUCCESS';
export const FETCH_APPROVAL_WORKFLOWS_FAILURE =
  'approvalWorkflow/FETCH_APPROVAL_WORKFLOWS_FAILURE';

export const FETCH_APPROVAL_WORKFLOW_REQUEST =
  'approvalWorkflow/FETCH_APPROVAL_WORKFLOW_REQUEST';
export const FETCH_APPROVAL_WORKFLOW_SUCCESS =
  'approvalWorkflow/FETCH_APPROVAL_WORKFLOW_SUCCESS';
export const FETCH_APPROVAL_WORKFLOW_FAILURE =
  'approvalWorkflow/FETCH_APPROVAL_WORKFLOW_FAILURE';

export const FETCH_APPROVERS_REQUEST =
  'approvalWorkflow/FETCH_APPROVERS_REQUEST';
export const FETCH_APPROVERS_SUCCESS =
  'approvalWorkflow/FETCH_APPROVERS_SUCCESS';
export const FETCH_APPROVERS_FAILURE =
  'approvalWorkflow/FETCH_APPROVERS_FAILURE';

export const SAVE_APPROVAL_WORKFLOW_REQUEST =
  'approvalWorkflow/SAVE_APPROVAL_WORKFLOW_REQUEST';
export const SAVE_APPROVAL_WORKFLOW_SUCCESS =
  'approvalWorkflow/SAVE_APPROVAL_WORKFLOW_SUCCESS';
export const SAVE_APPROVAL_WORKFLOW_FAILURE =
  'approvalWorkflow/SAVE_APPROVAL_WORKFLOW_FAILURE';

export const DELETE_APPROVAL_WORKFLOW_REQUEST =
  'approvalWorkflow/DELETE_APPROVAL_WORKFLOW_REQUEST';
export const DELETE_APPROVAL_WORKFLOW_SUCCESS =
  'approvalWorkflow/DELETE_APPROVAL_WORKFLOW_SUCCESS';
export const DELETE_APPROVAL_WORKFLOW_FAILURE =
  'approvalWorkflow/DELETE_APPROVAL_WORKFLOW_FAILURE';

export const UPDATE_APPROVAL_WORKFLOW_REQUEST =
  'approvalWorkflow/UPDATE_APPROVAL_WORKFLOW_REQUEST';
export const UPDATE_APPROVAL_WORKFLOW_SUCCESS =
  'approvalWorkflow/UPDATE_APPROVAL_WORKFLOW_SUCCESS';
export const UPDATE_APPROVAL_WORKFLOW_FAILURE =
  'approvalWorkflow/UPDATE_APPROVAL_WORKFLOW_FAILURE';
