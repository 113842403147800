export const GET_INTERVIEW_ADDRESS_SUGGESTIONS =
  'CHANGE_FORM_ADDRESS_SUGGESTIONS';
export const CHANGE_FORM_ADDRESS_SUGGESTIONS =
  'CHANGE_FORM_ADDRESS_SUGGESTIONS';
export const CHANGE_FORM_ADDRESS_SUGGESTIONS_SUCCESS =
  'CHANGE_FORM_ADDRESS_SUGGESTIONS_SUCCESS';
export const CHANGE_FORM_ADDRESS_SUGGESTIONS_FAIL =
  'CHANGE_FORM_ADDRESS_SUGGESTIONS_FAIL';
export const GET_COUNTRIES = 'GET_COUNTRIES_SUGGESTIONS';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUGGESTIONS_SUCCESS';
export const GET_COUNTRIES_FAIL = 'GET_COUNTRIES_SUGGESTIONS_FAIL';
