import axios from 'axios';
import request from 'request';
import CookieManager from '../managers/CookieManager';
import LocalStorageManager from '../managers/LocalstorageManager';

const headersFactory = customHeader => {
  const cookieManager = new CookieManager();
  const localStorageManager = new LocalStorageManager(window.localStorage);

  const headers = {
    'content-type': 'application/json',
  };

  if (cookieManager.hasLocale()) {
    headers['accept-language'] = cookieManager.getLocale();
  }

  if (localStorageManager.hasCompanyToken()) {
    headers.company_key_auth = localStorageManager.getCompanyToken();
  }

  if (customHeader && customHeader.fulltext) {
    headers.fulltext = customHeader.fulltext;
  }

  return headers;
};

const rawRequest = (
  method,
  url,
  data,
  maxRetries,
  customHeader,
  options = {},
) => {
  let newData = data;
  if (!newData) {
    newData = {};
  }

  return new Promise((resolve, reject) => {
    request(
      {
        url,
        method,
        json: newData,
        headers: headersFactory(customHeader),
        withCredentials: true,
      },
      // eslint-disable-next-line consistent-return
      (error, response, body) => {
        if (error) {
          reject(error);
        } else {
          const moreInfos =
            options && options.headers ? { headers: response.headers } : {};

          const res = {
            ...moreInfos,
            statusCode: response.statusCode,
            body,
          };

          if (body && body.statusCode) {
            res.statusCode = body.statusCode;
          }

          if (res.statusCode === 401) {
            new LocalStorageManager(window.localStorage).clearCompanyToken();

            window.location = '/companies/signin';
          }

          if (res.statusCode >= 300) {
            if (maxRetries) {
              return rawRequest(method, url, data, maxRetries - 1);
            }
            reject(res);
          } else {
            resolve(res);
          }
        }
      },
    );
  });
};

const apiRequest = (method, url, data, maxRetries, customHeader, options) =>
  rawRequest(
    method,
    `${process.env.REACT_APP_API_URL}${url}`,
    data,
    maxRetries,
    customHeader,
    options,
  );

const requestFile = customHeader =>
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: headersFactory(customHeader),
    withCredentials: true,
  });

const DarthVaderClient = {
  rawRequest(verb, url, data, maxRetries) {
    return rawRequest(verb, url, data, maxRetries);
  },

  requestFile(customHeader) {
    return requestFile(customHeader);
  },

  get(url, data, maxRetries, customHeader, options) {
    return apiRequest('GET', url, data, maxRetries, customHeader, options);
  },

  post(url, data, maxRetries, customHeader) {
    return apiRequest('POST', url, data, maxRetries, customHeader);
  },

  put(url, data) {
    return apiRequest('PUT', url, data);
  },

  patch(url, data) {
    return apiRequest('PATCH', url, data);
  },

  delete(url, data) {
    return apiRequest('DELETE', url, data);
  },

  headersFactory,
};

export default DarthVaderClient;
