import { combineReducers } from 'redux';
import * as ActionTypes from './ActionTypes';
import initialValues, {
  validationInitialValues,
} from './components/form/CareerPageInitialValues';

const mapTestimonialsToListItem = testimonials =>
  testimonials
    ? testimonials.map((testimonial, index) => ({
        ...testimonial,
        listId: index + 1,
      }))
    : [];

export default combineReducers({
  careerPages: (state = [], action) => {
    switch (action.type) {
      case ActionTypes.GET_CAREER_PAGES_SUCCESS:
        return action.payload.careerPages;
      default:
        return state;
    }
  },

  isLoading: (state = false, action) => {
    switch (action.type) {
      case ActionTypes.GET_CAREER_PAGES_REQUEST:
        return true;
      case ActionTypes.GET_CAREER_PAGES_SUCCESS:
      case ActionTypes.GET_CAREER_PAGES_FAIL:
        return false;
      default:
        return state;
    }
  },

  uploadingImageList: (state = [], action) => {
    switch (action.type) {
      case ActionTypes.START_IMAGE_UPLOAD:
        return [...state, action.payload.name];
      case ActionTypes.FINISH_IMAGE_UPLOAD:
        return state.filter(s => s !== action.payload.name);
      default:
        return state;
    }
  },

  careerPageForm: (state = initialValues, action) => {
    switch (action.type) {
      case ActionTypes.UPDATE_FORM:
      case ActionTypes.ADD_TEMP_IMAGE:
        return { ...state, ...action.payload };
      case ActionTypes.POPULATE_CAREER_PAGE_MODEL: {
        const { userTestimonials, candidateTestimonials } =
          action.payload.populatedModel;

        return {
          ...initialValues,
          ...action.payload.populatedModel,
          urlInstitutionalImageTotalToShow: action.payload.populatedModel
            .urlInstitutionalImageTotalToShow
            ? action.payload.populatedModel.urlInstitutionalImageTotalToShow.toString()
            : '3',
          userTestimonials: mapTestimonialsToListItem(userTestimonials),
          candidateTestimonials: mapTestimonialsToListItem(
            candidateTestimonials,
          ),
          userTestimonialsIdReference: userTestimonials
            ? userTestimonials.length
            : 0,
          candidateTestimonialsIdReference: candidateTestimonials
            ? candidateTestimonials.length
            : 0,
        };
      }
      case ActionTypes.POST_SUCCESS:
      case ActionTypes.PATCH_SUCCESS:
        return state;
      case ActionTypes.UNSET_CAREER_PAGE_MODEL:
        return { ...initialValues };
      case ActionTypes.CREATE_EMPLOYEE_TESTIMONIAL: {
        const listId = state.userTestimonialsIdReference + 1;
        return {
          ...state,
          userTestimonialsIdReference: listId,
          userTestimonials: [
            { listId, name: '', role: '', testimony: '' },
            ...state.userTestimonials,
          ],
        };
      }
      case ActionTypes.UPDATE_EMPLOYEE_TESTIMONIAL:
        return {
          ...state,
          userTestimonials: state.userTestimonials.map(testimonial => {
            if (testimonial.listId === action.payload.listId) {
              return { ...testimonial, ...action.payload };
            }
            return testimonial;
          }),
        };
      case ActionTypes.DELETE_EMPLOYEE_TESTIMONIAL:
        return {
          ...state,
          userTestimonials: state.userTestimonials.filter(
            testimonial => testimonial.listId !== action.payload.listId,
          ),
        };
      case ActionTypes.CREATE_CANDIDATE_TESTIMONIAL: {
        const listId = state.candidateTestimonialsIdReference + 1;
        return {
          ...state,
          candidateTestimonialsIdReference: listId,
          candidateTestimonials: [
            { listId, name: '', role: '', testimony: '' },
            ...state.candidateTestimonials,
          ],
        };
      }
      case ActionTypes.UPDATE_CANDIDATE_TESTIMONIAL:
        return {
          ...state,
          candidateTestimonials: state.candidateTestimonials.map(
            testimonial => {
              if (testimonial.listId === action.payload.listId) {
                return { ...testimonial, ...action.payload };
              }
              return testimonial;
            },
          ),
        };
      case ActionTypes.DELETE_CANDIDATE_TESTIMONIAL:
        return {
          ...state,
          candidateTestimonials: state.candidateTestimonials.filter(
            testimonial => testimonial.listId !== action.payload.listId,
          ),
        };
      case ActionTypes.CLEAR_CAREER_PAGE:
        return null;
      default:
        return state;
    }
  },

  validation: (state = validationInitialValues, action) => {
    switch (action.type) {
      case ActionTypes.POST_SUCCESS:
      case ActionTypes.PATCH_SUCCESS:
      case ActionTypes.UNSET_CAREER_PAGE_MODEL:
      case ActionTypes.POPULATE_CAREER_PAGE_MODEL:
        return validationInitialValues;
      case ActionTypes.POST_FAIL:
      case ActionTypes.PATCH_FAIL:
        return {
          ...validationInitialValues,
          ...action.error.validation,
        };
      default:
        return state;
    }
  },

  isEditMode: (state = false, action) => {
    switch (action.type) {
      case ActionTypes.POPULATE_CAREER_PAGE_MODEL:
        return true;
      case ActionTypes.UNSET_CAREER_PAGE_MODEL:
        return false;
      default:
        return state;
    }
  },

  isPublished: (state = false, action) => {
    switch (action.type) {
      case ActionTypes.POST_SUCCESS:
      case ActionTypes.PATCH_SUCCESS:
        return true;
      case ActionTypes.GET_CAREER_PAGES_REQUEST:
      case ActionTypes.UNSET_CAREER_PAGE_MODEL:
      case ActionTypes.POST_FAIL:
      case ActionTypes.PATCH_FAIL:
        return false;
      default:
        return state;
    }
  },

  hotjarPool: (state = null, action) => {
    switch (action.type) {
      case ActionTypes.SET_HOTJAR_POOL:
        return action.pool;
      default:
        return state;
    }
  },
});
