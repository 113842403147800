export const UPDATE_USER_CONSENT_REQUEST =
  'USER_CONSENT/TERMS_AND_PRIVACY/UPDATE_REQUEST';
export const UPDATE_USER_CONSENT_REQUEST_FAIL =
  'USER_CONSENT/TERMS_AND_PRIVACY/UPDATE_REQUEST_FAIL';
export const UPDATE_USER_CONSENT_FULFILLED =
  'USER_CONSENT/TERMS_AND_PRIVACY/UPDATE_FULFILLED';

export const updateUserConsent = {
  request: () => ({ type: UPDATE_USER_CONSENT_REQUEST }),
  fulfilled: () => ({ type: UPDATE_USER_CONSENT_FULFILLED }),
};
