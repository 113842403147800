import { Button, Chip, Tip } from '@gupy/design-system';
import { MainSelectList as SelectList } from '@gupy/front-commons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import LocalstorageConstants from '../../../constants/LocalstorageConstants';
import GroupTypeKnowMoreDialog from './GroupTypeKnowMoreDialog';
import GroupTypeNewDialog from './GroupTypeNewDialog';
import './GroupType.scss';

const propTypes = {
  messages: PropTypes.object.isRequired,
  value: PropTypes.array,
  onGroupTypeChange: PropTypes.func.isRequired,
};

const GroupType = ({ messages, value, onGroupTypeChange }) => {
  const newModalStorageKey = LocalstorageConstants.groupTypeNewModal;
  const newModalStorageItem = localStorage.getItem(newModalStorageKey);

  const [isKnowMoreModalOpen, updateIsKnowMoreModalOpen] = useState(false);
  const [isNewModalOpen, updateIsNewModalOpen] = useState(!newModalStorageItem);

  const handleKnowMoreClick = () => {
    updateIsKnowMoreModalOpen(true);
  };

  const handleCloseKnowMoreModal = () => {
    updateIsKnowMoreModalOpen(false);
  };

  const handleCloseNewModal = () => {
    localStorage.setItem(newModalStorageKey, 'true');
    updateIsNewModalOpen(false);
  };

  const label = (
    <label>
      <span className="group-type__select-label">
        {messages.selectGroupType}
      </span>
      <Chip color="blueSecondary">{messages.new}</Chip>
    </label>
  );

  return (
    <div className="group-type">
      <div className="group-type__select">
        <SelectList
          id="group-type"
          name="groupType"
          className="group-type__select-list"
          label={label}
          value={value}
          noItemsSelectedLabel={messages.selectOption}
          onChange={event => onGroupTypeChange(event)}
          data={[
            { label: messages.managementGroup, value: 'management' },
            { label: messages.recruitmentGroup, value: 'recruitment' },
            { label: messages.admissionGroup, value: 'admission' },
          ]}
          multiSelect
          responsive
        />
      </div>
      <Tip
        id="group-type-hint"
        type="hint"
        showIcon
        text={
          <span>
            {messages.groupTypeHint}{' '}
            <Button
              type="link"
              size="sm"
              className="group-type__hint-link"
              onClick={handleKnowMoreClick}
            >
              {messages.groupTypeHintBtn}
            </Button>
          </span>
        }
        inverted
      />
      <GroupTypeKnowMoreDialog
        messages={messages}
        isModalOpen={isKnowMoreModalOpen}
        handleCloseModal={handleCloseKnowMoreModal}
      />
      <GroupTypeNewDialog
        messages={messages}
        isModalOpen={isNewModalOpen}
        handleCloseModal={handleCloseNewModal}
      />
    </div>
  );
};

GroupType.propTypes = propTypes;

export default GroupType;
