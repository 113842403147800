import { combineReducers } from 'redux';
import { JobOfferActionTypes } from './JobOfferActions';

export const isLoading = (state = false, action) => {
  switch (action.type) {
    case JobOfferActionTypes.SAVE:
    case JobOfferActionTypes.DELETE:
    case JobOfferActionTypes.CANCEL:
    case JobOfferActionTypes.GET:
      return true;
    case JobOfferActionTypes.GET_SUCCESS:
    case JobOfferActionTypes.GET_FAIL:
    case JobOfferActionTypes.DELETE_SUCCESS:
    case JobOfferActionTypes.DELETE_FAIL:
    case JobOfferActionTypes.CANCEL_SUCCESS:
    case JobOfferActionTypes.CANCEL_FAIL:
    case JobOfferActionTypes.SAVE_SUCCESS:
    case JobOfferActionTypes.SAVE_FAIL:
      return false;
    default:
      return state;
  }
};

export const jobOffers = (state = [], action) => {
  switch (action.type) {
    case JobOfferActionTypes.GET_ALL_SUCCESS:
      return action.jobOffers;
    default:
      return state;
  }
};

export const selectedOfferId = (state = null, action) => {
  switch (action.type) {
    case JobOfferActionTypes.SELECT_OFFER_BY_ID:
      return action.payload.selectedOfferId;
    case JobOfferActionTypes.CLEAR_SELECTED_OFFER_ID:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  isLoading,
  jobOffers,
  selectedOfferId,
});
