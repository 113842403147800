import PropTypes from 'prop-types';
import React from 'react';
import '@gupy/design-system/Styles/base/_table.scss';
import JobCustomFormTemplateMenuButton from './JobCustomFormTemplateMenuButton';
import './JobCustomFormTemplateTable.scss';

const propTypes = {
  handleItemSort: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  messages: PropTypes.object.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  onEditItem: PropTypes.func.isRequired,
};

const JobCustomFormTemplateTable = ({
  handleItemSort,
  items,
  messages,
  onDeleteItem,
  onEditItem,
}) => {
  const itemsCount = items.length;
  const dataRows = items.map((item, index) => (
    <tr key={`data-row-${item.key}`}>
      <td data-testid={`title-data-row-${index}`}>
        {itemsCount > 1 ? (
          <span className="job-custom-form-template-table__index">
            {index + 1}.{' '}
          </span>
        ) : null}
        {item.title}
      </td>
      <td>{item.dataType}</td>
      <td>{item.required}</td>
      <td>
        <JobCustomFormTemplateMenuButton
          handleItemSort={handleItemSort}
          index={index}
          item={item}
          itemsCount={itemsCount}
          messages={messages}
          onDeleteItem={onDeleteItem}
          onEditItem={onEditItem}
        />
      </td>
    </tr>
  ));

  return (
    <table
      className={
        'gupy-table gupy-table-100 gupy-table--with-actions job-custom-form-template-table}'
      }
    >
      <thead>
        <tr>
          <th>{messages.fieldTableHeader}</th>
          <th>{messages.fieldTypeTableHeader}</th>
          <th>{messages.fieldRequiredTableHeader}</th>
          <th />
        </tr>
      </thead>
      <tbody>{dataRows}</tbody>
    </table>
  );
};

JobCustomFormTemplateTable.propTypes = propTypes;

export default JobCustomFormTemplateTable;
