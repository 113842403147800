import { combineReducers } from 'redux';
import ProfileTestActionTypes from '../../constants/ProfileTest/ProfileTestActionTypes';
import * as AcceptCookieDialogActions from '../AcceptCookieDialog/AcceptCookieDialogActions';
import { SAVE_FEATURE_FLAGS_SUCCESS } from '../RootSetup/redux/RootSetupTypes';
import { UPDATE_USER_CONSENT_FULFILLED } from '../TermsAndPrivacy/actions';
import * as TermsAndPrivacyUpdateNoticeActions from '../TermsAndPrivacy/TermsAndPrivacyUpdateNotice/TermsAndPrivacyUpdateNoticeActions';
import {
  AuthenticationActionTypes,
  ForgotPasswordActionTypes,
  SetPasswordActionTypes,
  SigninActionTypes,
} from './ActionTypes';

const isSignOut = (state = false, action) => {
  switch (action.type) {
    case SigninActionTypes.INIT_SIGNIN:
      return false;
    case SigninActionTypes.SIGNOUT_SUCCESS:
    case SigninActionTypes.SIGNOUT_FAIL:
    case SigninActionTypes.IDP_SIGNOUT_SUCCESS:
      return true;
    default:
      return state;
  }
};

const isAuthenticated = (state = false, action) => {
  switch (action.type) {
    case SigninActionTypes.POST_SUCCESS:
    case SetPasswordActionTypes.POST_SUCCESS:
    case SigninActionTypes.SIGNIN_WITH_COOKIE_SUCCESS:
    case SigninActionTypes.SIGNIN_WITH_SOCIAL_OR_SAML_SUCCESS:
      return true;
    case SigninActionTypes.POST_FAIL:
    case SetPasswordActionTypes.POST_FAIL:
    case SigninActionTypes.SIGNIN_WITH_COOKIE_FAIL:
    case SigninActionTypes.SIGNOUT_SUCCESS:
    case SigninActionTypes.SIGNOUT_FAIL:
      return false;
    default:
      return state;
  }
};

const useSsoLogin = (state = false, action) => {
  switch (action.type) {
    case AuthenticationActionTypes.SET_SSO_LOGIN:
      return action.useSsoLogin;
    default:
      return state;
  }
};

const currentUser = (state = null, action) => {
  switch (action.type) {
    case SigninActionTypes.POST_SUCCESS:
    case SigninActionTypes.SIGNIN_WITH_COOKIE_SUCCESS:
    case SigninActionTypes.SIGNIN_WITH_SOCIAL_OR_SAML_SUCCESS:
    case SigninActionTypes.RELOAD_CURRENT_USER_SUCCESS:
    case SetPasswordActionTypes.POST_SUCCESS: {
      return action.currentUser;
    }
    case ProfileTestActionTypes.CLOSE_TEST_SUCCESS:
    case ProfileTestActionTypes.FINISH_TEST_SUCCESS: {
      return {
        ...state,
        shouldTakeProfileTest: false,
      };
    }
    case SigninActionTypes.POST_FAIL:
    case SigninActionTypes.SIGNIN_WITH_COOKIE_FAIL:
    case SigninActionTypes.SIGNOUT:
    case SetPasswordActionTypes.POST_FAIL: {
      return null;
    }
    case SAVE_FEATURE_FLAGS_SUCCESS:
      return {
        ...state,
        featureFlags: action.payload,
      };

    case AcceptCookieDialogActions.ACCEPT_COOKIE_SUCCESS: {
      return {
        ...state,
        cookieAcceptedAt: action.payload,
      };
    }
    case AcceptCookieDialogActions.ACCEPT_COOKIE_FAIL:
      return state;

    case TermsAndPrivacyUpdateNoticeActions.GET_SHOW_NEW_TERMS_AND_PRIVACY_SUCCESS: {
      return {
        ...state,
        showNewTermsAndPrivacy: action.payload,
      };
    }
    case TermsAndPrivacyUpdateNoticeActions.GET_SHOW_NEW_TERMS_AND_PRIVACY_FAIL:
      return state;

    case UPDATE_USER_CONSENT_FULFILLED:
      return {
        ...state,
        showNewTermsAndPrivacy: false,
      };

    default:
      return state;
  }
};

export const setPasswordValidation = (state = {}, action) => {
  switch (action.type) {
    case SetPasswordActionTypes.VALIDATE_TOKEN:
    case SetPasswordActionTypes.INIT_COMPANY_LANGUAGE:
    case SetPasswordActionTypes.POST:
      return {};
    case SetPasswordActionTypes.POST_VALIDATION_FAIL:
    case SetPasswordActionTypes.POST_FAIL:
      return action.validation;
    default:
      return state;
  }
};

const setPasswordIsPosting = (state = false, action) => {
  switch (action.type) {
    case SetPasswordActionTypes.POST:
      return true;
    case SetPasswordActionTypes.POST_SUCCESS:
    case SetPasswordActionTypes.POST_FAIL:
    case SetPasswordActionTypes.POST_VALIDATION_FAIL:
      return false;
    default:
      return state;
  }
};

const isTokenValid = (state = false, action) => {
  switch (action.type) {
    case SetPasswordActionTypes.VALIDATE_TOKEN_SUCCESS:
      return action.isTokenValid;
    case SetPasswordActionTypes.VALIDATE_TOKEN_FAIL:
      return false;
    default:
      return state;
  }
};

const errorType = (state = null, action) => {
  if (action.type === SetPasswordActionTypes.VALIDATE_TOKEN_SUCCESS) {
    return action.errorType || null;
  }
  return state;
};

const isValidatingToken = (state = false, action) => {
  switch (action.type) {
    case SetPasswordActionTypes.VALIDATE_TOKEN:
      return true;
    case SetPasswordActionTypes.VALIDATE_TOKEN_SUCCESS:
    case SetPasswordActionTypes.VALIDATE_TOKEN_FAIL:
      return false;
    default:
      return state;
  }
};

const isPosting = (state = false, action) => {
  switch (action.type) {
    case SigninActionTypes.POST:
      return true;
    case SigninActionTypes.POST_FAIL:
    case SigninActionTypes.POST_SUCCESS:
    case SigninActionTypes.POST_VALIDATION_FAIL:
      return false;
    default:
      return state;
  }
};

const signinWithCookieResult = (state = null, action) => {
  switch (action.type) {
    case SigninActionTypes.SIGNIN_WITH_COOKIE:
      return null;
    case SigninActionTypes.SIGNIN_WITH_COOKIE_SUCCESS:
      return 'success';
    case SigninActionTypes.SIGNIN_WITH_COOKIE_FAIL:
      return 'error';
    default:
      return state;
  }
};

const validation = (state = {}, action) => {
  switch (action.type) {
    case SigninActionTypes.POST:
    case SigninActionTypes.INIT_SIGNIN:
      return {};
    case SigninActionTypes.POST_VALIDATION_FAIL:
    case SigninActionTypes.POST_FAIL:
      return action.validation;
    default:
      return state;
  }
};

const companyInitialState = {
  isPosting: false,
  signinWithCookieResult: null,
  validation: {},
  company: {},
};

const company = (state = companyInitialState, action) => {
  switch (action.type) {
    case SigninActionTypes.GET_COMPANY_ID_BY_SUBDOMAIN_SUCCESS: {
      return {
        ...state,
        id: action.payload.companyId,
        isSamlLoginEnabled: action.payload.isSamlLoginEnabled,
      };
    }
    case SigninActionTypes.INIT_SIGNIN_SUCCESS: {
      const { careerPages } = action.companySummary;
      const hasSingleCareerPage = careerPages && careerPages.length === 1;
      const defaultCareerPage = hasSingleCareerPage ? careerPages[0] : null;
      return {
        ...action.companySummary,
        defaultCareerPage,
      };
    }
    default:
      return state;
  }
};

const companyError = (state = null, action) => {
  switch (action.type) {
    case SigninActionTypes.GET_COMPANY_ID_BY_SUBDOMAIN_FAIL:
      return action.error;
    default:
      return state;
  }
};

const initSigninError = (state = null, action) => {
  switch (action.type) {
    case SigninActionTypes.INIT_SIGNIN_FAIL:
      return action.error;
    default:
      return state;
  }
};

const samlLoginRequest = (_, action) => {
  switch (action.type) {
    case SigninActionTypes.CREATE_SAML_LOGIN_REQUEST_SUCCESS:
      return action.request;
    default:
      return null;
  }
};

export const emailSent = (state = false, action) => {
  switch (action.type) {
    case ForgotPasswordActionTypes.INIT_FORGOT_PASSWORD:
      return false;
    case ForgotPasswordActionTypes.POST_SUCCESS:
      return true;
    default:
      return state;
  }
};

const isForgotPasswordPosting = (state = false, action) => {
  switch (action.type) {
    case ForgotPasswordActionTypes.POST:
      return true;
    case ForgotPasswordActionTypes.POST_SUCCESS:
    case ForgotPasswordActionTypes.POST_FAIL:
      return false;
    default:
      return state;
  }
};

const forgotPasswordValidation = (state = {}, action) => {
  switch (action.type) {
    case ForgotPasswordActionTypes.INIT_FORGOT_PASSWORD:
    case ForgotPasswordActionTypes.POST:
      return {};
    case ForgotPasswordActionTypes.POST_FAIL:
      return action.validation;
    default:
      return state;
  }
};

const exchangedToken = (state = false, action) => {
  switch (action.type) {
    case SigninActionTypes.EXCHANGE_TOKEN:
    case SigninActionTypes.EXCHANGE_TOKEN_FAIL:
      return false;
    case SigninActionTypes.EXCHANGE_TOKEN_SUCCESS:
      return true;
    default:
      return state;
  }
};

const keepOrChangeCompany = (state = {}, action) => {
  switch (action.type) {
    case SigninActionTypes.KEEP_OR_CHANGE_COMPANY_SUCCESS:
      return {
        should: true,
        subdomain: action.subdomain,
        email: action.email,
      };
    case SigninActionTypes.KEEP_OR_CHANGE_COMPANY_FAIL:
      return {};
    default:
      return state;
  }
};

export const Authentication = combineReducers({
  useSsoLogin,
  isAuthenticated,
  currentUser,
  exchangedToken,
  keepOrChangeCompany,
});

export const SetPassword = combineReducers({
  isPosting: setPasswordIsPosting,
  validation: setPasswordValidation,
  isTokenValid,
  errorType,
  isValidatingToken,
});

export const Signin = combineReducers({
  isPosting,
  signinWithCookieResult,
  validation,
  company,
  companyError,
  initSigninError,
  samlLoginRequest,
});

export const SignOut = combineReducers({
  isSignOut,
});

export const ForgotPassword = combineReducers({
  isPosting: isForgotPasswordPosting,
  emailSent,
  validation: forgotPasswordValidation,
});
