import api from '../../api/DarthVaderClient';

export default {
  validateToken(token, userId) {
    return api.post('/authentication/company/account/validate-token', {
      token,
      userId,
    });
  },

  postReset(payload) {
    return api.post('/authentication/company/account/define-password', payload);
  },
};
