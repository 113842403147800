import { combineReducers } from 'redux';
import { GupyBadgeFeedbackInsightsActionTypes } from './GupyBadgeFeedbackInsightsAction';

export const isLoading = (state = true, action) => {
  switch (action.type) {
    case GupyBadgeFeedbackInsightsActionTypes.INIT_GUPY_BADGE_FEEDBACK_JOB_INSIGHTS:
      return true;
    case GupyBadgeFeedbackInsightsActionTypes.INIT_GUPY_BADGE_FEEDBACK_JOB_INSIGHTS_SUCCESS:
    case GupyBadgeFeedbackInsightsActionTypes.INIT_GUPY_BADGE_FEEDBACK_JOB_INSIGHTS_FAIL:
      return false;
    default:
      return state;
  }
};

export const gupyBadgeFeedbackInsightsResult = (state = null, action) => {
  switch (action.type) {
    case GupyBadgeFeedbackInsightsActionTypes.INIT_GUPY_BADGE_FEEDBACK_JOB_INSIGHTS_SUCCESS:
      return action.gupyBadgeFeedbackJobInsightsResult;
    default:
      return state;
  }
};

export const jobsIsLoading = (state = true, action) => {
  switch (action.type) {
    case GupyBadgeFeedbackInsightsActionTypes.GET_JOB_POSTINGS_WITHOUT_FEEDBACK:
    case GupyBadgeFeedbackInsightsActionTypes.GET_INACTIVE_JOB_POSTINGS:
    case GupyBadgeFeedbackInsightsActionTypes.NEXT_PAGE_JOB_POSTINGS_WITHOUT_FEEDBACK:
    case GupyBadgeFeedbackInsightsActionTypes.NEXT_PAGE_INACTIVE_JOB_POSTINGS:
      return true;
    case GupyBadgeFeedbackInsightsActionTypes.GET_JOB_POSTINGS_WITHOUT_FEEDBACK_SUCCESS:
    case GupyBadgeFeedbackInsightsActionTypes.GET_JOB_POSTINGS_WITHOUT_FEEDBACK_FAIL:
    case GupyBadgeFeedbackInsightsActionTypes.GET_INACTIVE_JOB_POSTINGS_SUCCESS:
    case GupyBadgeFeedbackInsightsActionTypes.GET_INACTIVE_JOB_POSTINGS_FAIL:
    case GupyBadgeFeedbackInsightsActionTypes.NEXT_PAGE_JOB_POSTINGS_WITHOUT_FEEDBACK_SUCCESS:
    case GupyBadgeFeedbackInsightsActionTypes.NEXT_PAGE_JOB_POSTINGS_WITHOUT_FEEDBACK_FAIL:
    case GupyBadgeFeedbackInsightsActionTypes.NEXT_PAGE_INACTIVE_JOB_POSTINGS_SUCCESS:
    case GupyBadgeFeedbackInsightsActionTypes.NEXT_PAGE_INACTIVE_JOB_POSTINGS_FAIL:
      return false;
    default:
      return state;
  }
};

export const jobPostingsWithoutFeedback = (state = [], action) => {
  switch (action.type) {
    case GupyBadgeFeedbackInsightsActionTypes.GET_JOB_POSTINGS_WITHOUT_FEEDBACK_SUCCESS:
      return action.getJobPostingsWithoutFeedbackResult.data;
    case GupyBadgeFeedbackInsightsActionTypes.NEXT_PAGE_JOB_POSTINGS_WITHOUT_FEEDBACK_SUCCESS:
      return [...state, ...action.getJobPostingsWithoutFeedbackResult.data];
    default:
      return state;
  }
};

export const jobPostingsWithoutFeedbackSummary = (state = {}, action) => {
  switch (action.type) {
    case GupyBadgeFeedbackInsightsActionTypes.GET_JOB_POSTINGS_WITHOUT_FEEDBACK_SUCCESS:
    case GupyBadgeFeedbackInsightsActionTypes.NEXT_PAGE_JOB_POSTINGS_WITHOUT_FEEDBACK_SUCCESS:
      return action.getJobPostingsWithoutFeedbackResult.summary;
    default:
      return state;
  }
};

export const inactiveJobPostings = (state = [], action) => {
  switch (action.type) {
    case GupyBadgeFeedbackInsightsActionTypes.GET_INACTIVE_JOB_POSTINGS_SUCCESS:
      return action.getInactiveJobPostingsResult.data;
    case GupyBadgeFeedbackInsightsActionTypes.NEXT_PAGE_INACTIVE_JOB_POSTINGS_SUCCESS:
      return [...state, ...action.getInactiveJobPostingsResult.data];
    default:
      return state;
  }
};

export const inactiveJobPostingsSummary = (state = {}, action) => {
  switch (action.type) {
    case GupyBadgeFeedbackInsightsActionTypes.GET_INACTIVE_JOB_POSTINGS_SUCCESS:
    case GupyBadgeFeedbackInsightsActionTypes.NEXT_PAGE_INACTIVE_JOB_POSTINGS_SUCCESS:
      return action.getInactiveJobPostingsResult.summary;
    default:
      return state;
  }
};

export default combineReducers({
  isLoading,
  gupyBadgeFeedbackInsightsResult,
  jobsIsLoading,
  jobPostingsWithoutFeedback,
  jobPostingsWithoutFeedbackSummary,
  inactiveJobPostings,
  inactiveJobPostingsSummary,
});
