export const API_ACCESS_POLICY_LIST_SUCCESS = 'ApiAccessPolicy/LIST_SUCCESS';
export const API_ACCESS_POLICY_LIST_FAIL = 'ApiAccessPolicy/LIST_FAIL';
export const API_ACCESS_POLICY_LIST = 'ApiAccessPolicy/LIST';

export const API_ACCESS_POLICY_LIST_ROUTES_SUCCESS =
  'apiAccessPolicy/LIST_ROUTES_SUCCESS';
export const API_ACCESS_POLICY_LIST_ROUTES_FAIL =
  'apiAccessPolicy/LIST_ROUTES_FAIL';
export const API_ACCESS_POLICY_LIST_ROUTES = 'apiAccessPolicy/LIST_ROUTES';

export const API_ACCESS_POLICY_LIST_PERMISSION =
  'apiAccessPolicy/LIST_PERMISSION';
export const API_ACCESS_POLICY_LIST_PERMISSION_SUCCESS =
  'apiAccessPolicy/LIST_PERMISSION_SUCCESS';
export const API_ACCESS_POLICY_LIST_PERMISSION_FAIL =
  'apiAccessPolicy/LIST_PERMISSION_FAIL';

export const API_ACCESS_POLICY_DELETE_MODAL = 'apiAccessPolicy/DELETE_MODAL';
export const API_ACCESS_POLICY_DELETE = 'apiAccessPolicy/DELETE_PERMISSION';
export const API_ACCESS_POLICY_DELETE_SUCCESS =
  'apiAccessPolicy/DELETE_PERMISSION_SUCCESS';
export const API_ACCESS_POLICY_DELETE_FAIL =
  'apiAccessPolicy/DELETE_PERMISSION_FAIL';

export const API_ACCESS_POLICY_TOGGLE_PERMISSIONS =
  'apiAccessPolicy/TOGGLE_PERMISSION';
export const API_ACCESS_POLICY_TOGGLE_HEADER_PERMISSIONS =
  'apiAccessPolicy/TOGGLE_HEADER_PERMISSION';
export const API_ACCESS_POLICY_TOGGLE_VERSIONS =
  'apiAccessPolicy/TOGGLE_VERSION';
export const API_ACCESS_POLICY_SET_POLICY = 'apiAccessPolicy/SET_POLICY';
export const API_ACCESS_POLICY_TOGGLE_CATEGORY =
  'apiAccessPolicy/COLLAPSE_CATEGORY';
export const API_ACCESS_PERMISSIONS_CLEAR = 'apiAccessPolicy/CLEAR_PERMISSION';
export const API_ACCESS_PERMISSIONS_UPDATE_DESCRIPTION =
  'apiAccessPolicy/UPDATE_DESCRIPTION';

export const POST_API_ACCESS_PERMISSIONS = 'apiAccessPolicy/POST_PERMISSION';
export const POST_API_ACCESS_PERMISSIONS_SUCCESS =
  'apiAccessPolicy/POST_PERMISSION_SUCCESS';
export const POST_API_ACCESS_PERMISSIONS_FAIL =
  'apiAccessPolicy/POST_PERMISSION_FAIL';
