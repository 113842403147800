import { Button, Dialog, ProgressBar, Tip } from '@gupy/design-system';
import { Checkbox } from '@gupy/design-system-v2';
import { useKeycloak } from '@react-keycloak/web';
import { sanitize } from 'dompurify';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { useDeleteChildApp } from '../../../../hooks/useDeleteChildApp';
import { useSaveChildAppDeleteEmail } from '../../../../hooks/useSaveChildAppDeleteEmail';
import { initRscLinkedinIntegration } from '../../../../IntegrationsAction';
import './DeleteInLinkedinDialog.scss';

export const DeleteInLinkedinStepEnum = Object.freeze({
  sendEmail: '1',
  waitLinkedin: '2',
  confirmSuccess: '3',
});

const DeleteInLinkedinDialog = ({
  messages,
  childApp,
  open,
  setOpen,
  refreshChildAppList,
}) => {
  const [step, setStep] = useState(
    childApp.hasDeleteEmail
      ? DeleteInLinkedinStepEnum.waitLinkedin
      : DeleteInLinkedinStepEnum.sendEmail,
  );

  const [emailSentChecked, setEmailSentChecked] = useState(false);
  const [hasDeleteError, setHasDeleteError] = useState(false);

  const {
    keycloak: { token },
  } = useKeycloak();

  const { saveChildAppDeleteEmail } = useSaveChildAppDeleteEmail({
    onSuccess: () => {
      setStep(DeleteInLinkedinStepEnum.waitLinkedin);
    },
  });

  const { deleteChildApp } = useDeleteChildApp({
    onSuccess: () => {
      setStep(DeleteInLinkedinStepEnum.confirmSuccess);
    },
    onError: () => {
      setHasDeleteError(true);
    },
  });

  const handleSaveChildAppDeleteEmail = () => {
    saveChildAppDeleteEmail({ childAppId: childApp.id, token });
  };

  const handleDeleteChildApp = () => {
    deleteChildApp({ childAppId: childApp.id, token });
  };

  const handleRefreshChildAppList = () => {
    refreshChildAppList(true, token);
    setOpen(false);
  };

  const dialogData = {
    [DeleteInLinkedinStepEnum.sendEmail]: {
      size: 'md',
      title: messages.rscDeleteInLinkedinDialogTitle1,
      content: (
        <Fragment>
          <p>{messages.rscDeleteInLinkedinStep1Slice1}</p>
          <p>{messages.rscDeleteInLinkedinStep1Slice2}</p>
          <div className="email-template-tip">
            <Tip
              type="danger"
              showIcon
              text={messages.rscDeleteInLinkedinStep1Slice3}
              inverted
            />
            <a
              className="email-template-tip-link"
              href="https://www.linkedin.com/help/linkedin/answer/a415420/associate-your-linkedin-company-id-with-the-linkedin-job-board-faqs"
              target="_blank"
              rel="noopener noreferrer"
            >
              {messages.rscDeleteInLinkedinStep1Slice4}
            </a>
          </div>

          <p>
            {messages.rscDeleteInLinkedinStep1Slice5}{' '}
            <b>ltsatsintegrations@linkedin.com</b>
          </p>
          <div className="email-template-section">
            <div className="email-template-copy">
              {messages.rscDeleteInLinkedinStep1Slice6}
              <CopyToClipboard
                text={messages.rscDeleteInLinkedinStep1EmailTemplate}
                options={{
                  format: 'text/plain',
                  onCopy: clipboardData => {
                    clipboardData.setData(
                      'text/html',
                      messages.rscDeleteInLinkedinStep1EmailTemplate,
                    );
                  },
                }}
              >
                <Button type="text" icon="FileCopyOutlined">
                  {messages.rscDeleteInLinkedinStep1CopyButton}
                </Button>
              </CopyToClipboard>
            </div>
            <div
              className="email-template"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  messages.rscDeleteInLinkedinStep1EmailTemplate,
                ),
              }}
            />
            <Checkbox
              checked={emailSentChecked}
              onCheckboxChange={() => setEmailSentChecked(!emailSentChecked)}
            >
              {messages.rscDeleteInLinkedinStep1Checkbox}
            </Checkbox>
          </div>
        </Fragment>
      ),
      footer: {
        primaryButton: {
          label: messages.rscDeleteInLinkedinDialogContinueButton,
          disabled: !emailSentChecked,
          action: () => {
            handleSaveChildAppDeleteEmail();
          },
        },
      },
      onClose: () => setOpen(false),
    },
    [DeleteInLinkedinStepEnum.waitLinkedin]: {
      size: 'sm',
      title: messages.rscDeleteInLinkedinDialogTitle2,
      content: (
        <Fragment>
          {hasDeleteError && (
            <Tip
              type="danger"
              showIcon
              text={messages.rscDeleteInLinkedinStep2ErrorTip}
              inverted
            />
          )}
          <p>{messages.rscDeleteInLinkedinStep2Slice1}</p>
          <p>{messages.rscDeleteInLinkedinStep2Slice2}</p>
          <p>{messages.rscDeleteInLinkedinStep2Slice3}</p>
        </Fragment>
      ),
      footer: {
        primaryButton: {
          label: messages.rscDeleteInLinkedinDialogConfirmButton,
          action: () => {
            handleDeleteChildApp();
          },
        },
        secondaryButton: {
          label: messages.rscDeleteInLinkedinDialogWaitButton,
          action: () => {
            setOpen(false);
          },
        },
      },
      onClose: () => setOpen(false),
    },
    [DeleteInLinkedinStepEnum.confirmSuccess]: {
      size: 'sm',
      title: messages.rscDeleteInLinkedinDialogTitle3,
      content: (
        <Fragment>
          <p>{messages.rscDeleteInLinkedinStep3Slice1}</p>
        </Fragment>
      ),
      footer: {
        primaryButton: {
          label: messages.rscDeleteInLinkedinDialogOkButton,
          action: () => {
            handleRefreshChildAppList();
          },
        },
      },
      onClose: () => handleRefreshChildAppList(),
    },
  };

  return (
    <Dialog
      title={dialogData[step].title}
      maxWidth={dialogData[step].size}
      open={open}
      onClose={dialogData[step].onClose}
      footer={dialogData[step].footer}
    >
      <div className="job-board-panel-rsc-child-apps__delete-in-linkedin-dialog__header">
        <span>{`${messages.rscDeleteInLinkedinStep} ${step}/3`}</span>
        <ProgressBar
          total={3}
          completed={step}
          withSolidColor
          withSteps={false}
        />
      </div>
      <div className="job-board-panel-rsc-child-apps__delete-in-linkedin-dialog__content">
        {dialogData[step].content}
      </div>
    </Dialog>
  );
};

DeleteInLinkedinDialog.propTypes = {
  messages: PropTypes.object.isRequired,
  childApp: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  refreshChildAppList: PropTypes.func.isRequired,
};

export default connect(null, {
  refreshChildAppList: initRscLinkedinIntegration,
})(DeleteInLinkedinDialog);
