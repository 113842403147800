import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  termsLabel: {
    id: 'terms-label',
    defaultMessage: 'Termos e condições de uso',
  },
  termsTitle: {
    id: 'terms-title',
    defaultMessage: 'Termos e condições de uso',
  },
  termsConfirmButtonText: {
    id: 'terms-confirm-button-text',
    defaultMessage: 'Ok, entendi',
  },
  privacyLabel: {
    id: 'privacy-label',
    defaultMessage: 'Política de Privacidade',
  },
  privacyTitle: {
    id: 'privacy-title',
    defaultMessage: 'Política de privacidade da Gupy para empresas',
  },
  privacyConfirmButtonText: {
    id: 'privacy-confirm-button-text',
    defaultMessage: 'Ok, entendi',
  },
});

export const getTermsAndPrivacyMessages = intl =>
  formatMessages(intl, messages);
