import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import NotFoundPage from './404';
import ErrorPage from './500';

const propTypes = {
  isNotFoundError: PropTypes.bool.isRequired,
};

export const CompanyError = ({ isNotFoundError }) => {
  if (isNotFoundError) {
    return (
      <NotFoundPage
        title={
          <FormattedMessage
            id="company_not_found"
            defaultMessage="Ops! Empresa não encontrada."
          />
        }
      />
    );
  }

  return <ErrorPage />;
};

CompanyError.propTypes = propTypes;
