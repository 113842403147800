import { IndicatorsHomeInsightsActionTypes } from './ActionTypes';

export const initialState = {
  candidatesWithoutFeedbackInClosedJobs: 0,
  expiredPublishedJobs: 0,
  isLoadingCandidatesWithoutFeedbackInClosedJobs: true,
  isLoadingExpiredPublishedJobs: true,
};

const IndicatorsHomeInsights = (state = initialState, action) => {
  switch (action.type) {
    case IndicatorsHomeInsightsActionTypes.GET_EXPIRED_PUBLISHED_JOBS_SUCCESS: {
      return {
        ...state,
        expiredPublishedJobs: action.payload,
        isLoadingExpiredPublishedJobs: false,
      };
    }
    case IndicatorsHomeInsightsActionTypes.GET_EXPIRED_PUBLISHED_JOBS_FAIL: {
      return {
        ...state,
        expiredPublishedJobs: 0,
        isLoadingExpiredPublishedJobs: false,
      };
    }
    case IndicatorsHomeInsightsActionTypes.GET_CANDIDATES_WITHOUT_FEEDBACK_IN_CLOSED_JOBS_SUCCESS: {
      return {
        ...state,
        candidatesWithoutFeedbackInClosedJobs: action.payload,
        isLoadingCandidatesWithoutFeedbackInClosedJobs: false,
      };
    }
    case IndicatorsHomeInsightsActionTypes.GET_CANDIDATES_WITHOUT_FEEDBACK_IN_CLOSED_JOBS_FAIL: {
      return {
        ...state,
        candidatesWithoutFeedbackInClosedJobs: 0,
        isLoadingCandidatesWithoutFeedbackInClosedJobs: false,
      };
    }
    default:
      return state;
  }
};

export default IndicatorsHomeInsights;
