import { Card, FooterButtons, Icon, IconEnum } from '@gupy/design-system';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import './JobCustomFormTemplateSave.scss';

const propTypes = {
  messages: PropTypes.object.isRequired,
  onSaveCompanySchema: PropTypes.func.isRequired,
  onCancelCompanySchema: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

const JobCustomFormTemplateSave = ({
  messages,
  onSaveCompanySchema,
  onCancelCompanySchema,
  isSaving,
}) => (
  <Card id="job-custom-form-template-save" className="custom-form-save">
    <div className="custom-form-save__content">
      <div className="custom-form-save__icon">
        <Icon icon={IconEnum.ErrorOutline} />
        <div>{messages.deletedQuestionAlert}</div>
      </div>
      <p className="custom-form-save__text">
        <FormattedHTMLMessage
          id="save-info"
          defaultMessage="As alterações dos campos só serão refletidas em <strong>novas vagas</strong> e para que elas ocorram é necessário que você <strong>salve essas alterações</strong>."
        />
      </p>
    </div>
    <FooterButtons
      buttons={[
        {
          id: 'custom-form-save-cancel',
          onClick: () => onCancelCompanySchema(),
          type: 'text',
          text: messages.jobCustomFormTemplateCancelButton,
          disabled: isSaving,
        },
        {
          onClick: () => onSaveCompanySchema(),
          id: 'custom-form-save-updates',
          text: messages.saveUpdates,
          isLoading: isSaving,
        },
      ]}
    />
  </Card>
);

JobCustomFormTemplateSave.propTypes = propTypes;
export default JobCustomFormTemplateSave;
