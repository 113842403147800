import { combineReducers } from 'redux';
import ChoicesHelper from '../../helpers/ChoicesHelper';
import SocialMediasActionTypes from './ActionTypes';

const initialState = {
  socialTitle: '',
  socialDescription: '',
  socialPictureUrl: '',
  socialImageBase64: '',
  socialImageName: '',
};

const isSaving = (state = false, action) => {
  switch (action.type) {
    case SocialMediasActionTypes.PATCH_CAREER_PAGE_SOCIAL_MEDIA_REQUEST:
      return true;
    case SocialMediasActionTypes.PATCH_CAREER_PAGE_SOCIAL_MEDIA_SUCCESS:
    case SocialMediasActionTypes.PUT_CAREER_PAGE_SOCIAL_MEDIA_SUCCESS:
    case SocialMediasActionTypes.PATCH_CAREER_PAGE_SOCIAL_MEDIA_FAIL:
    case SocialMediasActionTypes.PUT_CAREER_PAGE_SOCIAL_MEDIA_FAIL:
      return false;

    default:
      return state;
  }
};

const validation = (state = {}, action) => {
  switch (action.type) {
    case SocialMediasActionTypes.SET_SELECTED:
    case SocialMediasActionTypes.GET_ALL_SUCCESS:
    case SocialMediasActionTypes.PATCH_CAREER_PAGE_SOCIAL_MEDIA_SUCCESS:
    case SocialMediasActionTypes.PUT_CAREER_PAGE_SOCIAL_MEDIA_SUCCESS:
      return {};
    case SocialMediasActionTypes.PATCH_CAREER_PAGE_SOCIAL_MEDIA_FAIL:
    case SocialMediasActionTypes.PUT_CAREER_PAGE_SOCIAL_MEDIA_FAIL:
      return action.validation;

    default:
      return state;
  }
};

export const modelForm = (state = initialState, action) => {
  switch (action.type) {
    case SocialMediasActionTypes.GET_ALL_SUCCESS:
      return action.modelForm || state;
    case SocialMediasActionTypes.GET_ALL_FAIL:
      return initialState;
    case SocialMediasActionTypes.CHANGE_FORM: {
      return {
        ...state,
        ...action.modelForm,
      };
    }
    case SocialMediasActionTypes.SET_SELECTED: {
      return action.modelForm;
    }
    default:
      return state;
  }
};

export const socialMediaList = (state = [], action) => {
  switch (action.type) {
    case SocialMediasActionTypes.GET_ALL_SUCCESS:
      return action.socialMediaList.map(
        ChoicesHelper.getCareerPageModelWithValueAndLabel,
      );
    case SocialMediasActionTypes.GET_ALL_FAIL:
      return [];
    default:
      return state;
  }
};

export default combineReducers({
  isSaving,
  modelForm,
  validation,
  socialMediaList,
});
