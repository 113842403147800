import { Button, InputSearch, Spinner } from '@gupy/design-system';
import { MainSelectList as SelectList } from '@gupy/front-commons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { openConfirmDialog } from '../../actions/App/AppActions';
import GridContent from '../../components/Grid/GridContent';
import {
  clearForm,
  deleteUserAccessProfile,
  getAllUserAccessProfile,
  searchUserAccessProfile,
  typeSearchUserAccessProfiles,
} from './Actions';
import UserAccessProfileList from './components/UserAccessProfileList';
import { GroupTypeEnum } from './Enums';
import { getMessages } from './UserAccessProfileForm.messages';
import './styles/_user-access-profile.scss';

const propTypes = {
  UserAccessProfile: PropTypes.object.isRequired,
  featureFlags: PropTypes.arrayOf(PropTypes.string),
  getAllUserAccessProfile: PropTypes.func.isRequired,
  clearForm: PropTypes.func.isRequired,
  typeSearchUserAccessProfiles: PropTypes.func.isRequired,
  searchUserAccessProfile: PropTypes.func.isRequired,
  deleteUserAccessProfile: PropTypes.func.isRequired,
  openConfirmDialog: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

const UserAccessProfileContainer = props => {
  const {
    permissions: {
      create_company_user_access_profile: canCreateUserAccessProfile,
      edit_company_user_access_profile: canEditAccessProfile,
      delete_company_user_access_profile: canDeleteAccessProfile,
    } = {},
    UserAccessProfile: { isLoading, userAccessProfiles },
    intl,
    featureFlags,
  } = props;

  const [searchValue, setSearchValue] = useState('');
  const [searchGroup, setSearchGroup] = useState([]);

  const messages = getMessages(intl);

  const isAdmissionNewPermissionExperienceFeatureFlagEnabled =
    featureFlags &&
    featureFlags.includes('admission_new_permission_experience');

  const defaultGroupTypes = [
    { label: messages.managementGroup, value: GroupTypeEnum.management },
    { label: messages.recruitmentGroup, value: GroupTypeEnum.recruitment },
    { label: messages.groupTypeNotDefined, value: GroupTypeEnum.noGroup },
  ];

  useEffect(() => {
    props.getAllUserAccessProfile();
  }, []);

  useEffect(() => {
    props.typeSearchUserAccessProfiles();

    const timeout = setTimeout(() => {
      props.searchUserAccessProfile(searchValue, searchGroup);
    }, 400);

    return () => clearTimeout(timeout);
  }, [searchValue, searchGroup]);

  const handleCreateClick = () => {
    props.history.push('/companies/setup/user-access-profile/new');
  };

  const handleEditClick = id => {
    props.clearForm();
    props.history.push(`/companies/setup/user-access-profile/${id}`);
  };

  const handleDeleteClick = id => {
    props.openConfirmDialog({
      title: messages.confirmDialogTitle,
      message: messages.confirmDialogMessage,
      confirmButtonText: messages.confirmButtonText,
      cancelButtonText: messages.cancelButtonText,
      onConfirmClick: () => {
        props.deleteUserAccessProfile(id, {
          success: messages.confirmDialogSuccess,
        });
      },
    });
  };

  return (
    <GridContent
      className="card-list user-access-profile"
      title={messages.gridContentTitle}
    >
      <div className="user-access-profile__actions">
        <div className="user-access-profile__search">
          <InputSearch
            id="job-user-profile-search"
            name="userAccessProfileSearch"
            inputLabel={messages.gridContentTitle}
            buttonLabel={messages.searchUserProfileAccess}
            formLabel={''}
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            placeholder={messages.searchUserProfileAccess}
            block
            size="md"
          />
        </div>

        <div className="user-access-profile__group-type-filter">
          <SelectList
            id="group-type"
            name="group-type"
            className="user-access-profile__group-type-list"
            noItemsSelectedLabel={messages.allGroups}
            value={searchGroup}
            onChange={e => setSearchGroup(e.target.value)}
            data={
              isAdmissionNewPermissionExperienceFeatureFlagEnabled
                ? [
                    ...defaultGroupTypes,
                    {
                      label: messages.admissionGroup,
                      value: GroupTypeEnum.admission,
                    },
                  ]
                : defaultGroupTypes
            }
            disableSelectAll
            multiSelect
            responsive
          />
        </div>

        {canCreateUserAccessProfile ? (
          <div>
            <Button
              size="md"
              onClick={handleCreateClick}
              className="user-access-profile__new-btn"
            >
              {messages.createNewProfile}
            </Button>
          </div>
        ) : null}
      </div>

      {isLoading ? (
        <div className="card-list__grid row">
          <div className="user-access-profile__inner-loading">
            <Spinner color="secondary" />
          </div>
        </div>
      ) : (
        <UserAccessProfileList
          userAccessProfiles={userAccessProfiles}
          canEdit={canEditAccessProfile}
          canDelete={canDeleteAccessProfile}
          onDeleteClick={handleDeleteClick}
          onEditClick={handleEditClick}
          featureFlags={featureFlags}
          messages={messages}
        />
      )}
    </GridContent>
  );
};

const mapStateToProps = state => ({
  UserAccessProfile: state.reducers.UserAccessProfile,
  featureFlags:
    state.reducers.Authentication &&
    state.reducers.Authentication.currentUser &&
    state.reducers.Authentication.currentUser.featureFlags,
});

UserAccessProfileContainer.propTypes = propTypes;

export default injectIntl(
  withRouter(
    connect(mapStateToProps, {
      getAllUserAccessProfile,
      clearForm,
      typeSearchUserAccessProfiles,
      searchUserAccessProfile,
      deleteUserAccessProfile,
      openConfirmDialog,
    })(UserAccessProfileContainer),
  ),
);
