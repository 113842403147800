import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'analytics-callout-modal-title',
    defaultMessage: 'Vamos avaliar a performance da vaga?',
  },
  tip: {
    id: 'analytics-callout-modal-tip',
    defaultMessage: 'A vaga foi encerrada com sucesso',
  },
  body: {
    id: 'analytics-callout-modal-body',
    defaultMessage:
      'Veja os principais indicadores da vaga encerrada e entenda mais detalhes das pessoas candidatas e da performance do processo.',
  },
  seeLater: {
    id: 'analytics-callout-modal-see-later',
    defaultMessage: 'Ver depois',
  },
  seeNow: {
    id: 'analytics-callout-modal-see-now',
    defaultMessage: 'Ver dados da vaga',
  },
});

const formattedMessages = defineMessages({
  subtitle: {
    id: 'analytics-callout-modal-subtitle',
    defaultMessage: 'Deseja analisar os dados da vaga {jobName}?',
  },
});

export const getMessages = intl => ({
  ...formatMessages(intl, messages),
  subtitle: ({ jobName }) =>
    intl.formatMessage(formattedMessages.subtitle, { jobName }),
});
