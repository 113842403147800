import { asyncActionType, createActionTypes } from '@gupy/front-commons';

export default createActionTypes(
  'Address',
  asyncActionType('CHANGE_FORM_ADDRESS_SUGGESTIONS'),
  asyncActionType('CHANGE_FORM_ADDRESS_SELECT_SUGGESTION'),
  asyncActionType('SAVE_ADDRESS_MODAL'),
  asyncActionType('GET_COUNTRIES'),
  'CHANGE_FORM_ADDRESS_CLEAR_SUGGESTIONS',
  'CHANGE_FORM_ADDRESS_BLUR',
  'CHANGE_FORM_ADDRESS_MODAL',
  'CLOSE_ADDRESS_MODAL',
  'OPEN_ADDRESS_MODAL',
);
