import api from '../../../api/DarthVaderClient';

export default {
  getApprovers: () => api.get('/job-management/companies/jobs/approvers'),
  getWorkflowApprovers: additionalIds =>
    api.get(
      `/job-management/companies/jobs/approvers?additionalIds=${additionalIds}`,
    ),
  getApprovalWorkflows: () =>
    api.get('/job-management/companies/jobs/approval-workflows'),
  getApprovalWorkflow: id =>
    api.get(`/job-management/companies/jobs/approval-workflows/${id}`),
  saveApprovalWorkflow: payload =>
    api.post('/job-management/companies/jobs/approval-workflows', payload),
  deleteApprovalWorkflow: ({ id }) =>
    api.delete(`/job-management/companies/jobs/approval-workflows/${id}`),
  updateApprovalWorkflow: (id, payload) =>
    api.put(`/job-management/companies/jobs/approval-workflows/${id}`, payload),
};
