export const SAVE_FEATURE_FLAGS_REQUEST =
  'rootSetup/SAVE_FEATURE_FLAGS_REQUEST';
export const SAVE_FEATURE_FLAGS_SUCCESS =
  'rootSetup/SAVE_FEATURE_FLAGS_SUCCESS';
export const SAVE_FEATURE_FLAGS_FAILURE =
  'rootSetup/SAVE_FEATURE_FLAGS_FAILURE';

export const INIT_FEATURE_FLAGS = 'rootSetup/INIT_FEATURE_FLAGS';
export const ADD_FEATURE_FLAG = 'rootSetup/ADD_FEATURE_FLAG';
export const REMOVE_FEATURE_FLAG = 'rootSetup/REMOVE_FEATURE_FLAG';
