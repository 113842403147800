import { Card, Icon, IconEnum } from '@gupy/design-system';
import PropTypes from 'prop-types';
import React from 'react';
import JobCustomFormTemplateConditionalForm from './JobCustomFormTemplateConditionalForm';
import JobCustomFormTemplateConditionalMenuButton from './JobCustomFormTemplateConditionalMenuButton';
import './JobCustomFormTemplateConditionalCard.scss';

const propTypes = {
  messages: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  fieldTitle: PropTypes.string.isRequired,
  fieldType: PropTypes.object.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  selectedItems: PropTypes.array,
  onRemoveField: PropTypes.func.isRequired,
  onEditField: PropTypes.func.isRequired,
  fieldKey: PropTypes.string,
  dependentOption: PropTypes.array.isRequired,
  childToEdit: PropTypes.string.isRequired,
  cancelEditing: PropTypes.func.isRequired,
  onUpdateConditionalChild: PropTypes.func.isRequired,
  answerOptions: PropTypes.array.isRequired,
  fieldTypes: PropTypes.array.isRequired,
  editFormData: PropTypes.object,
  onMoveTo: PropTypes.func.isRequired,
};

const JobCustomFormTemplateConditionalCard = ({
  messages,
  items,
  fieldTitle,
  fieldType,
  fieldIndex,
  selectedItems,
  onRemoveField,
  onEditField,
  fieldKey,
  childToEdit,
  cancelEditing,
  dependentOption,
  onUpdateConditionalChild,
  answerOptions,
  fieldTypes,
  editFormData,
  onMoveTo,
}) => (
  <div key={`conditional-card-${fieldTitle}-${fieldIndex}`}>
    {childToEdit === `${dependentOption}-${fieldIndex}` ? (
      <JobCustomFormTemplateConditionalForm
        messages={messages}
        answerOptions={answerOptions}
        fieldTypes={fieldTypes}
        onSave={onUpdateConditionalChild}
        onCancel={cancelEditing}
        editFormData={editFormData}
        fieldIndex={fieldIndex - 1}
        fieldKey={fieldKey}
        isEditing
      />
    ) : (
      <Card className="conditional-card">
        <div>
          <p className="conditional-card__title">
            <span className="conditional-card__index">{fieldIndex}.</span>
            <span>{fieldTitle}</span>
          </p>
          <div className="conditional-card__info">
            <div className="conditional-card__type">
              {messages.fieldTypeTableHeader}:
            </div>
            <div className="conditional-card__icon">
              <Icon icon={IconEnum[fieldType.icon]} fontSize="small" />
            </div>
            <div>{fieldType.label}</div>
          </div>
        </div>
        <div className="conditional-menu__button">
          <JobCustomFormTemplateConditionalMenuButton
            index={fieldIndex - 1}
            itemsCount={items.length}
            messages={messages}
            onMoveTo={onMoveTo}
            onRemoveField={onRemoveField}
            onEditField={onEditField}
            fieldIndex={fieldIndex}
            fieldTitle={fieldTitle}
            fieldType={fieldType}
            dependentOption={dependentOption}
            selectedItems={selectedItems}
            items={items}
          />
        </div>
      </Card>
    )}
  </div>
);

JobCustomFormTemplateConditionalCard.propTypes = propTypes;

export default JobCustomFormTemplateConditionalCard;
