import { Spinner } from '@gupy/design-system';
import { Paper } from '@material-ui/core';
import { any, bool, func, objectOf, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { updateGlobalization } from '../../actions/App/AppActions';
import indicationStatusEnum from '../../constants/ConfirmIndication/indicationStatusEnum';
import AppFooter from '../App/components/AppFooter';
import ConfirmMessage from './components/ConfirmMessage';
import FormForConfirmation from './components/FormForConfirmation';
import GiveFeedback from './components/GiveFeedback';
import HeaderSimple from './components/HeaderSimple';
import ModalConfirmAction from './components/ModalConfirmAction';
import { giveFeedback, validateToken } from './ConfirmIndicationActions';

const ConfirmIndication = ({
  updateLanguage,
  defaultLanguage,
  match,
  checkToken,
  sendFeedback,
  application,
  isLoading,
  hasError,
}) => {
  const [isIndicatedStatus, setIsIndicatedStatus] = useState(null);
  const [commentary, setCommentary] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [answered, setAnswered] = useState(false);

  const handleSelectionChange = value => {
    setIsIndicatedStatus(value);
  };

  const handleTextChange = ({ target }) => {
    setCommentary(target.value);
  };

  const handleClickFeedback = () => {
    if (isIndicatedStatus === indicationStatusEnum.confirmed && !commentary) {
      setModalOpen(false);
    } else {
      setModalOpen(true);
    }
  };

  const handleModalConfirm = async () => {
    const {
      params: { token },
    } = match;
    await sendFeedback({ token, isIndicatedStatus, commentary });
    setAnswered(true);
    setModalOpen(false);
  };

  const handleModalCancel = () => setModalOpen(false);

  useEffect(() => {
    checkToken(match.params.token);
  }, []);

  useEffect(() => {
    if (application) {
      const candidateLanguage = application.candidate.defaultLanguage;
      updateLanguage(defaultLanguage, candidateLanguage);
    }
  }, [application]);

  if (hasError) return <Redirect to="/companies/confirm-indication" />;
  if (isLoading || !application) {
    return (
      <div className="confirm-indication-spinner">
        <Spinner />
      </div>
    );
  }

  const {
    indicator,
    company,
    candidate: { name, lastName },
    job,
  } = application;
  const candidateName = `${name} ${lastName}`;

  return (
    <div className="home">
      <HeaderSimple />
      <div className="home__container">
        <Paper className="confirmation-card">
          <div className="confirmation-card__col">
            <FormattedMessage
              id="valid-indication"
              defaultMessage="Validação de indicação"
            >
              {txt => (
                <h5>
                  {txt} | <strong>{candidateName}</strong>
                </h5>
              )}
            </FormattedMessage>
            <br />
            <br />
            <main className="confirmation-card__item">
              <ConfirmMessage
                candidateName={candidateName}
                indicatorName={indicator.name}
                companyName={company.name}
                jobName={job.name}
              />
              <FormForConfirmation
                commentary={commentary}
                onClick={handleClickFeedback}
                onSelect={handleSelectionChange}
                onChange={handleTextChange}
                selected={isIndicatedStatus}
              />
              {answered && <GiveFeedback />}
            </main>
          </div>
        </Paper>
        <ModalConfirmAction
          onCancel={handleModalCancel}
          onConfirm={handleModalConfirm}
          isOpen={modalOpen}
        />
      </div>
      <AppFooter />
    </div>
  );
};

ConfirmIndication.propTypes = {
  updateLanguage: func.isRequired,
  defaultLanguage: string.isRequired,
  match: objectOf(any).isRequired,
  checkToken: func.isRequired,
  sendFeedback: func.isRequired,
  application: objectOf(any),
  isLoading: bool,
  hasError: bool,
};

ConfirmIndication.defaultProps = {
  savedFeedback: false,
  isLoading: false,
  hasError: false,
  application: {},
};

const mapStateToProps = state => {
  const {
    ConfirmIndication: consfirmIndicationState,
    App: { defaultLanguage },
  } = state.reducers;
  return { ...consfirmIndicationState, defaultLanguage };
};

export default withRouter(
  connect(mapStateToProps, {
    updateLanguage: updateGlobalization,
    checkToken: validateToken,
    sendFeedback: giveFeedback,
  })(ConfirmIndication),
);
