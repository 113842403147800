import {
  DsBorderWidth,
  DsColorGraySnow,
  DsColorPrimary,
  DsFontSizeLg,
  DsFontWeightExtraRegular,
  DsScreenXs,
  DsSpacingLg,
  DsSpacingMd,
  DsSpacingSm,
  DsSpacingXmd,
} from '@gupy/design-system-v2';
import styled, { css } from 'styled-components';

const DsColorPrimaryDark = '#A5390A';

const baseButtonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${DsSpacingSm};
  font-size: ${DsFontSizeLg};
  padding: ${DsSpacingXmd} ${DsSpacingLg};
  font-weight: ${DsFontWeightExtraRegular};
  text-decoration: none;
  border-radius: ${DsSpacingXmd};
  min-width: fit-content;
  margin: ${DsSpacingMd} 0;
`;

const normalButtonStyles = css`
  ${baseButtonStyles};
  background-color: ${DsColorPrimary};
  color: ${DsColorGraySnow};
  border: ${DsBorderWidth} solid ${DsColorPrimary};

  svg {
    color: ${DsColorGraySnow};
  }

  &:hover,
  &:active {
    color: ${DsColorGraySnow};
    border: ${DsBorderWidth} solid ${DsColorPrimaryDark};
    background-color: ${DsColorPrimaryDark};
    color: ${DsColorGraySnow};
  }

  @media only screen and (max-width: ${DsScreenXs}) {
    width: 100%;
    padding: ${DsSpacingXmd} 0;

    svg {
      color: ${DsColorGraySnow};
    }
  }
`;

export const ButtonLink = styled.a`
  ${normalButtonStyles};
`;
