import { call, put, takeLatest } from 'redux-saga/effects';
import InsightsService from '../../../../services/Insight/InsightsService';
import { GupyBadgeFeedbackInsightsActionTypes } from './GupyBadgeFeedbackInsightsAction';

export function* initGupyBadgeFeedbackJobInsights() {
  try {
    const gupyBadgeFeedbackJobInsightsResponse = yield call(
      InsightsService.gupyBadgeFeedbackJobInsights,
    );

    yield put({
      type: GupyBadgeFeedbackInsightsActionTypes.INIT_GUPY_BADGE_FEEDBACK_JOB_INSIGHTS_SUCCESS,
      gupyBadgeFeedbackJobInsightsResult:
        gupyBadgeFeedbackJobInsightsResponse.body,
    });
  } catch (error) {
    yield put({
      type: GupyBadgeFeedbackInsightsActionTypes.INIT_GUPY_BADGE_FEEDBACK_JOB_INSIGHTS_FAIL,
      error,
    });
  }
}

export function* getJobPostingsWithoutFeedback() {
  try {
    const getJobPostingsWithoutFeedbackResponse = yield call(
      InsightsService.getJobPostingsWithoutFeedback,
    );

    yield put({
      type: GupyBadgeFeedbackInsightsActionTypes.GET_JOB_POSTINGS_WITHOUT_FEEDBACK_SUCCESS,
      getJobPostingsWithoutFeedbackResult:
        getJobPostingsWithoutFeedbackResponse.body,
    });
  } catch (error) {
    yield put({
      type: GupyBadgeFeedbackInsightsActionTypes.GET_JOB_POSTINGS_WITHOUT_FEEDBACK_FAIL,
      error,
    });
  }
}

export function* getInactiveJobPostings() {
  try {
    const getInactiveJobPostingsResponse = yield call(
      InsightsService.getInactiveJobPostings,
    );

    yield put({
      type: GupyBadgeFeedbackInsightsActionTypes.GET_INACTIVE_JOB_POSTINGS_SUCCESS,
      getInactiveJobPostingsResult: getInactiveJobPostingsResponse.body,
    });
  } catch (error) {
    yield put({
      type: GupyBadgeFeedbackInsightsActionTypes.GET_INACTIVE_JOB_POSTINGS_FAIL,
      error,
    });
  }
}

export function* nextPageJobPostingsWithoutFeedback({ page }) {
  try {
    const pagination = {
      page,
      perPage: 4,
    };

    const getJobPostingsWithoutFeedbackResponse = yield call(
      InsightsService.getJobPostingsWithoutFeedback,
      { pagination },
    );

    yield put({
      type: GupyBadgeFeedbackInsightsActionTypes.NEXT_PAGE_JOB_POSTINGS_WITHOUT_FEEDBACK_SUCCESS,
      getJobPostingsWithoutFeedbackResult:
        getJobPostingsWithoutFeedbackResponse.body,
    });
  } catch (error) {
    yield put({
      type: GupyBadgeFeedbackInsightsActionTypes.NEXT_PAGE_JOB_POSTINGS_WITHOUT_FEEDBACK_FAIL,
      error,
    });
  }
}

export function* nextPageInactiveJobPostings({ page }) {
  try {
    const pagination = {
      page,
      perPage: 4,
    };

    const getInactiveJobPostingsResponse = yield call(
      InsightsService.getInactiveJobPostings,
      { pagination },
    );

    yield put({
      type: GupyBadgeFeedbackInsightsActionTypes.NEXT_PAGE_INACTIVE_JOB_POSTINGS_SUCCESS,
      getInactiveJobPostingsResult: getInactiveJobPostingsResponse.body,
    });
  } catch (error) {
    yield put({
      type: GupyBadgeFeedbackInsightsActionTypes.NEXT_PAGE_INACTIVE_JOB_POSTINGS_FAIL,
      error,
    });
  }
}

function* GupyBadgeFeedbackInsightsSaga() {
  yield takeLatest(
    GupyBadgeFeedbackInsightsActionTypes.INIT_GUPY_BADGE_FEEDBACK_JOB_INSIGHTS,
    initGupyBadgeFeedbackJobInsights,
  );
  yield takeLatest(
    GupyBadgeFeedbackInsightsActionTypes.GET_JOB_POSTINGS_WITHOUT_FEEDBACK,
    getJobPostingsWithoutFeedback,
  );
  yield takeLatest(
    GupyBadgeFeedbackInsightsActionTypes.GET_INACTIVE_JOB_POSTINGS,
    getInactiveJobPostings,
  );
  yield takeLatest(
    GupyBadgeFeedbackInsightsActionTypes.NEXT_PAGE_JOB_POSTINGS_WITHOUT_FEEDBACK,
    nextPageJobPostingsWithoutFeedback,
  );
  yield takeLatest(
    GupyBadgeFeedbackInsightsActionTypes.NEXT_PAGE_INACTIVE_JOB_POSTINGS,
    nextPageInactiveJobPostings,
  );
}

export default GupyBadgeFeedbackInsightsSaga;
