import { buildToast, ToastTypes } from '@gupy/front-commons';
import * as types from './HiringCelebrationActionTypes';

export const openHiringCelebrationModal = () => ({
  type: types.OPEN_HIRING_CELEBRATION_MODAL,
});

export const closeHiringCelebrationModal = () => ({
  type: types.CLOSE_HIRING_CELEBRATION_MODAL,
});

export const saveFeedbackRequest = ({
  context,
  feedbackType,
  answer,
  messages,
}) => ({
  type: types.SAVE_FEEDBACK_REQUEST,
  context,
  feedbackType,
  answer,
  messages,
});

export const saveFeedbackRequestFailure = () => ({
  type: types.SAVE_FEEDBACK_REQUEST_FAILURE,
});

export const saveFeedbackRequestSuccess = ({ messages, candidates }) => ({
  type: types.SAVE_FEEDBACK_REQUEST_SUCCESS,
  toast: buildToast(
    messages.saveFeedbackRequestSuccess({
      amount: candidates && candidates.length,
    }),
    ToastTypes.success,
    { autoClose: 5000 },
  ),
});
