import { call, put, takeLatest } from 'redux-saga/effects';
import history from '../../../history';
import JobService from '../../../services/Job/JobService';
import {
  initJobOverviewSuccess,
  JobOverviewActionTypes,
} from './JobOverviewAction';
import JobOverviewService from './JobOverviewService';

export function* init() {
  try {
    const response = yield JobOverviewService.getJobTemplates();

    yield put(initJobOverviewSuccess(response.body.data));
  } catch (error) {
    yield put({
      type: JobOverviewActionTypes.INIT_FAIL,
      error,
    });
  }
}

export function* createJobFromTemplate(action) {
  const { templateId, jobDetailsPath } = action;

  try {
    const response = yield JobService.duplicateJob(templateId, true, false);

    yield put({
      type: JobOverviewActionTypes.CREATE_JOB_FROM_TEMPLATE_SUCCESS,
      modelForm: response.body,
    });

    yield call(history.push, jobDetailsPath.replace(':id', response.body.id));
  } catch (error) {
    yield put({
      type: JobOverviewActionTypes.CREATE_JOB_FROM_TEMPLATE_FAIL,
      error,
    });
  }
}

function* JobOverviewSaga() {
  yield takeLatest(JobOverviewActionTypes.INIT, init);
  yield takeLatest(
    JobOverviewActionTypes.CREATE_JOB_FROM_TEMPLATE,
    createJobFromTemplate,
  );
}

export default JobOverviewSaga;
