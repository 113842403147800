import { asyncActionType, createActionTypes } from '@gupy/front-commons';
import TestProviderStatusEnum from '../../../constants/TestProviderStatusEnum';

const { activating, active, inactive, recentlyActivated } =
  TestProviderStatusEnum;

const updateProvidersWithInitialStatus = availableProviders =>
  availableProviders.map(provider => ({
    ...provider,
    currentStatus:
      provider.isRegistered && provider.isActiveToCompany ? active : inactive,
  }));

const updateProviderStatus = (
  testProviderId,
  availableProviders,
  currentStatus,
) =>
  availableProviders.map(provider => {
    const isCurrentTestProvider = provider.uuid === testProviderId;

    if (isCurrentTestProvider) {
      return { ...provider, currentStatus };
    }

    return provider;
  });

const TestProvidersActionTypes = createActionTypes(
  'TestProviders',
  asyncActionType('GET'),
  asyncActionType('SAVE_TOKEN'),
  'SET_PROVIDER_STATUS',
);

const getTestProviders = () => ({
  type: TestProvidersActionTypes.GET,
});

const getTestProvidersSuccess = availableProviders => ({
  type: TestProvidersActionTypes.GET_SUCCESS,
  availableProviders: updateProvidersWithInitialStatus(availableProviders),
});

const saveToken = (testProviderId, token, availableProviders) => ({
  type: TestProvidersActionTypes.SAVE_TOKEN,
  availableProviders: updateProviderStatus(
    testProviderId,
    availableProviders,
    activating,
  ),
  token,
  testProviderId,
});

const saveTokenSuccess = (testProviderId, availableProviders) => ({
  type: TestProvidersActionTypes.SAVE_TOKEN_SUCCESS,
  availableProviders: updateProviderStatus(
    testProviderId,
    availableProviders,
    recentlyActivated,
  ),
});

const setProviderCurrentStatus = (
  testProviderId,
  newStatus,
  availableProviders,
) => ({
  type: TestProvidersActionTypes.SET_PROVIDER_STATUS,
  availableProviders: updateProviderStatus(
    testProviderId,
    availableProviders,
    newStatus,
  ),
});

export {
  getTestProviders,
  getTestProvidersSuccess,
  saveToken,
  TestProvidersActionTypes,
  setProviderCurrentStatus,
  saveTokenSuccess,
};
