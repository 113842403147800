import { TextEditor } from '@gupy/front-commons';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import CompaniesApi from '../../../../../companies/src/api/DarthVaderClient';
import { imageUploadUrl } from '../../../../src/helpers/FileUploadHelper';
import { getMarkupsMessages, getMessages } from '../Messages';

const propTypes = {
  body: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  markups: PropTypes.array,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  showMarkups: PropTypes.bool,
  validation: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

const defaultProps = {
  attachedFiles: [],
  body: '',
  currentAttachedFile: null,
  disabled: false,
  isTalentPool: false,
  isTemplateEditMode: false,
  label: null,
  markups: [],
  required: false,
  showMarkups: true,
  validation: null,
  onFileUploadStatusChange: () => {},
};

class JobOfferTemplateBodyBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.messages = getMessages(props.intl);

    this.state = {
      selection: null,
    };
  }

  adaptVariables = variables =>
    variables.map(variable => ({
      value: `[${variable.value}]`,
      label: `[${getMarkupsMessages(this.props.intl)[variable.value]}]`,
    }));

  getNewTextWithMarkup = markup => {
    const { selection } = this.state;
    const nodeText = selection.textContent;
    const postionSelection = selection.focusOffset;

    const textWithMarkup =
      nodeText.substring(0, postionSelection) +
      markup +
      nodeText.substring(postionSelection, selection.focusNode.length);

    return textWithMarkup;
  };

  translateToView = jobOfferBody => {
    let translatedjobOfferBody = jobOfferBody;
    const markups = this.adaptVariables(this.props.markups);
    markups.forEach(markup => {
      translatedjobOfferBody = translatedjobOfferBody
        .split(markup.value)
        .join(markup.label);
    });
    return translatedjobOfferBody;
  };

  translateToModel = jobOfferBody => {
    let translatedjobOfferBody = jobOfferBody;
    if (!translatedjobOfferBody) {
      return '';
    }
    const markups = this.adaptVariables(this.props.markups);
    markups.forEach(markup => {
      translatedjobOfferBody = translatedjobOfferBody
        .split(markup.label)
        .join(markup.value);
    });
    return translatedjobOfferBody;
  };

  handleMarkupClick = markup => {
    const oldValue = this.props.body;
    let newBodyValue;

    const { selection } = this.state;
    let parentNodeFromSelection = null;
    let quillEditorNode = null;
    if (selection && selection.focusNode && selection.focusNode.parentNode) {
      parentNodeFromSelection = selection.focusNode.parentNode;
      quillEditorNode = parentNodeFromSelection.closest('.ql-editor');
    }

    if (selection && quillEditorNode) {
      selection.focusNode.textContent = this.getNewTextWithMarkup(markup.label);
      newBodyValue = quillEditorNode.innerHTML;
    } else {
      newBodyValue = oldValue + markup.label;
    }

    this.props.onChange({
      target: {
        name: this.props.name,
        value: newBodyValue,
      },
    });
  };

  handleChange = event => {
    const newEvent = event;
    if (newEvent && newEvent.target) {
      const isNotRelatedToAttachments = !['fileName', 'files'].includes(
        event.target.name,
      );
      if (isNotRelatedToAttachments) {
        newEvent.target.value = this.translateToModel(newEvent.target.value);
      }
    }
    this.props.onChange(newEvent);
  };

  getSelectionOptions = offsetAdjust => {
    const htmlSelection = document.getSelection();
    const parentNodeFromSelection = htmlSelection.focusNode.parentNode;
    const quillEditorNode = parentNodeFromSelection.closest('.ql-editor');

    const selection = {
      focusNode: htmlSelection.focusNode,
      textContent: htmlSelection.focusNode.textContent,
      focusOffset: htmlSelection.focusOffset + offsetAdjust,
      type: htmlSelection.type,
    };

    return {
      ...selection,
      hasQuillEditorNode: !!quillEditorNode,
      hasFocusText:
        selection.focusNode && selection.focusNode.nodeName === '#text',
      hasEditableContent:
        htmlSelection.extentNode && htmlSelection.extentNode.isContentEditable,
    };
  };

  handleSetSelection = (offsetAdjust = 0) => {
    try {
      const {
        hasQuillEditorNode,
        hasFocusText,
        hasEditableContent,
        ...selection
      } = this.getSelectionOptions(offsetAdjust);

      if ((hasFocusText || hasEditableContent) && hasQuillEditorNode) {
        this.setState({
          selection,
        });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.debug(err);
    }
  };

  handleKeyPressTextEditor = () => {
    this.handleSetSelection(1);
  };

  handleClickTextEditor = () => {
    this.handleSetSelection();
  };

  render() {
    const {
      body,
      disabled,
      id,
      label,
      markups,
      name,
      required,
      showMarkups,
      validation,
    } = this.props;

    const markupsArr = this.adaptVariables(markups);

    const markupsNode = (
      <div className="body-builder__markups">
        <div>
          <b>
            <FormattedMessage id="markups" defaultMessage="Marcadores" />:
          </b>
        </div>
        <div>
          {markupsArr.map((marker, index) => (
            <span
              id={`${id}-markup-${index}`}
              tabIndex="-1"
              role="button"
              onClick={() => {
                this.handleMarkupClick(marker);
              }}
              key={marker.value}
            >
              {marker.label}
            </span>
          ))}
        </div>
      </div>
    );

    return (
      <div
        role="button"
        tabIndex="-1"
        className="body-builder"
        onClick={this.handleClickTextEditor}
      >
        <TextEditor
          messages={this.messages}
          enableImageFeatures
          preElement={showMarkups && markups.length > 0 ? markupsNode : null}
          label={label}
          id={id}
          name={name}
          required={required}
          value={this.translateToView(body)}
          validation={validation}
          disabled={disabled}
          onChange={this.handleChange}
          onKeyUp={this.handleKeyPressTextEditor}
          uploadBodyImageUrl={imageUploadUrl}
          fileUploadApiRequestHeaders={CompaniesApi.headersFactory()}
          blockDangerousExtensions
          invalidImageExtensionMessage={this.messages.invalidImageExtension}
          invalidImageSizeMessage={this.messages.invalidImageSize}
          hyperlinkEnabled
        />
      </div>
    );
  }
}

JobOfferTemplateBodyBuilder.propTypes = propTypes;
JobOfferTemplateBodyBuilder.defaultProps = defaultProps;

export default injectIntl(JobOfferTemplateBodyBuilder);
