import { getSuggestions } from '@gupy/front-commons';
import { call, put, takeLatest } from 'redux-saga/effects';
import GeolocationService from '../../services/Geolocation/GeolocationService';
import * as ActionTypes from './ActionTypes';
import AddressService from './Service';

export function* getAddressSuggestions(action) {
  const { input } = action.payload;

  try {
    const suggestions = yield getSuggestions(
      input,
      'address',
      GeolocationService.getSuggestions,
    );

    yield put({
      type: ActionTypes.CHANGE_FORM_ADDRESS_SUGGESTIONS_SUCCESS,
      suggestions: suggestions.body.items,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.CHANGE_FORM_ADDRESS_SUGGESTIONS_FAIL,
      error,
    });
  }
}

export function* getCountries() {
  try {
    const countriesResponse = yield call(AddressService.getCountries);
    yield put({
      type: ActionTypes.GET_COUNTRIES_SUCCESS,
      countries: countriesResponse.body,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_COUNTRIES_FAIL,
      error,
    });
  }
}

function* CustomAddressSaga() {
  yield takeLatest(ActionTypes.GET_COUNTRIES, getCountries);
  yield takeLatest(
    ActionTypes.GET_INTERVIEW_ADDRESS_SUGGESTIONS,
    getAddressSuggestions,
  );
}

export default CustomAddressSaga;
