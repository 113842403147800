import { FooterButtons, InputGroup } from '@gupy/design-system';
import { debounce } from '@gupy/front-commons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import FormAlert from '../../components/Form/FormAlert';
import Grid from '../../components/Grid/Grid';
import GridContent from '../../components/Grid/GridContent';
import SetupGridSidebarMenu from '../../components/Grid/SetupGridSidebarMenu';
import {
  clearJobOfferTemplate,
  getJobOfferTemplate,
  getJobOfferTemplateVariables,
  postJobOfferTemplate,
  putJobOfferTemplate,
} from './Actions';
import JobOfferTemplateBodyBuilder from './components/JobOfferTemplateBodyBuilder';
import { getFooterButtons } from './helpers/getFooterButtons';
import { getMessages } from './Messages';

const propTypes = {
  JobOfferTemplate: PropTypes.object.isRequired,
  postJobOfferTemplate: PropTypes.func.isRequired,
  putJobOfferTemplate: PropTypes.func.isRequired,
  getJobOfferTemplate: PropTypes.func.isRequired,
  getJobOfferTemplateVariables: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  match: PropTypes.object.isRequired,
  clearJobOfferTemplate: PropTypes.func.isRequired,
};

const JobOfferTemplateForm = ({
  intl,
  match,
  permissions,
  JobOfferTemplate,
  getJobOfferTemplate: getJobOfferTemplateAction,
  putJobOfferTemplate: putJobOfferTemplateAction,
  postJobOfferTemplate: postJobOfferTemplateAction,
  clearJobOfferTemplate: clearJobOfferTemplateAction,
  getJobOfferTemplateVariables: getJobOfferTemplateVariablesAction,
}) => {
  const [redirect, setRedirect] = useState(null);
  const [form, setForm] = useState({
    body: '',
    createdAt: '',
    id: undefined,
    name: '',
    subject: 'JobOffer',
    type: 'job_offer',
    userId: null,
    fileName: '',
    files: [],
  });
  const messages = getMessages(intl);

  useEffect(() => {
    const { modelForm } = JobOfferTemplate;
    setForm({
      id: modelForm.id,
      name: modelForm.name,
      subject: modelForm.subject,
      body: modelForm.body,
      createdAt: modelForm.createdAt,
      type: modelForm.type,
      userId: modelForm.userId,
      fileName: modelForm.fileName,
      files: modelForm.files,
    });
  }, [JobOfferTemplate.modelForm]);

  useEffect(() => {
    const { id } = match.params;

    if (id) {
      getJobOfferTemplateAction(id);
    }

    getJobOfferTemplateVariablesAction();

    return () => {
      clearJobOfferTemplateAction();
    };
  }, []);

  const handleFieldChange = ({ target }) =>
    debounce(
      setForm({
        ...form,
        [target.name]: target.value,
      }),
      1000,
    );

  const handleCancelClick = () =>
    setRedirect('/companies/setup/job-offer-template');

  const handleFormSubmit = () =>
    form.id
      ? putJobOfferTemplateAction(
          { ...form, public: true },
          '/companies/setup/job-offer-template',
          { success: messages.success },
        )
      : postJobOfferTemplateAction(
          { ...form, public: true },
          '/companies/setup/job-offer-template',
          { success: messages.success },
        );

  const { validation, isSaving, variables } = JobOfferTemplate;

  const isNewItem = form.id === undefined;
  const canSave = !!permissions.edit_job_offer_template || isNewItem;

  const buttons = getFooterButtons({
    messages,
    canSave,
    isSaving,
    onCancelClick: handleCancelClick,
    onSaveClick: handleFormSubmit,
  });
  if (redirect) return <Redirect push to={redirect} />;

  return (
    <Grid>
      <SetupGridSidebarMenu permissions={permissions} />
      <GridContent title={messages.jobOfferTemplatesPageGridContentTitle}>
        <div className="job_offer_template">
          {validation && validation.alert && (
            <FormAlert validation={validation.alert} />
          )}
          <div className="row">
            <div className={'col-xs-12 job-offer-template__input-container'}>
              <InputGroup
                label={messages.jobOfferTemplateName}
                id="name"
                value={form.name}
                validation={validation.name}
                disabled={isSaving}
                onChange={handleFieldChange}
                required
                block
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <JobOfferTemplateBodyBuilder
                id="body-builder"
                name="body"
                validation={validation.body}
                body={form.body}
                markups={variables}
                disabled={isSaving}
                onChange={handleFieldChange}
                required
                currentAttachedFile={form.fileName}
                attachedFiles={form.files}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 align-center job-offer-template-footer">
              <div className="align-right actions">
                <FooterButtons
                  buttons={buttons}
                  checked={false}
                  color="primary"
                  name="jobOfferTemplate"
                />
              </div>
            </div>
          </div>
        </div>
      </GridContent>
    </Grid>
  );
};

JobOfferTemplateForm.propTypes = propTypes;

const mapStateToProps = state => ({
  JobOfferTemplate: state.reducers.JobOfferTemplate,
  Authentication: state.reducers.Authentication,
});

export default compose(
  injectIntl,
  connect(mapStateToProps, {
    getJobOfferTemplate,
    putJobOfferTemplate,
    postJobOfferTemplate,
    clearJobOfferTemplate,
    getJobOfferTemplateVariables,
  }),
)(JobOfferTemplateForm);
