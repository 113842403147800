import { Icon, IconEnum } from '@gupy/design-system';
import PropTypes from 'prop-types';
import React from 'react';
import './UserNotificationsButton.scss';

const propTypes = {
  onClick: PropTypes.func,
  total: PropTypes.number,
  label: PropTypes.string.isRequired,
};

const UserNotificationsButton = ({ total, onClick, label }) => (
  <div
    className="user-notifications-button"
    data-testid="user-notifications-button"
  >
    <Icon
      className="root"
      id="header-notifications-btn"
      icon={IconEnum.Notifications}
      label={label}
      onClick={onClick}
      variant="text"
    />
    {total > 0 && <span className="user-notifications-button__count" />}
  </div>
);

UserNotificationsButton.propTypes = propTypes;

export default UserNotificationsButton;
