import { asyncActionType, createActionTypes } from '@gupy/front-commons';

const UserAccessProfileActionTypes = createActionTypes(
  'UserAccessProfile',
  'CHANGE_FORM',
  'CLEAR_FORM',
  'EDIT',
  asyncActionType('POST'),
  asyncActionType('PUT'),
  asyncActionType('DELETE'),
  asyncActionType('GET'),
  asyncActionType('GET_TYPES'),
  asyncActionType('GET_ALL'),
  asyncActionType('GET_STRUCTURE'),
  asyncActionType('SEARCH'),
  asyncActionType('TYPE_SEARCH'),
);

const changeForm = modelForm => ({
  type: UserAccessProfileActionTypes.CHANGE_FORM,
  modelForm,
});

const clearForm = () => ({
  type: UserAccessProfileActionTypes.CLEAR_FORM,
});

const typeSearchUserAccessProfiles = () => ({
  type: UserAccessProfileActionTypes.TYPE_SEARCH,
});

const postUserAccessProfile = (payload, redirectUrl, messages) => ({
  type: UserAccessProfileActionTypes.POST,
  payload,
  redirectUrl,
  messages,
});

const putUserAccessProfile = (payload, redirectUrl, messages) => ({
  type: UserAccessProfileActionTypes.PUT,
  payload,
  redirectUrl,
  messages,
});

const deleteUserAccessProfile = (id, messages) => ({
  type: UserAccessProfileActionTypes.DELETE,
  id,
  messages,
});

const editUserAccessProfile = modelForm => ({
  type: UserAccessProfileActionTypes.EDIT,
  modelForm,
});

const getAllUserAccessProfile = () => ({
  type: UserAccessProfileActionTypes.GET_ALL,
});

const getUserAccessProfileTypes = () => ({
  type: UserAccessProfileActionTypes.GET_TYPES,
});

const getStructureUserAccessProfile = () => ({
  type: UserAccessProfileActionTypes.GET_STRUCTURE,
});

const getUserAccessProfile = id => ({
  type: UserAccessProfileActionTypes.GET,
  id,
});

const searchUserAccessProfile = (searchString, group) => ({
  type: UserAccessProfileActionTypes.SEARCH,
  searchString,
  group,
});

export {
  UserAccessProfileActionTypes,
  changeForm,
  clearForm,
  postUserAccessProfile,
  putUserAccessProfile,
  deleteUserAccessProfile,
  editUserAccessProfile,
  getAllUserAccessProfile,
  getUserAccessProfile,
  searchUserAccessProfile,
  typeSearchUserAccessProfiles,
  getStructureUserAccessProfile,
  getUserAccessProfileTypes,
};
