import { Button, RadioButton } from '@gupy/design-system';
import { RadioButtonGroup } from '@gupy/front-commons';
import { func, string } from 'prop-types';
import React, { Fragment } from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import indicationStatusEnum from '../../../constants/ConfirmIndication/indicationStatusEnum';

const messages = defineMessages({
  option1: {
    defaultMessage: 'Conheço e indico',
    id: 'know-and-refer',
  },
  option2: {
    defaultMessage: 'Conheço e não indico',
    id: 'dont-refer',
  },
  option3: {
    defaultMessage: 'Não conheço essa pessoa',
    id: 'dont-know',
  },
  callToAction: {
    defaultMessage: 'Confirmar resposta',
    id: 'submit-indication',
  },
  [indicationStatusEnum.confirmed]: {
    defaultMessage:
      'Caso conheça e indique essa pessoa, por favor, informe o motivo. =)',
    id: 'confirm-indication',
  },
  [indicationStatusEnum.rejected]: {
    defaultMessage:
      'Caso queira justificar a não indicação, por favor, informe o motivo.',
    id: 'refuse-indication',
  },
  [indicationStatusEnum.unknown]: {
    defaultMessage:
      'Caso queira justificar a não indicação, por favor, informe o motivo.',
    id: 'refuse-indication',
  },
});

const FormForConfirmation = ({
  commentary,
  onClick,
  onChange,
  onSelect,
  selected,
  intl,
}) => (
  <Fragment>
    <RadioButtonGroup required inline>
      <section className="confirmation-card__row">
        <RadioButton
          name="confirmation"
          id={indicationStatusEnum.confirmed}
          onChange={() => onSelect(indicationStatusEnum.confirmed)}
          checked={selected === indicationStatusEnum.confirmed}
          label={intl.formatMessage(messages.option1)}
          value={indicationStatusEnum.confirmed}
        />
        <RadioButton
          name="confirmation"
          id={indicationStatusEnum.rejected}
          onChange={() => onSelect(indicationStatusEnum.rejected)}
          checked={selected === indicationStatusEnum.rejected}
          label={intl.formatMessage(messages.option2)}
          value={indicationStatusEnum.rejected}
        />
        <RadioButton
          name="confirmation"
          id={indicationStatusEnum.unknown}
          onChange={() => onSelect(indicationStatusEnum.unknown)}
          checked={selected === indicationStatusEnum.unknown}
          label={intl.formatMessage(messages.option3)}
          value={indicationStatusEnum.unknown}
        />
      </section>
    </RadioButtonGroup>
    {selected && (
      <Fragment>
        <label className="confirmation-card__label">
          {intl.formatMessage(messages[selected])}
        </label>
        <textarea onChange={onChange} />
        <section className="confirmation-card__row confirmation-card__row--align-end">
          <Button
            type="outlined"
            disabled={
              !selected ||
              (selected === indicationStatusEnum.confirmed && !commentary)
            }
            onClick={() => onClick(indicationStatusEnum.unknown)}
          >
            {intl.formatMessage(messages.callToAction)}
          </Button>
        </section>
      </Fragment>
    )}
  </Fragment>
);

FormForConfirmation.propTypes = {
  intl: intlShape.isRequired,
  onSelect: func.isRequired,
  onChange: func.isRequired,
  onClick: func.isRequired,
  commentary: string,
  selected: string,
};

FormForConfirmation.defaultProps = {
  commentary: '',
  selected: '',
};

export default injectIntl(FormForConfirmation);
