import { Button } from '@gupy/design-system';
import { GoogleAnalyticsHelper } from '@gupy/front-helpers';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { getMessages } from './HomeListProfileButtonMessages';

const propTypes = {
  applicationId: PropTypes.number.isRequired,
  jobId: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
  className: PropTypes.string,
  history: PropTypes.object.isRequired,
  analytics: PropTypes.shape({
    action: PropTypes.string,
    category: PropTypes.string,
  }),
};

const redirectToCandidateProfile = ({
  jobId,
  applicationId,
  analytics: { action = null, category = null } = {},
  history,
}) => {
  GoogleAnalyticsHelper.sendEvent({
    category: category || '[Home]',
    action: action || 'click-go-to-candidate-profile',
  });
  history.push(`/companies/jobs/${jobId}/candidates/${applicationId}?type=all`);
};

const HomeListProfileButton = ({
  applicationId,
  jobId,
  intl,
  className,
  analytics,
  history,
}) => (
  <Button
    type={'outlined'}
    size={'sm'}
    className={className}
    onClick={() =>
      redirectToCandidateProfile({
        applicationId,
        jobId,
        analytics,
        history,
      })
    }
  >
    {getMessages(intl).seeApplication}
  </Button>
);

HomeListProfileButton.propTypes = propTypes;

export default injectIntl(HomeListProfileButton);
