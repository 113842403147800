import {
  Button,
  Dropdown,
  Icon,
  IconEnum,
  Infotip,
  InfotipTypes,
} from '@gupy/design-system';
import { InputValidationTypes } from '@gupy/front-helpers/src/enums/InputValidationTypes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import NumberFormat from 'react-number-format';
import { currencies } from '../../../constants/currencies';
import NoPermission from '../JobApplication/components/NoPermission/NoPermission';
import './SalaryRange.scss';

const propTypes = {
  idFrom: PropTypes.string.isRequired,
  nameFrom: PropTypes.string.isRequired,
  idTo: PropTypes.string.isRequired,
  nameTo: PropTypes.string.isRequired,
  nameRange: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  readonlySalary: PropTypes.bool,
  hiddenSalary: PropTypes.bool,
  required: PropTypes.bool,
  showRange: PropTypes.bool,
  hideToggleRange: PropTypes.bool,
  decimalSeparator: PropTypes.string,
  thousandSeparator: PropTypes.string,
  salaryCurrencyType: PropTypes.string,
  salaryFrom: PropTypes.string,
  salaryTo: PropTypes.string,
  onSalaryChange: PropTypes.func,
  onValueChange: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  validation: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(InputValidationTypes)),
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }),
  intl: intlShape.isRequired,
  context: PropTypes.oneOf(['hiringInformation']),
};

const withSalaryLimit = ({ formattedValue, floatValue }, context) => {
  const MAX_SALARY =
    context === 'hiringInformation' ? 9999999999999.99 : 999999999.99;

  return formattedValue === '' || floatValue <= MAX_SALARY;
};

const defaultProps = {
  decimalSeparator: ',',
  thousandSeparator: '.',
  salaryCurrencyType: 'R$',
  hideToggleRange: false,
  disabled: false,
  readonlySalary: false,
  hiddenSalary: false,
  required: false,
  showRange: false,
  salaryFrom: '',
  salaryTo: '',
  placeholder: undefined,
  validation: {},
  onSalaryChange: () => {},
  onValueChange: () => {},
  context: undefined,
};

class SalaryRange extends Component {
  handleOnChange = (name, value) => {
    this.props.onValueChange({
      target: {
        name,
        value: parseFloat(value) || null,
      },
    });
  };

  toggleSalaryRange = () => {
    const { showRange, nameRange, nameTo, onValueChange } = this.props;
    const toggle = !showRange;

    onValueChange({
      target: {
        name: nameRange,
        value: toggle,
      },
    });

    if (!toggle) {
      this.handleOnChange(nameTo, '');
    }
  };

  render() {
    const {
      idFrom,
      nameFrom,
      idTo,
      nameTo,
      required,
      validation,
      disabled,
      readonlySalary,
      hiddenSalary,
      onSalaryChange,
      salaryFrom,
      salaryTo,
      placeholder,
      thousandSeparator,
      decimalSeparator,
      showRange,
      salaryCurrencyType,
      intl,
      hideToggleRange,
      context,
    } = this.props;

    let errorMessageElement = null;
    let validationClassName = '';

    if (validation) {
      if (validation.type === InputValidationTypes.error) {
        if (validation.message) {
          errorMessageElement = (
            <small className="error-message">
              {validation.message}
              <br />
            </small>
          );
        }
        validationClassName = InputValidationTypes.error;
      } else if (validation.type === InputValidationTypes.success) {
        validationClassName = InputValidationTypes.success;
      }
    }

    const commonProps = {
      thousandSeparator,
      decimalSeparator,
      decimalScale: 2,
      allowNegative: false,
      isNumericString: true,
      required,
      disabled,
      placeholder: hiddenSalary ? '---' : placeholder,
      maxLength: context === 'hiringInformation' ? '20' : '17',
    };

    const messages = defineMessages({
      monthlySalaryTooltipMessage: {
        id: 'monthly_salary_tooltip_message',
        defaultMessage:
          'O campo de Salário se refere a remuneração estipulada para a(s) posição(ões) em aberto. Esse campo não é exibido para as pessoas candidatas.',
      },
      monthlySalary: {
        id: 'monthly_salary',
        defaultMessage: 'Salário mensal',
      },
      addSalaryRange: {
        id: 'add_salary_range',
        defaultMessage: 'adicionar faixa salarial',
      },
      useFixedSalaryText: {
        id: 'use_fixed_salary_text',
        defaultMessage: 'utilizar salário fixo',
      },
      rangeText: {
        id: 'range_text',
        defaultMessage: 'até',
      },
      salaryValue: {
        id: 'salary-value',
        defaultMessage: 'valor do salário',
      },
      minimumSalaryValue: {
        id: 'minimum-salary-value',
        defaultMessage: 'valor mínimo do salário',
      },
      maximumSalaryValue: {
        id: 'maximum-salary-value',
        defaultMessage: 'valor máximo do salário',
      },
      salaryCoin: {
        id: 'salary-coin',
        defaultMessage: 'moeda',
      },
    });

    const addSalaryText = intl.formatMessage(messages.addSalaryRange);
    const useFixedSalaryText = intl.formatMessage(messages.useFixedSalaryText);
    const rangeText = intl.formatMessage(messages.rangeText);

    const missingPermission = hiddenSalary || readonlySalary;

    const handleValueChange = (name, value) => {
      this.handleOnChange(name, value);
      onSalaryChange('salaryCurrencyType', salaryCurrencyType);
    };

    return (
      <fieldset
        className={classNames(
          'form-group',
          'salary-range',
          'salary-range__fieldset',
          validationClassName,
          { 'salary-range__disabled': disabled },
          context,
        )}
      >
        <legend className="salary-range__group-label" id="salaryRange_label">
          <span
            className={classNames('salary-range__label', {
              'salary-range__label--disabled': disabled,
            })}
          >
            {intl.formatMessage(messages.monthlySalary)}
          </span>
          <Infotip
            id="salaryRange_label monthlySalary_tooltip"
            alignment={InfotipTypes.TOP_CENTER}
            message={intl.formatMessage(messages.monthlySalaryTooltipMessage)}
            width={250}
          >
            <Icon icon={IconEnum.Info} fontSize="small" />
          </Infotip>
        </legend>
        <ul
          role="group"
          className="form-group__content salary-range__values salary-range__items"
        >
          <li>
            <Dropdown
              id="salaryCurrencyType"
              onChange={value => onSalaryChange('salaryCurrencyType', value)}
              dropdownAriaLabel={intl.formatMessage(messages.salaryCoin)}
              placeholder={hiddenSalary ? '---' : ''}
              options={currencies}
              selectedOptionValue={salaryCurrencyType}
              disabled={disabled}
              size={'large'}
            />
          </li>
          <li>
            <NumberFormat
              id={idFrom}
              name={nameFrom}
              value={salaryFrom}
              isAllowed={e => withSalaryLimit(e, context)}
              aria-label={intl.formatMessage(
                showRange ? messages.minimumSalaryValue : messages.salaryValue,
              )}
              onValueChange={values =>
                handleValueChange(nameFrom, values.value)
              }
              {...commonProps}
            />
          </li>
          {showRange ? (
            <li>
              <span>{rangeText}</span>
            </li>
          ) : null}

          {showRange ? (
            <li>
              <NumberFormat
                id={idTo}
                name={nameTo}
                value={salaryTo}
                isAllowed={e => withSalaryLimit(e, context)}
                onValueChange={values =>
                  handleValueChange(nameTo, values.value)
                }
                {...commonProps}
                aria-label={intl.formatMessage(messages.maximumSalaryValue)}
              />
            </li>
          ) : null}
        </ul>
        {!missingPermission && !hideToggleRange && (
          <Button
            type="link"
            size="sm"
            onClick={this.toggleSalaryRange}
            disabled={disabled}
          >
            {showRange ? useFixedSalaryText : addSalaryText}
          </Button>
        )}
        {errorMessageElement}
        {missingPermission && (
          <NoPermission
            noEditPermission={readonlySalary}
            noViewPermission={hiddenSalary}
          />
        )}
      </fieldset>
    );
  }
}

SalaryRange.propTypes = propTypes;
SalaryRange.defaultProps = defaultProps;

export default injectIntl(SalaryRange);
