import dayjs from 'dayjs';
import { combineReducers } from 'redux';
import JobStepFinalActionTypes from '../../../constants/Job/JobStep/JobStepFinalActionTypes';

const initialModelForm = {
  id: undefined,
  jobId: undefined,
  name: '',
  type: '',
  objectivesDescription: '',
  endDate: undefined,
  status: '',
};

const modelForm = (state = initialModelForm, action) => {
  switch (action.type) {
    case JobStepFinalActionTypes.GET_SUCCESS: {
      const { endDate } = action.modelForm;
      const newState = {
        id: action.modelForm.id,
        jobId: action.modelForm.jobId,
        name: action.modelForm.name,
        type: action.modelForm.type,
        objectivesDescription: action.modelForm.objectivesDescription,
        status: action.modelForm.status,
        endDate: endDate ? dayjs(endDate) : endDate,
      };
      return newState;
    }
    case JobStepFinalActionTypes.CHANGE_FORM:
      return Object.assign({}, state, action.modelForm);
    default:
      return state;
  }
};

const validation = (state = {}, action) => {
  switch (action.type) {
    case JobStepFinalActionTypes.PATCH:
    case JobStepFinalActionTypes.GET:
      return {};
    case JobStepFinalActionTypes.PATCH_FAIL:
      return action.validation;
    default:
      return state;
  }
};

export const isSaving = (state = false, action) => {
  switch (action.type) {
    case JobStepFinalActionTypes.PATCH:
      return true;
    case JobStepFinalActionTypes.PATCH_SUCCESS:
    case JobStepFinalActionTypes.PATCH_FAIL:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  modelForm,
  validation,
  isSaving,
});
