import Integrations from './Integrations';
import {
  createRsclinkedinIntegrationChildApp,
  initIntegrations,
  IntegrationsActionTypes,
  saveIntegrations,
} from './IntegrationsAction';
import IntegrationsReducer from './IntegrationsReducer';
import IntegrationsSaga from './IntegrationsSaga';

const IntegrationsAction = {
  initIntegrations,
  IntegrationsActionTypes,
  saveIntegrations,
  createRsclinkedinIntegrationChildApp,
};

export {
  Integrations,
  IntegrationsAction,
  IntegrationsReducer,
  IntegrationsSaga,
};
