import { useFlag } from '@unleash/proxy-client-react';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  WrappedComponent: PropTypes.node.isRequired,
};

const WithUnleash = WrappedComponent => featureFlags => props => {
  const featureFlagProps = {};

  featureFlags.forEach(featureFlag =>
    Object.assign(
      // TODO: FRONT-75 - Regra de hook quebrada
      featureFlagProps,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      { [featureFlag.propName]: useFlag(featureFlag.name) },
    ),
  );

  return <WrappedComponent {...props} {...featureFlagProps} />;
};

WithUnleash.propTypes = propTypes;

export default WithUnleash;
