import { BaseForm, cpfValidator, notEmpty } from '@gupy/front-commons';

class UserProfileTaxpayerRegistryForm extends BaseForm {
  constructor() {
    super({
      taxpayerRegistry: {
        validate: {
          notEmpty: true,
        },
      },
      selectedCountry: {
        validate: {
          notEmpty: true,
        },
      },
    });
  }

  customValidateModel() {
    let validation = this.validateModel();

    const taxpayerRegistry = this.fields.taxpayerRegistry
      ? this.fields.taxpayerRegistry.value
      : null;

    const selectedCountry = this.fields.selectedCountry
      ? this.fields.selectedCountry.value
      : null;

    if (selectedCountry && selectedCountry === 'BR') {
      const taxpayerError =
        notEmpty({}, taxpayerRegistry) || cpfValidator({}, taxpayerRegistry);

      if (taxpayerError) {
        validation = {
          ...validation,
          taxpayerRegistry: {
            type: 'error',
            message: taxpayerError,
          },
        };
      }
    }

    return validation;
  }
}

export default UserProfileTaxpayerRegistryForm;
