import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import * as service from './TermsAndPrivacyService';

function* updateUserConsent() {
  try {
    yield call(service.updateTermsConsent);
    yield call(service.updatePrivacyConsent);
    yield put(actions.updateUserConsent.fulfilled());
  } catch (error) {
    yield put({
      type: actions.UPDATE_USER_CONSENT_REQUEST_FAIL,
      error,
    });
  }
}

function* saga() {
  yield takeLatest(actions.UPDATE_USER_CONSENT_REQUEST, updateUserConsent);
}

export default saga;
