import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from './TaskActionTypes';
import TaskService from './TaskService';

export function* getUserTasks() {
  try {
    const response = yield call(TaskService.get);

    yield put({
      type: types.GET_TASKS_SUCCESS,
      userTasks: response.body,
    });
  } catch (error) {
    if (error.statusCode === 404) {
      yield put({
        type: types.GET_TASKS_SUCCESS,
        userTasks: [],
      });
      return;
    }
    yield put({
      type: types.GET_TASKS_FAIL,
      error,
    });
  }
}

export function* deleteTask(action) {
  const { taskId } = action;
  try {
    yield call(TaskService.delete, { taskId });

    yield put({
      type: types.DELETE_TASK_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.DELETE_TASK_FAIL,
      error,
    });
  }
}

function* TaskSaga() {
  yield takeLatest(types.GET_TASKS, getUserTasks);
  yield takeLatest(types.DELETE_TASK_SUCCESS, getUserTasks);
  yield takeLatest(types.DELETE_TASK, deleteTask);
}

export default TaskSaga;
