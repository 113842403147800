import {
  ForgotPasswordActionTypes,
  ForgotPasswordForm,
} from '@gupy/front-commons';
import { put, takeLatest } from 'redux-saga/effects';
import { getSubdomain } from '../../helpers/subdomainHelper';
import AuthenticationService from '../../services/Authentication/AuthenticationService';
import ForgotPasswordService from '../../services/Authentication/ForgotPasswordService';

function* postForgot(action) {
  const { payload } = action;
  const form = new ForgotPasswordForm();

  payload.subdomain = getSubdomain();

  form.populate(payload);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: ForgotPasswordActionTypes.POST_FAIL,
      validation,
    });
  } else {
    try {
      yield ForgotPasswordService.postForgot(payload);

      yield put({
        type: ForgotPasswordActionTypes.POST_SUCCESS,
      });
    } catch (error) {
      yield put({
        type: ForgotPasswordActionTypes.POST_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* initForgotPassword(action) {
  try {
    const { subdomain, currentCompany } = action;

    // Fetch company info only if there's no currentCompany already
    if (Object.keys(currentCompany).length === 0) {
      const companySummaryResponse =
        yield AuthenticationService.getCompanySummary(subdomain);
      yield put({
        type: ForgotPasswordActionTypes.INIT_FORGOT_PASSWORD_SUCCESS,
        currentCompany: companySummaryResponse.body,
      });
    } else {
      yield put({
        type: ForgotPasswordActionTypes.INIT_FORGOT_PASSWORD_SUCCESS,
        currentCompany,
      });
    }
  } catch (error) {
    yield put({
      type: ForgotPasswordActionTypes.INIT_FORGOT_PASSWORD_FAIL,
      error,
    });
  }
}

function* forgotPasswordSaga() {
  yield takeLatest(
    ForgotPasswordActionTypes.INIT_FORGOT_PASSWORD,
    initForgotPassword,
  );
  yield takeLatest(ForgotPasswordActionTypes.POST, postForgot);
}

export default forgotPasswordSaga;
