import { IconButton } from '@gupy/design-system';
import React, { Fragment, useState } from 'react';
import ContentModal from './ContentModalDiscoveryProductsWrapper';
import ModalDiscoveryProductsStyles from './ModalDiscoveryProductsWrapper.styles';

const ModalDiscoveryProductsWrapper = ModalDiscoveryProductsTag => {
  const { modalData, mobileProductNavDisplay, setMobileProductNavDisplay } =
    ModalDiscoveryProductsTag;

  const { ButtonOpenModal, Modal, ProductsList, GupyProduct, ProductTitleTab } =
    ModalDiscoveryProductsStyles;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSelectTab, currentSelectTab] = useState(0);

  const handleModalButtonClick = () => {
    if (window.innerWidth > 1024) {
      setIsModalOpen(true);
    } else if (mobileProductNavDisplay === 'none') {
      setMobileProductNavDisplay('block');
    } else {
      setMobileProductNavDisplay('none');
    }
  };

  return (
    <Fragment>
      {modalData && (
        <Fragment>
          <div className="button-modal-discovery-products">
            <IconButton
              variant="text"
              size="sm"
              aria-label={modalData.titleModal}
              alt={modalData.titleModal}
              className="modal-discovery-products-icon-button"
              onClick={handleModalButtonClick}
              icon={
                <img
                  alt=""
                  src={`${process.env.REACT_APP_API_CENTRAL_TALENTOS}/modal-icons/icon-dragDrop.svg`}
                />
              }
            />
            <ButtonOpenModal
              {...modalData.styleButtonOpenModal}
              variant="link"
              size="md"
              text={modalData.titleModal}
              aria-label={modalData.titleModal}
              onClick={() => setIsModalOpen(true)}
            />
          </div>

          <Modal
            {...modalData.styleModal}
            open={isModalOpen}
            hasCloseButton
            onOpenChange={value => setIsModalOpen(value)}
            id="modal-discovery-products"
            maxWidth="lg"
            title={modalData.titleModal}
            onClose={() => setIsModalOpen(false)}
          >
            {modalData.tabsContent && (
              <Fragment>
                <ProductsList {...modalData.styleProductsList}>
                  <GupyProduct {...modalData.styleGupyProduct}>
                    {modalData.tabsContent.map(discoveryProduct => (
                      <ProductTitleTab
                        items={[{ title: `${discoveryProduct.tabsName}` }]}
                        ariaLabel={discoveryProduct.tabsName}
                        id={discoveryProduct.id}
                        onClick={() =>
                          currentSelectTab(
                            modalData.tabsContent.findIndex(
                              tab => tab.id === discoveryProduct.id,
                            ),
                          )
                        }
                        hasBoxShadow={false}
                        tabIndex={discoveryProduct.id}
                      />
                    ))}
                  </GupyProduct>
                </ProductsList>
                <ContentModal
                  currentContent={modalData.tabsContent[isSelectTab]}
                  data={modalData}
                  styleModalContent={modalData.styleModalContent}
                  styleModalDiv={modalData.styleModalDiv}
                  styleModalContentList={modalData.styleModalContentList}
                  styleFooterModalContent={modalData.styleFooterModalContent}
                />
              </Fragment>
            )}
          </Modal>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ModalDiscoveryProductsWrapper;
