import PropTypes from 'prop-types';
import React from 'react';
import Grid from '../../../../components/Grid/Grid';
import SetupGridSidebarMenu from '../../../../components/Grid/SetupGridSidebarMenu';
import MainLayout from './MainLayout';

const propTypes = {
  permissions: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};

const SetupLayout = ({ children, permissions }) => (
  <MainLayout>
    <Grid>
      <SetupGridSidebarMenu permissions={permissions} />
      {children}
    </Grid>
  </MainLayout>
);

SetupLayout.propTypes = propTypes;

export default SetupLayout;
