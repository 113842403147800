import api from '../../api/DarthVaderClient';

export default {
  getProfileTest() {
    return api.get('/profile-test/companies/profiles/tests');
  },

  getProfileTestAverage(departmentId, roleId) {
    const departmentParam = departmentId ? `departmentId=${departmentId}` : '';
    const roleParam = roleId ? `roleId=${roleId}` : '';
    const queryParams =
      departmentId || roleId ? `?${departmentParam}${roleParam}` : '';

    return api.get(`/profile-test/companies/profiles${queryParams}`);
  },

  getCompanyUserProfileTest() {
    return api.get('/profile-test/companies/profiles/current');
  },

  getProfileTestDiagnostic({ groupKey, groupName }) {
    const queryString = groupName ? `?groupName=${groupName}` : '';
    return api.get(
      `/profile-test/companies/diagnostic/${groupKey + queryString}`,
    );
  },

  getCandidateMatching({ groupName, groupKey, applicationId }) {
    return api.get(
      `/profile-test/companies/groups/${groupName}/${groupKey}/matching/${applicationId}`,
    );
  },

  patchProfileTest(payload) {
    return api.patch('/profile-test/companies/profiles/tests', payload);
  },

  finishProfileTest() {
    return api.post('/profile-test/companies/profiles/close');
  },

  closeProfileTest(payload) {
    return api.post('/profile-test/companies/profiles/close', payload);
  },

  getProfileTestStatistics() {
    return api.get('/profile-test/companies/statistics');
  },

  switchProfileTestCompatibility(enabled) {
    return api.post('/profile-test/companies/compatibility/options', {
      enabled,
    });
  },
};
