import { combineReducers } from 'redux';
import EmailDomainTypes from './ActionTypes';

export const emailDomains = (state = [], action) => {
  switch (action.type) {
    case EmailDomainTypes.LIST_EMAIL_DOMAINS_SUCCESS:
      return action.emailDomains;
    case EmailDomainTypes.CREATE_EMAIL_DOMAIN_SUCCESS:
      return [...state, action.newEmailDomain];
    case EmailDomainTypes.DELETE_EMAIL_DOMAIN_SUCCESS:
      return state.filter(
        emailDomain => emailDomain.id !== action.emailDomainId,
      );
    case EmailDomainTypes.LIST_EMAIL_DOMAINS:
    case EmailDomainTypes.LIST_EMAIL_DOMAINS_FAIL:
    default:
      return state;
  }
};

export const newEmailDomain = (state = {}, action) => {
  switch (action.type) {
    case EmailDomainTypes.CREATE_EMAIL_DOMAIN_SUCCESS:
      return action.newEmailDomain;
    case EmailDomainTypes.CREATE_EMAIL_DOMAIN:
    case EmailDomainTypes.CREATE_EMAIL_DOMAIN_FAIL:
    default:
      return state;
  }
};

export const isLoading = (state = false, action) => {
  switch (action.type) {
    case EmailDomainTypes.LIST_EMAIL_DOMAINS:
      return true;
    case EmailDomainTypes.LIST_EMAIL_DOMAINS_SUCCESS:
    case EmailDomainTypes.LIST_EMAIL_DOMAINS_FAIL:
      return false;
    default:
      return state;
  }
};

const validation = (state = {}, action) => {
  switch (action.type) {
    case EmailDomainTypes.CREATE_EMAIL_DOMAIN_FAIL:
      return action.validation;
    case EmailDomainTypes.CLOSE_MODAL:
    case EmailDomainTypes.CREATE_EMAIL_DOMAIN:
      return {};
    default:
      return state;
  }
};

export const isModalOpen = (state = false, action) => {
  switch (action.type) {
    case EmailDomainTypes.OPEN_MODAL:
      return true;
    case EmailDomainTypes.CLOSE_MODAL:
    case EmailDomainTypes.CREATE_EMAIL_DOMAIN_SUCCESS:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  emailDomains,
  isLoading,
  validation,
  isModalOpen,
});
