const stepTypeNames = {
  Cadastro: 'register',
  Contratação: 'hiring',
  'Dados pré-contratação': 'preHiringData',
};

const renderStepTypeName = (messages, itemName) =>
  messages[stepTypeNames[itemName]] || itemName;

export default renderStepTypeName;
