import { TextEditor } from '@gupy/front-commons';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import CompaniesApi from '../../../../../companies/src/api/DarthVaderClient';
import {
  emailAttachmentUploadUrl,
  imageUploadUrl,
} from '../../../../src/helpers/FileUploadHelper';
import { getMessages } from '../Messages';

const propTypes = {
  attachedFiles: PropTypes.array,
  body: PropTypes.string,
  currentAttachedFile: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isTalentPool: PropTypes.bool,
  isTemplateEditMode: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  markups: PropTypes.array,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  showMarkups: PropTypes.bool,
  validation: PropTypes.object,

  onChange: PropTypes.func.isRequired,
  onFileUploadStatusChange: PropTypes.func,
  intl: intlShape.isRequired,
};

const defaultProps = {
  attachedFiles: [],
  body: '',
  currentAttachedFile: null,
  disabled: false,
  isTalentPool: false,
  isTemplateEditMode: false,
  label: null,
  markups: [],
  required: false,
  showMarkups: true,
  validation: null,
  onFileUploadStatusChange: () => {},
};

class EmailTemplateBodyBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.messages = getMessages(props.intl);

    this.state = {
      selection: null,
    };
  }

  getNewTextWithMarkup = markup => {
    const { selection } = this.state;
    const nodeText = selection.textContent;
    const postionSelection = selection.focusOffset;

    const textWithMarkup =
      nodeText.substring(0, postionSelection) +
      markup +
      nodeText.substring(postionSelection, selection.focusNode.length);

    return textWithMarkup;
  };

  translateToView = emailBody => {
    let translatedEmailBody = emailBody;
    this.props.markups.forEach(markup => {
      translatedEmailBody = translatedEmailBody
        .split(markup.value)
        .join(markup.label);
    });
    return translatedEmailBody;
  };

  translateToModel = emailBody => {
    let translatedEmailBody = emailBody;
    if (!translatedEmailBody) {
      return '';
    }
    this.props.markups.forEach(markup => {
      translatedEmailBody = translatedEmailBody
        .split(markup.label)
        .join(markup.value);
    });
    return translatedEmailBody;
  };

  handleChange = event => {
    const newEvent = event;
    if (newEvent && newEvent.target) {
      const isNotRelatedToAttachments = !['fileName', 'files'].includes(
        event.target.name,
      );
      if (isNotRelatedToAttachments) {
        newEvent.target.value = this.translateToModel(newEvent.target.value);
      }
    }
    this.props.onChange(newEvent);
  };

  render() {
    const {
      attachedFiles,
      body,
      currentAttachedFile,
      disabled,
      id,
      isTalentPool,
      isTemplateEditMode,
      label,
      markups,
      name,
      onFileUploadStatusChange,
      required,
      showMarkups,
      validation,
    } = this.props;

    let markupsArr = markups;

    if (isTalentPool) {
      markupsArr = markups.filter(
        variable =>
          variable.value !== '[jobLink]' && variable.value !== '[jobName]',
      );
    }

    return (
      <div role="button" tabIndex="-1" className="body-builder">
        <TextEditor
          messages={this.messages}
          enableImageFeatures
          label={label}
          id={id}
          name={name}
          required={required}
          value={this.translateToView(body)}
          validation={validation}
          disabled={disabled}
          onChange={this.handleChange}
          fileUploadApiUrl={
            !isTalentPool && !isTemplateEditMode
              ? emailAttachmentUploadUrl
              : undefined
          }
          uploadBodyImageUrl={imageUploadUrl}
          fileUploadApiRequestHeaders={CompaniesApi.headersFactory()}
          blockDangerousExtensions
          onUploadStatusChange={onFileUploadStatusChange}
          attachedFiles={attachedFiles}
          currentAttachedFieldValue={currentAttachedFile}
          attachFileText={
            <FormattedMessage
              id="attach_files_label"
              defaultMessage="ANEXAR ARQUIVO"
            />
          }
          attachFileDescription={
            <FormattedMessage
              id="attach_file_description"
              defaultMessage="Anexos de até 20 mb são permitidos."
            />
          }
          invalidSizeMessage={
            <FormattedMessage
              id="invalid_files_total_size_25mb"
              defaultMessage="Falha ao anexar o arquivo. Total de 25MB excedido."
            />
          }
          fileErrorMessage={
            <FormattedMessage
              id="file_error_message"
              defaultMessage="Falha ao carregar arquivo."
            />
          }
          invalidImageExtensionMessage={this.messages.invalidImageExtension}
          invalidImageSizeMessage={this.messages.invalidImageSize}
          hyperlinkEnabled
          markList={
            showMarkups && markups.length > 0
              ? [
                  {
                    markups: markupsArr.map(marker => ({
                      name: marker.label,
                    })),
                  },
                ]
              : null
          }
        />
      </div>
    );
  }
}

EmailTemplateBodyBuilder.propTypes = propTypes;
EmailTemplateBodyBuilder.defaultProps = defaultProps;

export default injectIntl(EmailTemplateBodyBuilder);
