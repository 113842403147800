import ArrayHelper from '@gupy/front-helpers/src/helpers/ArrayHelper';
import dayjs from 'dayjs';
import { combineReducers } from 'redux';
import AdditionalQuestionTypes from '../../../../../constants/Job/AdditionalQuestionTypes';
import { JobAdditionalQuestionsActionTypes } from './JobAdditionalQuestionsAction';
import { JobStepRegisterActionTypes } from './JobStepRegisterAction';

const initialModelForm = {
  id: undefined,
  jobId: undefined,
  name: '',
  objectivesDescription: '',
  hasEndDate: false,
  endDate: undefined,
  testId: false,
  onlineMoveType: 'manual',
  onlineMoveIsOnlyExternal: false,
};

const isSaving = (state = false, action) => {
  switch (action.type) {
    case JobStepRegisterActionTypes.POST:
    case JobStepRegisterActionTypes.PATCH:
    case JobAdditionalQuestionsActionTypes.POST:
    case JobAdditionalQuestionsActionTypes.PUT:
      return true;
    case JobStepRegisterActionTypes.POST_SUCCESS:
    case JobStepRegisterActionTypes.POST_FAIL:
    case JobStepRegisterActionTypes.PATCH_SUCCESS:
    case JobStepRegisterActionTypes.PATCH_FAIL:
    case JobAdditionalQuestionsActionTypes.POST_SUCCESS:
    case JobAdditionalQuestionsActionTypes.POST_FAIL:
    case JobAdditionalQuestionsActionTypes.PUT_SUCCESS:
    case JobAdditionalQuestionsActionTypes.PUT_FAIL:
      return false;
    default:
      return state;
  }
};

const modelForm = (state = initialModelForm, action) => {
  switch (action.type) {
    case JobStepRegisterActionTypes.GET_SUCCESS: {
      const { endDate } = action.modelForm;

      const newState = {
        id: action.modelForm.id,
        jobId: action.modelForm.jobId,
        name: action.modelForm.name,
        objectivesDescription: action.modelForm.objectivesDescription,
        hasEndDate: !!endDate,
        endDate: endDate ? dayjs(endDate) : endDate,
        testId: action.modelForm.testId === 'profile',
        onlineMoveType: action.modelForm.onlineMoveType,
        onlineMoveIsOnlyExternal: action.modelForm.onlineMoveIsOnlyExternal,
      };
      return newState;
    }
    case JobStepRegisterActionTypes.CHANGE_FORM:
      return Object.assign({}, state, action.modelForm);
    default:
      return state;
  }
};

const isLoading = (state = true, action) => {
  switch (action.type) {
    case JobStepRegisterActionTypes.GET_SUCCESS:
    case JobStepRegisterActionTypes.GET_FAIL:
      return false;
    default:
      return state;
  }
};

const validation = (state = {}, action) => {
  switch (action.type) {
    case JobStepRegisterActionTypes.PATCH:
    case JobStepRegisterActionTypes.GET:
      return {};
    case JobStepRegisterActionTypes.PATCH_FAIL:
      return action.validation;
    default:
      return state;
  }
};

const initialQuestionForm = {
  id: undefined,
  jobId: undefined,
  questionType: '',
  description: '',
  options: [],
  required: false,
  eliminatory: false,
};

export const questionForm = (state = initialQuestionForm, action) => {
  switch (action.type) {
    case JobAdditionalQuestionsActionTypes.EDIT: {
      const options = action.modelForm.options
        ? action.modelForm.options.map(option => ({
            ...option,
            listId: ArrayHelper.generateReactKey(option.id),
          }))
        : [];

      return {
        id: action.modelForm.id,
        jobId: action.modelForm.jobId,
        questionType: action.modelForm.questionType,
        description: action.modelForm.description,
        required: !!action.modelForm.required,
        eliminatory: !!action.modelForm.eliminatory,
        options,
      };
    }
    case JobAdditionalQuestionsActionTypes.CANCEL_EDIT:
    case JobAdditionalQuestionsActionTypes.CLEAR_FORM:
      return initialQuestionForm;
    case JobAdditionalQuestionsActionTypes.CHANGE_FORM: {
      const newState = {
        ...state,
        ...action.modelForm,
      };

      if (action.modelForm.eliminatory === true) {
        newState.required = true;

        if (
          [
            AdditionalQuestionTypes.singleLineAnswer,
            AdditionalQuestionTypes.multiLineAnswer,
          ].indexOf(newState.questionType) !== -1
        ) {
          newState.questionType = '';
        }
      } else if (action.modelForm.required === false) {
        newState.eliminatory = false;

        for (let i = 0; i < newState.options.length; i += 1) {
          newState.options[i].eliminatory = false;
        }
      }

      if (action.modelForm.eliminatory === false) {
        for (let i = 0; i < newState.options.length; i += 1) {
          newState.options[i].eliminatory = false;
        }
      }

      if (
        action.modelForm.questionType &&
        [
          AdditionalQuestionTypes.choiceAnswer,
          AdditionalQuestionTypes.checkAnswer,
        ].indexOf(action.modelForm.questionType) !== -1 &&
        newState.options.length === 0
      ) {
        newState.options.push({
          listId: ArrayHelper.generateReactKey(),
          description: '',
        });
        newState.options.push({
          listId: ArrayHelper.generateReactKey(),
          description: '',
        });
      }

      if (
        action.modelForm.questionType &&
        [
          AdditionalQuestionTypes.singleLineAnswer,
          AdditionalQuestionTypes.multiLineAnswer,
        ].indexOf(action.modelForm.questionType) !== -1
      ) {
        newState.options = [];
      }

      return newState;
    }
    case JobAdditionalQuestionsActionTypes.CHANGE_FORM_ADDITIONAL_QUESTION_OPTION: {
      return {
        ...state,
        options: state.options.map(item => {
          if (item.listId === action.option.listId) {
            return {
              ...item,
              ...action.option,
            };
          }

          return item;
        }),
      };
    }
    case JobAdditionalQuestionsActionTypes.ADD_OPTION: {
      const options = state.options ? state.options.slice() : [];

      options.push({
        listId: ArrayHelper.generateReactKey(),
        description: '',
      });

      return {
        ...state,
        options,
      };
    }
    case JobAdditionalQuestionsActionTypes.DELETE_OPTION:
      return {
        ...state,
        options: state.options.filter(
          item => item.listId !== action.option.listId,
        ),
      };
    default:
      return state;
  }
};

export const questions = (state = [], action) => {
  switch (action.type) {
    case JobAdditionalQuestionsActionTypes.GET_ALL_SUCCESS:
      return action.questions;
    default:
      return state;
  }
};

export const modalIsOpen = (state = false, action) => {
  switch (action.type) {
    case JobAdditionalQuestionsActionTypes.POST_SUCCESS:
    case JobAdditionalQuestionsActionTypes.PUT_SUCCESS:
    case JobAdditionalQuestionsActionTypes.CANCEL_EDIT:
      return false;
    case JobAdditionalQuestionsActionTypes.POST_FAIL:
    case JobAdditionalQuestionsActionTypes.EDIT:
    case JobAdditionalQuestionsActionTypes.PUT_FAIL:
      return true;
    default:
      return state;
  }
};

const modalValidation = (state = {}, action) => {
  switch (action.type) {
    case JobAdditionalQuestionsActionTypes.EDIT:
    case JobAdditionalQuestionsActionTypes.POST:
    case JobAdditionalQuestionsActionTypes.PUT:
    case JobAdditionalQuestionsActionTypes.CLEAR_FORM:
      return {};
    case JobAdditionalQuestionsActionTypes.POST_FAIL:
    case JobAdditionalQuestionsActionTypes.PUT_FAIL:
      return action.validation;
    default:
      return state;
  }
};

export default combineReducers({
  modelForm,
  validation,
  isSaving,
  questionForm,
  questions,
  modalIsOpen,
  modalValidation,
  isLoading,
});
