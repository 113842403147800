import { buildToast, ToastTypes } from '@gupy/front-commons';
import { call, put, takeLatest } from 'redux-saga/effects';
import SocialMediasService from '../../services/SocialMedias/SocialMediasService';
import { getAllSocialMediasSuccess } from './Actions';
import SocialMediasActionTypes from './ActionTypes';
import {
  adaptToGetSocialMedia,
  adaptToPutSocialMedia,
} from './helpers/adapters';
import SocialMediaFormValidator from './helpers/SocialMediaFormValidator';

function* getAll(action) {
  const { selectedId } = action;
  try {
    const allResponse = yield SocialMediasService.getAll();
    yield put(getAllSocialMediasSuccess(allResponse.body, selectedId));
  } catch (error) {
    yield put({
      type: SocialMediasActionTypes.GET_ALL_FAIL,
    });
  }
}

export function* patchCareerPageSocialMedia(action) {
  const { id, ...payloadNoId } = action.payload;
  const { messages } = action;

  if (action.payload.socialImageBase64) {
    delete payloadNoId.socialPictureUrl;
  }

  const form = new SocialMediaFormValidator();
  form.populate(payloadNoId);
  const validation = form.validateForm();

  if (validation) {
    yield put({
      type: SocialMediasActionTypes.PATCH_CAREER_PAGE_SOCIAL_MEDIA_FAIL,
      validation,
    });
  } else {
    try {
      const response = yield call(
        SocialMediasService.patchCareerPage,
        id,
        payloadNoId,
      );

      yield put({
        type: SocialMediasActionTypes.PATCH_CAREER_PAGE_SOCIAL_MEDIA_SUCCESS,
        modelForm: response.body,
        toast: buildToast(messages.success, ToastTypes.success),
      });

      yield put({
        type: SocialMediasActionTypes.GET_ALL_REQUEST,
        selectedId: action.payload.id,
      });
    } catch (error) {
      yield put({
        type: SocialMediasActionTypes.PATCH_CAREER_PAGE_SOCIAL_MEDIA_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* getAllCareerPagesExpanded(action) {
  const { selectedId } = action;
  try {
    const allResponse = yield SocialMediasService.getAllCareerPages();
    const adaptedResponse = adaptToGetSocialMedia(allResponse.body);
    yield put(getAllSocialMediasSuccess(adaptedResponse, selectedId));
  } catch (error) {
    yield put({
      type: SocialMediasActionTypes.GET_ALL_FAIL,
    });
  }
}

export function* putCareerPageSocialMedia(action) {
  const { id, ...payloadNoId } = action.payload;
  const { messages } = action;

  const form = new SocialMediaFormValidator();
  form.populate(payloadNoId);
  const validation = form.validateForm();

  if (validation) {
    yield put({
      type: SocialMediasActionTypes.PUT_CAREER_PAGE_SOCIAL_MEDIA_FAIL,
      validation,
    });
  } else {
    try {
      const adaptedPayload = adaptToPutSocialMedia(payloadNoId);
      const response = yield call(
        SocialMediasService.putCareerPage,
        id,
        adaptedPayload,
      );

      yield put({
        type: SocialMediasActionTypes.PUT_CAREER_PAGE_SOCIAL_MEDIA_SUCCESS,
        modelForm: response.body,
        toast: buildToast(messages.success, ToastTypes.success),
      });

      yield put({
        type: SocialMediasActionTypes.GET_CAREER_PAGES_EXPANDED_REQUEST,
        selectedId: action.payload.id,
      });
    } catch (error) {
      yield put({
        type: SocialMediasActionTypes.PUT_CAREER_PAGE_SOCIAL_MEDIA_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* SocialMediaSaga() {
  yield takeLatest(SocialMediasActionTypes.GET_ALL_REQUEST, getAll);
  yield takeLatest(
    SocialMediasActionTypes.PATCH_CAREER_PAGE_SOCIAL_MEDIA_REQUEST,
    patchCareerPageSocialMedia,
  );
  yield takeLatest(
    SocialMediasActionTypes.GET_CAREER_PAGES_EXPANDED_REQUEST,
    getAllCareerPagesExpanded,
  );
  yield takeLatest(
    SocialMediasActionTypes.PUT_CAREER_PAGE_SOCIAL_MEDIA_REQUEST,
    putCareerPageSocialMedia,
  );
}

export default SocialMediaSaga;
