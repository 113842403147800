import { PropTypes } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const propTypes = {
  onUndoRemove: PropTypes.func.isRequired,
};

const UserNotificationDeleted = ({ onUndoRemove }) => (
  <div className="gdp-notifications-card-deleted">
    <span className="gdp-notifications-card-deleted__message">
      <FormattedMessage
        id="sucess-message-remove-notification"
        defaultMessage="Notificação excluída com sucesso."
      />
      <br />
      <span
        role="button"
        tabIndex={0}
        onClick={onUndoRemove}
        className="gdp-notifications-card-deleted__undo-button"
      >
        <FormattedMessage id="undo" defaultMessage="Desfazer." />
      </span>
    </span>
  </div>
);

UserNotificationDeleted.propTypes = propTypes;

export default UserNotificationDeleted;
