import { call, put, takeLatest } from 'redux-saga/effects';
import { getJobSuccess } from '../../../actions/Job/JobAction';
import {
  getCompanyBranchesLeavesSuccess,
  getJobDetailsSuccess,
} from '../../../actions/Job/JobWorkplaceAction';
import JobWorkplaceActionTypes from '../../../constants/Job/JobWorkplaceActionTypes';
import history from '../../../history';
import JobService from '../../../services/Job/JobService';
import SubsidiaryService from '../../../services/Subsidiary/SubsidiaryService';

function* get(action) {
  try {
    const response = yield JobService.get(action.id);
    const responseSubsidiaries = yield SubsidiaryService.getAll();

    yield put(
      getJobDetailsSuccess(responseSubsidiaries.body.data, response.body),
    );
    yield put(getJobSuccess(response.body));
  } catch (error) {
    if (error.statusCode === 404) {
      yield call(history.push, '/companies/jobs/not-found');
    } else {
      yield put({
        type: JobWorkplaceActionTypes.INIT_FAIL,
      });
    }
  }
}

export function* patch(action) {
  const { payload, jobPath } = action;
  try {
    const model = {
      ...payload,
    };
    const response = yield JobService.patch(model);

    yield put({
      type: JobWorkplaceActionTypes.PATCH_SUCCESS,
      modelForm: response.body,
    });
    yield call(history.push, jobPath);
  } catch (error) {
    yield put({
      type: JobWorkplaceActionTypes.PATCH_FAIL,
      error: { alert: error.body },
    });
  }
}

function* getCompanyBranchesLeaves(action) {
  try {
    const response = yield JobService.getCompanyBranchesLeaves(action.search);

    yield put(getCompanyBranchesLeavesSuccess(response.body.data));
  } catch (error) {
    yield put({
      type: JobWorkplaceActionTypes.GET_COMPANY_BRANCHES_LEAVES_FAIL,
      error,
    });
  }
}

function* JobWorkplaceSaga() {
  yield takeLatest(JobWorkplaceActionTypes.PATCH, patch);
  yield takeLatest(JobWorkplaceActionTypes.INIT, get);
  yield takeLatest(
    JobWorkplaceActionTypes.GET_COMPANY_BRANCHES_LEAVES,
    getCompanyBranchesLeaves,
  );
}

export default JobWorkplaceSaga;
