import { buildToast, ToastTypes } from '@gupy/front-commons';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import uuid from 'uuid';
import IntegrationsService from '../../services/Integrations/IntegrationsService';
import { adaptResponseToJobBoardsCustomIdsViewModel } from './adapters/jobBoardBindingAdapter';
import {
  IntegrationsActionTypes,
  setRscLinkedinIntegrationChildAppFormValidationError,
} from './IntegrationsAction';

export function* initIntegrations(action) {
  try {
    const [
      companyIntegrations,
      companyPublicCareerPages,
      jobBoardBoundCareerPages,
    ] = yield all([
      IntegrationsService.getCompanyIntegrations(action.renderScreen),
      IntegrationsService.getCompanyPublicCareerPages(),
      IntegrationsService.getJobBoardsCustomIds(),
    ]);

    const { jobBoardBindings = [] } = jobBoardBoundCareerPages.body.data || {};
    const jobBoardsCustomIds = jobBoardBindings.map(
      adaptResponseToJobBoardsCustomIdsViewModel,
    );

    if (!jobBoardsCustomIds.length) {
      const getLinkedinJobBoardId =
        yield IntegrationsService.getLinkedinJobBoardId();
      const { id: jobBoardId, name } = getLinkedinJobBoardId.body.data[0];
      jobBoardsCustomIds.push({
        jobBoardId,
        name,
        customIds: [
          {
            keyId: uuid(),
            customId: '',
            careerPageIds: [],
            displayErrors: false,
          },
        ],
      });
    }

    yield put({
      type: IntegrationsActionTypes.INIT_INTEGRATIONS_SUCCESS,
      integrations: companyIntegrations.body.data,
      jsonSchema: companyIntegrations.body.jsonSchema,
      uiSchema: companyIntegrations.body.uiSchema,
      publicCareerPages: companyPublicCareerPages.body,
      jobBoardsCustomIds,
      initialJobBoardsCustomIds: JSON.parse(JSON.stringify(jobBoardsCustomIds)),
    });
  } catch (error) {
    yield put({
      type: IntegrationsActionTypes.INIT_FAIL,
      error,
    });
  }
}

export function* initLinkedinRsc(action) {
  try {
    const { shouldDisplayRscLinkedinIntegration, token } = action;

    let rscLinkedinIntegrationServiceOnError = false;
    const [
      rscLinkedinIntegrationChildApps,
      companyAllCareerPages,
      rscChildAppsWithDeleteEmail,
    ] = yield all([
      ...(shouldDisplayRscLinkedinIntegration
        ? [
            (function* getRscLinkedinIntegrationChildApps() {
              try {
                return yield call(
                  IntegrationsService.getRscLinkedinIntegrationChildApps,
                  token,
                );
              } catch (_) {
                rscLinkedinIntegrationServiceOnError = true;
                return null;
              }
            })(),
            (function* getCompanyAllCareerPages() {
              try {
                return yield call(IntegrationsService.getCompanyAllCareerPages);
              } catch (_) {
                rscLinkedinIntegrationServiceOnError = true;
                return null;
              }
            })(),
            (function* getRscChildAppsWithDeleteEmail() {
              try {
                return yield call(
                  IntegrationsService.getRscChildAppsWithDeleteEmail,
                  token,
                );
              } catch (_) {
                rscLinkedinIntegrationServiceOnError = true;
                return null;
              }
            })(),
          ]
        : []),
    ]);

    yield put({
      type: IntegrationsActionTypes.INIT_LINKEDIN_RSC_SUCCESS,
      ...(rscLinkedinIntegrationChildApps
        ? {
            rscLinkedinIntegrationChildApps:
              rscLinkedinIntegrationChildApps.body,
          }
        : {}),
      ...(companyAllCareerPages
        ? { rscLinkedinIntegrationCareerPages: companyAllCareerPages.body }
        : {}),
      rscLinkedinIntegrationServiceOnError,
      ...(rscChildAppsWithDeleteEmail
        ? { rscChildAppsWithDeleteEmail: rscChildAppsWithDeleteEmail.body }
        : {}),
    });
  } catch (error) {
    yield put({
      type: IntegrationsActionTypes.INIT_FAIL,
      error,
    });
  }
}

function* saveIntegrations(action) {
  const { integrations, messages, renderScreen, jobBoardsCustomIds } = action;

  try {
    yield Promise.all([
      IntegrationsService.saveCompanyIntegrations(integrations, renderScreen),
      ...(jobBoardsCustomIds
        ? [IntegrationsService.saveJobBoardsCustomIds(jobBoardsCustomIds)]
        : []),
    ]);
    yield put({
      type: IntegrationsActionTypes.SAVE_INTEGRATIONS_SUCCESS,
      renderScreen,
      toast: buildToast(messages.success, ToastTypes.success),
    });
  } catch (error) {
    yield put({
      type: IntegrationsActionTypes.SAVE_INTEGRATIONS_FAIL,
      toast: buildToast(messages.error, ToastTypes.error),
    });
  }
}

function* getRSCChildApps(action) {
  const { messages, token } = action;

  try {
    const rscLinkedinIntegrationChildApps =
      yield IntegrationsService.getRscLinkedinIntegrationChildApps(token);

    yield put({
      type: IntegrationsActionTypes.GET_RSC_LINKEDIN_INTEGRATION_CHILD_APPS_SUCCESS,
      rscLinkedinIntegrationChildApps:
        rscLinkedinIntegrationChildApps.body || [],
      ...(messages.success
        ? { toast: buildToast(messages.success, ToastTypes.success) }
        : {}),
    });
  } catch (error) {
    yield put({
      type: IntegrationsActionTypes.GET_RSC_LINKEDIN_INTEGRATION_CHILD_APPS_FAIL,
      toast: buildToast(messages.error, ToastTypes.error),
    });
  }
}

function* putRscErrorToast(action) {
  const { errorMessage } = action;

  yield put({
    toast: buildToast(errorMessage, ToastTypes.error),
  });
}

function* createRsclinkedinIntegrationChildApp(action) {
  const { messages, token } = action;

  try {
    const { body: childAppCreated } =
      yield IntegrationsService.createRsclinkedinIntegrationChildApp(token);

    const { body: rscLinkedinIntegrationChildApps } =
      yield IntegrationsService.getRscLinkedinIntegrationChildApps(token);

    yield put({
      type: IntegrationsActionTypes.CREATE_RSC_LINKEDIN_INTEGRATION_CHILD_APP_SUCCESS,
      childAppCreated,
      toast: buildToast(messages.rscChildAppCreated, ToastTypes.success),
    });

    yield put({
      type: IntegrationsActionTypes.GET_RSC_LINKEDIN_INTEGRATION_CHILD_APPS_SUCCESS,
      rscLinkedinIntegrationChildApps,
    });
  } catch (error) {
    yield put({
      type: IntegrationsActionTypes.CREATE_RSC_LINKEDIN_INTEGRATION_CHILD_APP_FAIL,
      toast: buildToast(
        error && error.message ? error.message : messages.error,
        ToastTypes.error,
      ),
    });
  }
}

function* handleFormValidationErrors(error, childApp, messages, actionType) {
  const isValidationError =
    error &&
    error.statusCode === 400 &&
    error.message === 'A child app with the passed name already exists';

  if (isValidationError) {
    const formValidationErrors = {
      name: messages.formValidationErrors
        .rscIntegrationChildAppsLinkedinPageDuplicateError,
    };

    yield put(
      setRscLinkedinIntegrationChildAppFormValidationError(
        childApp,
        formValidationErrors,
      ),
    );
  } else {
    yield put({
      type: actionType,
      toast: buildToast(messages.error, ToastTypes.error),
    });
  }
}

export function* bindRscLinkedinIntegrationChildAppCareerPages(action) {
  const { childApp, user, messages, token } = action;

  try {
    try {
      yield IntegrationsService.bindRscLinkedinIntegrationChildAppCareerPages(
        childApp,
        token,
        user.language,
      );
    } catch (e) {
      // 422 - child app já está na fila de sincronia
      if (e.statusCode !== 422) {
        throw e;
      }
    }

    const { body: rscLinkedinIntegrationChildApps } =
      yield IntegrationsService.getRscLinkedinIntegrationChildApps(token);

    yield put({
      type: IntegrationsActionTypes.GET_RSC_LINKEDIN_INTEGRATION_CHILD_APPS_SUCCESS,
      toast: buildToast(messages.success, ToastTypes.success, {
        autoClose: false,
      }),
      rscLinkedinIntegrationChildApps,
    });
  } catch (error) {
    yield* handleFormValidationErrors(
      error,
      childApp,
      messages,
      IntegrationsActionTypes.BIND_RSC_LINKEDIN_INTEGRATION_CHILD_APP_CAREER_PAGES_FAIL,
    );
  }
}

function* editRscLinkedinIntegrationChildApp(action) {
  const { childApp, messages, token } = action;

  try {
    const payload = { ...childApp, careerPagesIds: undefined };
    yield IntegrationsService.editRscLinkedinIntegrationChildApp(
      payload,
      token,
    );

    const { body: rscLinkedinIntegrationChildApps } =
      yield IntegrationsService.getRscLinkedinIntegrationChildApps(token);

    yield put({
      type: IntegrationsActionTypes.GET_RSC_LINKEDIN_INTEGRATION_CHILD_APPS_SUCCESS,
      toast: buildToast(messages.success, ToastTypes.success),
      rscLinkedinIntegrationChildApps,
    });
  } catch (error) {
    yield* handleFormValidationErrors(
      error,
      childApp,
      messages,
      IntegrationsActionTypes.EDIT_RSC_LINKEDIN_INTEGRATION_CHILD_APP_FAIL,
    );
  }
}

export function* restartSyncRscLinkedinIntegrationChildApp(action) {
  const { childApp, messages, token, user } = action;

  try {
    try {
      yield IntegrationsService.restartSyncRscLinkedinIntegrationChildApp(
        childApp,
        token,
        user.language,
      );
    } catch (e) {
      // 422 - child app já está na fila de sincronia
      if (e.statusCode !== 422) {
        throw e;
      }
    }

    const { body: rscLinkedinIntegrationChildApps } =
      yield IntegrationsService.getRscLinkedinIntegrationChildApps(token);

    yield put({
      type: IntegrationsActionTypes.GET_RSC_LINKEDIN_INTEGRATION_CHILD_APPS_SUCCESS,
      rscLinkedinIntegrationChildApps,
    });

    yield put({
      type: IntegrationsActionTypes.RESTART_SYNC_RSC_LINKEDIN_INTEGRATION_CHILD_APP_SUCCESS,
      childApp,
      toast: buildToast(messages.success, ToastTypes.success, {
        autoClose: false,
      }),
    });
  } catch (error) {
    yield put({
      type: IntegrationsActionTypes.RESTART_SYNC_RSC_LINKEDIN_INTEGRATION_CHILD_APP_FAIL,
      childApp,
      toast: buildToast(messages.error, ToastTypes.error),
    });
  }
}

export function* desyncRscLinkedinIntegrationChildApp(action) {
  const { childApp, token, user } = action;

  try {
    yield IntegrationsService.desyncRscLinkedinIntegrationChildApp(
      childApp,
      token,
      user.language,
    );

    const { body: rscLinkedinIntegrationChildApps } =
      yield IntegrationsService.getRscLinkedinIntegrationChildApps(token);

    yield put({
      type: IntegrationsActionTypes.GET_RSC_LINKEDIN_INTEGRATION_CHILD_APPS_SUCCESS,
      rscLinkedinIntegrationChildApps,
    });
  } catch (error) {
    yield put({
      type: IntegrationsActionTypes.DESYNC_RSC_LINKEDIN_INTEGRATION_CHILD_APP_FAIL,
    });
  }
}

function* IntegrationsSaga() {
  yield takeLatest(IntegrationsActionTypes.INIT, initIntegrations);
  yield takeLatest(IntegrationsActionTypes.INIT, initLinkedinRsc);
  yield takeLatest(
    IntegrationsActionTypes.INIT_RSC_LINKEDIN_INTEGRATION,
    initLinkedinRsc,
  );
  yield takeLatest(IntegrationsActionTypes.SAVE_INTEGRATIONS, saveIntegrations);
  yield takeLatest(
    IntegrationsActionTypes.CREATE_RSC_LINKEDIN_INTEGRATION_CHILD_APP,
    createRsclinkedinIntegrationChildApp,
  );
  yield takeLatest(
    IntegrationsActionTypes.SAVE_INTEGRATIONS_SUCCESS,
    initIntegrations,
  );
  yield takeLatest(
    IntegrationsActionTypes.GET_RSC_LINKEDIN_INTEGRATION_CHILD_APPS,
    getRSCChildApps,
  );
  yield takeLatest(
    IntegrationsActionTypes.BIND_RSC_LINKEDIN_INTEGRATION_CHILD_APP_CAREER_PAGES,
    bindRscLinkedinIntegrationChildAppCareerPages,
  );
  yield takeLatest(
    IntegrationsActionTypes.EDIT_RSC_LINKEDIN_INTEGRATION_CHILD_APP,
    editRscLinkedinIntegrationChildApp,
  );
  yield takeLatest(
    IntegrationsActionTypes.RESTART_SYNC_RSC_LINKEDIN_INTEGRATION_CHILD_APP,
    restartSyncRscLinkedinIntegrationChildApp,
  );
  yield takeLatest(
    IntegrationsActionTypes.DESYNC_RSC_LINKEDIN_INTEGRATION_CHILD_APP,
    desyncRscLinkedinIntegrationChildApp,
  );
  yield takeLatest(
    IntegrationsActionTypes.PUT_RSC_LINKEDIN_INTEGRATION_ERROR_TOAST,
    putRscErrorToast,
  );
}

export default IntegrationsSaga;
