import api from '../../api/DarthVaderClient';

const companyRolesApi = '/company-management/companies/roles';

export default {
  post(payload) {
    return api.post(companyRolesApi, payload);
  },

  put(id, payload) {
    return api.put(`${companyRolesApi}/${id}`, payload);
  },

  delete(id) {
    return api.delete(`${companyRolesApi}/${id}`);
  },

  getAll() {
    return api.get(`${companyRolesApi}?order=name&page=0&perPage=9999`);
  },

  getRoleSimilarities() {
    return api.get(`${companyRolesApi}/similarities`);
  },
};
