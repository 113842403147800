import { call, put, select, takeLatest } from 'redux-saga/effects';
import { IndicatorsHomeInsightsActionTypes as ActionTypes } from './ActionTypes';
import Service from './Service';

export const selectors = {
  filters: state =>
    state &&
    state.reducers &&
    state.reducers.Authentication &&
    state.reducers.Authentication.currentUser,
};

export function* getExpiredPublishedJobs() {
  try {
    const filters = yield select(selectors.filters);
    const response = yield call(Service.getExpiredPublishedJobs, filters);
    const formattedResponse = response.body.data.rows[0][1];

    yield put({
      type: ActionTypes.GET_EXPIRED_PUBLISHED_JOBS_SUCCESS,
      payload: formattedResponse,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_EXPIRED_PUBLISHED_JOBS_FAIL,
    });
  }
}

export function* getCandidatesWithoutFeedbackInClosedJobs() {
  try {
    const filters = yield select(selectors.filters);
    const response = yield call(
      Service.getCandidatesWithoutFeedbackInClosedJobs,
      filters,
    );
    const formattedResponse = response.body.data.rows.reduce(
      (accumulator, currentValue) => accumulator + currentValue[1],
      0,
    );

    yield put({
      type: ActionTypes.GET_CANDIDATES_WITHOUT_FEEDBACK_IN_CLOSED_JOBS_SUCCESS,
      payload: formattedResponse,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_CANDIDATES_WITHOUT_FEEDBACK_IN_CLOSED_JOBS_FAIL,
    });
  }
}

function* IndicatorsHomeInsightsSaga() {
  yield takeLatest(
    ActionTypes.GET_EXPIRED_PUBLISHED_JOBS,
    getExpiredPublishedJobs,
  );
  yield takeLatest(
    ActionTypes.GET_CANDIDATES_WITHOUT_FEEDBACK_IN_CLOSED_JOBS,
    getCandidatesWithoutFeedbackInClosedJobs,
  );
}

export default IndicatorsHomeInsightsSaga;
