import {
  CustomTestCard,
  InputNumberMask,
  SetupGridLayout,
} from './CustomTestComponents';
import {
  Questions,
  TestEdit,
  TestsCorrection,
  TestsCreate,
  TestsManagement,
} from './CustomTestContainers';
import CustomTestReducers from './CustomTestReducers';
import CustomTestSagas from './CustomTestSagas';

const CustomTestComponents = {
  InputNumberMask,
  SetupGridLayout,
  CustomTestCard,
};

const CustomTestContainers = {
  Questions,
  TestsCreate,
  TestEdit,
  TestsManagement,
  TestsCorrection,
};

export {
  CustomTestContainers,
  CustomTestComponents,
  CustomTestReducers,
  CustomTestSagas,
};
