import { JobStepOnlineMoveTypeEnum } from '@gupy/enums';
import { combineReducers } from 'redux';
import JobActionTypes from '../../../constants/Job/JobActionTypes';
import JobStepCustomActionTypes from '../../../constants/Job/JobStep/JobStepCustomActionTypes';
import JobStepTypes from '../../../constants/Job/JobStep/JobStepTypes';

const initialModelForm = {
  id: undefined,
  jobId: undefined,
  name: '',
  type: JobStepTypes.offline,
  objectivesDescription: '',
  hasEndDate: false,
  endDate: undefined,
  testId: undefined,
  testPlatform: undefined,
  testProviderId: undefined,
  interviewScriptId: undefined,
  interviewScriptIdV2: undefined,
  category: undefined,
  onlineMoveMinimumScore: 0,
  onlineMoveType: JobStepOnlineMoveTypeEnum.manual,
  onlineTestType: null,
};

const isSaving = (state = false, action) => {
  switch (action.type) {
    case JobStepCustomActionTypes.POST:
    case JobStepCustomActionTypes.PATCH:
      return true;
    case JobStepCustomActionTypes.POST_SUCCESS:
    case JobStepCustomActionTypes.POST_FAIL:
    case JobStepCustomActionTypes.PATCH_SUCCESS:
    case JobStepCustomActionTypes.PATCH_FAIL:
      return false;
    default:
      return state;
  }
};

export const isLoadingStep = (state = true, action) => {
  switch (action.type) {
    case JobStepCustomActionTypes.GET:
      return true;
    case JobStepCustomActionTypes.GET_SUCCESS:
    case JobStepCustomActionTypes.GET_FAIL:
    case JobStepCustomActionTypes.CLEAR_FORM:
    case JobActionTypes.GET_SUCCESS:
      return false;
    default:
      return state;
  }
};

export const modelForm = (state = initialModelForm, action) => {
  switch (action.type) {
    case JobStepCustomActionTypes.GET_SUCCESS: {
      return {
        ...initialModelForm,
        ...action.modelForm,
        hasEndDate: !!action.modelForm.endDate,
      };
    }
    case JobStepCustomActionTypes.POST_SUCCESS:
    case JobStepCustomActionTypes.CLEAR_FORM:
      return initialModelForm;
    case JobStepCustomActionTypes.CHANGE_FORM:
      return Object.assign({}, state, action.modelForm);
    default:
      return state;
  }
};

export const validation = (state = {}, action) => {
  switch (action.type) {
    case JobStepCustomActionTypes.POST:
    case JobStepCustomActionTypes.PATCH:
    case JobStepCustomActionTypes.CLEAR_FORM:
    case JobStepCustomActionTypes.GET:
    case JobStepCustomActionTypes.VALIDATE_JOB_STEP_CUSTOM:
    case JobStepCustomActionTypes.CHANGE_FORM:
      return {};
    case JobStepCustomActionTypes.POST_FAIL:
    case JobStepCustomActionTypes.PATCH_FAIL:
    case JobStepCustomActionTypes.VALIDATE_JOB_STEP_CUSTOM_FAIL:
      return action.validation;
    default:
      return state;
  }
};

const testProviders = (state = {}, action) => {
  switch (action.type) {
    case JobStepCustomActionTypes.GET_TEST_PROVIDERS:
      return {};
    case JobStepCustomActionTypes.GET_TEST_PROVIDERS_SUCCESS:
      return action.testProviders;
    case JobStepCustomActionTypes.GET_TESTS_FROM_PROVIDER:
    case JobStepCustomActionTypes.GET_TESTS_FROM_PROVIDER_SUCCESS:
    case JobStepCustomActionTypes.GET_TESTS_FROM_PROVIDER_FAIL:
      return { ...state, [action.testProvider.uuid]: action.testProvider };
    default:
      return state;
  }
};

export default combineReducers({
  modelForm,
  validation,
  isSaving,
  testProviders,
  isLoadingStep,
});
