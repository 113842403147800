import { combineReducers } from 'redux';
import { JobSummaryActionTypes } from './ActionTypes';

const initialJobSummary = {
  details: {
    name: '',
    role: '',
    department: '',
    type: '',
    handicapped: false,
    numVacancies: 0,
    description: '',
    responsibilities: '',
    prerequisites: '',
    relevantExperiences: '',
    status: '',
    approvedAt: '',
    disapprovalReason: '',
    approvalReason: '',
    salaryFrom: '',
    salaryTo: '',
    salaryCurrencyType: '',
    address: {
      city: '',
      state: '',
      country: '',
      line: '',
      complements: '',
      district: '',
      zipCode: '',
      remoteWorking: false,
      workplaceType: '',
    },
    approver: {
      name: '',
      observation: '',
    },
    metadata: undefined,
  },
  steps: [],
  classifiers: [],
  boards: [],
};
const initialApproveJobFormModel = {
  approvalReason: '',
  salaryFrom: '',
  salaryTo: '',
  salaryCurrencyType: undefined,
  isSalaryRange: false,
  jobVacanciesCodes: [],
  numVacancies: 0,
};
const initialDisapproveJobFormModel = {
  disapproveReason: '',
  salaryFrom: '',
  salaryTo: '',
  salaryCurrencyType: undefined,
  isSalaryRange: false,
};

export const job = (state = {}, action) => {
  switch (action.type) {
    case JobSummaryActionTypes.INIT_JOB_SUMMARY_SUCCESS:
      return action.job;
    default:
      return state;
  }
};

export const summaryIsLoading = (state = true, action) => {
  switch (action.type) {
    case JobSummaryActionTypes.INIT_JOB_SUMMARY_SUCCESS:
    case JobSummaryActionTypes.INIT_JOB_SUMMARY_FAIL:
      return false;
    case JobSummaryActionTypes.INIT_JOB_SUMMARY:
      return true;
    default:
      return state;
  }
};

export const summary = (state = initialJobSummary, action) => {
  switch (action.type) {
    case JobSummaryActionTypes.INIT_JOB_SUMMARY_SUCCESS: {
      const { jobTypes, classifiersList, steps } = action;
      const jobDetails = action.job;

      const jobType = jobTypes.data.filter(
        type => type.value === jobDetails.jobType,
      )[0];
      const approver = jobDetails.approvers
        ? jobDetails.approvers[0]
        : undefined;
      const classifiers = classifiersList.data.map(classifier => ({
        id: classifier.user.id,
        name: classifier.user.name,
        type: classifier.classifierType,
        creator: classifier.jobCreator,
      }));
      const jobSteps = steps.map(step => ({
        id: step.id,
        name: step.name,
        objectives: step.objectivesDescription,
        type: step.type,
      }));
      const jobBoards = jobDetails.jobBoards || [];
      const boards = jobBoards.map(board => ({
        id: board.id,
        name: board.name,
      }));
      const jobSummary = {
        details: {
          name: jobDetails.name,
          role: jobDetails.role ? jobDetails.role.name : '',
          department: jobDetails.department ? jobDetails.department.name : '',
          type: jobType.label,
          handicapped: jobDetails.handicapped,
          description: jobDetails.description,
          responsibilities: jobDetails.responsibilities,
          prerequisites: jobDetails.prerequisites,
          relevantExperiences: jobDetails.relevantExperiences,
          status: jobDetails.status,
          approvalReason: jobDetails.approvalReason,
          disapprovalReason: jobDetails.disapprovalReason,
          approvedAt: jobDetails.approvedAt,
          code: jobDetails.code,
          recruiter: jobDetails.recruiter,
          manager: jobDetails.manager,
          reason: jobDetails.reason,
          salaryFrom: jobDetails.salaryFrom,
          salaryTo: jobDetails.salaryTo,
          salaryCurrencyType: jobDetails.salaryCurrencyType,
          numVacancies: jobDetails.numVacancies,
          jobVacanciesCodes: jobDetails.jobVacanciesCodes,
          customFormData: jobDetails.customFormData,
          customFormSchema: jobDetails.customFormSchema,
          address: {
            city: jobDetails.addressCity,
            state: jobDetails.addressState,
            country: jobDetails.addressCountry,
            line: jobDetails.addressLine,
            complements: jobDetails.addressComplements,
            district: jobDetails.addressDistrict,
            zipCode: jobDetails.addressZipCode,
            remoteWorking: jobDetails.remoteWorking,
            workplaceType: jobDetails.workplaceType,
          },
          subsidiary: {
            name: jobDetails.companyBranchLabel
              ? jobDetails.companyBranchLabel
              : '',
            addressLine: jobDetails.companyBranchAddressLine
              ? jobDetails.companyBranchAddressLine
              : '',
          },
          approver: {
            name: approver ? approver.name : '',
            observation: jobDetails.observation,
          },
          // eslint-disable-next-line no-underscore-dangle
          metadata: jobDetails._metadata,
        },
        steps: jobSteps,
        classifiers,
        boards,
      };
      return Object.assign({}, initialJobSummary, jobSummary);
    }
    default:
      return state;
  }
};

export const isApproversChanging = (state = false, action) => {
  switch (action.type) {
    case JobSummaryActionTypes.REPLACE_APPROVER_SUCCESS:
    case JobSummaryActionTypes.REPLACE_APPROVER_FAIL:
    case JobSummaryActionTypes.REPLACE_APPROVER_BULK_SUCCESS:
    case JobSummaryActionTypes.REPLACE_APPROVER_BULK_FAIL:
    case JobSummaryActionTypes.GET_ALL_JOBS_BY_APPROVER_ID_SUCCESS:
    case JobSummaryActionTypes.GET_ALL_JOBS_BY_APPROVER_ID_FAIL:
    case JobSummaryActionTypes.ADD_APPROVER_SUCCESS:
    case JobSummaryActionTypes.ADD_APPROVER_FAIL:
    case JobSummaryActionTypes.REMOVE_APPROVER_SUCCESS:
    case JobSummaryActionTypes.REMOVE_APPROVER_FAIL:
    case JobSummaryActionTypes.REMOVE_APPROVER_BULK_SUCCESS:
    case JobSummaryActionTypes.REMOVE_APPROVER_BULK_FAIL:
      return false;
    case JobSummaryActionTypes.REMOVE_APPROVER:
    case JobSummaryActionTypes.REMOVE_APPROVER_BULK:
    case JobSummaryActionTypes.ADD_APPROVER:
    case JobSummaryActionTypes.REPLACE_APPROVER:
    case JobSummaryActionTypes.REPLACE_APPROVER_BULK:
    case JobSummaryActionTypes.GET_ALL_JOBS_BY_APPROVER_ID:
      return true;
    default:
      return state;
  }
};

export const isApproveJobModalOpen = (state = false, action) => {
  switch (action.type) {
    case JobSummaryActionTypes.OPEN_APPROVE_JOB_MODAL:
      return true;
    case JobSummaryActionTypes.CLOSE_APPROVE_JOB_MODAL:
    case JobSummaryActionTypes.PATCH_APPROVE_JOB_SUCCESS:
      return false;
    default:
      return state;
  }
};

export const approveJobFormModel = (
  state = initialApproveJobFormModel,
  action,
) => {
  switch (action.type) {
    case JobSummaryActionTypes.OPEN_APPROVE_JOB_MODAL:
      return {
        ...state,
        salaryFrom: action.payload.salaryFrom,
        salaryTo: action.payload.salaryTo,
        salaryCurrencyType:
          action.payload.salaryCurrencyType || state.salaryCurrencyType,
        isSalaryRange: !!action.payload.salaryTo,
        jobVacanciesCodes: action.payload.jobVacanciesCodes,
        numVacancies: action.payload.numVacancies,
        // eslint-disable-next-line dot-notation
        metadata: action.payload['_metadata'],
      };
    case JobSummaryActionTypes.CHANGE_APPROVE_JOB_FORM_MODEL:
      return {
        ...state,
        ...action.model,
      };
    case JobSummaryActionTypes.PATCH_APPROVE_JOB_SUCCESS:
      return initialApproveJobFormModel;
    default:
      return state;
  }
};

export const isSaving = (state = false, action) => {
  switch (action.type) {
    case JobSummaryActionTypes.PATCH_APPROVE_JOB:
    case JobSummaryActionTypes.PATCH_DISAPPROVE_JOB:
      return true;
    case JobSummaryActionTypes.PATCH_DISAPPROVE_JOB_SUCCESS:
    case JobSummaryActionTypes.PATCH_DISAPPROVE_JOB_FAIL:
    case JobSummaryActionTypes.PATCH_APPROVE_JOB_SUCCESS:
    case JobSummaryActionTypes.PATCH_APPROVE_JOB_FAIL:
      return false;
    default:
      return state;
  }
};

export const isDisapproveJobModalOpen = (state = false, action) => {
  switch (action.type) {
    case JobSummaryActionTypes.OPEN_DISAPPROVE_JOB_MODAL:
      return true;
    case JobSummaryActionTypes.CLOSE_DISAPPROVE_JOB_MODAL:
    case JobSummaryActionTypes.PATCH_DISAPPROVE_JOB_SUCCESS:
      return false;
    default:
      return state;
  }
};

export const disapproveJobFormModel = (
  state = initialDisapproveJobFormModel,
  action,
) => {
  switch (action.type) {
    case JobSummaryActionTypes.OPEN_DISAPPROVE_JOB_MODAL:
      return {
        ...state,
        salaryFrom: action.job.salaryFrom,
        salaryTo: action.job.salaryTo,
        salaryCurrencyType:
          action.job.salaryCurrencyType || state.salaryCurrencyType,
        isSalaryRange: !!action.job.salaryTo,
        // eslint-disable-next-line dot-notation
        metadata: action.job['_metadata'],
      };
    case JobSummaryActionTypes.CHANGE_DISAPPROVE_JOB_FORM_MODEL:
      return {
        ...state,
        ...action.model,
      };
    case JobSummaryActionTypes.PATCH_DISAPPROVE_JOB_SUCCESS:
      return initialDisapproveJobFormModel;
    default:
      return state;
  }
};

export const disapproveJobFormValidation = (state = {}, action) => {
  switch (action.type) {
    case JobSummaryActionTypes.PATCH_DISAPPROVE_JOB:
      return {};
    case JobSummaryActionTypes.PATCH_DISAPPROVE_JOB_FAIL:
      return action.validation;
    default:
      return state;
  }
};

export const approveJobFormValidation = (state = {}, action) => {
  switch (action.type) {
    case JobSummaryActionTypes.PATCH_APPROVE_JOB:
      return {};
    case JobSummaryActionTypes.PATCH_APPROVE_JOB_FAIL:
      return action.validation;
    default:
      return state;
  }
};

export const allJobsByApproverId = (state = [], action) => {
  switch (action.type) {
    case JobSummaryActionTypes.GET_ALL_JOBS_BY_APPROVER_ID_SUCCESS:
      return action.jobs;
    default:
      return state;
  }
};

export default combineReducers({
  job,
  allJobsByApproverId,
  summaryIsLoading,
  summary,
  isApproversChanging,
  isApproveJobModalOpen,
  approveJobFormModel,
  approveJobFormValidation,
  isSaving,
  isDisapproveJobModalOpen,
  disapproveJobFormModel,
  disapproveJobFormValidation,
});
