import {
  DsColorGraySpaceGray,
  DsFontSizeLg,
  DsFontSizeSm,
  DsFontWeightSemibold,
  Paragraph as dsParagraph,
  DsScreenMd,
  DsScreenXs,
  DsSpacingMd,
  DsSpacingXl,
  Tip as dsTip,
} from '@gupy/design-system-v2';
import styled from 'styled-components';

export const Tip = styled(dsTip)`
  margin-top: ${({ noMargin }) => (noMargin ? 0 : DsSpacingXl)};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${DsSpacingMd};

  @media screen and (max-width: ${DsScreenXs}) {
    flex-direction: column;
  }
`;

export const Title = styled.h3`
  font-family: Inter;
  text-align: left;
  font-size: ${DsFontSizeLg};
  font-weight: ${DsFontWeightSemibold};
  line-height: 24px;
  text-align: left;

  color: ${DsColorGraySpaceGray};

  margin: 0 0 ${DsSpacingMd};
`;

export const Paragraph = styled(dsParagraph)`
  text-align: left;
`;

export const Logo = styled.img`
  width: 98px;
  height: 40px;

  @media screen and (max-width: ${DsScreenMd}) {
    width: 71.5px;
    height: 30px;
  }
`;

export const SubDescription = styled(dsParagraph)`
  margin-top: ${DsSpacingMd};
  color: ${DsColorGraySpaceGray};
  font-size: ${DsFontSizeSm};
  text-align: left;
`;
