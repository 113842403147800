import { Icon, IconButton, IconEnum, InfotipTypes } from '@gupy/design-system';
import { JobStatusEnum } from '@gupy/enums';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { openConfirmDialog } from '../../../actions/App/AppActions';
import { deleteJob, patchJobStatus } from '../../../actions/Job/JobAction';
import JobPublicationType from '../../../constants/Job/JobPublicationType/JobPublicationType';
import PermissionsEnum from '../../../containers/Authentication/PermissionsEnum';
import UrlHelper from '../../../helpers/UrlHelper';
import history from '../../../history';
import JobPermissionsManager from '../../../managers/JobPermissionsManager';
import {
  closeAnalyticsCalloutModal,
  openAnalyticsCalloutModal,
} from '../../Analytics/Actions';
import AnalyticsCalloutModal from '../../Analytics/components/AnalyticsCalloutModal';
import { JobClosureModal } from '../JobClosure/';
import {
  closeJob,
  closeJobClosureModal,
  openJobClosureModal,
} from '../JobClosure/JobClosureAction';
import './JobActions.scss';
import { getJobActionsMessages } from './JobActions.messages';

const propTypes = {
  jobModel: PropTypes.object,
  showShareButton: PropTypes.bool,
  closeJob: PropTypes.func.isRequired,
  deleteJob: PropTypes.func.isRequired,
  patchJobStatus: PropTypes.func.isRequired,
  openConfirmDialog: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  openJobClosureModal: PropTypes.func.isRequired,
  closeJobClosureModal: PropTypes.func.isRequired,
  JobClosure: PropTypes.object.isRequired,
  openAnalyticsCalloutModal: PropTypes.func.isRequired,
  closeAnalyticsCalloutModal: PropTypes.func.isRequired,
  isAnalyticsCalloutModalOpen: PropTypes.bool,
  permissions: PropTypes.object.isRequired,
};

const defaultProps = {
  jobModel: {},
  showShareButton: true,
};

class JobActions extends Component {
  constructor(props) {
    super(props);
    this.messages = getJobActionsMessages(props.intl);
  }

  componentDidUpdate() {
    const { closeJobSuccess } = this.props.JobClosure;
    if (closeJobSuccess) {
      this.props.closeJobClosureModal();

      if (this.canViewAnalytics()) {
        const job = this.props.jobModel;
        this.props.openAnalyticsCalloutModal(job);
      } else {
        history.push('/companies/jobs');
      }
    }
  }

  handleDeleteClick = () => {
    const { jobModel } = this.props;
    this.props.openConfirmDialog({
      title: this.messages.deleteTitle,
      message: this.messages.deleteMessage,
      confirmButtonText: this.messages.deleteConfirmButtonText,
      cancelButtonText: this.messages.deleteCancelButtonText,
      onConfirmClick: () => {
        this.props.deleteJob(jobModel.id, undefined, '/companies/jobs', {
          success: this.messages.deleteSuccess,
          error: this.messages.deleteError,
        });
      },
    });
  };

  handleFinishJob = isFeedbackSent => {
    const { jobModel } = this.props;

    this.props.closeJob(
      jobModel.id,
      isFeedbackSent,
      this.canViewAnalytics() ? null : this.messages.finishSuccess,
    );
  };

  handleCloseAnalyticsModal = () => {
    this.props.closeAnalyticsCalloutModal();
    history.push('/companies/jobs');
  };

  handleCloseClick = () => {
    const { jobModel } = this.props;
    this.props.openJobClosureModal(jobModel.id);
  };

  handleFreezeClick = () => {
    const { jobModel } = this.props;
    this.props.openConfirmDialog({
      title: this.messages.freezeTitle,
      message: this.messages.freezeMessage,
      confirmButtonText: this.messages.freezeConfirmButtonText,
      cancelButtonText: this.messages.freezeCancelButtonText,
      onConfirmClick: () => {
        const payload = { id: jobModel.id, status: JobStatusEnum.frozen };
        this.props.patchJobStatus(
          payload,
          undefined,
          `/companies/jobs/edit/${jobModel.id}/overview`,
          {
            success: this.messages.freezeSuccess,
            error: this.messages.freezeError,
          },
        );
      },
    });
  };

  canViewAnalytics = () =>
    this.props.permissions[PermissionsEnum.view_analytics];

  render() {
    const { jobModel, showShareButton, JobClosure } = this.props;
    const { permissions } = jobModel;
    const { isModalOpen } = JobClosure;
    const currentPath = window.location.href;

    const afterPublicationStatuses = [
      JobStatusEnum.published,
      JobStatusEnum.frozen,
      JobStatusEnum.canceled,
      JobStatusEnum.closed,
    ];

    const neverBeenPublished = !afterPublicationStatuses.includes(
      jobModel.status,
    );

    const isJobClosed = jobModel.status === JobStatusEnum.canceled;
    const isJobCanceled = jobModel.status === JobStatusEnum.closed;
    const statusInvalidForPublication = isJobClosed || isJobCanceled;

    const isSummaryCurrentPath = currentPath.search('summary') > -1;

    const showViewActionButton =
      jobModel.status === JobStatusEnum.published &&
      jobModel.careerPage &&
      jobModel.publicationType !== JobPublicationType.internal;

    if (jobModel.id === undefined || !permissions) {
      return <div />;
    }

    return (
      <div id="job-actions" className="grid__actions job-actions--mobile">
        {isSummaryCurrentPath && (
          <Link className="grid__actions-back" to={'/companies/jobs'}>
            <Icon icon={IconEnum.ChevronLeft} />
            <span className="mobile-hidden">
              {this.messages.backManageJobs}
            </span>
          </Link>
        )}
        <div>
          {JobPermissionsManager.canEdit(permissions) &&
          isSummaryCurrentPath ? (
            <span data-tip data-for="action-edit" className="user__actions">
              <IconButton
                icon={IconEnum.Edit}
                id="job-actions-icon-edit"
                infotip={{
                  alignment: InfotipTypes.BOTTOM_CENTER,
                  id: 'job-actions-action-edit',
                  message: this.messages.editJob,
                }}
                onClick={() => {
                  history.push(`/companies/jobs/edit/${jobModel.id}/overview`);
                }}
                size="sm"
                variant="outlined"
              />
            </span>
          ) : null}
          {showShareButton && JobPermissionsManager.canShare(permissions) ? (
            <span data-tip data-for="action-share" className="user__actions">
              <IconButton
                icon={IconEnum.Share}
                id="job-actions-icon-share"
                infotip={{
                  alignment: InfotipTypes.BOTTOM_CENTER,
                  id: 'job-actions-action-share',
                  message: this.messages.shareJob,
                }}
                onClick={() => {
                  history.push(`/companies/jobs/edit/${jobModel.id}/share`);
                }}
                size="sm"
                variant="outlined"
              />
            </span>
          ) : null}
          {JobPermissionsManager.canFreeze(permissions) ? (
            <span data-tip data-for="action-freeze" className="user__actions">
              <IconButton
                icon={IconEnum.AccessTime}
                id="job-actions-icon-freeze"
                infotip={{
                  alignment: InfotipTypes.BOTTOM_CENTER,
                  id: 'job-actions-action-freeze',
                  message: this.messages.freeze,
                }}
                onClick={this.handleFreezeClick}
                size="sm"
                variant="outlined"
              />
            </span>
          ) : null}
          {JobPermissionsManager.canClose(permissions) ? (
            <span data-tip data-for="action-close" className="user__actions">
              <IconButton
                icon={IconEnum.Https}
                id="job-actions-icon-close"
                infotip={{
                  alignment: InfotipTypes.BOTTOM_CENTER,
                  id: 'job-actions-action-close',
                  message: this.messages.close,
                }}
                onClick={this.handleCloseClick}
                size="sm"
                variant="outlined"
              />
            </span>
          ) : null}
          {JobPermissionsManager.canApprove(permissions) &&
          !isSummaryCurrentPath ? (
            <span data-tip data-for="action-approve" className="user__actions">
              <IconButton
                id="job-actions-icon-approve"
                infotip={{
                  alignment: InfotipTypes.BOTTOM_CENTER,
                  id: 'job-actions-action-approve',
                  message: this.messages.approve,
                }}
                onClick={() => {
                  history.push(
                    `/companies/jobs/${jobModel.id}/candidates/summary`,
                  );
                }}
                size="sm"
                variant="outlined"
              />
            </span>
          ) : null}
          {JobPermissionsManager.canPublish(permissions) &&
          !statusInvalidForPublication ? (
            <span
              id="action-publish"
              data-tip
              data-for="action-publish"
              className="user__actions"
            >
              <IconButton
                icon={IconEnum.Language}
                id="job-actions-icon-publish"
                infotip={{
                  alignment: InfotipTypes.BOTTOM_CENTER,
                  id: 'job-actions-action-publish',
                  message: this.messages.publish,
                }}
                onClick={() => {
                  history.push(
                    `/companies/jobs/edit/${jobModel.id}/publication`,
                  );
                }}
                size="sm"
                variant="outlined"
              />
            </span>
          ) : null}
          {showViewActionButton ? (
            <span
              id="action-view"
              data-tip
              data-for="action-view"
              className="user__actions"
            >
              <IconButton
                icon={IconEnum.Eye}
                id="job-actions-icon-view"
                infotip={{
                  alignment: InfotipTypes.BOTTOM_CENTER,
                  id: 'job-actions-action-view',
                  message: this.messages.viewJob,
                }}
                onClick={() => {
                  window.open(
                    UrlHelper.buildCareerPageSubdomainUrl({
                      careerPageSubdomain: jobModel.careerPage.subdomain,
                      jobId: jobModel.id,
                      location: window.location,
                    }),
                    '_blank',
                  );
                }}
                size="sm"
                variant="outlined"
              />
            </span>
          ) : null}
          {neverBeenPublished &&
          JobPermissionsManager.canDelete(permissions) ? (
            <span
              id="action-delete"
              data-tip
              data-for="action-delete"
              className="user__actions"
            >
              <IconButton
                icon={IconEnum.Delete}
                id="job-actions-icon-delete"
                infotip={{
                  alignment: InfotipTypes.BOTTOM_CENTER,
                  id: 'job-actions-action-delete',
                  message: this.messages.deleteJob,
                }}
                onClick={this.handleDeleteClick}
                size="sm"
                variant="outlined"
              />
            </span>
          ) : null}
          <JobClosureModal
            isOpen={isModalOpen}
            onCancelButtonClick={this.props.closeJobClosureModal}
            job={jobModel}
            onConfirmFinishJob={this.handleFinishJob}
            hasErrorFinishingJob={this.props.JobClosure.hasErrorClosingJob}
          />
          <AnalyticsCalloutModal
            isOpen={this.props.isAnalyticsCalloutModalOpen}
            onClose={this.handleCloseAnalyticsModal}
          />
        </div>
      </div>
    );
  }
}

JobActions.propTypes = propTypes;
JobActions.defaultProps = defaultProps;

const mapStateToProps = state => ({
  Job: state.reducers.Job,
  JobClosure: state.reducers.JobClosureReducer,
  featureFlags:
    (state.reducers.Authentication.currentUser &&
      state.reducers.Authentication.currentUser.featureFlags) ||
    [],
  permissions:
    (state.reducers.Authentication.currentUser &&
      state.reducers.Authentication.currentUser.permissions) ||
    {},
  isAnalyticsCalloutModalOpen:
    state.reducers.Analytics &&
    state.reducers.Analytics.isAnalyticsCalloutModalOpen,
});

export default withRouter(
  injectIntl(
    connect(mapStateToProps, {
      closeJob,
      deleteJob,
      patchJobStatus,
      openConfirmDialog,
      openJobClosureModal,
      closeJobClosureModal,
      openAnalyticsCalloutModal,
      closeAnalyticsCalloutModal,
    })(JobActions),
  ),
);
