export const SAVE_CUSTOM_FORM_SCHEMA =
  'JobAdditionalQuestions/SAVE_CUSTOM_FORM_SCHEMA_REQUEST';
export const SAVE_CUSTOM_FORM_SCHEMA_SUCCESS =
  'JobAdditionalQuestions/SAVE_CUSTOM_FORM_SCHEMA_SUCCESS';
export const SAVE_CUSTOM_FORM_SCHEMA_FAILURE =
  'JobAdditionalQuestions/SAVE_CUSTOM_FORM_SCHEMA_FAILURE';

export const SAVE_CUSTOM_FORM_LIST_SCHEMA =
  'JobAdditionalQuestions/SAVE_CUSTOM_FORM_LIST_SCHEMA_REQUEST';
export const SAVE_CUSTOM_FORM_LIST_SCHEMA_SUCCESS =
  'JobAdditionalQuestions/SAVE_CUSTOM_FORM_LIST_SCHEMA_SUCCESS';
export const SAVE_CUSTOM_FORM_LIST_SCHEMA_FAILURE =
  'JobAdditionalQuestions/SAVE_CUSTOM_FORM_LIST_SCHEMA_FAILURE';

export const GET_CUSTOM_FORM_LIST_SCHEMA =
  'JobAdditionalQuestions/GET_CUSTOM_FORM_LIST_SCHEMA_REQUEST';
export const GET_CUSTOM_FORM_LIST_SCHEMA_SUCCESS =
  'JobAdditionalQuestions/GET_CUSTOM_FORM_LIST_SCHEMA_SUCCESS';
export const GET_CUSTOM_FORM_LIST_SCHEMA_FAILURE =
  'JobAdditionalQuestions/GET_CUSTOM_FORM_LIST_SCHEMA_FAILURE';
