import { adaptGeolocationFromHere } from '@gupy/front-commons';
import SubsidiaryActionTypes from '../../constants/Subsidiary/SubsidiaryActionTypes';

export const changeForm = modelForm => ({
  type: SubsidiaryActionTypes.CHANGE_FORM,
  modelForm,
});

export const clearForm = () => ({
  type: SubsidiaryActionTypes.CLEAR_FORM,
});

export const postSubsidiary = (payload, messages) => ({
  type: SubsidiaryActionTypes.POST,
  payload,
  messages,
});

export const putSubsidiary = (payload, messages) => ({
  type: SubsidiaryActionTypes.PUT,
  payload,
  messages,
});

export const deleteSubsidiary = (id, messages) => ({
  type: SubsidiaryActionTypes.DELETE,
  id,
  messages,
});

export const editSubsidiary = modelForm => ({
  type: SubsidiaryActionTypes.EDIT,
  modelForm,
});

export const getAllSubsidiaries = () => ({
  type: SubsidiaryActionTypes.GET_ALL,
});

export const initSubsidiary = () => ({
  type: SubsidiaryActionTypes.INIT,
});

export const changeAddressSuggestions = input => ({
  type: SubsidiaryActionTypes.CHANGE_FORM_ADDRESS_SUGGESTIONS,
  input,
});

export const getAddressSuggestionsSuccess = suggestions => {
  const hasSuggestions =
    suggestions && suggestions.body && suggestions.body.items;

  return {
    type: SubsidiaryActionTypes.CHANGE_FORM_ADDRESS_SUGGESTIONS_SUCCESS,
    suggestions: hasSuggestions
      ? suggestions.body.items.map(item => adaptGeolocationFromHere(item))
      : [],
  };
};

export const selectAddressSuggestion = suggestion => ({
  type: SubsidiaryActionTypes.SELECT_ADDRESS_SUGGESTION,
  suggestion,
});
