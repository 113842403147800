import { Tip } from '@gupy/design-system';
import { Checkbox } from '@gupy/design-system-v2';
import CardCollapse from '@gupy/design-system/CardCollapse/CardCollapse';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { StepEnum } from '../JobDeletionAfterPublicationModal';

const propTypes = {
  messages: PropTypes.object.isRequired,
  setStep: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  confirmDeletion: PropTypes.bool.isRequired,
  setConfirmDeletion: PropTypes.func.isRequired,
};

const ConfirmStep = ({
  messages,
  setStep,
  expanded,
  setConfirmDeletion,
  confirmDeletion,
}) => {
  const handleChange = () => {
    setStep(StepEnum.confirm);
  };
  const handleCurrentColor = () => {
    const gupyColorSteel = '#8C8C8C';
    const colorSuccess = '#13ce8b';
    if (confirmDeletion) {
      return colorSuccess;
    }
    return gupyColorSteel;
  };

  return (
    <div className="job-deletion-confirm">
      <CardCollapse
        id="job-deletion-confirm-expansion-panel"
        title={messages.confirmStepTitle}
        description=""
        icon="CheckCircle"
        iconColor={handleCurrentColor()}
        expanded={expanded}
        onCollapse={handleChange}
      >
        <div className="job-deletion-confirm__container">
          <Tip
            type="warning"
            className="job-deletion-confirm__tip"
            text={
              <div>
                <p className="job-deletion-confirm__tip-title">
                  <FormattedHTMLMessage
                    id="job-deletion-confirm-tip"
                    defaultMessage="<strong>Atenção,</strong> ao excluir a vaga:"
                  />
                </p>
                <ul className="job-deletion-confirm__tip-list">
                  <li className="job-deletion-confirm__tip-list-item">
                    {messages.confirmAttentionDeletion}
                  </li>
                  <li>{messages.confirmAttentionHistory}</li>
                  <li>{messages.confirmAttentionFind}</li>
                  <li>{messages.confirmAttentionTalent}</li>
                </ul>
              </div>
            }
            showIcon
            ariaLive="polite"
          />
          <Checkbox
            id="checkbox-example"
            name="checkbox-name"
            data-testid="job-deletion-confirm-checkbox"
            checked={confirmDeletion}
            onCheckboxChange={({ checked }) => setConfirmDeletion(checked)}
          >
            <FormattedHTMLMessage
              id="job-deletion-confirm-checkbox"
              defaultMessage="Estou ciente de que a <strong>exclusão é irreversível</strong> e de todas as consequências descritas acima."
            />
          </Checkbox>
        </div>
      </CardCollapse>
    </div>
  );
};

ConfirmStep.propTypes = propTypes;

export default ConfirmStep;
