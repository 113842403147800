import { Button, InputSearch } from '@gupy/design-system';
import PropTypes from 'prop-types';
import React from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';

const propTypes = {
  onAddNewTemplateClick: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  canCreate: PropTypes.bool,
  searchValue: PropTypes.string.isRequired,
};

const defaultProps = {
  canCreate: false,
};

const EmailTemplateBar = ({
  onSearchChange,
  onAddNewTemplateClick,
  intl,
  canCreate,
  searchValue,
}) => {
  const messages = defineMessages({
    searchEmailTemplate: {
      id: 'search_email_template',
      defaultMessage: 'Buscar template de e-mail',
    },
  });
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-6">
        <div className="card-list__search email-template-bar__input-container">
          <InputSearch
            onChange={onSearchChange}
            id="email-template-search"
            name="emailTemplateSearch"
            placeholder={intl.formatMessage(messages.searchEmailTemplate)}
            value={searchValue}
            inputLabel=""
            buttonLabel=""
            formLabel=""
            label=""
            block
          />
        </div>
      </div>
      {canCreate ? (
        <div className="col-xs-12 col-sm-6">
          <Button onClick={onAddNewTemplateClick}>
            <FormattedMessage
              id="create_new_email_template"
              defaultMessage="Novo template de e-mail"
            />
          </Button>
        </div>
      ) : null}
    </div>
  );
};

EmailTemplateBar.propTypes = propTypes;
EmailTemplateBar.defaultProps = defaultProps;

export default injectIntl(EmailTemplateBar);
