import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  optionalField: {
    id: 'optional_field',
    defaultMessage: 'Opcional',
  },
  requiredField: {
    id: 'required_field',
    defaultMessage: 'Obrigatório',
  },
  requiredJsonSchemaField: {
    id: 'required_json_schema_field',
    defaultMessage: 'Este campo é obrigatório',
  },
  fieldTableHeader: {
    id: 'field_table_header',
    defaultMessage: 'Campo',
  },
  fieldTypeTableHeader: {
    id: 'field_type_table_header',
    defaultMessage: 'Tipo do campo',
  },
  fieldRequiredTableHeader: {
    id: 'field_required_table_header',
    defaultMessage: 'Obrigatoriedade',
  },
  createNewFieldButton: {
    id: 'create_new_field_button',
    defaultMessage: 'Criar novo campo',
  },
  sectionTitle: {
    id: 'setup_job_custom_form_template',
    defaultMessage:
      'Customize campos para criação de vaga. Esses campos serão exibidos em "Dados Internos"',
  },
  noFieldDataFound: {
    id: 'no_field_data_found',
    defaultMessage:
      'Ainda não foram criados campos customizados para criação de vagas.',
  },
  date: {
    id: 'date',
    defaultMessage: 'Data',
  },
  shortText: {
    id: 'short_text',
    defaultMessage: 'Texto curto',
  },
  yesNo: {
    id: 'yes_no',
    defaultMessage: 'Sim/Não',
  },
  dropDownList: {
    id: 'drop_down_list',
    defaultMessage: 'Lista suspensa',
  },
  conditionalField: {
    id: 'conditional_field',
    defaultMessage: 'Campo condicional',
  },
  conditionalFields: {
    id: 'conditional_fields',
    defaultMessage: 'Campos condicionais',
  },
  conditional: {
    id: 'conditional',
    defaultMessage: 'Campo condicional',
  },
  multiSelect: {
    id: 'multi-select',
    defaultMessage: 'Lista de opções (multiseleção)',
  },
  attachment: {
    id: 'attachment',
    defaultMessage: 'Anexar arquivo',
  },
  number: {
    id: 'number',
    defaultMessage: 'Numérico',
  },
  time: {
    id: 'time',
    defaultMessage: 'Horário',
  },
  confirmDeleteTitle: {
    id: 'confirm_delete_title',
    defaultMessage: 'Excluir campo',
  },
  confirmDeleteMessage: {
    id: 'confirm_delete_message',
    defaultMessage:
      'Você confirma a exclusão do campo? Esse campo ainda estará visível em vagas criadas, mas não será mais exibido em novas vagas.',
  },
  confirmDeleteConfirmButton: {
    id: 'confirm_delete_confirm_button',
    defaultMessage: 'Excluir',
  },
  confirmDeleteCancelButton: {
    id: 'confirm_delete_cancel_button',
    defaultMessage: 'Cancelar',
  },
  jobCustomFormTemplateModalTitle: {
    id: 'create_new_field',
    defaultMessage: 'Criar novo campo',
  },
  jobCustomFormTemplateEditModalTitle: {
    id: 'edit_modal',
    defaultMessage: 'Editar campo',
  },
  jobCustomFormTemplateFieldName: {
    id: 'field_name',
    defaultMessage: 'Nome do campo',
  },
  jobCustomFormTemplateSelectFieldType: {
    id: 'select_field_type',
    defaultMessage: 'Selecione o tipo do campo',
  },
  jobCustomFormTemplateCancelButton: {
    id: 'cancel',
    defaultMessage: 'Cancelar',
  },
  jobCustomFormTemplateIncludeFieldButton: {
    id: 'include-field',
    defaultMessage: 'Incluir campo',
  },
  jobCustomFormTemplateFinishEditionButton: {
    id: 'finish-edition',
    defaultMessage: 'Finalizar edição',
  },
  jobCustomFormTemplateFieldSelectItems: {
    id: 'selection-options',
    defaultMessage: 'Opções de seleção',
  },
  jobCustomFormTemplateFieldSelectItemsInfo: {
    id: 'field_select_items_info',
    defaultMessage:
      'Se a lista copiada (CTRL+C) estiver em um arquivo de texto, certifique-se de ter apenas uma opção por linha, cole (CTRL+V) e pressione enter para separá-las.',
  },
  jobCustomFormTemplateFieldSelectItemsClearButton: {
    id: 'field_select_items_clear_button',
    defaultMessage: 'Excluir todos os itens',
  },
  jobCustomFormTemplateFieldSelectItemsPlaceholder: {
    id: 'field_select_items_placeholder',
    defaultMessage: 'Opção 1 Opção 2 Opção 3',
  },
  jobCustomFormTemplateFieldSelectOptionsPlaceholder: {
    id: 'field_select_options_placeholder',
    defaultMessage: 'Digite o nome da opção',
  },
  jobCustomFormTemplateAddNewOptionButton: {
    id: 'button_add_new_option_name',
    defaultMessage: 'Adicionar opção',
  },
  jobCustomFormTemplateConditionalFieldRequiredTip: {
    id: 'add-conditional-field-required-tip',
    defaultMessage:
      'Sinalizar o campo como obrigatório, fará com que todos os campos condicionais configurados também sejam obrigatórios.',
  },
  jobCustomFormTemplateConditionalFieldAdd: {
    id: 'add-conditional-field-button',
    defaultMessage: 'Adicionar campo condicional',
  },
  jobCustomFormTemplateConditionalFieldAddTipTitle: {
    id: 'add-conditional-field-tip-title',
    defaultMessage: 'Adicione campos condicionais para cada opção criada acima',
  },
  jobCustomFormTemplateConditionalFieldAddTipSubtitle: {
    id: 'add-conditional-field-tip-subtitle',
    defaultMessage:
      'Adicione um campo filho que será exibido de acordo com a resposta escolhida da lista de opções do campo customizado lista suspensa.',
  },
  edit: {
    id: 'edit',
    defaultMessage: 'Editar',
  },
  exclude: {
    id: 'exclude',
    defaultMessage: 'Excluir',
  },
  filterLabel: {
    id: 'select-list-filter',
    defaultMessage: 'Filtrar',
  },
  noResultsLabel: {
    id: 'select-list-no-results',
    defaultMessage: 'Nenhum item encontrado',
  },
  saveUpdates: {
    id: 'save-updates',
    defaultMessage: 'Salvar Alterações',
  },
  moveUp: {
    id: 'job-custom-form-template-move-up',
    defaultMessage: 'Mover para cima',
  },
  moveDown: {
    id: 'job-custom-form-template-move-down',
    defaultMessage: 'Mover para baixo',
  },
  moveToTop: {
    id: 'job-custom-form-template-move-to-top',
    defaultMessage: 'Mover para o topo',
  },
  moveToEnd: {
    id: 'job-custom-form-template-move-to-end',
    defaultMessage: 'Mover para o final',
  },
  leavingWithoutSavingInMemoryUpdates: {
    id: 'leaving-without-saving-in-memory-updates',
    defaultMessage:
      'Você está saindo sem salvar as alterações realizadas e elas serão perdidas. Deseja sair mesmo assim?',
  },
  addConditionalField: {
    id: 'add-conditional-field',
    defaultMessage: 'Adicione o campo condicional',
  },
  ifTheAnswer: {
    id: 'if-the-answer',
    defaultMessage: 'Se a resposta for:',
  },
  selectAnOption: {
    id: 'select-an-option',
    defaultMessage: 'Selecione uma opção',
  },
  fieldNameToShow: {
    id: 'field-name-to-show',
    defaultMessage: 'Nome do campo será exibido',
  },
  addField: {
    id: 'add-field',
    defaultMessage: 'Adicionar campo',
  },
  enterFieldName: {
    id: 'enter-field-name',
    defaultMessage: 'Digite o nome do campo',
  },
  leavingWithoutSaving: {
    id: 'leaving-without-saving',
    defaultMessage: 'Você está saindo sem salvar as alterações realizadas...',
  },
  saveQuestionBeforeLeaving: {
    id: 'save-question-before-leaving',
    defaultMessage:
      'Deseja salvar as alterações antes de sair da edição de campos customizados?',
  },
  saveChanges: {
    id: 'save-changes',
    defaultMessage: 'Sim, salvar alterações',
  },
  cancelAndStay: {
    id: 'cancel-and-stay',
    defaultMessage: 'Cancelar, permanecer nessa página',
  },
  editConditionalField: {
    id: 'edit-conditional-field',
    defaultMessage: 'Edite o campo condicional',
  },
  saveConditionalUpdates: {
    id: 'save-conditional-updates',
    defaultMessage: 'Salvar alterações',
  },
  cancelEditConditionalFieldConfirm: {
    id: 'cancel-edit-conditional-field-confirm',
    defaultMessage: 'Tem certeza que deseja cancelar?',
  },
  editConditionalFieldConfirmDescription: {
    id: 'edit-conditional-field-confirm-description',
    defaultMessage: 'Cancelando você perderá toda a mudança realizada.',
  },
  editConditionalFieldConfirmCancel: {
    id: 'edit-conditional-field-confirm-cancel',
    defaultMessage: 'Sim, cancelar',
  },
  editConditionalFieldConfirmBack: {
    id: 'edit-conditional-field-confirm-back',
    defaultMessage: 'Voltar para edição',
  },
  conditionalOption: {
    id: 'conditional-option',
    defaultMessage: 'Opção:',
  },
  conditionalFilterBy: {
    id: 'conditional-filter-by',
    defaultMessage: 'Filtrar por:',
  },
  deleteAllOptionsButton: {
    id: 'button-delete-all-options',
    defaultMessage: 'Excluir todas as opções',
  },
  custom_fields_repacking_title: {
    id: 'custom-fields-repacking-title',
    defaultMessage: 'Campos customizados da vaga',
  },
  custom_fields_repacking_card_title: {
    id: 'custom-fields-repacking-card-title',
    defaultMessage: 'Precisa de ajuda?',
  },
  custom_fields_repacking_know_more: {
    id: 'custom-fields-repacking-know-more',
    defaultMessage: 'Quero saber mais',
  },
});

export const getMessages = intl => formatMessages(intl, messages);
