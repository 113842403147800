import { asyncActionType, createActionTypes } from '@gupy/front-commons';

export default createActionTypes(
  'JobCandidatesClassifiers',
  'CHANGE_FORM',
  asyncActionType('CHANGE_CLASSIFIERS_LIST'),
  asyncActionType('GET_USERS_LIST'),
  asyncActionType('GET_CLASSIFIERS_LIST'),
  asyncActionType('POST'),
  asyncActionType('DELETE'),
  asyncActionType('POST_TO_JOBS'),
  asyncActionType('POST_TO_JOBS_ASYNC'),
  'CHANGE_FORM_USERS_CLEAR_SUGGESTIONS',
  'OPEN_CLASSIFIERS_MODAL',
  'CLOSE_CLASSIFIERS_MODAL',
);
