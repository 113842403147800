import { groupNotificationsByType } from './helpers/adapters';
import * as NotificationsActionTypes from './NotificationsActionTypesV2';

const viewNotification = notification => ({
  type: NotificationsActionTypes.VIEW_REQUEST_V2,
  data: notification,
});

const removeNotification = (id, type) => ({
  type: NotificationsActionTypes.REMOVE_REQUEST_V2,
  data: { id, type },
});

const getAllNotifications = namespace => ({
  type: NotificationsActionTypes.GET_ALL_REQUEST_V2,
  data: {
    namespace,
  },
});

const getAllNotificationsSuccess = notifications => ({
  type: NotificationsActionTypes.GET_ALL_SUCCESS_V2,
  notifications: groupNotificationsByType(notifications),
});

export {
  NotificationsActionTypes,
  viewNotification,
  removeNotification,
  getAllNotifications,
  getAllNotificationsSuccess,
};
