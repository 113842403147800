import api from '../../api/DarthVaderClient';

const getFilterParams = summary => {
  const params = [];

  const { pagination } = summary;

  if (pagination) {
    if (pagination.page >= 0) {
      params.push(`page=${pagination.page}`);
    }
    if (pagination.perPage) {
      params.push(`perPage=${pagination.perPage}`);
    }
  }

  return params;
};

export default {
  gupyBadgeFeedbackJobInsights() {
    return api.get('/analytics/companies/jobs-insights');
  },
  getJobPostingsWithoutFeedback(options = { pagination: { perPage: 4 } }) {
    const params = getFilterParams(options);
    const queryParams = params.length > 1 ? params.join('&') : params[0];
    return api.get(
      `/analytics/companies/jobs-insights/jobs-without-feedback?${queryParams}`,
    );
  },
  getInactiveJobPostings(options = { pagination: { perPage: 4 } }) {
    const params = getFilterParams(options);
    const queryParams = params.length > 1 ? params.join('&') : params[0];
    return api.get(
      `/analytics/companies/jobs-insights/inactive-jobs?${queryParams}`,
    );
  },
};
