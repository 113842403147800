import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import FeaturesFlagsEnum from '../Authentication/FeaturesFlagsEnum';

const propTypes = {
  userId: PropTypes.number,
  isFeatureFlagEnabled: PropTypes.bool,
};

const defaultProps = {
  isFeatureFlagEnabled: false,
};

const ReCaptchaInjector = ({ isFeatureFlagEnabled, userId }) => {
  const [isInjected, setIsInjected] = useState(false);

  React.useEffect(() => {
    if (isInjected && !isFeatureFlagEnabled) {
      return;
    }

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    script.type = 'text/javascript';
    script.defer = true;
    document.body.appendChild(script);

    setIsInjected(true);
  }, []);

  React.useEffect(() => {
    const badge = document.querySelector('.grecaptcha-badge');

    if (userId && badge) {
      badge.style.visibility = 'hidden';
    }

    if (!userId && badge) {
      badge.style.visibility = 'visible';
    }
  }, [userId]);

  return null;
};

ReCaptchaInjector.propTypes = propTypes;
ReCaptchaInjector.defaultProps = defaultProps;

const mapStateToProps = ({ reducers }) => ({
  userId:
    reducers.Authentication &&
    reducers.Authentication.currentUser &&
    reducers.Authentication.currentUser.id,
  isFeatureFlagEnabled:
    reducers.Authentication &&
    reducers.Authentication.currentUser &&
    reducers.Authentication.currentUser.featureFlags &&
    reducers.Authentication.currentUser.featureFlags.includes(
      FeaturesFlagsEnum.recaptchaV3,
    ),
});

export default connect(mapStateToProps)(ReCaptchaInjector);
