import { asyncActionType, createActionTypes } from '@gupy/front-commons';
import JobStepTestIds from '../../../../../constants/Job/JobStepTestIds';
import FormatVideoURLHelper from '../../../../../helpers/FormatVideoURL';
import { adaptCustomTestResult } from './CustomTestAdapter';
import testsTypeEnum from './TestsTypeEnum';

const CandidateTestsActionTypes = createActionTypes(
  'CandidateTests',
  asyncActionType('INIT_CANDIDATE_TESTS'),
  asyncActionType('GET_PROFILE_TEST_RESULT'),
  asyncActionType('GET_CUSTOM_TESTS_RESULTS'),
  asyncActionType('GET_APPLICATION_DETAILS'),
);

const getCustomTestsResultsSuccess = ({ customTestsResult, application }) => ({
  type: CandidateTestsActionTypes.GET_CUSTOM_TESTS_RESULTS_SUCCESS,
  customTestsResult: adaptCustomTestResult({ customTestsResult, application }),
  applicationId: application.id,
});

const getProfileTestResult = ({ jobId, application, jobSteps }) => ({
  type: CandidateTestsActionTypes.GET_PROFILE_TEST_RESULT,
  jobId,
  application,
  jobSteps,
});

const getProfileTestResultSuccess = ({ profileTestResult, jobSteps }) => ({
  type: CandidateTestsActionTypes.GET_PROFILE_TEST_RESULT_SUCCESS,
  profileTestResult,
  jobSteps,
});

const initCandidateTests = ({ jobId, application, jobSteps }) => ({
  type: CandidateTestsActionTypes.INIT_CANDIDATE_TESTS,
  jobId,
  application,
  jobSteps,
});

const getApplicationDetails = ({ jobId, applicationId }) => ({
  type: CandidateTestsActionTypes.GET_APPLICATION_DETAILS,
  jobId,
  applicationId,
});

const getApplicationDetailsSuccess = application => ({
  type: CandidateTestsActionTypes.GET_APPLICATION_DETAILS_SUCCESS,
  application,
});

const initCandidateTestsSuccess = ({
  video,
  jobSteps,
  applicationId,
  providerTestsSummary,
  multipleTestsResult,
}) => {
  let hasVideo = false;
  let hasExternalProviderTest = false;

  const providerTestSteps = [];
  const videoResult =
    video && video.url
      ? { ...video, description: '', type: testsTypeEnum.video }
      : null;

  if (jobSteps) {
    jobSteps.forEach(step => {
      if (step.testId === JobStepTestIds.video) {
        hasVideo = true;
        if (videoResult) {
          videoResult.description = step.objectivesDescription || null;
        }
      } else if (step.testProviderId && step.newTestId) {
        hasExternalProviderTest = true;
        const summary =
          providerTestsSummary.find(test => test.testId === step.newTestId) ||
          {};
        providerTestSteps.push({
          name: step.name,
          testProviderId: step.testProviderId,
          testId: step.newTestId,
          testTitle: summary.testTitle,
          hasMultipleResults: summary.hasMultipleResults,
          order: summary.order,
          type: testsTypeEnum.providerTest,
          result: {
            description: step.objectivesDescription,
            isComplete: summary.isComplete,
            average: summary.average,
            score: summary.score,
            company_result_string: summary.companyResultString,
            resultPageUrl: summary.resultPageUrl,
          },
        });
      }
    });
  }

  if (video && !video.isInternalVideo && video.url) {
    videoResult.url = FormatVideoURLHelper.formatVideoURL(video.url);
  }

  return {
    type: CandidateTestsActionTypes.INIT_CANDIDATE_TESTS_SUCCESS,
    multipleTestsResult: multipleTestsResult && {
      ...multipleTestsResult,
      type: multipleTestsResult.results
        ? testsTypeEnum.multipleTestResult
        : testsTypeEnum.multipleTestResultLegacy,
      results: (multipleTestsResult.results || []).map(mtr => ({
        ...mtr,
        type: testsTypeEnum.multipleTestResult,
      })),
    },
    hasTests: {
      hasVideo,
      hasExternalProviderTest,
    },
    testsResults: {
      videoResult,
      providerTestSteps,
    },
    applicationId,
  };
};

export {
  CandidateTestsActionTypes,
  initCandidateTests,
  initCandidateTestsSuccess,
  getCustomTestsResultsSuccess,
  getProfileTestResult,
  getProfileTestResultSuccess,
  getApplicationDetails,
  getApplicationDetailsSuccess,
};
