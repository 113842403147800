import {
  changeForm,
  clearForm,
  deleteUserAccessProfile,
  editUserAccessProfile,
  getAllUserAccessProfile,
  getStructureUserAccessProfile,
  getUserAccessProfile,
  getUserAccessProfileTypes,
  postUserAccessProfile,
  putUserAccessProfile,
  searchUserAccessProfile,
  typeSearchUserAccessProfiles,
  UserAccessProfileActionTypes,
} from './Actions';
import UserAccessProfileReducer from './Reducers';
import UserAccessProfileSaga from './Sagas';
import UserAccessProfileContainer from './UserAccessProfileContainer';
import UserAccessProfileFormContainer from './UserAccessProfileFormContainer';

const UserAccessProfileAction = {
  UserAccessProfileActionTypes,
  changeForm,
  clearForm,
  postUserAccessProfile,
  putUserAccessProfile,
  deleteUserAccessProfile,
  editUserAccessProfile,
  getAllUserAccessProfile,
  getUserAccessProfile,
  searchUserAccessProfile,
  typeSearchUserAccessProfiles,
  getStructureUserAccessProfile,
  getUserAccessProfileTypes,
};

export {
  UserAccessProfileAction,
  UserAccessProfileFormContainer,
  UserAccessProfileContainer,
  UserAccessProfileReducer,
  UserAccessProfileSaga,
};
