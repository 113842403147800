import { combineReducers } from 'redux';
import CustomTestActionTypes from './CustomTestActionTypes';

const CompanyCustomTestModel = {
  id: undefined,
  title: '',
  instructions: '',
  timeLimit: undefined,
  isRandomQuestions: undefined,
};

const isSaving = (state = false, action) => {
  switch (action.type) {
    case CustomTestActionTypes.CREATE:
    case CustomTestActionTypes.DUPLICATE:
    case CustomTestActionTypes.UPDATE:
      return true;
    case CustomTestActionTypes.CREATE_SUCCESS:
    case CustomTestActionTypes.CREATE_FAIL:
    case CustomTestActionTypes.DUPLICATE_SUCCESS:
    case CustomTestActionTypes.DUPLICATE_FAIL:
    case CustomTestActionTypes.UPDATE_SUCCESS:
    case CustomTestActionTypes.UPDATE_FAIL:
      return false;
    default:
      return state;
  }
};

const searchString = (state = '', action) => {
  switch (action.type) {
    case CustomTestActionTypes.CHANGE_SEARCH:
      return action.searchString;
    case CustomTestActionTypes.LIST:
      return '';
    default:
      return state;
  }
};

const selectedCards = (state = [], action) => {
  switch (action.type) {
    case CustomTestActionTypes.SELECT_CARD: {
      const { id, selected } = action;
      if (selected) return [...state, id];
      return state.filter(oldId => oldId !== id);
    }
    case CustomTestActionTypes.DELETE_MULTIPLE_SUCCESS:
    case CustomTestActionTypes.READ:
      return [];
    default:
      return state;
  }
};

const modelForm = (state = CompanyCustomTestModel, action) => {
  switch (action.type) {
    case CustomTestActionTypes.CHANGE_FORM:
      return Object.assign({}, state, action.modelForm);
    case CustomTestActionTypes.MODEL_CLEAR:
    case CustomTestActionTypes.CREATE_SUCCESS:
    case CustomTestActionTypes.DUPLICATE_SUCCESS:
    case CustomTestActionTypes.UPDATE_SUCCESS:
      return CompanyCustomTestModel;
    default:
      return state;
  }
};

const customTestList = (state = {}, action) => {
  switch (action.type) {
    case CustomTestActionTypes.LIST:
    case CustomTestActionTypes.CREATE:
    case CustomTestActionTypes.UPDATE:
      return {};
    case CustomTestActionTypes.FAVORITE_SUCCESS:
      return {
        ...state,
        data: state.data.map(test =>
          test.id !== action.id
            ? test
            : {
                ...test,
                isFavorite: action.isFavorite,
              },
        ),
      };
    case CustomTestActionTypes.LIST_SUCCESS:
      return action.CustomTests;
    case CustomTestActionTypes.DUPLICATE_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.customTest],
      };
    case CustomTestActionTypes.DELETE_SUCCESS:
    case CustomTestActionTypes.ARCHIVE_SUCCESS:
      return {
        ...state,
        data: state.data.filter(({ id }) => !action.ids.includes(id)),
      };
    case CustomTestActionTypes.LIST_FAIL:
    case CustomTestActionTypes.DELETE_FAIL:
    case CustomTestActionTypes.ARCHIVE_FAIL:
    default:
      return state;
  }
};

const customTestListToJob = (state = [], action) => {
  switch (action.type) {
    case CustomTestActionTypes.LIST_TO_JOB:
      return [];
    case CustomTestActionTypes.LIST_TO_JOB_SUCCESS:
      return action.CustomTests;
    case CustomTestActionTypes.LIST_TO_JOB_FAIL:
    default:
      return state;
  }
};

const data = (state = {}, action) => {
  switch (action.type) {
    case CustomTestActionTypes.LIST:
      return {};
    case CustomTestActionTypes.CHANGE_FORM:
      return Object.assign({}, state, action.data);
    case CustomTestActionTypes.DETAIL_SUCCESS:
    case CustomTestActionTypes.READ_SUCCESS:
      return action.CustomTest;
    case CustomTestActionTypes.DETAIL_FAIL:
    case CustomTestActionTypes.READ_FAIL:
    default:
      return state;
  }
};

const selectedCustomTest = (state, action) => {
  switch (action.type) {
    case CustomTestActionTypes.READ_SUCCESS:
      return action.CustomTest;
    case CustomTestActionTypes.RESET_CUSTOM_TEST:
      return {};
    case CustomTestActionTypes.READ_FAIL:
    default:
      return state || {};
  }
};

const validation = (state = {}, action) => {
  switch (action.type) {
    case CustomTestActionTypes.CREATE:
    case CustomTestActionTypes.UPDATE:
    case CustomTestActionTypes.VALIDATE_TEST_CUSTOM:
      return {};
    case CustomTestActionTypes.CREATE_FAIL:
    case CustomTestActionTypes.UPDATE_FAIL:
    case CustomTestActionTypes.VALIDATE_TEST_CUSTOM_FAIL:
      return action.validation;
    default:
      return state;
  }
};

const isLoading = (state = false, action) => {
  switch (action.type) {
    case CustomTestActionTypes.LIST:
    case CustomTestActionTypes.DELETE:
    case CustomTestActionTypes.DUPLICATE:
    case CustomTestActionTypes.READ:
      return true;
    case CustomTestActionTypes.LIST_SUCCESS:
    case CustomTestActionTypes.LIST_FAIL:
    case CustomTestActionTypes.DELETE_FAIL:
    case CustomTestActionTypes.DELETE_SUCCESS:
    case CustomTestActionTypes.DUPLICATE_FAIL:
    case CustomTestActionTypes.DUPLICATE_SUCCESS:
    case CustomTestActionTypes.READ_SUCCESS:
    case CustomTestActionTypes.READ_FAIL:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  data,
  customTestList,
  isSaving,
  modelForm,
  validation,
  searchString,
  selectedCards,
  customTestListToJob,
  isLoading,
  selectedCustomTest,
});
