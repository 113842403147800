import { combineReducers } from 'redux';
import ActionTypes from './TestCorrectionActionTypes';

const questions = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_SCORE:
    case ActionTypes.READ_SUCCESS:
      return action.model.questions;
    case ActionTypes.LIST:
    case ActionTypes.READ:
    case ActionTypes.SAVE_SUCCESS:
      return [];
    default:
      return state;
  }
};

const test = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.READ_SUCCESS:
      return action.model.test;
    case ActionTypes.LIST:
    case ActionTypes.READ:
    case ActionTypes.SAVE_SUCCESS:
      return {};
    default:
      return state;
  }
};

const candidate = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.READ_SUCCESS:
      return action.model.candidate;
    case ActionTypes.LIST:
    case ActionTypes.READ:
    case ActionTypes.SAVE_SUCCESS:
      return {};
    default:
      return state;
  }
};

export const hasError = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.SAVE_SUCCESS:
    case ActionTypes.READ_SUCCESS:
    case ActionTypes.LIST_SUCCESS:
      return false;
    case ActionTypes.SAVE_FAIL:
    case ActionTypes.READ_FAIL:
    case ActionTypes.LIST_FAIL:
      return true;
    default:
      return state;
  }
};

export default combineReducers({
  questions,
  test,
  candidate,
  hasError,
});
