import { buildToast, ToastTypes } from '@gupy/front-commons';
import { call, put, takeLatest } from 'redux-saga/effects';
import EmailTemplateService from '../../../src/services/EmailTemplate/EmailTemplateService';
import JobApplicationActionTypes from '../../constants/Job/JobApplication/JobApplicationActionTypes';
import EmailTemplateForm from '../../forms/EmailTemplate/EmailTemplateForm';
import history from '../../history';
import { EmailTemplateActionTypes } from './Actions';

function* getAll({ publicOnly, options: { attributes = [] } = {} } = {}) {
  try {
    const responseVariables = yield EmailTemplateService.getVariables();
    const responseTemplates = yield EmailTemplateService.getAll({
      publicOnly,
      attributes,
    });

    yield put({
      type: EmailTemplateActionTypes.GET_ALL_SUCCESS,
      emailTemplates: responseTemplates.body.map(emailTemplate => ({
        ...emailTemplate,
        files: emailTemplate.attachments || [],
      })),
      variables: responseVariables.body.data,
    });
  } catch (error) {
    yield put({
      type: EmailTemplateActionTypes.GET_ALL_FAIL,
    });
  }
}

function* getAllByType({ emailType }) {
  try {
    const responseVariables = yield EmailTemplateService.getVariables();
    const responseTemplates =
      yield EmailTemplateService.getAllByType(emailType);

    yield put({
      type: EmailTemplateActionTypes.GET_ALL_BY_TYPE_SUCCESS,
      emailTemplates: responseTemplates.body,
      variables: responseVariables.body.data,
    });
  } catch (error) {
    yield put({
      type: EmailTemplateActionTypes.GET_ALL_BY_TYPE_FAIL,
    });
  }
}

function* get(action) {
  const { id } = action;
  try {
    const responseTemplate = yield EmailTemplateService.get(id);
    const responseVariables = yield EmailTemplateService.getVariables();

    yield put({
      type: EmailTemplateActionTypes.GET_SUCCESS,
      modelForm: {
        ...responseTemplate.body,
        files: responseTemplate.body.attachments || [],
      },
      variables: responseVariables.body.data,
    });
  } catch (error) {
    yield put({
      type: EmailTemplateActionTypes.GET_FAIL,
    });
  }
}

function* getTypes() {
  try {
    const response = yield EmailTemplateService.getTypes();

    yield put({
      type: EmailTemplateActionTypes.GET_TYPES_SUCCESS,
      types: response.body.data,
    });
  } catch (error) {
    yield put({
      type: EmailTemplateActionTypes.GET_TYPES_FAIL,
    });
  }
}

function* getVariables() {
  try {
    const response = yield EmailTemplateService.getVariables();

    yield put({
      type: EmailTemplateActionTypes.GET_VARIABLES_SUCCESS,
      variables: response.body.data,
    });
  } catch (error) {
    yield put({
      type: EmailTemplateActionTypes.GET_VARIABLES_FAIL,
    });
  }
}

function* post(action) {
  const { payload, messages, redirectUrl } = action;
  const newPayload = {
    ...payload,
    files: null,
    attachments: (payload.files || []).map(({ id, name, size, tempName }) => ({
      id,
      name,
      size,
      tempName,
    })),
  };
  const form = new EmailTemplateForm();

  form.populate(newPayload);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: EmailTemplateActionTypes.POST_FAIL,
      validation,
    });
  } else {
    try {
      yield EmailTemplateService.post(newPayload);

      yield put({
        type: EmailTemplateActionTypes.POST_SUCCESS,
        toast: buildToast(messages.success, ToastTypes.success),
      });

      yield call(history.push, redirectUrl);
    } catch (error) {
      yield put({
        type: EmailTemplateActionTypes.POST_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* update(action) {
  const { payload, messages, redirectUrl } = action;
  const form = new EmailTemplateForm();

  const { id } = payload;
  const newPayload = {
    ...payload,
    id: undefined,
    files: null,
    attachments: (payload.files || []).map(
      ({ id: fileId, name, size, tempName }) => ({
        id: fileId,
        name,
        size,
        tempName,
      }),
    ),
  };

  form.populate(newPayload);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: EmailTemplateActionTypes.PUT_FAIL,
      validation,
    });
  } else {
    try {
      yield EmailTemplateService.patch(id, newPayload);

      yield put({
        type: EmailTemplateActionTypes.PUT_SUCCESS,
        toast: buildToast(messages.success, ToastTypes.success),
      });

      yield call(history.push, redirectUrl);
    } catch (error) {
      yield put({
        type: EmailTemplateActionTypes.PUT_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* del(action) {
  const { id, messages } = action;

  try {
    yield EmailTemplateService.delete(id);

    yield put({
      type: EmailTemplateActionTypes.DELETE_SUCCESS,
      toast: buildToast(messages.success, ToastTypes.success),
    });
  } catch (error) {
    yield put({
      type: EmailTemplateActionTypes.DELETE_FAIL,
      toast: buildToast(error.body.message, ToastTypes.error),
      error,
    });
  }
}

function* search(action) {
  const { searchString } = action;

  try {
    const response = yield EmailTemplateService.getAll({ searchString });
    yield put({
      type: EmailTemplateActionTypes.SEARCH_SUCCESS,
      emailTemplates: response.body,
    });
  } catch (error) {
    yield put({
      type: EmailTemplateActionTypes.SEARCH_FAIL,
      error,
    });
  }
}

function* checkHasAssociatedJob(action) {
  const { id } = action;

  try {
    const response = yield EmailTemplateService.checkHasAssociatedJob(id);

    yield put({
      type: EmailTemplateActionTypes.CHECK_HAS_ASSOCIATED_JOB_SUCCESS,
      associatedJob: {
        hasAssociatedJob: response.body.hasAssociatedJob,
        templateId: id,
      },
    });
  } catch (error) {
    yield put({
      type: EmailTemplateActionTypes.CHECK_HAS_ASSOCIATED_JOB_FAILED,
      error,
    });
  }
}

function* getTemplateAssociatedJobs(action) {
  try {
    const { id, offset, limit } = action;
    const {
      body: { results: associatedJobs, count: totalAssociatedJobs },
    } = yield EmailTemplateService.getTemplateAssociatedJobs(id, {
      offset,
      limit,
    });

    yield put({
      type: EmailTemplateActionTypes.GET_ASSOCIATED_JOBS_SUCCESS,
      associatedJobs,
      totalAssociatedJobs,
    });
  } catch (error) {
    yield put({
      type: EmailTemplateActionTypes.GET_ASSOCIATED_JOBS_FAIL,
      error,
    });
  }
}

function* unlinkTemplateAssociatedJobs(action) {
  const { id, payload, messages } = action;

  try {
    yield EmailTemplateService.unlinkTemplateAssociatedJobs(id, payload);

    yield put({
      type: EmailTemplateActionTypes.UNLINK_TEMPLATE_ASSOCIATED_JOBS_SUCCESS,
      id,
      stepIds: payload.stepIds,
      toast: buildToast(messages, ToastTypes.success),
    });
  } catch (error) {
    yield put({
      type: EmailTemplateActionTypes.UNLINK_TEMPLATE_ASSOCIATED_JOBS_FAIL,
    });
  }
}

function* emailTemplateSaga() {
  yield takeLatest(EmailTemplateActionTypes.GET_ALL, getAll);
  yield takeLatest(EmailTemplateActionTypes.GET_ALL_BY_TYPE, getAllByType);
  yield takeLatest(JobApplicationActionTypes.OPEN_DISAPPROVE_JOB_MODAL, getAll);
  yield takeLatest(JobApplicationActionTypes.OPEN_SEND_EMAIL_MODAL, getAll);
  yield takeLatest(JobApplicationActionTypes.LOAD_SELECTED_TEMPLATE, get);
  yield takeLatest(EmailTemplateActionTypes.GET, get);
  yield takeLatest(EmailTemplateActionTypes.GET_TYPES, getTypes);
  yield takeLatest(EmailTemplateActionTypes.GET_VARIABLES, getVariables);
  yield takeLatest(EmailTemplateActionTypes.POST, post);
  yield takeLatest(EmailTemplateActionTypes.POST_SUCCESS, getAll);
  yield takeLatest(EmailTemplateActionTypes.PUT, update);
  yield takeLatest(EmailTemplateActionTypes.PUT_SUCCESS, getAll);
  yield takeLatest(EmailTemplateActionTypes.DELETE, del);
  yield takeLatest(EmailTemplateActionTypes.DELETE_SUCCESS, getAll);
  yield takeLatest(EmailTemplateActionTypes.SEARCH, search);
  yield takeLatest(
    EmailTemplateActionTypes.CHECK_HAS_ASSOCIATED_JOB,
    checkHasAssociatedJob,
  );
  yield takeLatest(
    EmailTemplateActionTypes.GET_ASSOCIATED_JOBS,
    getTemplateAssociatedJobs,
  );
  yield takeLatest(
    EmailTemplateActionTypes.UNLINK_TEMPLATE_ASSOCIATED_JOBS,
    unlinkTemplateAssociatedJobs,
  );
}

export default emailTemplateSaga;
