import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  chooseTemplate: {
    id: 'choose_template',
    defaultMessage: 'Escolha o template',
  },
  jobClosureModalTitle: {
    id: 'job-closure_modal_title',
    defaultMessage: 'Encerrar vaga',
  },
  success: {
    id: 'send_email_success_message',
    defaultMessage: 'E-mail enviado com sucesso.',
  },
  error: {
    id: 'send_email_error_message',
    defaultMessage: 'Ocorreu um erro ao enviar os emails.',
  },
  noHiredCandidatesFound: {
    id: 'no_hired_candidates_found',
    defaultMessage: 'Não encontramos nenhum candidato como contratado.',
  },
  emailTemplate: {
    id: 'email_template',
    defaultMessage: 'Template de e-mail',
  },
  moveToFinalStep: {
    id: 'do_you_want_to_move_someone_to_final_step',
    defaultMessage: 'Deseja mover algum candidato para etapa contratação?',
  },
  moveMoreToFinalStep: {
    id: 'do_you_want_to_move_someone_else_to_final_step',
    defaultMessage: 'Deseja mover mais algum candidato para etapa contratação?',
  },
  verify: {
    id: 'verify',
    defaultMessage: 'Verificar',
  },
  next: {
    id: 'next',
    defaultMessage: 'Continuar',
  },
  doYouWantToSendFeedback: {
    id: 'do_you_want_to_send_feedback',
    defaultMessage:
      'Deseja enviar feedback para quem não está na etapa contratação?',
  },
  sendEmail: {
    id: 'send_email',
    defaultMessage: 'Enviar e-mail',
  },
  doNotSendEmail: {
    id: 'do_not_send_email',
    defaultMessage: 'Continuar sem feedback',
  },
  doYouConfirmJobClosure: {
    id: 'are_you_sure_you_want_to_finish',
    defaultMessage: 'Você confirma o encerramento da vaga?',
  },
  thisJobCannotBeReactivated: {
    id: 'this_job_cannot_be_reactivated',
    defaultMessage: 'Uma vaga encerrada não pode ser reativada.',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancelar',
  },
  closeJob: {
    id: 'close_job',
    defaultMessage: 'Encerrar',
  },
  chooseATemplate: {
    id: 'choose_an_email_template',
    defaultMessage: 'Escolha um template de e-mail',
  },
  candidates: {
    id: 'candidates',
    defaultMessage: 'candidatos',
  },
  noFeedbackPendingToSend: {
    id: 'no-feedback-pending-to-send',
    defaultMessage:
      'Todas as pessoas aptas a receberem e-mails da Gupy nessa vaga já receberam feedback! Não se preocupe, você não precisa enviar nenhum e-mail.',
  },
  errorCloseJob: {
    id: 'job_list_close_job_error_message',
    defaultMessage: 'Erro ao encerrar vaga.',
  },
});

const step1ContentMessage = defineMessages({
  thereIsXcandidatesInThisStep: {
    id: 'there_is_x_candidates_in_this_step',
    defaultMessage: 'Há {finalApplicationsCount} candidatos nesta etapa.',
  },
});

export const getMessages = (intl, finalApplicationsCount) => {
  const simpleMessages = formatMessages(intl, messages);
  return {
    ...simpleMessages,
    thereIsXcandidatesInThisStep: intl.formatMessage(
      step1ContentMessage.thereIsXcandidatesInThisStep,
      { finalApplicationsCount },
    ),
  };
};
