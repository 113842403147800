import { JobStepCategoryEnum, JobStepOnlineMoveTypeEnum } from '@gupy/enums';
import { combineReducers } from 'redux';
import JobActionTypes from '../../../constants/Job/JobActionTypes';
import JobStepsActionTypes from '../../../constants/Job/JobStep/JobStepsActionTypes';

const attentionStepCategories = [
  JobStepCategoryEnum.interview,
  JobStepCategoryEnum.offer,
  JobStepCategoryEnum.preHiring,
];

const automaticMoveTypes = [
  JobStepOnlineMoveTypeEnum.automatic,
  JobStepOnlineMoveTypeEnum.byScore,
];

const initialSteps = {};

const initialJobModelForm = {
  id: undefined,
};

const isAutomaticStep = step =>
  automaticMoveTypes.includes(step.onlineMoveType);

const isAttentionCategory = (step = {}) =>
  attentionStepCategories.includes(step.category);

const hasInvalidAutomaticMove = (step, nextStep) =>
  isAutomaticStep(step) && isAttentionCategory(nextStep);

const hydrateSteps = steps => {
  const initialAndCustomSteps = steps.initial.concat(steps.custom);
  const sortedSteps = initialAndCustomSteps.sort((a, b) => a.order - b.order);
  sortedSteps.forEach((step, index) => {
    sortedSteps[index].hasInvalidAutomaticMove = hasInvalidAutomaticMove(
      step,
      sortedSteps[index + 1],
    );
  });

  return {
    ...steps,
    hasAutomaticMoveBeforeAttentionCategory: sortedSteps.some(
      step => step.hasInvalidAutomaticMove,
    ),
  };
};

export const steps = (state = initialSteps, action) => {
  switch (action.type) {
    case JobStepsActionTypes.GET_SUCCESS:
      return action.steps.initial ? hydrateSteps(action.steps) : initialSteps;
    case JobStepsActionTypes.GET_FAIL:
      return initialSteps;
    case JobStepsActionTypes.REORDER: {
      return hydrateSteps(state);
    }
    case JobStepsActionTypes.DELETE_SUCCESS: {
      const filteredSteps = {
        ...state,
        custom: state.custom.filter(item => item.id !== action.step.id),
      };
      return hydrateSteps(filteredSteps);
    }
    default:
      return state;
  }
};

export const isLoading = (state = false, action) => {
  switch (action.type) {
    case JobStepsActionTypes.GET:
      return true;
    case JobStepsActionTypes.GET_SUCCESS:
    case JobStepsActionTypes.GET_FAIL:
      return false;
    default:
      return state;
  }
};

export const modelForm = (state = initialJobModelForm, action) => {
  switch (action.type) {
    case JobActionTypes.GET_SUCCESS:
      return {
        id: action.model.id,
      };
    case JobStepsActionTypes.CHANGE_FORM:
      return Object.assign({}, state, action.modelForm);
    default:
      return state;
  }
};

const isSaving = (state = false, action) => {
  switch (action.type) {
    case JobStepsActionTypes.CHANGE_FORM:
      return true;
    case JobStepsActionTypes.PATCH_JOB_SUCCESS:
    case JobStepsActionTypes.PATCH_JOB_FAIL:
      return false;
    default:
      return state;
  }
};

export const isDeleting = (state = false, action) => {
  switch (action.type) {
    case JobStepsActionTypes.DELETE:
      return true;
    case JobStepsActionTypes.DELETE_SUCCESS:
    case JobStepsActionTypes.DELETE_FAIL:
      return false;
    default:
      return state;
  }
};

export const validation = (state = {}, action) => {
  switch (action.type) {
    case JobStepsActionTypes.DELETE_FAIL:
      return action.error;
    case JobStepsActionTypes.DELETE:
    case JobStepsActionTypes.DELETE_SUCCESS:
      return {};
    default:
      return state;
  }
};

// PATCH_JOB_FAIL and PATCH_JOB_FAIL_SUCCESS are not being treated below
// to force the error state until the user refreshes the page.
export const hasError = (state = false, action) => {
  switch (action.type) {
    case JobStepsActionTypes.PATCH_JOB_FAIL:
      return true;
    case JobStepsActionTypes.GET_SUCCESS:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  steps,
  modelForm,
  isSaving,
  isLoading,
  isDeleting,
  validation,
  hasError,
});
