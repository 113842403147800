import { Button, Spinner } from '@gupy/design-system';
import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { openConfirmDialog } from '../../actions/App/AppActions';
import Grid from '../../components/Grid/Grid';
import GridContent from '../../components/Grid/GridContent';
import SetupGridSidebarMenu from '../../components/Grid/SetupGridSidebarMenu';
import featuresFlagsEnum from '../Authentication/FeaturesFlagsEnum';
import {
  checkEmailTemplateHasAssociatedJob,
  clearAssociatedJob,
  deleteEmailTemplate,
  getAllEmailTemplates,
  searchEmailTemplates,
  typeSearchEmailTemplates,
} from './Actions';
import EmailTemplateBar from './components/EmailTemplateBar';
import EmailTemplateCard from './components/EmailTemplateCard';
import { getMessages } from './Messages';

const propTypes = {
  Authentication: PropTypes.object.isRequired,
  EmailTemplate: PropTypes.object.isRequired,
  getAllEmailTemplates: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  deleteEmailTemplate: PropTypes.func.isRequired,
  openConfirmDialog: PropTypes.func.isRequired,
  searchEmailTemplates: PropTypes.func.isRequired,
  typeSearchEmailTemplates: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  checkEmailTemplateHasAssociatedJob: PropTypes.func.isRequired,
  clearAssociatedJob: PropTypes.func.isRequired,
};

function EmailTemplateContainer(props) {
  const [searchValue, setSearchValue] = useState('');

  const { EmailTemplate, permissions, intl, Authentication } = props;
  const { emailTemplates, isLoading, searched, associatedJob } = EmailTemplate;
  const hasEmailTemplate =
    (emailTemplates && emailTemplates.length > 0) || searched;
  const messages = getMessages(intl);
  const user = Authentication.currentUser;
  const { featureFlags = [] } = user;
  const isAdmissionOnly = featureFlags.includes('admission_only');

  const tabsIsEnabled = useFlag(featuresFlagsEnum.emailTemplateTabs);

  useEffect(() => {
    props.getAllEmailTemplates();
  }, []);

  useEffect(() => {
    props.typeSearchEmailTemplates();

    const timeout = setTimeout(() => {
      props.searchEmailTemplates(searchValue);
    }, 400);

    return () => clearTimeout(timeout);
  }, [searchValue]);

  const handleCreateClick = useCallback(() => {
    props.history.push('/companies/setup/email-template/new');
  }, [props.history]);

  const handleEditClick = useCallback(
    id => {
      props.history.push(`/companies/setup/email-template/${id}`);
    },
    [props.history],
  );

  const handleRedirectToTab = useCallback(
    templateId => {
      const confirmMessages = defineMessages({
        title: {
          id: 'email_template_associated_job_confirmation_title',
          defaultMessage: 'Erro ao excluir template',
        },
        message: {
          id: 'email_template_associated_job_confirmation_message',
          defaultMessage:
            'Não é possível excluir o template porque ele está vinculado à etapa de pelo menos uma vaga não encerrada ou não cancelada.',
        },
        confirmButtonText: {
          id: 'email_template_associated_job_confirmation_redirect',
          defaultMessage: ' Revisar vagas vinculadas',
        },
        cancelButtonText: {
          id: 'cancel',
          defaultMessage: 'Cancelar',
        },
      });

      props.openConfirmDialog({
        title: intl.formatMessage(confirmMessages.title),
        message: intl.formatMessage(confirmMessages.message),
        confirmButtonText: intl.formatMessage(
          confirmMessages.confirmButtonText,
        ),
        cancelButtonText: intl.formatMessage(confirmMessages.cancelButtonText),
        onConfirmClick: () => {
          props.clearAssociatedJob();
          props.history.push(
            `/companies/setup/email-template/${templateId}?tab=associatedJob`,
          );
        },
        onCancelClick: () => {
          props.clearAssociatedJob();
        },
      });
    },
    [props.history],
  );

  const handleDelete = useCallback(templateId => {
    const confirmMessages = defineMessages({
      title: {
        id: 'email_template_delete_confirmation_title',
        defaultMessage: 'Excluir template de e-mail',
      },
      message: {
        id: 'email_template_delete_confirmation_message',
        defaultMessage: 'Você confirma a exclusão do template de e-mail?',
      },
      confirmButtonText: {
        id: 'delete',
        defaultMessage: 'Excluir',
      },
      cancelButtonText: {
        id: 'cancel',
        defaultMessage: 'Cancelar',
      },
    });

    props.openConfirmDialog({
      title: intl.formatMessage(confirmMessages.title),
      message: intl.formatMessage(confirmMessages.message),
      confirmButtonText: intl.formatMessage(confirmMessages.confirmButtonText),
      cancelButtonText: intl.formatMessage(confirmMessages.cancelButtonText),
      onConfirmClick: () => {
        const messagesConfirm = defineMessages({
          success: {
            id: 'email_template_delete_success_message',
            defaultMessage: 'Template de e-mail excluído com sucesso.',
          },
        });

        props.deleteEmailTemplate(templateId, {
          success: intl.formatMessage(messagesConfirm.success),
        });
      },
      onCancelClick: () => {
        props.clearAssociatedJob();
      },
    });
  }, []);

  useEffect(() => {
    if (associatedJob) {
      if (associatedJob.hasAssociatedJob) {
        handleRedirectToTab(associatedJob.templateId);
      } else {
        handleDelete(associatedJob.templateId);
      }
    }
  }, [associatedJob, handleRedirectToTab, handleDelete]);

  const handleDeleteClick = useCallback((event, templateId) => {
    event.stopPropagation();

    if (!tabsIsEnabled) {
      handleDelete(templateId);
    } else {
      props.checkEmailTemplateHasAssociatedJob(templateId);
    }
  }, []);

  const noResultsNode = useMemo(
    () => (
      <div>
        <h3>{messages.noEmailTemplateFoundTitle}</h3>
        <p>{messages.noEmailTemplateFoundDescription}</p>
      </div>
    ),
    [
      messages.noEmailTemplateFoundTitle,
      messages.noEmailTemplateFoundDescription,
    ],
  );

  const emptyEmailTemplate = useMemo(
    () => (
      <div className="col-xs-12 col-sm-9 empty-email-template">
        <div className="empty-email-template__illustration">
          <img
            className="empty-email-template__image"
            src={`${process.env.REACT_APP_ASSETS_URL}/email-template/illustration.png`}
            alt={messages.twoPeopleTalking}
          />
        </div>
        <div className="empty-email-template__content">
          <h3 className="empty-email-template__title">
            {messages.noEmailTemplateCreatedYet}
          </h3>
          <p className="empty-email-template__subtitle">
            {isAdmissionOnly
              ? messages.noEmailTemplateCreatedYetAdmissionOnlyDescription
              : messages.noEmailTemplateCreatedYetDescription}
          </p>
          <Button onClick={handleCreateClick}>
            {messages.createEmailTemplateButton}
          </Button>
        </div>
      </div>
    ),
    [
      handleCreateClick,
      messages.twoPeopleTalking,
      messages.noEmailTemplateCreatedYet,
      messages.noEmailTemplateCreatedYetAdmissionOnlyDescription,
      messages.noEmailTemplateCreatedYetDescription,
      messages.createEmailTemplateButton,
    ],
  );

  const resultsNode = useMemo(() => {
    if (emailTemplates && emailTemplates.length > 0) {
      return emailTemplates.map(template => (
        <EmailTemplateCard
          key={template.id}
          emailTemplate={template}
          onDeleteClick={handleDeleteClick}
          onEditClick={handleEditClick}
          canEditPublic={
            permissions.edit_public_email_template ||
            permissions.view_public_email_template
          }
          canEditPrivate={permissions.edit_email_template}
          canDeletePublic={permissions.delete_public_email_template}
          canDeletePrivate={permissions.delete_email_template}
          messages={messages}
        />
      ));
    }

    return noResultsNode;
  }, [
    emailTemplates,
    handleDeleteClick,
    handleEditClick,
    permissions.edit_public_email_template,
    permissions.view_public_email_template,
    permissions.edit_email_template,
    permissions.delete_public_email_template,
    permissions.delete_email_template,
  ]);

  return (
    <Grid>
      <SetupGridSidebarMenu permissions={permissions} />
      {hasEmailTemplate || isLoading ? (
        <GridContent
          className={classNames('card-list')}
          title={messages.emailTemplatesPageGridContentTitle}
        >
          <React.Fragment>
            <EmailTemplateBar
              onAddNewTemplateClick={handleCreateClick}
              onSearchChange={event => setSearchValue(event.target.value)}
              canCreate={
                permissions.create_email_template ||
                permissions.create_public_email_template
              }
              searchValue={searchValue}
            />
            <hr />
            <div className="card-list__grid row">
              {isLoading ? (
                <div className="email-template__loading">
                  <Spinner color="secondary" />
                </div>
              ) : (
                resultsNode
              )}
            </div>
          </React.Fragment>
        </GridContent>
      ) : (
        emptyEmailTemplate
      )}
    </Grid>
  );
}

EmailTemplateContainer.propTypes = propTypes;

const mapStateToProps = state => ({
  EmailTemplate: state.reducers.EmailTemplate,
  Authentication: state.reducers.Authentication,
});

export default injectIntl(
  withRouter(
    connect(mapStateToProps, {
      getAllEmailTemplates,
      deleteEmailTemplate,
      openConfirmDialog,
      searchEmailTemplates,
      typeSearchEmailTemplates,
      checkEmailTemplateHasAssociatedJob,
      clearAssociatedJob,
    })(EmailTemplateContainer),
  ),
);
