import React from 'react';
import { FormattedMessage } from 'react-intl';
import config from '../../../../../config.json';

const AppFooter = () => (
  <footer className="company-footer">
    Gupy {new Date().getFullYear()}.
    <FormattedMessage
      id="app_footer_copyright"
      defaultMessage="Todos os direitos reservados."
    />
    {` v${config.version}`}
  </footer>
);

export default AppFooter;
