import { combineReducers } from 'redux';
import JobApplicationActionTypes from '../../../../../constants/Job/JobApplication/JobApplicationActionTypes';
import * as types from './HiringCelebrationActionTypes';

export const modalOpened = (state = false, action) => {
  switch (action.type) {
    case types.CLOSE_HIRING_CELEBRATION_MODAL:
      return false;
    case JobApplicationActionTypes.HIRING_FEEDBACK_REQUESTED:
      return true;
    case JobApplicationActionTypes.CURRICULUM_CHANGE_APPLICATION_STEP_SUCCESS:
      return !!action.feedbackRequest;
    default:
      return state;
  }
};

export const context = (state = null, action) => {
  switch (action.type) {
    case types.CLOSE_HIRING_CELEBRATION_MODAL:
      return null;
    case JobApplicationActionTypes.HIRING_FEEDBACK_REQUESTED:
      return action.context;
    case JobApplicationActionTypes.CURRICULUM_CHANGE_APPLICATION_STEP_SUCCESS:
      return action.feedbackRequest ? action.feedbackRequest.context : null;
    default:
      return state;
  }
};

export const feedbackType = (state = null, action) => {
  switch (action.type) {
    case types.CLOSE_HIRING_CELEBRATION_MODAL:
      return null;
    case JobApplicationActionTypes.HIRING_FEEDBACK_REQUESTED:
      return action.feedbackType;
    case JobApplicationActionTypes.CURRICULUM_CHANGE_APPLICATION_STEP_SUCCESS:
      return action.feedbackRequest
        ? action.feedbackRequest.feedbackType
        : null;
    default:
      return state;
  }
};

export default combineReducers({
  modalOpened,
  feedbackType,
  context,
});
