import { asyncActionType, createActionTypes } from '@gupy/front-commons';

const SetupEngageSurveyActionTypes = createActionTypes(
  'SetupEngageSurvey',
  asyncActionType('GET_CAREER_PAGES_OPTIONS_LIST'),
  asyncActionType('GET_SURVEY_OPTIONS_LIST'),
  asyncActionType('SAVE_BIND_SURVEY_CAREER_PAGES'),
  asyncActionType('GET_BIND_SURVEY_CAREER_PAGES'),
  asyncActionType('DELETE_BIND_SURVEY_CAREER_PAGES'),
  asyncActionType('CREATE_SURVEY'),
  asyncActionType('BIND_ACCOUNT'),
  asyncActionType('CREATE_CLIMATE_ENGAGEMENT_ACCOUNT'),
  'SET_MODAL_OPEN',
);

const getCareerPagesOptionsList = ({ messages }) => ({
  type: SetupEngageSurveyActionTypes.GET_CAREER_PAGES_OPTIONS_LIST,
  messages,
});

const getSurveyOptionsList = ({ messages }) => ({
  type: SetupEngageSurveyActionTypes.GET_SURVEY_OPTIONS_LIST,
  messages,
});

const saveBindSurveyCareerPages = ({ careerPages, surveys, messages }) => ({
  type: SetupEngageSurveyActionTypes.SAVE_BIND_SURVEY_CAREER_PAGES,
  messages,
  payload: {
    careerPages: careerPages.map(item => Number(item)),
    surveys: surveys.map(item => Number(item)),
  },
});

const getSurveyCareerPageBindList = ({ messages }) => ({
  type: SetupEngageSurveyActionTypes.GET_BIND_SURVEY_CAREER_PAGES,
  messages,
});

const deleteSurveyCareerPageBind = ({ id, messages }) => ({
  type: SetupEngageSurveyActionTypes.DELETE_BIND_SURVEY_CAREER_PAGES,
  messages,
  payload: {
    id,
  },
});

const createSurvey = ({ messages, keycloak }) => ({
  type: SetupEngageSurveyActionTypes.CREATE_SURVEY,
  messages,
  keycloak,
});

const setModalOpen = ({ isOpen }) => ({
  type: SetupEngageSurveyActionTypes.SET_MODAL_OPEN,
  isOpen,
});

const bindAccount = payload => ({
  type: SetupEngageSurveyActionTypes.BIND_ACCOUNT,
  payload,
});

const createClimateEngagementAccount = payload => ({
  type: SetupEngageSurveyActionTypes.CREATE_CLIMATE_ENGAGEMENT_ACCOUNT,
  payload,
});

export {
  SetupEngageSurveyActionTypes,
  getCareerPagesOptionsList,
  getSurveyOptionsList,
  saveBindSurveyCareerPages,
  getSurveyCareerPageBindList,
  deleteSurveyCareerPageBind,
  createSurvey,
  setModalOpen,
  bindAccount,
  createClimateEngagementAccount,
};
