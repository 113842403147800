import { Spinner } from '@gupy/design-system';
import PropTypes from 'prop-types';
import React from 'react';
import './HomeListPlaceholder.scss';

const propTypes = {
  isLoading: PropTypes.bool,
  emptyListMessage: PropTypes.string.isRequired,
  emptyListImage: PropTypes.string.isRequired,
};

const HomeListPlaceholder = ({
  isLoading = false,
  emptyListMessage,
  emptyListImage,
}) => (
  <div className="home-list-box-placeholder">
    {isLoading ? (
      <Spinner color="secondary" />
    ) : (
      <div className="home-list-box-placeholder__content">
        <div className={'home-list-box-placeholder__image'}>
          <img src={emptyListImage} alt={emptyListMessage} />
        </div>
        <div className={'home-list-box-placeholder__label'}>
          {emptyListMessage}
        </div>
      </div>
    )}
  </div>
);

HomeListPlaceholder.propTypes = propTypes;

export default HomeListPlaceholder;
