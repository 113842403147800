// TODO: Possibilitar a criação de ramos com nome igual, por exemplo Engenharia -> rj -> rj ->rj
export const formatCompanyBranchToTreeStruct = companyBranches =>
  companyBranches &&
  companyBranches.map(item => {
    const branchPaths = item.branchLabel.split(' > ');
    const title = branchPaths[branchPaths.length - 1];
    const level = branchPaths.length;
    const branchData = Object.assign({}, item);

    delete branchData.children;

    return {
      ...branchData,
      title,
      level,
    };
  });

export const injectNodeInsideCompanyBranches = ({
  tree,
  level,
  branchLabel,
  items,
}) => {
  if (!level && !branchLabel) {
    return items;
  }

  const parentNode = tree.find(
    node => node.level === level && node.branchLabel === branchLabel,
  );
  if (parentNode) {
    parentNode.children = parentNode.children
      ? parentNode.children.concat(items)
      : items;

    return tree;
  }

  tree
    .filter(node => node.children)
    .forEach(node =>
      injectNodeInsideCompanyBranches({
        tree: node.children,
        level,
        branchLabel,
        items,
      }),
    );

  return tree;
};
