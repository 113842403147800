import { call, put, takeLatest } from 'redux-saga/effects';
import JobService from '../../../../../services/Job/JobService';
import {
  getRegistrationSchemaSuccess,
  JobRegistrationFormActionTypes,
} from './JobRegistrationFormActions';

function* getRegistrationSchema(action) {
  const { jobId } = action;

  try {
    const response = yield JobService.getRegistrationSchema(jobId);
    yield put(getRegistrationSchemaSuccess(response.body));
  } catch (error) {
    yield put({
      type: JobRegistrationFormActionTypes.GET_REGISTRATION_SCHEMA_FAIL,
      error,
    });
  }
}

function* saveRegistrationSchema(action) {
  const { jobId, payload, successCallback } = action;

  try {
    yield JobService.saveRegistrationSchema(jobId, payload);
    yield put({
      type: JobRegistrationFormActionTypes.SAVE_REGISTRATION_SCHEMA_SUCCESS,
      jobId,
    });

    if (successCallback) {
      yield call(successCallback);
    }
  } catch (error) {
    yield put({
      type: JobRegistrationFormActionTypes.SAVE_REGISTRATION_SCHEMA_FAIL,
      error,
    });
  }
}

function* JobSaga() {
  yield takeLatest(
    JobRegistrationFormActionTypes.GET_REGISTRATION_SCHEMA,
    getRegistrationSchema,
  );
  yield takeLatest(
    JobRegistrationFormActionTypes.SAVE_REGISTRATION_SCHEMA,
    saveRegistrationSchema,
  );
}

export default JobSaga;
