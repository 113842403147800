import { combineReducers } from 'redux';
import JobApplicationActionTypes from '../../../../../constants/Job/JobApplication/JobApplicationActionTypes';
import { HiringInformationActionTypes } from './HiringInformationAction';
import { JobOfferAdapter } from './JobOfferAdapter';

const initialModelForm = {
  isLoading: true,
  data: {},
};

export const modelForm = (state = initialModelForm, action) => {
  switch (action.type) {
    case HiringInformationActionTypes.OPEN_HIRING_INFORMATION_MODAL: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.application,
        },
      };
    }
    case HiringInformationActionTypes.GET_JOB_OFFERS_SUCCESS: {
      const [firstOffer] = action.payload.offers;
      const { salary, hiringType, salaryCurrencyType, hiringDate } = state.data;
      const hasDataSaved = Boolean(
        salary || hiringType || salaryCurrencyType || hiringDate,
      );
      if (!hasDataSaved && firstOffer) {
        return {
          isLoading: false,
          data: {
            ...state.data,
            ...JobOfferAdapter.toFormValues(firstOffer),
          },
        };
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case HiringInformationActionTypes.CLOSE_HIRING_INFORMATION_MODAL: {
      return initialModelForm;
    }
    case HiringInformationActionTypes.CHANGE_FORM: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.modelForm,
        },
      };
    }
    case JobApplicationActionTypes.RELOAD_SUCCESS: {
      const applicationUp = action.applications.find(
        ({ id }) => id === state.data.applicationId,
      );
      if (applicationUp && applicationUp.salary) {
        return {
          isLoading: false,
          data: {
            ...state.data,
            salary: applicationUp.salary,
          },
        };
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

const initialHiringTypes = {
  isLoading: true,
  data: [],
};

export const hiringTypes = (state = initialHiringTypes, action) => {
  switch (action.type) {
    case HiringInformationActionTypes.GET_HIRING_TYPES_SUCCESS: {
      return {
        isLoading: false,
        data: action.hiringTypes,
      };
    }
    default:
      return state;
  }
};

const initialVacancyCodes = {
  isLoading: true,
  data: [],
};

export const vacancyCodes = (state = initialVacancyCodes, action) => {
  switch (action.type) {
    case HiringInformationActionTypes.GET_VACANCY_CODES_SUCCESS: {
      return {
        isLoading: false,
        data: action.vacancyCodes,
      };
    }
    case HiringInformationActionTypes.CLOSE_HIRING_INFORMATION_MODAL:
    case HiringInformationActionTypes.GET_VACANCY_CODES_FAIL:
    case JobApplicationActionTypes.PATCH_APPLICATION_SUCCESS: {
      return {
        isLoading: false,
        data: [],
      };
    }
    default:
      return state;
  }
};

export const isHiringInformationModalOpen = (state = false, action) => {
  switch (action.type) {
    case HiringInformationActionTypes.OPEN_HIRING_INFORMATION_MODAL:
      return true;
    case HiringInformationActionTypes.CLOSE_HIRING_INFORMATION_MODAL:
    case JobApplicationActionTypes.PATCH_APPLICATION_SUCCESS:
      return false;
    default:
      return state;
  }
};

const isSaving = (state = false, action) => {
  switch (action.type) {
    case JobApplicationActionTypes.PATCH_APPLICATION:
      return true;
    case JobApplicationActionTypes.PATCH_APPLICATION_SUCCESS:
    case JobApplicationActionTypes.PATCH_APPLICATION_FAIL:
      return false;
    default:
      return state;
  }
};

const isReloadingApplications = (state = false, action) => {
  switch (action.type) {
    case HiringInformationActionTypes.OPEN_HIRING_INFORMATION_MODAL:
      return true;
    case JobApplicationActionTypes.RELOAD_SUCCESS:
    case JobApplicationActionTypes.RELOAD_FAIL:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  isSaving,
  modelForm,
  hiringTypes,
  vacancyCodes,
  isReloadingApplications,
  isHiringInformationModalOpen,
});
