import { put, takeLatest } from 'redux-saga/effects';
import TestProviderStatusEnum from '../../../constants/TestProviderStatusEnum';
import TestProvidersService from '../../../services/TestProviders/TestProvidersService';
import {
  getTestProvidersSuccess,
  saveTokenSuccess,
  setProviderCurrentStatus,
  TestProvidersActionTypes,
} from './TestProvidersActions';

const delay = ms => new Promise(res => setTimeout(res, ms));

export function* getAvailableProviders() {
  try {
    const response = yield TestProvidersService.getAvailableProviders();

    yield put(getTestProvidersSuccess(response.body));
  } catch (error) {
    yield put({
      type: TestProvidersActionTypes.GET_FAIL,
      error,
    });
  }
}

export function* saveToken(action) {
  const { testProviderId, token, availableProviders } = action;
  const { invalid, active, error } = TestProviderStatusEnum;
  try {
    yield TestProvidersService.saveProviderToken({ testProviderId, token });
    yield put(saveTokenSuccess(testProviderId, availableProviders));
    yield delay(1000);
    yield put(
      setProviderCurrentStatus(testProviderId, active, availableProviders),
    );
  } catch (err) {
    const status =
      err && err.body && err.body.message === 'token_cannot_be_empty'
        ? invalid
        : error;

    yield put({
      type: TestProvidersActionTypes.SAVE_TOKEN_FAIL,
      error: err,
    });
    yield put(
      setProviderCurrentStatus(testProviderId, status, availableProviders),
    );
  }
}

function* TestProvidersSaga() {
  yield takeLatest(TestProvidersActionTypes.GET, getAvailableProviders);
  yield takeLatest(TestProvidersActionTypes.SAVE_TOKEN, saveToken);
}

export default TestProvidersSaga;
