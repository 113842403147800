import {
  asyncActionType,
  buildToast,
  createActionTypes,
  ToastTypes,
} from '@gupy/front-commons';

const JobOfferActionTypes = createActionTypes(
  'JobOffer',
  'SELECT_OFFER_BY_ID',
  'CLEAR_SELECTED_OFFER_ID',
  asyncActionType('GET_ALL'),
  asyncActionType('DELETE'),
  asyncActionType('CANCEL'),
  asyncActionType('DOWNLOAD'),
);

export const selectOfferById = selectedOfferId => ({
  type: JobOfferActionTypes.SELECT_OFFER_BY_ID,
  payload: {
    selectedOfferId,
  },
});

export const clearSelectedOfferId = () => ({
  type: JobOfferActionTypes.CLEAR_SELECTED_OFFER_ID,
});

const deleteJobOffer = ({ id, messages }) => ({
  type: JobOfferActionTypes.DELETE,
  id,
  messages,
});

const cancelJobOffer = ({ id, messages }) => ({
  type: JobOfferActionTypes.CANCEL,
  id,
  messages,
});

const getAllJobOffers = ({ messages }) => ({
  type: JobOfferActionTypes.GET_ALL,
  messages,
});

const downloadJobOffer = ({
  offer,
  fileName,
  messages,
  isNewGeneratePdfOfferLetterEnabled,
}) => ({
  type: JobOfferActionTypes.DOWNLOAD,
  offer,
  messages,
  fileName,
  isNewGeneratePdfOfferLetterEnabled,
  toast: buildToast(messages.processingJobOffer, ToastTypes.info),
});

export {
  JobOfferActionTypes,
  deleteJobOffer,
  cancelJobOffer,
  getAllJobOffers,
  downloadJobOffer,
};
