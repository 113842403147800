import { buildToast, ToastTypes } from '@gupy/front-commons';
import html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { JobOfferActionTypes } from './JobOfferActions';
import { selectCandidate, selectJob } from './JobOfferSelectors';
import JobOfferService from './JobOfferService';

function* getAllJobOffers() {
  const [job, candidate] = yield all([
    select(selectJob),
    select(selectCandidate),
  ]);
  try {
    const response = yield JobOfferService.get({ job, candidate });
    yield put({
      type: JobOfferActionTypes.GET_ALL_SUCCESS,
      jobOffers: response.body.offers,
    });
  } catch (error) {
    yield put({
      type: JobOfferActionTypes.GET_ALL_FAIL,
      toast: buildToast(error.body.message, ToastTypes.error),
      error,
    });
  }
}

function generateWithHtml2pdf({ fileName, content }) {
  const options = {
    filename: fileName,
    margin: [0.93, 0.62],
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2, useCORS: true, letterRendering: true },
    jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
  };

  html2pdf()
    .set(options)
    .set({ pagebreak: { mode: ['avoid-all', 'css', 'legacy', 'whiteline'] } })
    .from(content)
    .save();
}

function generateWithJspdf({ fileName, container }) {
  const pageFormat = 'a4';
  const pageOrientation = 'p';
  const pdfOptions = {
    compress: true,
    format: pageFormat,
    hotfixes: ['px_scaling'],
    orientation: pageOrientation,
    unit: 'px',
    precision: 100,
  };

  // eslint-disable-next-line new-cap
  const doc = new jsPDF(pdfOptions);
  const pageMarginX = 60;
  const pageMarginY = 90;
  const pageWidth = doc.internal.pageSize.width - pageMarginX * 2;

  doc.html(container, {
    margin: [pageMarginY, pageMarginX, pageMarginY, pageMarginX],
    width: pageWidth,
    windowWidth: pageWidth,
    callback: () => {
      doc.save(fileName);
    },
  });
}

function* generateFile({
  offer,
  fileName,
  messages,
  isNewGeneratePdfOfferLetterEnabled,
}) {
  try {
    const container = document.createElement('div');
    const content = document.createElement('div');
    content.innerHTML = offer.body;
    container.appendChild(content);

    if (isNewGeneratePdfOfferLetterEnabled) {
      generateWithHtml2pdf({ fileName, content });
    } else {
      generateWithJspdf({ fileName, container });
    }

    yield put({
      type: JobOfferActionTypes.DOWNLOAD_SUCCESS,
      toastUpdate: buildToast(messages.jobOfferProcessed, ToastTypes.sucess),
    });
  } catch (error) {
    yield put({
      type: JobOfferActionTypes.DOWNLOAD_FAIL,
      toast: buildToast(messages.jobOfferNotProcessed, ToastTypes.error),
    });
  }
}

function* deleteJobOffer({ id, messages }) {
  try {
    yield JobOfferService.delete(id);

    yield put({
      type: JobOfferActionTypes.DELETE_SUCCESS,
      toast: buildToast(messages.deletedJobOffer, ToastTypes.success),
    });
  } catch (error) {
    yield put({
      type: JobOfferActionTypes.DELETE_FAIL,
      toast: buildToast(error.body.message, ToastTypes.error),
      error,
    });
  }
}

function* cancelJobOffer({ id, messages }) {
  try {
    yield JobOfferService.cancel(id);

    yield put({
      type: JobOfferActionTypes.CANCEL_SUCCESS,
      toast: buildToast(messages.cancelJobOffer, ToastTypes.success),
    });
  } catch (error) {
    yield put({
      type: JobOfferActionTypes.CANCEL_FAIL,
      toast: buildToast(error.body.message, ToastTypes.error),
      error,
    });
  }
}

export default function* JobOfferSaga() {
  yield takeLatest(
    [
      JobOfferActionTypes.GET_ALL,
      JobOfferActionTypes.SAVE_SUCCESS,
      JobOfferActionTypes.DELETE_SUCCESS,
      JobOfferActionTypes.CANCEL_SUCCESS,
    ],
    getAllJobOffers,
  );
  yield takeLatest(JobOfferActionTypes.DELETE, deleteJobOffer);
  yield takeLatest(JobOfferActionTypes.CANCEL, cancelJobOffer);
  yield takeLatest(JobOfferActionTypes.DOWNLOAD, generateFile);
}
