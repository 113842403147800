import { asyncActionType, createActionTypes } from '@gupy/front-commons';

export default createActionTypes(
  'ProfileTest',
  asyncActionType('GET'),
  asyncActionType('GET_AVERAGE'),
  asyncActionType('GET_MATCHING'),
  asyncActionType('GET_USER_TEST'),
  asyncActionType('GET_DIAGNOSTIC'),
  asyncActionType('SWITCH_COMPATIBILITY_STATUS'),
  'TOPIC_GO_NEXT',
  'GO_PREVIOUS',
  'CHANGE_ANSWER',
  asyncActionType('GET_PROFILE_TEST_STATISTICS'),
  asyncActionType('GET_PROFILE_TEST_STATISTICS_SUCCESS'),
  asyncActionType('GET_PROFILE_TEST_STATISTICS_FAIL'),
  asyncActionType('SAVE_QUESTION'),
  asyncActionType('FINISH_TEST'),
  asyncActionType('CLOSE_TEST'),
  'CHANGE_GROUP',
  'CHANGE_GROUP_ITEM',
);
