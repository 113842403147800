import { buildToast, ToastTypes } from '@gupy/front-commons';
import { put, takeLatest } from 'redux-saga/effects';
import ActionTypes from '../../constants/Inbound/InboundActionTypes';
import * as Service from '../../services/Inbound/InboundService';

function* getSegmentedLists() {
  try {
    const response = yield Service.getSegmentedLists();

    yield put({
      type: ActionTypes.GET_SEGMENTED_LISTS_SUCCESS,
      segmentedLists: response.segmentedLists.map(segmentedList => ({
        value: segmentedList.id.toString(),
        label: segmentedList.name,
      })),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_SEGMENTED_LISTS_FAIL,
    });
  }
}

function* postTalentsToLists(action) {
  const { data, messages } = action;
  try {
    const response = yield Service.postTalentsToLists(data);
    yield put({
      type: ActionTypes.POST_TALENTS_TO_LIST_SUCCESS,
      data: response,
      toast: buildToast(messages.addToListSuccess, ToastTypes.success),
    });
  } catch (err) {
    yield put({
      type: ActionTypes.POST_TALENTS_TO_LIST_FAIL,
      toast: buildToast(messages.addToListError, ToastTypes.error),
    });
  }
}

function* InboundConfigSaga() {
  yield takeLatest(ActionTypes.GET_SEGMENTED_LISTS, getSegmentedLists);
  yield takeLatest(ActionTypes.POST_TALENTS_TO_LIST, postTalentsToLists);
}

export default InboundConfigSaga;
