import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  notificationsButton: {
    id: 'notifications-button',
    defaultMessage: 'Notificações',
  },
  notificationsButtonLabel: {
    id: 'notifications-button-label',
    defaultMessage: 'Ver notificações',
  },
  fixedNotificationAlertEnabledV2Title: {
    id: 'fixedNotificationAlertEnabledV2Title',
    defaultMessage: 'A partir de ',
  },
  fixedNotificationAlertEnabledV2Date: {
    id: 'fixedNotificationAlertEnabledV2Date',
    defaultMessage: '28/10/2022 as ',
  },
  fixedNotificationAlertEnabledV2TextBold: {
    id: 'fixedNotificationAlertEnabledV2TextBold',
    defaultMessage: 'notificações abaixo não estarão mais disponíveis.',
  },
});

export const getNotificationsMessages = intl => formatMessages(intl, messages);
