import { buildToast, ToastTypes } from '@gupy/front-commons';
import { put, takeLatest } from 'redux-saga/effects';
import DepartmentActionTypes from '../../constants/Department/DepartmentActionTypes';
import DepartmentForm from '../../forms/Department/DepartmentForm';
import DepartmentService from '../../services/Department/DepartmentService';

function* getAll() {
  const form = new DepartmentForm();

  try {
    const response = yield DepartmentService.getAll();

    yield put({
      type: DepartmentActionTypes.GET_ALL_SUCCESS,
      departments: response.body.data,
    });
  } catch (error) {
    yield put({
      type: DepartmentActionTypes.GET_ALL_FAIL,
      validation: form.validateErrorResponse(error),
    });
  }
}

function* getAllSimilarities() {
  try {
    const response = yield DepartmentService.getAllSimilarities();

    yield put({
      type: DepartmentActionTypes.GET_ALL_SIMILARITIES_SUCCESS,
      similarities: response.body.data,
    });
  } catch (error) {
    yield put({
      type: DepartmentActionTypes.GET_ALL_SIMILARITIES_FAIL,
    });
  }
}

function* post(action) {
  const { payload, messages } = action;
  const form = new DepartmentForm();

  form.populate(payload);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: DepartmentActionTypes.POST_FAIL,
      validation,
    });
  } else {
    try {
      yield DepartmentService.post(payload);

      yield put({
        type: DepartmentActionTypes.POST_SUCCESS,
        toast: buildToast(messages.success, ToastTypes.success),
      });
    } catch (error) {
      yield put({
        type: DepartmentActionTypes.POST_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* update(action) {
  const { payload, messages } = action;
  const form = new DepartmentForm();

  const { id } = payload;
  const newPayload = { ...payload, id: undefined };

  form.populate(newPayload);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: DepartmentActionTypes.PUT_FAIL,
      validation,
    });
  } else {
    try {
      yield DepartmentService.put(id, newPayload);

      yield put({
        type: DepartmentActionTypes.PUT_SUCCESS,
        toast: buildToast(messages.success, ToastTypes.success),
      });
    } catch (error) {
      yield put({
        type: DepartmentActionTypes.PUT_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* del(action) {
  const { id, messages } = action;

  try {
    yield DepartmentService.delete(id);

    yield put({
      type: DepartmentActionTypes.DELETE_SUCCESS,
      toast: buildToast(messages.success, ToastTypes.success),
    });
  } catch (error) {
    const form = new DepartmentForm();
    yield put({
      type: DepartmentActionTypes.DELETE_FAIL,
      validation: form.validateErrorResponse(error),
      toast: buildToast(error.body.message, ToastTypes.error),
    });
  }
}

function* departmentSaga() {
  yield takeLatest(DepartmentActionTypes.GET_ALL, getAll);
  yield takeLatest(
    DepartmentActionTypes.GET_ALL_SIMILARITIES,
    getAllSimilarities,
  );
  yield takeLatest(DepartmentActionTypes.POST, post);
  yield takeLatest(DepartmentActionTypes.POST_SUCCESS, getAll);
  yield takeLatest(DepartmentActionTypes.PUT, update);
  yield takeLatest(DepartmentActionTypes.PUT_SUCCESS, getAll);
  yield takeLatest(DepartmentActionTypes.DELETE, del);
  yield takeLatest(DepartmentActionTypes.DELETE_SUCCESS, getAll);
}

export default departmentSaga;
