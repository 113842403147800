import { Icon, IconType } from '@gupy/design-system-v2';
import { StringHelper } from '@gupy/front-helpers';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import UserNotificationsContainerV2 from '../../containers/Notifications/UserNotificationsContainerV2';
import MenuContent from './MenuContent';
import MenuContentData from './MenuContentData';
import { getMessages } from './Messages';
import SidebarWrapperStyles from './SidebarWrapper.styles';

const propTypes = {
  currentUser: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

const SidebarWrapper = ({
  currentUser: { name, email, urlProfilePicture, permissions, language },
  data,
  intl,
  isMobileSidebarOpen,
  setMobileSidebarOpen,
}) => {
  const {
    Sidebar,
    Header,
    Profile,
    Avatar,
    Name,
    Email,
    LogoutButton,
    ProfileInfo,
  } = SidebarWrapperStyles;
  const messages = getMessages(intl);
  const iconBaseURL = `${process.env.REACT_APP_API_CENTRAL_TALENTOS}/sidebar-icons`;
  const infoSection = data && data.profileStyles.infoSectionStyles;
  const menuContentData = MenuContentData(messages, permissions, language);
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const currentUserInitials = StringHelper.getInitials(name);
  const expandedSidebarWidth =
    data && data.containerStyles.expandedOnHoverStyles.width;
  const { zE } = window;
  const isDesktop = window.innerWidth > 1024;
  const mouseOver = useRef(false);

  const handleMouseEnter = () => {
    if (!isDesktop) return;
    mouseOver.current = true;
    setTimeout(() => {
      if (mouseOver.current) {
        setSidebarExpanded(true);

        if (zE) {
          zE.hide();
        }
      }
    }, 500);
  };

  const handleMouseLeave = () => {
    if (!isDesktop) return;
    mouseOver.current = false;
    setSidebarExpanded(false);
    if (zE) {
      zE.show();
    }
  };

  let mobileSidebarDisplay;
  if (isMobileSidebarOpen) {
    mobileSidebarDisplay = 'block';
  }

  useEffect(() => {
    if (isMobileSidebarOpen) {
      setSidebarExpanded(true);
    }
  }, [isMobileSidebarOpen]);

  const ref = useRef();
  useEffect(() => {
    const checkIfClickedOutsideMobileSidebar = e => {
      if (
        isMobileSidebarOpen &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setMobileSidebarOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutsideMobileSidebar);
  });

  return data ? (
    <Sidebar
      ref={ref}
      data-testid="sidebar-ecosystem"
      {...data.containerStyles}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        display: mobileSidebarDisplay,
        width: !isMobileSidebarOpen && sidebarExpanded && expandedSidebarWidth,
      }}
    >
      <Header {...data.headerStyles}>
        {sidebarExpanded && (
          <img
            width="140"
            height="42"
            src={`${process.env.REACT_APP_ASSETS_URL}/sidebar/logo-R&S.svg`}
            alt={messages.productRecruitmentAndSelectionAlt}
          />
        )}
        <UserNotificationsContainerV2
          isContainerExpanded={sidebarExpanded}
          classes="sidebar-notifications"
        />
      </Header>
      <Profile
        {...data.profileStyles}
        aria-label={
          <FormattedHTMLMessage
            id="profile-label"
            defaultMessage="Conta da Gupy de {userName} com email {userEmail}"
            values={{ userName: name, userEmail: email }}
          />
        }
      >
        <ProfileInfo {...infoSection}>
          <Avatar {...data.avatarStyles}>
            {urlProfilePicture ? (
              <img src={urlProfilePicture} alt="user profile" />
            ) : (
              currentUserInitials
            )}
          </Avatar>
          {sidebarExpanded && (
            <div>
              <Name {...data.userNameStyles}>{name}</Name>
              <Email {...data.userEmailStyles}>{email}</Email>
            </div>
          )}
        </ProfileInfo>

        {sidebarExpanded && (
          <a href={`${process.env.REACT_APP_COMPANIES_PUBLIC_URL}/profile#`}>
            <Icon type={IconType.ArrowForward} />
          </a>
        )}
      </Profile>
      <MenuContent
        isSidebarExpanded={sidebarExpanded}
        styles={data.itemStyles}
        content={menuContentData}
      />
      <LogoutButton
        styles={data.itemStyles}
        isSidebarExpanded={sidebarExpanded}
        linkUrl={`${process.env.REACT_APP_COMPANIES_PUBLIC_URL}/signout`}
        itemTitle={messages.logout}
        icon={`${iconBaseURL}/icon-logout.svg`}
      />
    </Sidebar>
  ) : null;
};

SidebarWrapper.propTypes = propTypes;

export default injectIntl(SidebarWrapper);
