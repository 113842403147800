import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { setError500 } from '../../actions/Error/ErrorActions';
import LocalstorageManager from '../../managers/LocalstorageManager';
import Error500 from '../Error/500';

const propTypes = {
  Authentication: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  setError500: PropTypes.func.isRequired,
};

class PrivateRouteContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  // eslint-disable-next-line camelcase
  unstable_handleError(error) {
    this.setState({ hasError: true });
    this.props.setError500(error);
    // eslint-disable-next-line no-console
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      return <Error500 />;
    }

    const { children, Authentication, location } = this.props;

    if (Authentication.isAuthenticated) {
      return children;
    }

    const localStorageManager = new LocalstorageManager(window.localStorage);

    if (localStorageManager.hasCompanyToken()) {
      return (
        <Redirect
          to={{
            pathname: '/companies/auth',
            state: { from: location },
          }}
        />
      );
    }

    return (
      <Redirect
        to={{
          pathname: '/companies/signin',
          state: { from: location },
        }}
      />
    );
  }
}

PrivateRouteContent.propTypes = propTypes;

const mapStateToProps = state => ({
  Authentication: state.reducers.Authentication,
});

export default withRouter(
  connect(mapStateToProps, {
    setError500,
  })(PrivateRouteContent),
);
