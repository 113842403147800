import { Chip } from '@gupy/design-system';
import React from 'react';
import childAppPropTypes from '../../../../prop-types/childAppPropTypes';

const ChildAppStatus = ({ childApp }) => (
  <Chip color={childApp.getStatusColor()}>
    {childApp.getStatusIcon()}
    {childApp.getStatusLabel()}
  </Chip>
);

ChildAppStatus.propTypes = {
  childApp: childAppPropTypes.isRequired,
};

export default ChildAppStatus;
