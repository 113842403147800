import { call, put, takeLatest } from 'redux-saga/effects';
import JobStepFinalActionTypes from '../../../constants/Job/JobStep/JobStepFinalActionTypes';
import JobStepFinalForm from '../../../forms/Job/JobStep/JobStepFinalForm';
import history from '../../../history';
import JobService from '../../../services/Job/JobService';

function* getJobStepFinal(action) {
  const form = new JobStepFinalForm();

  try {
    const response = yield JobService.getJobStep(action.jobId, action.stepId);

    yield put({
      type: JobStepFinalActionTypes.GET_SUCCESS,
      modelForm: response.body,
    });
  } catch (error) {
    yield put({
      type: JobStepFinalActionTypes.GET_FAIL,
      validation: form.validateErrorResponse(error),
    });
  }
}

function* patchJobStepFinal(action) {
  const { payload } = action;
  const form = new JobStepFinalForm();

  form.populate(payload);

  try {
    const response = yield JobService.patchStep(payload);

    yield put({
      type: JobStepFinalActionTypes.PATCH_SUCCESS,
      modelForm: response.body,
    });
    yield call(history.push, `/companies/jobs/edit/${payload.jobId}/steps`);
  } catch (error) {
    yield put({
      type: JobStepFinalActionTypes.PATCH_FAIL,
      validation: form.validateErrorResponse(error),
    });
  }
}

function* JobStepFinalSaga() {
  yield takeLatest(JobStepFinalActionTypes.GET, getJobStepFinal);
  yield takeLatest(JobStepFinalActionTypes.PATCH, patchJobStepFinal);
}

export default JobStepFinalSaga;
