import { Card, Chip, MenuButton } from '@gupy/design-system';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import permissionsEnum from '../../Authentication/PermissionsEnum';
import './UserAccessProfileCard.scss';

const propTypes = {
  userAccessProfile: PropTypes.object.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  messages: PropTypes.object.isRequired,
  shouldShowDiversityChip: PropTypes.bool.isRequired,
};

const UserAccessProfileCard = ({
  userAccessProfile,
  onDeleteClick,
  onEditClick,
  canEdit,
  canDelete,
  messages,
  shouldShowDiversityChip,
}) => {
  const actionOptions = [];
  const onEditClickProp = {};
  if (canEdit) {
    onEditClickProp.onClick = () => {
      onEditClick(userAccessProfile.id);
    };

    actionOptions.push({
      action: () => {
        onEditClick(userAccessProfile.id);
      },
      title: messages.edit,
    });
  }
  if (canDelete) {
    actionOptions.push({
      action: () => {
        onDeleteClick(userAccessProfile.id);
      },
      title: messages.delete,
    });
  }

  const userAccessProfileCreationDate = moment
    .utc(userAccessProfile.createdAt)
    .format('DD/MM/YYYY');
  const creationInfoNode = (
    <FormattedHTMLMessage
      id="created_by_access_profile_card"
      defaultMessage="Criado por <b>{name}</b> em <b>{when}</b>"
      values={{
        name: userAccessProfile.creator
          ? userAccessProfile.creator.name
          : 'Gupy',
        when: userAccessProfileCreationDate,
      }}
    />
  );
  const hasGroupTypes =
    userAccessProfile.groupType && userAccessProfile.groupType.length;

  const groupTypeNode = (
    <div className="user-access-profile-card__group-type">
      {hasGroupTypes ? (
        userAccessProfile.groupType
          .sort()
          .map(groupType => (
            <Chip color="blueSecondary">{messages[groupType]}</Chip>
          ))
      ) : (
        <Chip color="gray">{messages.groupTypeNotDefined}</Chip>
      )}
    </div>
  );

  const hasDiversityPermissions =
    userAccessProfile.permissions &&
    (userAccessProfile.permissions.includes(
      permissionsEnum.view_diversity_information,
    ) ||
      userAccessProfile.permissions.includes(
        permissionsEnum.view_diversity_dashboard,
      ) ||
      userAccessProfile.permissions.includes(
        permissionsEnum.apply_diversity_selection_process_filter,
      ) ||
      userAccessProfile.permissions.includes(
        permissionsEnum.apply_diversity_filter,
      ));

  const diversityChip = shouldShowDiversityChip && hasDiversityPermissions && (
    <div className="user-access-profile-card__diversity">
      <Chip diversity>{messages.diversity}</Chip>
    </div>
  );

  return (
    <Card padding="lg" className="user-access-profile-card">
      <React.Fragment>
        <div className="user-access-profile-card__content">
          <div
            {...onEditClickProp}
            className={classNames('user-access-profile-card__title', {
              'user-access-profile-card__title-disabled': !canEdit,
            })}
          >
            {userAccessProfile.name}
          </div>

          <MenuButton
            id={`${userAccessProfile.id}-user-access-profile-actions`}
            options={actionOptions}
          />
        </div>
        {groupTypeNode}
        {diversityChip}
        <div className="user-access-profile-card__subtitle">
          {creationInfoNode}
        </div>
      </React.Fragment>
    </Card>
  );
};

UserAccessProfileCard.propTypes = propTypes;

export default UserAccessProfileCard;
