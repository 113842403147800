import { Button, Grid, InputGroup } from '@gupy/design-system';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { intlShape } from 'react-intl';
import {
  ActionGAEnum,
  CategoryGAEnum,
} from '../../../../../../../src/constants/Job/JobApplication/JobApplicationGAEnum';
import { withSendGAEvent } from '../../../../../../helpers/withSendGAEvent';
import getLabels from '../CandidateInsertion.labels';

const propTypes = {
  disabled: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  modelForm: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onVerifyEmail: PropTypes.func.isRequired,
};

class EmailVerificationForm extends Component {
  labels = {};

  constructor(props) {
    super(props);

    this.labels = getLabels(props.intl);
  }

  handleFormSubmit = event => {
    event.preventDefault();
    this.props.onVerifyEmail();
  };

  handleStartClick = () => {
    withSendGAEvent(
      CategoryGAEnum.jobApplicationsAddCandidate,
      ActionGAEnum.jobApplicationsStartAddCandidate,
      () => this.props.onVerifyEmail(),
    )();
  };

  render() {
    const { disabled, modelForm, onChange } = this.props;

    return (
      <form
        onSubmit={this.handleFormSubmit}
        noValidate
        className="candidate-insertion-modal__form"
      >
        <Grid container spacing={40}>
          <Grid item xs={12}>
            <InputGroup
              id="email"
              label={this.labels.candidateEmail}
              placeholder="email@email.com"
              onChange={onChange}
              value={modelForm.email}
              required
              disabled={disabled}
              block
            />
          </Grid>
          <Grid item xs={12} container justify="center">
            <Button disabled={disabled} onClick={this.handleStartClick} submit>
              {this.labels.start}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

EmailVerificationForm.propTypes = propTypes;

export default EmailVerificationForm;
