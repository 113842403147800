import { InputText } from '@gupy/design-system-v2';
import PropTypes from 'prop-types';
import React from 'react';
import childAppPropTypes from '../../../../prop-types/childAppPropTypes';
import './ChildAppName.scss';

const RscLinkedinIntegrationChildAppsName = ({
  editMode,
  childApp,
  childAppName,
  setChildAppName,
  messages,
}) => {
  const renderOnEditMode = () => {
    const validationErrorMessage =
      childApp.getFormValidationErrorForField('name');

    const shouldShowValidationErrorMessage =
      !!validationErrorMessage &&
      childAppName.toLowerCase() ===
        childApp.nameWithFormValidationErrors.toLowerCase();

    const handleNameChange = event => setChildAppName(event.target.value);

    return (
      <span className="job-board-panel-rsc-child-apps__name-on-edit-mode">
        <InputText
          id={`child-app-${childApp.id}-name`}
          name={`child-app-${childApp.id}-name`}
          value={childAppName}
          onChange={handleNameChange}
          aria-label={messages.rscIntegrationChildAppsLinkedinPageLabel}
          placeholder={messages.rscIntegrationChildAppsLinkedinPagePlaceholder}
          {...(shouldShowValidationErrorMessage && {
            ariaLiveError: true,
            className: 'error',
            hasError: true,
            helperMessage: validationErrorMessage,
          })}
        />
      </span>
    );
  };

  const renderOnReadMode = () => (
    <span className="job-board-panel-rsc-child-apps__name-on-read-mode">
      {childAppName}
    </span>
  );

  return editMode ? renderOnEditMode() : renderOnReadMode();
};

RscLinkedinIntegrationChildAppsName.propTypes = {
  editMode: PropTypes.bool.isRequired,
  childApp: childAppPropTypes.isRequired,
  childAppName: PropTypes.string.isRequired,
  setChildAppName: PropTypes.func.isRequired,
  messages: PropTypes.object.isRequired,
};

export default RscLinkedinIntegrationChildAppsName;
