import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  deleteTestTitle: {
    id: 'delete_question_title',
    defaultMessage: 'Você deseja excluir esse teste customizado?',
  },
  deleteTestMessage: {
    id: 'delete_question_message',
    defaultMessage: 'Você confirma a exclusão do teste?',
  },
  deleteTestMessageInUse: {
    id: 'delete_question_message_in_use',
    defaultMessage:
      'Esse teste não pode ser excluído pois está vinculado à uma vaga em aberto.',
  },
  deleteTestConfirm: {
    id: 'delete_question_confirmation',
    defaultMessage: 'Sim, excluir',
  },
  deleteTestCancel: {
    id: 'delete_question_cancelation',
    defaultMessage: 'Cancelar',
  },
  deleteTestSuccess: {
    id: 'delete_question_success_message',
    defaultMessage: 'Teste deletado com sucesso.',
  },
  deleteTestError: {
    id: 'delete_question_error_message',
    defaultMessage: 'Erro ao deletar teste.',
  },
  deleteTestErrorTitle: {
    id: 'delete_question_error_in_use_tile',
    defaultMessage: 'Não é possível excluir esse teste customizado',
  },
  selectToDeleteTestTitle: {
    id: 'selectToDelete_question_title',
    defaultMessage: 'Apagar teste',
  },
  selectToDeleteTestMessage: {
    id: 'selectToDelete_question_message',
    defaultMessage:
      'Esse teste não pode ser selecionado para exclusão pois está vinculado à uma vaga em aberto.',
  },
  duplicateTestTitle: {
    id: 'duplicate_question_title',
    defaultMessage: 'Duplicar Teste',
  },
  duplicateTestMessage: {
    id: 'duplicate_question_message',
    defaultMessage: 'Você confirma a duplicação do teste?',
  },
  duplicateTestConfirm: {
    id: 'duplicate_question_confirmation',
    defaultMessage: 'Duplicar',
  },
  duplicateTestCancel: {
    id: 'duplicate_question_cancelation',
    defaultMessage: 'Cancelar',
  },
  duplicateTestSuccess: {
    id: 'duplicate_question_success_message',
    defaultMessage: 'Teste duplicado com sucesso.',
  },
  duplicateTestError: {
    id: 'duplicate_question_error_message',
    defaultMessage: 'Erro ao duplicar teste.',
  },
  editTestTitle: {
    id: 'edit_question_title',
    defaultMessage: 'Editar teste',
  },
  editTestMessage: {
    id: 'edit_question_message',
    defaultMessage:
      'Esse teste está vinculado à uma vaga em aberto, qualquer alteração pode comprometer o processo.',
  },
  editTestConfirm: {
    id: 'edit_question_confirmation',
    defaultMessage: 'Editar mesmo assim',
  },
  editTestCancel: {
    id: 'edit_question_cancelation',
    defaultMessage: 'Cancelar',
  },
  searchTest: {
    id: 'search-test',
    defaultMessage: 'Buscar teste',
  },
  createNewTest: {
    id: 'create-new-test',
    defaultMessage: 'Criar novo teste',
  },
  noCustomTestFoundMessage: {
    id: 'custom-tests-search-not-found',
    defaultMessage:
      'Nenhum teste correspondeu à sua pesquisa. Tente com outro nome.',
  },
  noCustomTestRegisteredMessage: {
    id: 'custom-tests-no-tests-registered',
    defaultMessage: 'Ainda não há testes a serem exibidos, tente cadastrar um.',
  },
  customTestsRepackagingButtonMessage: {
    id: 'custom_tests_repackaging_know_more',
    defaultMessage: 'Quero saber mais',
  },
  customTestsRepackagingDescription: {
    id: 'custom_tests_repackaging_description',
    defaultMessage:
      'Crie seus próprios testes de acordo com a necessidade do seu processo seletivo e avalie os conhecimentos que você busca para encontrar a pessoa candidata ideal.',
  },
  customTestesRepackagingTitle: {
    id: 'custom_tests_repackaging_title',
    defaultMessage: 'Testes Customizados',
  },
});

export const getMessages = intl => formatMessages(intl, messages);
