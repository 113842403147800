import { combineReducers } from 'redux';
import * as ActionTypes from './ActionTypes';

const initialState = {
  lowApplicationJobs: [],
  descriptionErrorJobs: [],
  lowApplicationJobsGeneratedAt: null,
  descriptionErrorJobsGeneratedAt: null,
  isClosedModalThisWeeklowApplicationJobs: false,
  isClosedModalThisWeekDescriptionErrors: false,
};

const jobsWithQualifiedApplications = {
  jobs: [],
  isClosed: false,
};

const jobsCloseToExpire = {
  jobs: [],
  isClosed: false,
};

export default combineReducers({
  insights: (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.GET_INSIGHTS_SUCCESS:
        return { ...state, ...action.payload };
      case ActionTypes.CLOSE_LOW_APPLICATION_INSIGHTS_SUCCESS:
        return {
          ...state,
          isClosedModalThisWeeklowApplicationJobs: true,
        };
      case ActionTypes.CLOSE_DESCRIPTION_ERRORS_INSIGHTS_SUCCESS:
        return {
          ...state,
          isClosedModalThisWeekDescriptionErrors: true,
        };
      default:
        return state;
    }
  },
  jobsWithQualifiedApplications: (
    state = jobsWithQualifiedApplications,
    { type, payload },
  ) => {
    switch (type) {
      case ActionTypes.GET_JOBS_WITH_QUALIFIED_APPLICATIONS_SUCCESS:
        return { ...state, ...payload };
      case ActionTypes.CLOSE_JOBS_WITH_QUALIFIED_APPLICATIONS_SUCCESS:
        return { ...state, ...payload };
      default:
        return state;
    }
  },
  jobsCloseToExpire: (state = jobsCloseToExpire, { type, payload }) => {
    switch (type) {
      case ActionTypes.GET_JOBS_CLOSE_TO_EXPIRE_SUCCESS:
        return { ...state, ...payload };
      case ActionTypes.CLOSE_JOBS_CLOSE_TO_EXPIRE_SUCCESS:
        return { ...state, ...payload };
      default:
        return state;
    }
  },
});
