import { FooterButtons } from '@gupy/design-system';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SetupGridLayout } from '../../CustomTestComponents';
import CustomTestForm from '../../forms/CustomTestForm';
import {
  createCompanyCustomTest,
  customTestModelClear,
  onCustomTestModelUpdate,
} from '../../redux/CustomTest/CustomTestActions';
import { getMessages } from './TestCreateMessages';

class TestsCreate extends React.Component {
  static propTypes = {
    Authentication: PropTypes.object.isRequired,
    CustomTest: PropTypes.object.isRequired,
    clearModel: PropTypes.func.isRequired,
    createCustomTest: PropTypes.func.isRequired,
    onModelUpdate: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleFieldUpdate = this.handleFieldUpdate.bind(this);
    this.handleSaveTest = this.handleSaveTest.bind(this);
  }

  componentDidMount() {
    this.props.clearModel();
  }

  handleFieldUpdate(event) {
    const { onModelUpdate } = this.props;
    const { name, value } = event.target;
    onModelUpdate({ [name]: value });
  }

  handleSaveTest() {
    const { CustomTest, createCustomTest, intl } = this.props;
    const messages = defineMessages({
      createTestSuccess: {
        id: 'create_custom_test_success_message',
        defaultMessage:
          'Teste criado com sucesso. Agora insira as questões do seu teste.',
      },
      createTestError: {
        id: 'create_custom_test_error_message',
        defaultMessage: 'Erro ao criar teste.',
      },
    });
    createCustomTest(CustomTest.modelForm, {
      success: intl.formatMessage(messages.createTestSuccess),
      error: intl.formatMessage(messages.createTestError),
    });
  }

  render() {
    const { Authentication, CustomTest, intl } = this.props;

    const messages = getMessages(intl);

    return (
      <SetupGridLayout
        Authentication={Authentication}
        title={messages.createTestGridContentTitle}
      >
        <section>
          <CustomTestForm
            isSaving={false}
            isRandomQuestions={CustomTest.data.isRandomQuestions}
            model={CustomTest.modelForm}
            onFieldChange={this.handleFieldUpdate}
            validation={CustomTest.validation}
          />
          <div className="toolbar toolbar--career">
            <div className="toolbar__right">
              <FooterButtons
                buttons={[
                  {
                    onClick: this.handleSaveTest,
                    id: 'save-test-button',
                    text: messages.saveButton,
                  },
                ]}
              />
            </div>
          </div>
        </section>
      </SetupGridLayout>
    );
  }
}

const mapStateToProps = state => ({
  Authentication: state.reducers.Authentication,
  CustomTest: state.reducers.CustomTest,
});

export default withRouter(
  injectIntl(
    connect(mapStateToProps, {
      onModelUpdate: onCustomTestModelUpdate,
      createCustomTest: createCompanyCustomTest,
      clearModel: customTestModelClear,
    })(TestsCreate),
  ),
);
