import { buildToast, ToastTypes } from '@gupy/front-commons';
import { call, put, takeLatest } from 'redux-saga/effects';
import history from '../../../../history';
import { TestsPath } from '../../CustomTestRoutes';
import CustomTestQuestionActionTypes from './CustomTestQuestionActionTypes';
import CustomTestQuestionService from './CustomTestQuestionService';
import CustomTestQuestionForm from './CustomTestQuestionValidation';

function* create(action) {
  const { payload, testId, messages } = action;
  const form = new CustomTestQuestionForm();
  const model = Object.assign(
    {},
    {
      ...payload,
    },
  );
  form.populate(model);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: CustomTestQuestionActionTypes.CREATE_FAIL,
      validation,
    });
  } else {
    try {
      const response = yield CustomTestQuestionService.create(testId, {
        ...model,
        weight: parseInt(model.weight || '1', 10),
      });
      yield put({
        question: response.body,
        type: CustomTestQuestionActionTypes.CREATE_SUCCESS,
        toast: buildToast(messages.success, ToastTypes.success),
      });
    } catch (error) {
      yield put({
        type: CustomTestQuestionActionTypes.CREATE_FAIL,
        validation: form.validateErrorResponse(error),
        toast: buildToast(error.body.message, ToastTypes.error),
      });
    }
  }
}

function* read(action) {
  try {
    const { id, testId } = action;
    const response = yield CustomTestQuestionService.read(id, testId);
    yield put({
      type: CustomTestQuestionActionTypes.READ_SUCCESS,
      CustomTestQuestion: response.body.data,
    });
  } catch (error) {
    yield put({
      type: CustomTestQuestionActionTypes.READ_FAIL,
    });
  }
}

function* detail(action) {
  try {
    const response = yield CustomTestQuestionService.detail(action.id);
    yield put({
      type: CustomTestQuestionActionTypes.DETAIL_SUCCESS,
      CustomTest: response.body.data,
    });
  } catch (error) {
    yield put({
      type: CustomTestQuestionActionTypes.DETAIL_FAIL,
    });
  }
}

function* update(action) {
  const form = new CustomTestQuestionForm();
  const { payload, testId, id, messages } = action;
  const { weight, id: modelId, testId: modelTestId, ...modelParams } = payload;
  const model = Object.assign(
    {},
    {
      testId,
      id,
      weight: parseInt(weight || '1', 10),
      ...modelParams,
    },
  );
  form.populate(model);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: CustomTestQuestionActionTypes.UPDATE_FAIL,
      validation,
    });
  } else {
    try {
      const response = yield CustomTestQuestionService.update(id, testId, {
        weight: parseInt(weight || '1', 10),
        ...modelParams,
      });
      yield put({
        question: { ...payload, ...response.body },
        type: CustomTestQuestionActionTypes.UPDATE_SUCCESS,
        toast: buildToast(messages.success, ToastTypes.success),
      });
      yield call(history.push, `${TestsPath}/${testId}/questions`);
    } catch (error) {
      yield put({
        type: CustomTestQuestionActionTypes.UPDATE_FAIL,
        validation: form.validateErrorResponse(error),
        toast: buildToast(messages.error, ToastTypes.error),
      });
    }
  }
}

function* saveSorted(action) {
  const { payload, testId, messages } = action;

  try {
    yield CustomTestQuestionService.saveSorted(testId, payload);

    yield put({
      changed: payload,
      type: CustomTestQuestionActionTypes.SAVE_SORTED_SUCCESS,
      toast: buildToast(messages.success, ToastTypes.success),
    });
  } catch (error) {
    yield put({
      type: CustomTestQuestionActionTypes.SAVE_SORTED_FAIL,
      toast: buildToast(messages.error, ToastTypes.error),
    });
  }
}

function* del(action) {
  try {
    const { id, testId, messages } = action;
    yield CustomTestQuestionService.delete(id, testId);
    yield put({
      id,
      type: CustomTestQuestionActionTypes.DELETE_SUCCESS,
      toast: buildToast(messages.success, ToastTypes.success),
    });
  } catch (error) {
    const { messages } = action;
    yield put({
      type: CustomTestQuestionActionTypes.DELETE_FAIL,
      toast: buildToast(messages.error, ToastTypes.error),
    });
  }
}
function* CustomTestQuestionSaga() {
  yield takeLatest(CustomTestQuestionActionTypes.CREATE, create);
  yield takeLatest(CustomTestQuestionActionTypes.READ, read);
  yield takeLatest(CustomTestQuestionActionTypes.DETAIL, detail);
  yield takeLatest(CustomTestQuestionActionTypes.UPDATE, update);
  yield takeLatest(CustomTestQuestionActionTypes.DELETE, del);
  yield takeLatest(CustomTestQuestionActionTypes.SAVE_SORTED, saveSorted);
}

export default CustomTestQuestionSaga;
