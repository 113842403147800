import { combineReducers } from 'redux';
import JobDetailsActionTypes from '../../constants/Job/JobDetailsActionTypes';
import RoleActionTypes from '../../constants/Role/RoleActionTypes';

const initialModelForm = {
  id: undefined,
  name: '',
  similarity: '',
};

const isSaving = (state = false, action) => {
  switch (action.type) {
    case RoleActionTypes.POST:
    case RoleActionTypes.PUT:
      return true;
    case RoleActionTypes.POST_SUCCESS:
    case RoleActionTypes.POST_FAIL:
    case RoleActionTypes.PUT_SUCCESS:
    case RoleActionTypes.PUT_FAIL:
      return false;
    default:
      return state;
  }
};

const modelForm = (state = initialModelForm, action) => {
  switch (action.type) {
    case RoleActionTypes.POST_SUCCESS:
    case RoleActionTypes.PUT_SUCCESS:
    case RoleActionTypes.CLEAR_FORM:
      return initialModelForm;
    case RoleActionTypes.CHANGE_FORM:
      return Object.assign({}, state, action.modelForm);
    case RoleActionTypes.EDIT:
      return Object.assign({}, initialModelForm, action.modelForm);
    default:
      return state;
  }
};

const validation = (state = {}, action) => {
  switch (action.type) {
    case RoleActionTypes.POST:
    case RoleActionTypes.PUT:
    case RoleActionTypes.EDIT:
    case RoleActionTypes.CLEAR_FORM:
      return {};
    case RoleActionTypes.POST_FAIL:
    case RoleActionTypes.PUT_FAIL:
    case RoleActionTypes.GET_ALL_FAIL:
      return action.validation;
    default:
      return state;
  }
};

const roles = (state = [], action) => {
  switch (action.type) {
    case RoleActionTypes.GET_ALL_SUCCESS:
    case JobDetailsActionTypes.INIT_JOB_DETAILS_SUCCESS:
      return action.roles;
    case RoleActionTypes.GET_ALL_FAIL:
    default:
      return state;
  }
};

const roleSimilarities = (state = [], action) => {
  switch (action.type) {
    case RoleActionTypes.GET_ROLE_SIMILARITIES_SUCCESS:
      return action.roleSimilarities;
    case RoleActionTypes.GET_ROLE_SIMILARITIES_FAIL:
      return [];
    default:
      return state;
  }
};

export default combineReducers({
  modelForm,
  isSaving,
  validation,
  roles,
  roleSimilarities,
});
