import { call, put, select, takeLatest } from 'redux-saga/effects';
import history from '../../history';
import { DiversityLegalTermActionTypes } from './ActionTypes';
import { selectIsUserOwner, selectUserConsentPermission } from './Selectors';
import Service from './Service';

const diversityLegalTermVersion = 'diversity_v1';

export function* getCompanyUserConsent() {
  const userConsentPermission = yield select(selectUserConsentPermission);
  if (userConsentPermission.isRoot) return;

  if (!userConsentPermission.isOwner) return;

  try {
    yield call(Service.getConsent, {
      field: diversityLegalTermVersion,
    });
  } catch (err) {
    const NOT_FOUND_ERROR = 404;
    if (err.statusCode === NOT_FOUND_ERROR) {
      yield put({
        type: DiversityLegalTermActionTypes.GET_COMPANY_USER_CONSENT_SUCCESS,
        payload: { userShouldView: true },
      });
    } else {
      yield put({
        type: DiversityLegalTermActionTypes.GET_COMPANY_USER_CONSENT_FAIL,
        payload: { userShouldView: false },
      });
    }
  }
}

export function* saveCompanyUserConsent() {
  try {
    yield call(Service.saveConsent, {
      field: diversityLegalTermVersion,
      value: true,
    });

    yield put({
      type: DiversityLegalTermActionTypes.SAVE_COMPANY_USER_CONSENT_SUCCESS,
      payload: { userShouldView: false },
    });

    const isOwner = yield select(selectIsUserOwner);
    if (isOwner) {
      yield call(history.push, '/companies/setup/user-access-profile');
    }
  } catch (error) {
    yield put({
      type: DiversityLegalTermActionTypes.SAVE_COMPANY_USER_CONSENT_FAIL,
      error,
    });
  }
}

function* diversityLegalTermSaga() {
  yield takeLatest(
    DiversityLegalTermActionTypes.GET_COMPANY_USER_CONSENT,
    getCompanyUserConsent,
  );
  yield takeLatest(
    DiversityLegalTermActionTypes.SAVE_COMPANY_USER_CONSENT,
    saveCompanyUserConsent,
  );
}

export default diversityLegalTermSaga;
