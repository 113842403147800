import api from '../../api/DarthVaderClient';
import { INTERNAL_RECRUITMENT_ENDPOINT } from './constants';

export const create = payload =>
  api.post(`${INTERNAL_RECRUITMENT_ENDPOINT}/employee`, payload);

export const update = ({ id, ...payload }) =>
  api.put(`${INTERNAL_RECRUITMENT_ENDPOINT}/employee/${id}`, payload);

export const remove = payload =>
  api.delete('/internal-recruitment/employee', payload);

export const listEmployees = (
  offset,
  search = null,
  column = null,
  order = null,
) => {
  let url = `${INTERNAL_RECRUITMENT_ENDPOINT}/employees?offset=${offset}`;
  if (search) url += `&search=${search}`;
  if (column && order) url += `&column=${column}&order=${order}`;
  return api.get(url);
};

export const getCountries = () => api.get('/address-service/public/country');

export const createBatch = payload =>
  api.post(`${INTERNAL_RECRUITMENT_ENDPOINT}/batch-employees`, payload);

export const deleteBatch = payload =>
  api.delete(`${INTERNAL_RECRUITMENT_ENDPOINT}/batch-employees`, payload);
