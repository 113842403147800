import {
  googleAnalyticsMiddleware,
  toastMiddleware,
} from '@gupy/front-commons';
import { intlReducer as intl } from 'react-intl-redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import pt from '../globalization/pt.json';
import reducers from '../reducers';
import sagas from '../sagas';

const configureStore = (initState = {}) => {
  const composeEnhancers = composeWithDevTools({
    actionsBlacklist: ['CHANGE_FORM'],
  });

  const reducer = combineReducers({
    reducers,
    intl,
  });

  const initialState = {
    intl: {
      locale: 'pt',
      messages: { ...pt },
    },
    ...initState,
  };

  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    reducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        googleAnalyticsMiddleware,
        toastMiddleware,
      ),
    ),
  );

  sagaMiddleware.run(sagas);

  return store;
};

export default configureStore;
