import PropTypes from 'prop-types';
import React, { Fragment, useRef } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { insightsCopilot } from '../../../../../services/Copilot/CopilotService';
import { getMessages } from '../../../Messages';

const propTypes = {
  intl: intlShape.isRequired,
  isOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  handleInsightsClickTime: PropTypes.func,
  isTypeInsights: PropTypes.string,
  isQuestionInsights: PropTypes.string,
  currentUser: PropTypes.object.isRequired,
  token: PropTypes.number,
  handleOpenInsightsCompare: PropTypes.func,
  handlePeriodInsights: PropTypes.func,
};

const InsightsFilter = ({
  isOpen,
  handleInsightsClickTime,
  token,
  isTypeInsights,
  isQuestionInsights,
  intl,
  currentUser,
  handleOpenInsightsCompare,
  handlePeriodInsights,
}) => {
  const messagesEndRef = useRef(null);
  const message = getMessages(intl);

  const handleSendTimeMonth = async () => {
    const question = {
      sender: 'user-message_input',
      text: message.lastMonthButton,
    };
    handleInsightsClickTime([question]);

    setTimeout(() => {
      const botMessage = {
        sender: 'bot-message',
        text: '...',
      };
      handleInsightsClickTime([question, botMessage]);
    }, 200);

    const reqInsights = {
      period: 'Last 1 month',
      metric: isTypeInsights,
      whatInsights: isQuestionInsights,
    };
    const resAPI = await insightsCopilot(reqInsights, currentUser, token);

    handlePeriodInsights(reqInsights.period);

    const resFilterInsightsMessage = {
      sender: 'bot-message_api',
      text: resAPI.data.insight.text,
    };
    handleInsightsClickTime([question, resFilterInsightsMessage]);

    const botMessageCompareInsights = {
      sender: 'bot-message_compare_insights',
      text: message.suggestionCompareMetrics,
    };
    handleOpenInsightsCompare();
    handleInsightsClickTime([
      question,
      resFilterInsightsMessage,
      botMessageCompareInsights,
    ]);
  };
  const handleSendTimeTrimester = async () => {
    const question = {
      sender: 'user-message_input',
      text: message.lastTrimesterButton,
    };
    handleInsightsClickTime([question]);
    setTimeout(() => {
      const botMessage = {
        sender: 'bot-message',
        text: '...',
      };
      handleInsightsClickTime([question, botMessage]);
    }, 200);

    const reqInsights = {
      period: 'Last 3 months',
      metric: isTypeInsights,
      whatInsights: isQuestionInsights,
    };
    const resAPI = await insightsCopilot(reqInsights, currentUser, token);
    handlePeriodInsights(reqInsights.period);
    const resFilterInsightsMessage = {
      sender: 'bot-message_api',
      text: resAPI.data.insight.text,
    };

    handleInsightsClickTime([question, resFilterInsightsMessage]);
    const botMessageCompareInsights = {
      sender: 'bot-message_compare_insights',
      text: message.suggestionCompareMetrics,
    };
    handleOpenInsightsCompare();
    handleInsightsClickTime([
      question,
      resFilterInsightsMessage,
      botMessageCompareInsights,
    ]);
  };
  const handleSendTimeSemester = async () => {
    const question = {
      sender: 'user-message_input',
      text: message.lastSemesterButton,
    };
    handleInsightsClickTime([question]);
    setTimeout(() => {
      const botMessage = {
        sender: 'bot-message',
        text: '...',
      };
      handleInsightsClickTime([question, botMessage]);
    }, 200);

    const reqInsights = {
      period: 'Last 6 months',
      metric: isTypeInsights,
      whatInsights: isQuestionInsights,
    };
    const resAPI = await insightsCopilot(reqInsights, currentUser, token);
    handlePeriodInsights(reqInsights.period);
    const resFilterInsightsMessage = {
      sender: 'bot-message_api',
      text: resAPI.data.insight.text,
    };

    handleInsightsClickTime([question, resFilterInsightsMessage]);
    const botMessageCompareInsights = {
      sender: 'bot-message_compare_insights',
      text: message.suggestionCompareMetrics,
    };
    handleOpenInsightsCompare();

    handleInsightsClickTime([
      question,
      resFilterInsightsMessage,
      botMessageCompareInsights,
    ]);
  };
  const handleSendTimeYear = async () => {
    const question = {
      sender: 'user-message_input',
      text: message.lastYearButton,
    };
    handleInsightsClickTime([question]);
    setTimeout(() => {
      const botMessage = {
        sender: 'bot-message',
        text: '...',
      };
      handleInsightsClickTime([question, botMessage]);
    }, 200);

    const reqInsights = {
      period: 'Last 1 year',
      metric: isTypeInsights,
      whatInsights: isQuestionInsights,
    };
    const resAPI = await insightsCopilot(reqInsights, currentUser, token);
    handlePeriodInsights(reqInsights.period);
    const resFilterInsightsMessage = {
      sender: 'bot-message_api',
      text: resAPI.data.insight.text,
    };

    handleInsightsClickTime([question, resFilterInsightsMessage]);
    const botMessageCompareInsights = {
      sender: 'bot-message_compare_insights',
      text: message.suggestionCompareMetrics,
    };
    handleOpenInsightsCompare();

    handleInsightsClickTime([
      question,
      resFilterInsightsMessage,
      botMessageCompareInsights,
    ]);
  };

  return (
    isOpen && (
      <Fragment>
        <div className="message_suggestion_btn">
          <button
            aria-label={message.questionFirstApplication}
            className="user-message_suggestion"
            onClick={handleSendTimeTrimester}
          >
            {message.lastTrimesterButton}
          </button>
          <button
            aria-label={message.questionFirstApplication}
            className="user-message_suggestion"
            onClick={handleSendTimeMonth}
          >
            {message.lastMonthButton}
          </button>
          <button
            aria-label={message.questionFirstApplication}
            className="user-message_suggestion"
            onClick={handleSendTimeYear}
          >
            {message.lastYearButton}
          </button>
          <button
            aria-label={message.questionFirstApplication}
            className="user-message_suggestion"
            onClick={handleSendTimeSemester}
          >
            {message.lastSemesterButton}
          </button>
        </div>
        <div ref={messagesEndRef} />
      </Fragment>
    )
  );
};
InsightsFilter.propTypes = propTypes;
export default injectIntl(InsightsFilter);
