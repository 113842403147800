import { combineReducers } from 'redux';
import { ConfirmIndicationActionTypes } from './ConfirmIndicationActions';

const isLoading = (state = null, { type }) => {
  switch (type) {
    case ConfirmIndicationActionTypes.VALIDATE:
    case ConfirmIndicationActionTypes.FEEDBACK:
      return true;
    case ConfirmIndicationActionTypes.VALIDATE_SUCCESS:
    case ConfirmIndicationActionTypes.VALIDATE_FAIL:
    case ConfirmIndicationActionTypes.FEEDBACK_SUCCESS:
    case ConfirmIndicationActionTypes.FEEDBACK_FAIL:
      return false;
    default:
      return state;
  }
};

const hasError = (state = null, { type }) => {
  switch (type) {
    case ConfirmIndicationActionTypes.VALIDATE_FAIL:
    case ConfirmIndicationActionTypes.FEEDBACK_FAIL:
      return true;
    default:
      return state;
  }
};

const application = (state = null, { type, payload }) => {
  switch (type) {
    case ConfirmIndicationActionTypes.VALIDATE_SUCCESS:
      return payload;
    default:
      return state;
  }
};

const savedFeedback = (state = false, { type }) => {
  switch (type) {
    case ConfirmIndicationActionTypes.FEEDBACK_SUCCESS:
      return true;
    default:
      return state;
  }
};

export default combineReducers({
  savedFeedback,
  application,
  isLoading,
  hasError,
});
