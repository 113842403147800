import { useState } from 'react';
import { getSubdomain } from '../../helpers/subdomainHelper';
import LocalstorageManager from '../../managers/LocalstorageManager';

export function useSubdomain() {
  const [subdomain] = useState(() => getSubdomain());

  return subdomain;
}

const getCookie = name => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));

  if (match) return match[2];

  const companyToken = new LocalstorageManager(
    window.localStorage,
  ).getCompanyToken();

  if (!companyToken) return null;

  const matchLocalStorage = companyToken.match(
    new RegExp(`(^| )${name}=([^;]+)`),
  );

  if (matchLocalStorage) return matchLocalStorage[2];
  return null;
};

export const MARKETING_SUBDOMAIN = 'marketing';

export const redirectToCookieSubdomain = (path, search) => {
  const subdomain = getCookie('company_user_subdomain');
  const { host, protocol } = window.location;
  if (subdomain) {
    const redirectHost = host.replace(MARKETING_SUBDOMAIN, subdomain);
    window.location.href = `${protocol}//${redirectHost}${path}${search}`;
  } else {
    window.location.href = 'https://www.gupy.io/login';
  }
};
