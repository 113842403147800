import { asyncActionType, createActionTypes } from '@gupy/front-commons';

export default createActionTypes(
  'CustomTest',
  'CHANGE_FORM',
  'CHANGE_SEARCH',
  'MODEL_CLEAR',
  'SELECT_CARD',
  'RESET_CUSTOM_TEST',
  asyncActionType('LIST'),
  asyncActionType('LIST_TO_JOB'),
  asyncActionType('CREATE'),
  asyncActionType('READ'),
  asyncActionType('DETAIL'),
  asyncActionType('UPDATE'),
  asyncActionType('DELETE'),
  asyncActionType('ARCHIVE'),
  asyncActionType('DELETE_MULTIPLE'),
  asyncActionType('DUPLICATE'),
  asyncActionType('FAVORITE'),
  asyncActionType('VALIDATE_TEST_CUSTOM'),
);
