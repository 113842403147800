import { combineReducers } from 'redux';
import * as types from './ActionTypes';

export const isEnabled = (state = false, action) => {
  switch (action.type) {
    case types.PRE_HIRE_STEP_PROFILE_SUCCESS:
      return action.payload;
    case types.PRE_HIRE_STEP_PROFILE_FAIL:
    case types.PRE_HIRE_STEP_PROFILE:
    default:
      return state;
  }
};

export const isSaving = (state = false, action) => {
  switch (action.type) {
    case types.PRE_HIRE_STEP_ENABLE_SUCCESS:
    case types.PRE_HIRE_STEP_ENABLE_FAIL:
      return false;
    case types.PRE_HIRE_STEP_ENABLE:
      return true;
    default:
      return state;
  }
};

export default combineReducers({
  isEnabled,
  isSaving,
});
