import { asyncActionType, createActionTypes } from '@gupy/front-commons';

const ConfirmIndicationActionTypes = createActionTypes(
  'ConfirmIndication',
  asyncActionType('VALIDATE'),
  asyncActionType('FEEDBACK'),
  'VALIDATE_SUCCESS',
  'VALIDATE_FAIL',
  'FEEDBACK_SUCCESS',
  'FEEDBACK_FAIL',
);

const validateToken = payload => ({
  type: ConfirmIndicationActionTypes.VALIDATE,
  payload,
});

const giveFeedback = payload => ({
  type: ConfirmIndicationActionTypes.FEEDBACK,
  payload,
});

export { ConfirmIndicationActionTypes, validateToken, giveFeedback };
