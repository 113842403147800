import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';
import AuthenticationFooter from '../../../Authentication/components/Footer/Footer';
import {
  MARKETING_SUBDOMAIN,
  redirectToCookieSubdomain,
  useSubdomain,
} from '../../../Authentication/helpers';
import backgroundImage from './images/backgroundImage.jpg';
import './AuthenticationLayout.scss';

const propTypes = {
  children: PropTypes.object.isRequired,
  location: PropTypes.object,
  intl: intlShape,
};

const AuthenticationLayout = ({ intl, children, ...props }) => {
  const subdomain = useSubdomain();
  if (subdomain === MARKETING_SUBDOMAIN) {
    const { from } = props.location.state || {
      from: { pathname: '/', search: '' },
    };
    redirectToCookieSubdomain(from.pathname, from.search);
  }

  const isRebrandingEnable =
    process.env.REACT_APP_ASSETS_URL === '/images' ||
    process.env.REACT_APP_ASSETS_URL === '/companies/images';
  const authenticationStyle = isRebrandingEnable
    ? 'authentication-layout-rebranding'
    : 'authentication-layout';
  // eslint-disable-next-line no-console
  console.log(
    'process.env.REACT_APP_ASSETS_URL',
    process.env.REACT_APP_ASSETS_URL,
  );
  return (
    <div className={authenticationStyle}>
      <div className={`${authenticationStyle}__container`}>
        <div className={`${authenticationStyle}__content`}>{children}</div>
        <div
          className={`${authenticationStyle}__background`}
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          &nbsp;
        </div>
      </div>
      <div className={`${authenticationStyle}__footer`}>
        <AuthenticationFooter intl={intl} />
      </div>
    </div>
  );
};

AuthenticationLayout.propTypes = propTypes;

export default injectIntl(withRouter(AuthenticationLayout));
