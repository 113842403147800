import CustomTestActionTypes from './CustomTestActionTypes';

export const selectCustomTestCard = (id, selected) => ({
  id,
  selected,
  type: CustomTestActionTypes.SELECT_CARD,
});

export const onCustomTestModelUpdate = modelForm => ({
  type: CustomTestActionTypes.CHANGE_FORM,
  modelForm,
});

export const onCustomTestSearchUpdate = searchString => ({
  type: CustomTestActionTypes.CHANGE_SEARCH,
  searchString,
});

export const onCustomTestUpdate = data => ({
  type: CustomTestActionTypes.CHANGE_FORM,
  data,
});

export const listCompanyCustomTest = ({ page, title }) => ({
  page,
  title,
  type: CustomTestActionTypes.LIST,
});

export const listCompanyCustomTestToJob = jobId => ({
  type: CustomTestActionTypes.LIST_TO_JOB,
  jobId,
});

export const createCompanyCustomTest = (payload, messages) => ({
  type: CustomTestActionTypes.CREATE,
  payload,
  messages,
});

export const readCompanyCustomTest = id => ({
  type: CustomTestActionTypes.READ,
  id,
});

export const resetCompanyCustomTestState = id => ({
  type: CustomTestActionTypes.RESET_CUSTOM_TEST,
  id,
});

export const detailCompanyCustomTest = id => ({
  type: CustomTestActionTypes.DETAIL,
  id,
});

export const updateCompanyCustomTest = (payload, messages) => ({
  type: CustomTestActionTypes.UPDATE,
  payload,
  messages,
});

export const deleteCompanyCustomTest = (payload, messages) => ({
  type: CustomTestActionTypes.DELETE,
  payload,
  messages,
});

export const archiveCompanyCustomTest = (payload, messages) => ({
  type: CustomTestActionTypes.ARCHIVE,
  payload,
  messages,
});

export const duplicateCompanyCustomTest = (customTest, messages) => ({
  type: CustomTestActionTypes.DUPLICATE,
  customTest,
  messages,
});

export const validateCompanyCustomTest = (id, testId, payload) => ({
  id,
  testId,
  payload,
  type: CustomTestActionTypes.VALIDATE_TEST_CUSTOM,
});

export const favoriteCompanyCustomTest = (id, isFavorite) => ({
  id,
  isFavorite,
  type: CustomTestActionTypes.FAVORITE,
});

export const customTestModelClear = () => ({
  type: CustomTestActionTypes.MODEL_CLEAR,
});
