import { buildToast, ToastTypes } from '@gupy/front-commons';
import { call, put, takeLatest } from 'redux-saga/effects';
import history from '../../../../history';
import { TestsPath } from '../../CustomTestRoutes';
import CustomTestActionTypes from './CustomTestActionTypes';
import CustomTestService from './CustomTestService';
import CustomTestForm from './CustomTestValidation';

function* list(action) {
  const form = new CustomTestForm();
  const { page, title } = action;
  try {
    const response = yield CustomTestService.list({ page, title });
    yield put({
      type: CustomTestActionTypes.LIST_SUCCESS,
      CustomTests: response.body,
    });
  } catch (error) {
    yield put({
      type: CustomTestActionTypes.LIST_FAIL,
      validation: form.validateErrorResponse(error),
    });
  }
}
function* listToJob(action) {
  try {
    const response = yield CustomTestService.listToJob(action.jobId);
    yield put({
      type: CustomTestActionTypes.LIST_TO_JOB_SUCCESS,
      CustomTests: response.body.data,
    });
  } catch (error) {
    yield put({
      type: CustomTestActionTypes.LIST_TO_JOB_FAIL,
    });
  }
}
function* create(action) {
  const { payload, messages } = action;
  const form = new CustomTestForm();
  const model = Object.assign(
    {},
    {
      ...payload,
      timeLimit: parseInt(payload.timeLimit || '0', 10),
    },
  );
  form.populate(model);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: CustomTestActionTypes.CREATE_FAIL,
      validation,
    });
  } else {
    try {
      const { body } = yield CustomTestService.create(model);
      const { id } = body.data;

      yield put({
        toast: buildToast(messages.success, ToastTypes.success),
        type: CustomTestActionTypes.CREATE_SUCCESS,
      });
      yield call(history.push, `${TestsPath}/${id}/questions`);
    } catch (error) {
      yield put({
        toast: buildToast(messages.error, ToastTypes.error),
        type: CustomTestActionTypes.CREATE_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* read(action) {
  try {
    const response = yield CustomTestService.read(action.id);
    yield put({
      type: CustomTestActionTypes.READ_SUCCESS,
      CustomTest: response.body.data,
    });
  } catch (error) {
    yield put({
      type: CustomTestActionTypes.READ_FAIL,
    });
  }
}

function* detail(action) {
  try {
    const response = yield CustomTestService.detail(action.id);
    yield put({
      type: CustomTestActionTypes.DETAIL_SUCCESS,
      CustomTest: response.body.data,
    });
  } catch (error) {
    yield put({
      type: CustomTestActionTypes.DETAIL_FAIL,
    });
  }
}

function* update(action) {
  const form = new CustomTestForm();
  const { payload, messages } = action;
  const { id, timeLimit, ...modelParams } = payload;
  const model = Object.assign(
    {},
    {
      timeLimit: parseInt(timeLimit || '0', 10),
      ...modelParams,
    },
  );
  form.populate(model);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: CustomTestActionTypes.UPDATE_FAIL,
      validation,
    });
  } else {
    try {
      yield CustomTestService.update(id, model);

      yield put({
        toast: buildToast(messages.success, ToastTypes.success),
        type: CustomTestActionTypes.UPDATE_SUCCESS,
      });
      yield call(history.push, `${TestsPath}/${id}/questions`);
    } catch (error) {
      yield put({
        toast: buildToast(messages.error, ToastTypes.error),
        type: CustomTestActionTypes.UPDATE_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* favorite(action) {
  const { id, isFavorite } = action;

  try {
    const model = {
      isFavorite,
    };
    yield CustomTestService.favorite(id, model);

    yield put({
      id,
      isFavorite,
      type: CustomTestActionTypes.FAVORITE_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: CustomTestActionTypes.FAVORITE_FAIL,
    });
  }
}

function* del(action) {
  const { messages } = action;
  try {
    yield CustomTestService.delete(action.payload);
    yield put({
      ids: action.payload,
      toast: buildToast(messages.success, ToastTypes.success),
      type: CustomTestActionTypes.DELETE_SUCCESS,
    });
  } catch (error) {
    yield put({
      toast: buildToast(messages.error, ToastTypes.error),
      type: CustomTestActionTypes.DELETE_FAIL,
    });
  }
}

function* duplicate(action) {
  const { customTest, messages } = action;
  try {
    const response = yield CustomTestService.duplicate(customTest.id);
    const newTest = response.body.data;
    yield put({
      customTest: newTest,
      toast: buildToast(messages.success, ToastTypes.success),
      type: CustomTestActionTypes.DUPLICATE_SUCCESS,
    });
  } catch (error) {
    yield put({
      toast: buildToast(messages.error, ToastTypes.error),
      type: CustomTestActionTypes.DUPLICATE_FAIL,
    });
  }
}

function* archive(action) {
  const { messages } = action;
  try {
    yield CustomTestService.archive(action.payload);
    yield put({
      ids: action.payload,
      toast: buildToast(messages.success, ToastTypes.success),
      type: CustomTestActionTypes.ARCHIVE_SUCCESS,
    });
  } catch (error) {
    yield put({
      toast: buildToast(messages.error, ToastTypes.error),
      type: CustomTestActionTypes.ARCHIVE_FAIL,
    });
  }
}

function* CustomTestSaga() {
  yield takeLatest(CustomTestActionTypes.FAVORITE, favorite);
  yield takeLatest(CustomTestActionTypes.LIST, list);
  yield takeLatest(CustomTestActionTypes.LIST_TO_JOB, listToJob);
  yield takeLatest(CustomTestActionTypes.CREATE, create);
  yield takeLatest(CustomTestActionTypes.READ, read);
  yield takeLatest(CustomTestActionTypes.DETAIL, detail);
  yield takeLatest(CustomTestActionTypes.UPDATE, update);
  yield takeLatest(CustomTestActionTypes.DELETE, del);
  yield takeLatest(CustomTestActionTypes.ARCHIVE, archive);
  yield takeLatest(CustomTestActionTypes.DUPLICATE, duplicate);
  yield takeLatest(CustomTestActionTypes.DUPLICATE_SUCCESS, list);
}

export default CustomTestSaga;
