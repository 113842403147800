export const GET_TEMPLATE_EMAILS = 'JobCancel/GET_TEMPLATE_EMAILS';
export const GET_TEMPLATE_EMAILS_SUCCESS =
  'JobCancel/GET_TEMPLATE_EMAILS_SUCCESS';
export const GET_TEMPLATE_EMAILS_FAIL = 'JobCancel/GET_TEMPLATE_EMAILS_FAIL';
export const GET_CANCEL_REASONS = 'JobCancel/GET_CANCEL_REASONS';
export const GET_CANCEL_REASONS_SUCCESS =
  'JobCancel/GET_CANCEL_REASONS_SUCCESS';
export const GET_CANCEL_REASONS_FAIL = 'JobCancel/GET_CANCEL_REASONS_FAIL';
export const GET_BIND_SURVEY_CAREER_PAGES =
  'JobCancel/GET_BIND_SURVEY_CAREER_PAGES';
export const GET_BIND_SURVEY_CAREER_PAGES_SUCCESS =
  'JobCancel/GET_BIND_SURVEY_CAREER_PAGES_SUCCESS';
export const GET_BIND_SURVEY_CAREER_PAGES_FAIL =
  'JobCancel/GET_BIND_SURVEY_CAREER_PAGES_FAIL';
export const SEND_EMAIL = 'JobCancel/SEND_EMAIL';
export const SEND_EMAIL_SUCCESS = 'JobCancel/SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAIL = 'JobCancel/SEND_EMAIL_FAIL';
export const GET_APPLICATIONS_TO_SEND_EMAIL =
  'JobCancel/GET_APPLICATIONS_TO_SEND_EMAIL';
export const GET_APPLICATIONS_TO_SEND_EMAIL_SUCCESS =
  'JobCancel/GET_APPLICATIONS_TO_SEND_EMAIL_SUCCESS';
export const GET_APPLICATIONS_TO_SEND_EMAIL_FAIL =
  'JobCancel/GET_APPLICATIONS_TO_SEND_EMAIL_FAIL';
