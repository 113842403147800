import { device } from 'aws-iot-device-sdk';
import * as moment from 'moment';
import * as mqtt from 'mqtt';
import api from '../../Notifications/NotificationsService';

const ERROR_TYPES = {
  MAX_HTTP_RETRIES: 'Max HTTPRequest retries. To check if internet fails.',
  EMPTY_CONNECTION: 'Connection object is empty. Hint: check API keys config',
};

const getClient = connection => {
  if (connection.local) {
    return mqtt.connect(connection.uri);
  }
  return device({
    ...connection,
    autoSubscribe: true,
    keepalive: 60,
  });
};

class WebsocketClient {
  client;

  onMessageFn;

  onConnectFn;

  MAX_HTTP_RETRIES = 10;

  HTTP_RETRIES_INTERVAL = 1000;

  currentToken;

  async getToken() {
    if (
      this.currentToken &&
      this.currentToken.expiration &&
      moment().add(1, 'minute').isBefore(this.currentToken.expiration)
    ) {
      return this.currentToken;
    }
    const payload = await this.getTokenPayload(
      this.MAX_HTTP_RETRIES,
      this.HTTP_RETRIES_INTERVAL,
    );
    this.currentToken = payload && payload.body;
    return this.currentToken;
  }

  disconnect = async () => {
    await this.client.end(true);
  };

  connect = async ({ onMessageFn, onConnectFn }) => {
    this.onMessageFn = onMessageFn;
    this.onConnectFn = onConnectFn;

    const token = await this.getToken();
    const { connection, topic } = token;
    if (!connection || Object.keys(connection).length === 0) {
      throw new Error(ERROR_TYPES.EMPTY_CONNECTION);
    }
    this.client = getClient(connection);

    this.client.on('connect', () => {
      this.client.subscribe(topic);
      this.onConnectFn();
    });
    this.client.on('error', err => this.handleError(err));
    this.client.on('message', this.onMessageFn);
  };

  handleError = async () => {
    await this.client.end(true);
    await this.connect({
      onMessageFn: this.onMessageFn,
      onConnectFn: this.onConnectFn,
    });
  };

  getTokenPayload = async (retries, interval) => {
    try {
      return await api.getToken();
    } catch (err) {
      if (!retries) {
        throw err;
      }
      await new Promise(resolve => setTimeout(resolve, interval));
      return this.getTokenPayload(retries - 1, interval * 2);
    }
  };
}

let websocketClientInstance;

const getWebsocketClient = ({ onMessageFn, onConnectFn }) => {
  websocketClientInstance = new WebsocketClient();
  websocketClientInstance.connect({ onMessageFn, onConnectFn });
  return websocketClientInstance;
};

export { getWebsocketClient, ERROR_TYPES };
