import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'job_template_delete_confirmation_title',
    defaultMessage: 'Excluir template de vaga',
  },
  message: {
    id: 'job_template_delete_confirmation_message',
    defaultMessage: 'Você confirma a exclusão do template de vaga?',
  },
  confirmButtonText: {
    id: 'delete',
    defaultMessage: 'Excluir',
  },
  cancelButtonText: {
    id: 'cancel',
    defaultMessage: 'Cancelar',
  },
  success: {
    id: 'job_template_delete_success_message',
    defaultMessage: 'Template de vaga excluído com sucesso.',
  },
  duplicateSuccess: {
    id: 'job_template_duplicate_success_message',
    defaultMessage: 'Template de vaga duplicado com sucesso.',
  },
  searchJobTemplate: {
    id: 'search_job_template',
    defaultMessage: 'Buscar template de vaga',
  },
  createNewTemplate: {
    id: 'create_new_job_template',
    defaultMessage: 'Criar template de vaga',
  },
  jobGridTitle: {
    id: 'job_templates_page_grid_content_title',
    defaultMessage:
      'Busque e gerencie os templates de vaga criados em sua empresa',
  },
  noJobTemplateAvaiable: {
    id: 'no_job_template_avaiable',
    defaultMessage: 'Nenhum template de vaga disponível',
  },
  noJobTemplateFound: {
    id: 'no_job_template_found',
    defaultMessage: 'Nenhum template de vaga encontrado',
  },
  noJobTemplateDescription: {
    id: 'job_template_description_no_templates',
    defaultMessage:
      'Com os templates de vaga, você torna a criação de vagas mais rápida, fácil e ágil.',
  },
  duplicate: {
    id: 'duplicate',
    defaultMessage: 'Duplicar',
  },
  delete: {
    id: 'delete',
    defaultMessage: 'Excluir',
  },
  edit: {
    id: 'edit',
    defaultMessage: 'Editar',
  },
  vacancy_type_apprentice: {
    id: 'vacancy_type_apprentice',
    defaultMessage: 'Aprendiz',
  },
  vacancy_type_associate: {
    id: 'vacancy_type_associate',
    defaultMessage: 'Associado',
  },
  vacancy_type_talent_pool: {
    id: 'vacancy_type_talent_pool',
    defaultMessage: 'Banco de talentos',
  },
  vacancy_type_lecturer: {
    id: 'vacancy_type_lecturer',
    defaultMessage: 'Docente',
  },
  vacancy_type_effective: {
    id: 'vacancy_type_effective',
    defaultMessage: 'Efetivo',
  },
  vacancy_type_internship: {
    id: 'vacancy_type_internship',
    defaultMessage: 'Estágio',
  },
  vacancy_legal_entity: {
    id: 'vacancy_legal_entity',
    defaultMessage: 'Pessoa jurídica',
  },
  vacancy_type_summer: {
    id: 'vacancy_type_summer',
    defaultMessage: 'Summer Job',
  },
  vacancy_type_parter: {
    id: 'vacancy_type_parter',
    defaultMessage: 'Sócio',
  },
  vacancy_type_temporary: {
    id: 'vacancy_type_temporary',
    defaultMessage: 'Temporário',
  },
  vacancy_type_outsource: {
    id: 'vacancy_type_outsource',
    defaultMessage: 'Terceiro',
  },
  vacancy_type_trainee: {
    id: 'vacancy_type_trainee',
    defaultMessage: 'Trainee',
  },
  vacancy_type_volunteer: {
    id: 'vacancy_type_volunteer',
    defaultMessage: 'Voluntário',
  },
  vacancy_type_autonomous: {
    id: 'vacancy_type_autonomous',
    defaultMessage: 'Autônomo',
  },
  vacancy_type_freelancer: {
    id: 'vacancy_type_freelancer',
    defaultMessage: 'Freelancer',
  },
});

export const getJobTemplatesMessages = intl => formatMessages(intl, messages);
