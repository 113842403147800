import CustomTestQuestionActionTypes from './CustomTestQuestionActionTypes';

export const swapCustomTestQuestions = (dragQuestion, hoverQuestion) => ({
  dragQuestion,
  hoverQuestion,
  type: CustomTestQuestionActionTypes.SWAP_QUESTION,
});

export const loadCustomTestQuestionModel = (question, clone = false) => {
  const secureQuestion = question;
  if (secureQuestion.urlImage) {
    secureQuestion.urlImageDelete = false;
  }
  return {
    question: secureQuestion,
    type: clone
      ? CustomTestQuestionActionTypes.CLONE_QUESTION
      : CustomTestQuestionActionTypes.LOAD_QUESTION,
  };
};

export const onCustomTestQuestionModelUpdate = modelForm => ({
  type: CustomTestQuestionActionTypes.CHANGE_FORM,
  modelForm,
});

export const onCustomTestQuestionUpdate = data => ({
  type: CustomTestQuestionActionTypes.CHANGE_FORM,
  data,
});

export const listCompanyCustomTestQuestion = () => ({
  type: CustomTestQuestionActionTypes.LIST,
});

export const createCompanyCustomTestQuestion = (testId, payload, messages) => ({
  payload,
  testId,
  messages,
  type: CustomTestQuestionActionTypes.CREATE,
});

export const readCompanyCustomTestQuestion = (id, testId) => ({
  id,
  testId,
  type: CustomTestQuestionActionTypes.READ,
});

export const detailCompanyCustomTestQuestion = id => ({
  type: CustomTestQuestionActionTypes.DETAIL,
  id,
});

export const updateCompanyCustomTestQuestion = (
  id,
  testId,
  payload,
  messages,
) => ({
  id,
  testId,
  payload,
  messages,
  type: CustomTestQuestionActionTypes.UPDATE,
});

export const deleteCompanyCustomTestQuestion = (id, testId, messages) => ({
  id,
  testId,
  messages,
  type: CustomTestQuestionActionTypes.DELETE,
});

export const validateCompanyCustomTestQuestion = (id, testId, payload) => ({
  id,
  testId,
  payload,
  type: CustomTestQuestionActionTypes.VALIDATE_QUESTION_CUSTOM,
});

export const customTestQuestionModelClear = () => ({
  type: CustomTestQuestionActionTypes.MODEL_CLEAR,
});

export const saveSortedCompanyTestQuestions = (testId, payload, messages) => ({
  payload,
  testId,
  messages,
  type: CustomTestQuestionActionTypes.SAVE_SORTED,
});
