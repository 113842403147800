import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  logo: {
    id: 'gupy-logo',
    defaultMessage: 'Logotipo da Gupy',
  },
  logoGroup: {
    id: 'logo-group-gupy',
    defaultMessage: 'Icone da Gupy',
  },
  titleList: {
    id: 'titleList',
    defaultMessage: 'Descubra nossos Produtos',
  },
  titleList2: {
    id: 'titleList2',
    defaultMessage: 'Conteúdos para RH',
  },
  titleList3: {
    id: 'titleList3',
    defaultMessage: 'Contato e ajuda',
  },
  linkRecruitment: {
    id: 'footer_link_recruitment',
    defaultMessage: 'Produto Gupy Recrutamento e Seleção',
  },
  linkAdmission: {
    id: 'footer_link_admission',
    defaultMessage: 'Produto Gupy Admissão',
  },
  linkEduCorp: {
    id: 'footer_link_eduCorp',
    defaultMessage: 'Produto Gupy Educação Corporativa',
  },
  linkClimateEngagement: {
    id: 'footer_link_climateEngagement',
    defaultMessage: 'Produto Gupy Clima e Engajamento',
  },
  linkPerformanceDevelopment: {
    id: 'footer_link_performanceDevelopment',
    defaultMessage: 'Produto Gupy Performance e Desenvolvimento',
  },
  linkGupy: {
    id: 'footer_link_gupy',
    defaultMessage: 'Por que a Gupy?',
  },
  linkPlans: {
    id: 'footer_link_plans',
    defaultMessage: 'Planos',
  },
  linkHR4results: {
    id: 'footer_link_hr4results',
    defaultMessage: 'HR4Results',
  },
  linkBlogGupy: {
    id: 'footer_link_blog',
    defaultMessage: 'Blog',
  },
  linkGupyAcademy: {
    id: 'footer_link_gupyAcademy',
    defaultMessage: 'Gupy Academy',
  },
  linkMaterialsAll: {
    id: 'footer_link_allMaterials',
    defaultMessage: 'Todos os materiais',
  },
  linkEvents: {
    id: 'footer_link_events',
    defaultMessage: 'Imprensa, eventos e parcerias',
  },
  linkSupportCompanies: {
    id: 'footer_link_supportCompanies',
    defaultMessage: 'Suporte para empresas',
  },
  linkSupportCandidates: {
    id: 'footer_link_supportCandidates',
    defaultMessage: 'Suporte para candidatos',
  },
  linkFinancialSupport: {
    id: 'footer_link_supportFinancial',
    defaultMessage: 'Suporte Financeiro',
  },
  gupyFooter: {
    id: 'footer_message',
    defaultMessage: 'Gupy 2024.Todos os direitos reservados.',
  },
});

export const getMessages = intl => formatMessages(intl, messages);
