import { combineReducers } from 'redux';
import AppActionTypes from '../../constants/App/AppActionTypes';
import { SigninActionTypes } from '../../containers/Authentication/ActionTypes';

export const isConfirmDialogOpened = (state = false, action) => {
  switch (action.type) {
    case AppActionTypes.OPEN_CONFIRM_DIALOG:
      return true;
    case AppActionTypes.CLOSE_CONFIRM_DIALOG:
      return false;
    default:
      return state;
  }
};

const initialConfirmDialogOptions = {
  title: '',
  message: '',
  confirmButtonText: 'OK',
};

export const confirmDialogOptions = (
  state = initialConfirmDialogOptions,
  action,
) => {
  switch (action.type) {
    case AppActionTypes.OPEN_CONFIRM_DIALOG:
      return {
        ...initialConfirmDialogOptions,
        ...action.options,
      };
    case AppActionTypes.CLOSE_CONFIRM_DIALOG:
      return initialConfirmDialogOptions;
    default:
      return state;
  }
};

export const defaultLanguage = (state = 'pt', action) => {
  switch (action.type) {
    case SigninActionTypes.INIT_SIGNIN_SUCCESS:
      return action.companySummary.language || state;
    default:
      return state;
  }
};

export const updateNetworkStatus = (state = '', action) => {
  switch (action.type) {
    case AppActionTypes.UPDATE_NETWORK_STATUS:
      return action.status;
    default:
      return state;
  }
};

export default combineReducers({
  isConfirmDialogOpened,
  confirmDialogOptions,
  defaultLanguage,
  updateNetworkStatus,
});
