import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

const defaultProps = {
  title: undefined,
};

function Error403({ title }) {
  return (
    <div className="error-page">
      <span className="error-page__number">403</span>
      <h6>
        {title || (
          <FormattedMessage
            id="error-403"
            defaultMessage="Ops! Você não têm direitos de acesso a esta página."
          />
        )}
      </h6>
    </div>
  );
}

Error403.propTypes = propTypes;
Error403.defaultProps = defaultProps;

export default Error403;
