import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  forgotPasswordQuestion: {
    id: 'forgot_password_question',
    defaultMessage: 'Esqueceu sua senha?',
  },
  forgotPasswordExplanation: {
    id: 'forgot_password_explanation',
    defaultMessage:
      'Informe seu endereço de e-mail e enviaremos um link para redefinir sua senha',
  },
  forgotPasswordInputLabel: {
    id: 'forgot_password_input_label',
    defaultMessage: 'Email utilizado no cadastro',
  },
  forgotPasswordInputPlaceholder: {
    id: 'forgot_password_input_placeholder',
    defaultMessage: 'Digite seu email aqui',
  },
  forgotPasswordSubmit: {
    id: 'forgot_password_submit',
    defaultMessage: 'Enviar link',
  },
  alreadyHaveAccountQuestion: {
    id: 'already_have_account_question',
    defaultMessage: 'Já tem uma conta?',
  },
  accessAccount: {
    id: 'access_account',
    defaultMessage: 'Entre agora mesmo',
  },
  setYourPassword: {
    id: 'set_your_password',
    defaultMessage: 'Redefina sua senha',
  },
  setYourPasswordDescription: {
    id: 'set_your_password_description',
    defaultMessage: 'Crie uma senha segura utilizando os critérios abaixo:',
  },
  newPasswordLabel: {
    id: 'new_password_label',
    defaultMessage: 'Insira a nova senha',
  },
  newPasswordConfirmationLabel: {
    id: 'new_password_confirmation_label',
    defaultMessage: 'Confirme a nova senha',
  },
  newPasswordHint: {
    id: 'set_your_password_explanation',
    defaultMessage:
      'A senha precisa ter no mínimo 8 caracteres, sendo ao menos uma letra, um número e 1 caractere especial.',
  },
  submit: {
    id: 'register_and_enter',
    defaultMessage: 'Salvar nova senha',
  },
  termsAndPrivacyInfoPart1: {
    id: 'terms_and_privacy_info_part1',
    defaultMessage: 'Ao entrar na plataforma da Gupy, você aceita os nossos ',
  },
  termsAndPrivacyInfoPart2: {
    id: 'terms_and_privacy_info_part2',
    defaultMessage: ' e nossa ',
  },
  linkAlreadyExpired: {
    id: 'link_already_expired',
    defaultMessage: 'O link para definição de senha expirou',
  },
  setPasswordInvalidLink: {
    id: 'set_password_invalid_link',
    defaultMessage:
      'Este link expirou ou já foi utilizado. Retorne ao login e se necessário, tente novamente.',
  },
  backToLogin: {
    id: 'back_to_login',
    defaultMessage: 'Voltar ao login',
  },
  passwordConfirmationMustBeEqualPasswordError: {
    id: 'password_confirmation_must_be_equal_password_error',
    defaultMessage: 'A nova senha não está igual a confirmação',
  },
  passwordMustHaveAtLeast8CharactersError: {
    id: 'password_must_have_at_least_8_characters_error',
    defaultMessage: 'A senha precisa ter pelo menos 8 caracteres',
  },
  passwordMustHaveAtLeast1SpecialCharacterError: {
    id: 'password_must_have_at_least_1_special_character_error',
    defaultMessage: 'A senha precisa ter pelo menos 1 caracter especial',
  },
  passwordMustHaveAtLeast1LetterError: {
    id: 'password_must_have_at_least_1_letter_error',
    defaultMessage: 'A senha precisa ter pelo menos 1 letra',
  },
  passwordMustHaveAtLeast1NumberError: {
    id: 'password_must_have_at_least_1_number_error',
    defaultMessage: 'A senha precisa ter pelo menos 1 número',
  },
  invalidTokenError: {
    id: 'invalid_token_error',
    defaultMessage: 'Token inválido',
  },
  passwordDoNotMatch: {
    id: 'password_do_not_match',
    defaultMessage: 'Os valores de senha inseridos não são iguais',
  },
  keepOrChangeCompanyTitle: {
    id: 'keep_or_change_company_title',
    defaultMessage: 'Você já possui uma sessão ativa com esse email!',
  },
  keepOrChangeCompanySubtitle1: {
    id: 'keep_or_change_company_subtitle_1',
    defaultMessage: 'O email ',
  },
  keepOrChangeCompanySubtitle2: {
    id: 'keep_or_change_company_subtitle_2',
    defaultMessage: ' tem acesso para ',
  },
  keepOrChangeCompanySubtitle3: {
    id: 'keep_or_change_company_subtitle_3',
    defaultMessage: 'Para acessar ',
  },
  keepOrChangeCompanySubtitle4: {
    id: 'keep_or_change_company_subtitle_4 ',
    defaultMessage: ' você deve logar com o email cadastrado nessa empresa.',
  },
  keepOrChangeCompanyButton1: {
    id: 'keep_or_change_company_button_1',
    defaultMessage: 'Permanecer com mesmo email',
  },
  keepOrChangeCompanyButton2: {
    id: 'keep_or_change_company_button_2',
    defaultMessage: 'Realizar login com outro email',
  },
});

export const getMessages = intl => formatMessages(intl, messages);
