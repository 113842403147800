import PropTypes from 'prop-types';
import React from 'react';
import './GridSidebarV2.scss';

const propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.string.isRequired,
  ]),
  description: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.string.isRequired,
  ]),
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
};

const GridSidebarV2 = ({ title, description, children }) => (
  <aside className="grid-sidebarV2">
    <h1 className="grid-sidebarV2__title">{title}</h1>
    <p className="grid-sidebarV2__description">{description}</p>
    {children ? <div>{children}</div> : null}
  </aside>
);

GridSidebarV2.propTypes = propTypes;
GridSidebarV2.defaultProps = defaultProps;

export default GridSidebarV2;
