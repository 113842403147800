import api from '../../api/DarthVaderClient';

const TERMS_VERSION = 'terms_v1';
const PRIVACY_VERSION = 'privacy_v1';

const companyUserConsentApi = '/consent-engine/company/user-consent';

export const updateTermsConsent = () => {
  api.put(companyUserConsentApi, {
    field: TERMS_VERSION,
  });
};

export const updatePrivacyConsent = () => {
  api.put(companyUserConsentApi, {
    field: PRIVACY_VERSION,
  });
};

export const getTermsConsent = () =>
  api.get(`${companyUserConsentApi}?field=${TERMS_VERSION}`);

export const getPrivacyConsent = () =>
  api.get(`${companyUserConsentApi}?field=${PRIVACY_VERSION}`);
