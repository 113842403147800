import CustomTest from './redux/CustomTest/CustomTestReducer';
import CustomTestQuestion from './redux/CustomTestQuestion/CustomTestQuestionReducer';
import TestCorrectionList from './redux/TestCorrection/TestCorrectionListReducer';
import TestCorrection from './redux/TestCorrection/TestCorrectionReducer';

export default {
  CustomTest,
  CustomTestQuestion,
  TestCorrection,
  TestCorrectionList,
};
