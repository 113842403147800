import { defineMessages } from 'react-intl';

const messages = defineMessages({
  addCandidate: {
    id: 'add-candidate',
    defaultMessage: 'Adicionar candidato',
  },
  basicInformation: {
    id: 'basic-information',
    defaultMessage: 'Informações básicas',
  },
  candidateAddSuccess: {
    id: 'candidate-add-success',
    defaultMessage: 'Candidato adicionado com sucesso',
  },
  candidateAddError: {
    id: 'candidate-add-error',
    defaultMessage: 'Erro ao adicionar candidato',
  },
  candidateEmail: {
    id: 'candidate-email',
    defaultMessage: 'E-mail do candidato',
  },
  candidateIsOnCurrentCompany: {
    id: 'candidate-is-on-current-company',
    defaultMessage:
      'já possui cadastro em sua empresa. Quer atribuir essa vaga?',
  },
  candidateIsOnCurrentJob: {
    id: 'candidate-is-on-current-job',
    defaultMessage: 'já está na vaga',
  },
  candidateManualInsertion: {
    id: 'candidate-manual-insertion',
    defaultMessage: 'Inserção manual de candidato',
  },
  candidateManualInsertionHint: {
    id: 'candidate-manual-insertion-hint',
    defaultMessage: 'Campos com * são obrigatórios',
  },
  mobileNumber: {
    id: 'mobile-number',
    defaultMessage: 'Celular',
  },
  chooseStep: {
    id: 'choose-step',
    defaultMessage: 'Escolha a etapa',
  },
  email: {
    id: 'email',
    defaultMessage: 'E-mail',
  },
  lastName: {
    id: 'last-name',
    defaultMessage: 'Sobrenome',
  },
  name: {
    id: 'name',
    defaultMessage: 'Nome',
  },
  relocateCandidateErrorMessage: {
    id: 'relocate-candidate-error-message',
    defaultMessage:
      'Ocorreu um erro ao atribuir a vaga, tente novamente mais tarde.',
  },
  relocateCandidateSuccessMessage: {
    id: 'relocate-candidate-success-message',
    defaultMessage:
      'Vaga atribuida com sucesso. Envie um e-mail ao candidato sobre essa vaga.',
  },
  relocateJob: {
    id: 'relocate-job',
    defaultMessage: 'Atribuir vaga',
  },
  retypeAnotherEmail: {
    id: 'retype-another-email',
    defaultMessage: 'Digitar outro e-mail',
  },
  sendInviteEmailToCandidate: {
    id: 'send-invite-email-to-candidate',
    defaultMessage:
      'Enviar e-mail convidando essa pessoa para o processo seletivo',
  },
  start: {
    id: 'start',
    defaultMessage: 'Começar',
  },
  viewCandidateProfile: {
    id: 'view-candidate-profile',
    defaultMessage: 'Visualizar perfil do candidato',
  },
  candidateInsertionSource: {
    id: 'candidate-insertion-source',
    defaultMessage: 'Origem da inserção',
  },
  candidateActiveContact: {
    id: 'candidate-active-contact',
    defaultMessage: 'Contato ativo do(a) candidato(a)',
  },
  events: {
    id: 'events',
    defaultMessage: 'Eventos',
  },
  hunting: {
    id: 'hunting',
    defaultMessage: 'Hunting',
  },
  externalReferralConsulting: {
    id: 'external-referral-consulting',
    defaultMessage: 'Indicação externa/consultoria',
  },
  internalReferral: {
    id: 'internal-referral',
    defaultMessage: 'Indicação interna',
  },
  chromeExtension: {
    id: 'chrome-extension',
    defaultMessage: 'LinkedIn',
  },
  candidateInsertionComment: {
    id: 'candidate-insertion-comment',
    defaultMessage: 'Anotações',
  },
  hideAnnotation: {
    id: 'hide-annotation',
    defaultMessage: 'Ocultar anotação',
  },
  hideAnnotationInfo: {
    id: 'hide-annotation-info',
    defaultMessage:
      'Comentários ficam na timeline dos candidatos e só podem ser visualizadas pela empresa.',
  },
  linkedinRsc: {
    id: 'linkedin-rsc',
    defaultMessage: 'Linkedin Recruiter RSC',
  },
  infotipEmailFromCandidate: {
    id: 'infotip-email-provided-for-candidate-only',
    defaultMessage:
      'Estes dados só podem ser fornecidos pela própria pessoa candidata',
  },
});

const getLabels = intl => ({
  addCandidate: intl.formatMessage(messages.addCandidate),
  basicInformation: intl.formatMessage(messages.basicInformation),
  candidateEmail: intl.formatMessage(messages.candidateEmail),
  candidateIsOnCurrentCompany: intl.formatMessage(
    messages.candidateIsOnCurrentCompany,
  ),
  candidateIsOnCurrentJob: intl.formatMessage(messages.candidateIsOnCurrentJob),
  candidateManualInsertion: intl.formatMessage(
    messages.candidateManualInsertion,
  ),
  candidateManualInsertionHint: intl.formatMessage(
    messages.candidateManualInsertionHint,
  ),
  mobileNumber: intl.formatMessage(messages.mobileNumber),
  chooseStep: intl.formatMessage(messages.chooseStep),
  email: intl.formatMessage(messages.email),
  lastName: intl.formatMessage(messages.lastName),
  name: intl.formatMessage(messages.name),
  relocateJob: intl.formatMessage(messages.relocateJob),
  retypeAnotherEmail: intl.formatMessage(messages.retypeAnotherEmail),
  sendInviteEmailToCandidate: intl.formatMessage(
    messages.sendInviteEmailToCandidate,
  ),
  start: intl.formatMessage(messages.start),
  viewCandidateProfile: intl.formatMessage(messages.viewCandidateProfile),
  candidateInsertionSource: intl.formatMessage(
    messages.candidateInsertionSource,
  ),
  candidateActiveContact: intl.formatMessage(messages.candidateActiveContact),
  events: intl.formatMessage(messages.events),
  hunting: intl.formatMessage(messages.hunting),
  externalReferralConsulting: intl.formatMessage(
    messages.externalReferralConsulting,
  ),
  internalReferral: intl.formatMessage(messages.internalReferral),
  chromeExtension: intl.formatMessage(messages.chromeExtension),
  candidateInsertionComment: intl.formatMessage(
    messages.candidateInsertionComment,
  ),
  hideAnnotation: intl.formatMessage(messages.hideAnnotation),
  hideAnnotationInfo: intl.formatMessage(messages.hideAnnotationInfo),
  linkedinRsc: intl.formatMessage(messages.linkedinRsc),
  infotipEmailFromCandidate: intl.formatMessage(
    messages.infotipEmailFromCandidate,
  ),
});

export default getLabels;
