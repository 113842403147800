export const translateTemplateToView = (template, markers) => {
  let translatedEmailBody = template.body;
  markers.forEach(marker => {
    translatedEmailBody = translatedEmailBody
      .split(marker.value)
      .join(marker.label);
  });

  let translatedEmailSubject = template.subject;
  markers.forEach(marker => {
    translatedEmailSubject = translatedEmailSubject
      .split(marker.value)
      .join(marker.label);
  });

  return {
    body: translatedEmailBody,
    subject: translatedEmailSubject,
    attachments: template.attachments,
  };
};

export const translateTemplateToModel = (template, markers) => {
  let translatedEmailBody = template.body;
  markers.forEach(marker => {
    translatedEmailBody = translatedEmailBody
      .split(marker.label)
      .join(marker.value);
  });

  let translatedEmailSubject = template.subject;
  markers.forEach(marker => {
    translatedEmailSubject = translatedEmailSubject
      .split(marker.label)
      .join(marker.value);
  });

  return {
    body: translatedEmailBody,
    subject: translatedEmailSubject,
  };
};
