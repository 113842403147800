import { string } from 'prop-types';
import React, { Fragment } from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';

const messages = defineMessages({
  hello: {
    defaultMessage: 'Olá',
    id: 'hello',
  },
  putYouAsIndicator: {
    defaultMessage: 'informou que você o(a) indicou para a vaga',
    id: 'put-you-as-indicator',
  },
  ofCompany: {
    defaultMessage: 'da empresa',
    id: 'of-company',
  },
  doYouConfirm: {
    defaultMessage:
      'Por isso, solicitamos sua confirmação para que esta indicação possa ser validada',
    id: 'do-you-confirm',
  },
});

const ConfirmMessage = ({
  indicatorName,
  candidateName,
  jobName,
  companyName,
  intl,
}) => (
  <Fragment>
    <p>
      {intl.formatMessage(messages.hello)}, <strong>{indicatorName}</strong>
    </p>
    <p>
      <strong>{candidateName}</strong>{' '}
      {intl.formatMessage(messages.putYouAsIndicator)}
      &nbsp;
      <strong>{jobName}</strong> {intl.formatMessage(messages.ofCompany)}
      &nbsp;
      <strong>{companyName}</strong>.{' '}
      {intl.formatMessage(messages.doYouConfirm)}.
    </p>
  </Fragment>
);

ConfirmMessage.propTypes = {
  indicatorName: string.isRequired,
  candidateName: string.isRequired,
  companyName: string.isRequired,
  jobName: string.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(ConfirmMessage);
