import * as moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import history from '../../history';
import HomeList from '../Home/components/HomeGridList/HomeList';
import HomeListItemTemplate from '../Home/components/HomeGridList/HomeListItemTemplate';
import InterviewEventListDetails from './components/InterviewEventListDetails';
import InterviewEventMenu from './components/InterviewEventMenu';
import { getByInterviewerId } from './InterviewEventAction';
import { getMessages } from './InterviewEventMessages';
import './InterviewEventHomeList.scss';

const TIMETABLE_KEY = 'interview_events_timetable';

const propTypes = {
  interviewEvents: PropTypes.array.isRequired,
  currentUser: PropTypes.object,
  isFetchingInterviewEvent: PropTypes.bool,
  intl: intlShape.isRequired,
  getByInterviewerId: PropTypes.func.isRequired,
  lg: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  xl: PropTypes.number,
  xs: PropTypes.number,
};

const InterviewEventHomeList = props => {
  const {
    interviewEvents,
    isFetchingInterviewEvent = false,
    intl,
    currentUser,
    lg,
    md,
    sm,
    xl,
    xs,
  } = props;
  const messages = getMessages(intl);

  const emptyListMessage = messages.noInterviewEventScheduled;

  const [timetable, setTimetable] = useState('day');

  const handleChangeTimetable = timetableOption => {
    if (timetableOption !== timetable) {
      setTimetable(timetableOption);
      localStorage.setItem(TIMETABLE_KEY, timetableOption);
    }
  };

  useEffect(() => {
    const selectedTimetable = localStorage.getItem(TIMETABLE_KEY);
    if (selectedTimetable) {
      setTimetable(selectedTimetable);
      return;
    }
    localStorage.setItem(TIMETABLE_KEY, 'day');
  }, []);

  const timetableOptions = [
    {
      title: messages.interviewsOfTheDay,
      action: () => handleChangeTimetable('day'),
    },
    {
      title: messages.currentWeekInterviews,
      action: () => handleChangeTimetable('week'),
    },
    {
      title: messages.currentMonthInterviews,
      action: () => handleChangeTimetable('month'),
    },
  ];

  useEffect(() => {
    if (currentUser && currentUser.id) {
      const interviewerId = currentUser.id;
      const timezone = moment.tz.guess(true);
      props.getByInterviewerId({ interviewerId, timezone, timetable });
    }
  }, [props.currentUser && props.currentUser.id, timetable]);

  return (
    <HomeList
      data={interviewEvents}
      isLoading={isFetchingInterviewEvent}
      className="interview-event"
      title={
        <InterviewEventMenu
          options={timetableOptions}
          activeOption={['day', 'week', 'month'].indexOf(timetable)}
        />
      }
      emptyListMessage={emptyListMessage}
      emptyListImage={`${process.env.REACT_APP_ASSETS_URL}/empty-list-interviews/empty-list.png`}
      lg={lg}
      md={md}
      sm={sm}
      xl={xl}
      xs={xs}
    >
      <HomeListItemTemplate
        component={InterviewEventListDetails}
        clickAnalytics={{
          category: '[Home] Interview Events List',
          action: `click-go-to-candidate-profile_${timetable}`,
        }}
        history={history}
      />
    </HomeList>
  );
};

InterviewEventHomeList.propTypes = propTypes;

const mapStateToProps = state => ({
  interviewEvents:
    state.reducers.InterviewEvents.InterviewEvents.interviewEvents,
  isFetchingInterviewEvent:
    state.reducers.InterviewEvents.InterviewEvents.isFetchingInterviewEvent,
  currentUser: state.reducers.Authentication.currentUser,
});

export default withRouter(
  injectIntl(
    connect(mapStateToProps, {
      getByInterviewerId,
    })(InterviewEventHomeList),
  ),
);
