export const GET_SHOW_NEW_TERMS_AND_PRIVACY_REQUEST =
  'TermsAndPrivacyUpdateNotice/GET_SHOW_NEW_TERMS_AND_PRIVACY_REQUEST';
export const GET_SHOW_NEW_TERMS_AND_PRIVACY_SUCCESS =
  'TermsAndPrivacyUpdateNotice/GET_SHOW_NEW_TERMS_AND_PRIVACY_SUCCESS';
export const GET_SHOW_NEW_TERMS_AND_PRIVACY_FAIL =
  'TermsAndPrivacyUpdateNotice/GET_SHOW_NEW_TERMS_AND_PRIVACY_FAIL';

export const getShowNewTermsAndPrivacy = () => ({
  type: GET_SHOW_NEW_TERMS_AND_PRIVACY_REQUEST,
});
