import { buildToast, ToastTypes } from '@gupy/front-commons';
import { call, put, takeLatest } from 'redux-saga/effects';
import history from '../../history';
import { JobTemplatesActionTypes } from './JobTemplatesAction';
import JobTemplatesService from './JobTemplatesService';

function* init({ page, searchString }) {
  try {
    const response = yield JobTemplatesService.get(searchString, page);
    yield put({
      type: JobTemplatesActionTypes.INIT_SUCCESS,
      templates: response.body.data,
      pagination: response.body.summary,
    });
  } catch (error) {
    yield put({
      type: JobTemplatesActionTypes.INIT_FAIL,
    });
  }
}

function* search(action) {
  const { searchString } = action;
  yield put({
    type: JobTemplatesActionTypes.SEARCH,
  });

  try {
    const response = yield JobTemplatesService.get(searchString);
    yield put({
      type: JobTemplatesActionTypes.SEARCH_SUCCESS,
      templates: response.body.data,
      pagination: response.body.summary,
    });
  } catch (error) {
    yield put({
      type: JobTemplatesActionTypes.SEARCH_FAIL,
    });
  }
}

function* del(action) {
  const { templateId, messages, page } = action;

  try {
    yield JobTemplatesService.delete(templateId);

    yield put({
      type: JobTemplatesActionTypes.DELETE_SUCCESS,
      toast: buildToast(messages.success, ToastTypes.success),
      page,
    });
  } catch (error) {
    yield put({
      type: JobTemplatesActionTypes.DELETE_FAIL,
      toast: buildToast(error.body.message, ToastTypes.error),
    });
  }
}

function* duplicate(action) {
  const {
    payload: { templateId, messages, jobPath },
  } = action;

  try {
    const { body } = yield call(JobTemplatesService.duplicate, templateId);

    yield put({
      type: JobTemplatesActionTypes.DUPLICATE_SUCCESS,
      toast: buildToast(messages.success, ToastTypes.success),
    });
    yield call(history.push, jobPath.replace(':id', body.id));
  } catch (error) {
    yield put({
      type: JobTemplatesActionTypes.DUPLICATE_FAIL,
      toast: buildToast(error.body.message, ToastTypes.error),
    });
  }
}

function* JobTemplatesSaga() {
  yield takeLatest(JobTemplatesActionTypes.INIT, init);
  yield takeLatest(JobTemplatesActionTypes.CHANGE_SEARCH, search);
  yield takeLatest(JobTemplatesActionTypes.DELETE, del);
  yield takeLatest(JobTemplatesActionTypes.DELETE_SUCCESS, init);
  yield takeLatest(JobTemplatesActionTypes.DUPLICATE, duplicate);
  yield takeLatest(JobTemplatesActionTypes.DUPLICATE_SUCCESS, init);
}

export { duplicate };

export default JobTemplatesSaga;
