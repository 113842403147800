import { buildToast, getSuggestions, ToastTypes } from '@gupy/front-commons';
import { put, takeLatest } from 'redux-saga/effects';
import { getAddressSuggestionsSuccess } from '../../actions/Subsidiary/SubsidiaryActions';
import SubsidiaryActionTypes from '../../constants/Subsidiary/SubsidiaryActionTypes';
import SubsidiaryForm from '../../forms/Subsidiary/SubsidiaryForm';
import GeolocationService from '../../services/Geolocation/GeolocationService';
import SubsidiaryService from '../../services/Subsidiary/SubsidiaryService';

function* getAll() {
  const form = new SubsidiaryForm();

  try {
    const response = yield SubsidiaryService.getAll();

    yield put({
      type: SubsidiaryActionTypes.GET_ALL_SUCCESS,
      subsidiaries: response.body.data,
    });
  } catch (error) {
    yield put({
      type: SubsidiaryActionTypes.GET_ALL_FAIL,
      validation: form.validateErrorResponse(error),
    });
  }
}

function* post(action) {
  const { payload, messages } = action;
  const form = new SubsidiaryForm();

  form.populate(payload);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: SubsidiaryActionTypes.POST_FAIL,
      validation,
    });
  } else {
    try {
      yield SubsidiaryService.post(payload);

      yield put({
        type: SubsidiaryActionTypes.POST_SUCCESS,
        toast: buildToast(messages.success, ToastTypes.success),
      });
    } catch (error) {
      yield put({
        type: SubsidiaryActionTypes.POST_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* update(action) {
  const { payload, messages } = action;
  const form = new SubsidiaryForm();

  const { id } = payload;
  const newPayload = { ...payload, id: undefined };

  form.populate(newPayload);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: SubsidiaryActionTypes.PUT_FAIL,
      validation,
    });
  } else {
    try {
      yield SubsidiaryService.put(id, newPayload);

      yield put({
        type: SubsidiaryActionTypes.PUT_SUCCESS,
        toast: buildToast(messages.success, ToastTypes.success),
      });
    } catch (error) {
      yield put({
        type: SubsidiaryActionTypes.PUT_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* del(action) {
  const { id, messages } = action;

  try {
    yield SubsidiaryService.delete(id);

    yield put({
      type: SubsidiaryActionTypes.DELETE_SUCCESS,
      toast: buildToast(messages.success, ToastTypes.success),
    });
  } catch (error) {
    const form = new SubsidiaryForm();
    yield put({
      type: SubsidiaryActionTypes.DELETE_FAIL,
      validation: form.validateErrorResponse(error),
      toast: buildToast(error.body.message, ToastTypes.error),
    });
  }
}

function* getAddressSuggestions({ input }) {
  try {
    const suggestions = yield getSuggestions(
      input,
      'address',
      GeolocationService.getSuggestions,
    );
    yield put(getAddressSuggestionsSuccess(suggestions));
  } catch (error) {
    yield put({
      type: SubsidiaryActionTypes.CHANGE_FORM_ADDRESS_SUGGESTIONS_FAIL,
      error,
    });
  }
}

function* subsidiarySaga() {
  yield takeLatest(SubsidiaryActionTypes.GET_ALL, getAll);
  yield takeLatest(SubsidiaryActionTypes.POST, post);
  yield takeLatest(SubsidiaryActionTypes.POST_SUCCESS, getAll);
  yield takeLatest(SubsidiaryActionTypes.PUT, update);
  yield takeLatest(SubsidiaryActionTypes.PUT_SUCCESS, getAll);
  yield takeLatest(SubsidiaryActionTypes.DELETE, del);
  yield takeLatest(SubsidiaryActionTypes.DELETE_SUCCESS, getAll);
  yield takeLatest(
    SubsidiaryActionTypes.CHANGE_FORM_ADDRESS_SUGGESTIONS,
    getAddressSuggestions,
  );
}

export default subsidiarySaga;
