import { combineReducers } from 'redux';
import AddressActionTypes from '../../constants/Address/AddressActionTypes';
import AddressStatus from '../../constants/Address/AddressStatus';
import SubsidiaryActionTypes from '../../constants/Subsidiary/SubsidiaryActionTypes';

const isSaving = (state = false, action) => {
  switch (action.type) {
    case SubsidiaryActionTypes.POST:
    case SubsidiaryActionTypes.PUT:
      return true;
    case SubsidiaryActionTypes.POST_SUCCESS:
    case SubsidiaryActionTypes.POST_FAIL:
    case SubsidiaryActionTypes.PUT_SUCCESS:
    case SubsidiaryActionTypes.PUT_FAIL:
      return false;
    default:
      return state;
  }
};

const initialModelForm = {
  id: undefined,
  addressStatus: AddressStatus.empty,
  name: '',
  addressLine: '',
  addressLatitude: null,
  addressLongitude: null,
};
const emptyAdress = {
  addressCountry: '',
  addressCountryShortName: '',
  addressCity: '',
  addressStreet: '',
  addressStateShortName: '',
  addressState: '',
  addressZipCode: '',
  addressNumber: '',
  addressLongitude: null,
  addressLatitude: null,
};

const modelForm = (state = initialModelForm, action) => {
  switch (action.type) {
    case SubsidiaryActionTypes.INIT:
    case SubsidiaryActionTypes.POST_SUCCESS:
    case SubsidiaryActionTypes.PUT_SUCCESS:
    case SubsidiaryActionTypes.CLEAR_FORM:
      return initialModelForm;
    case SubsidiaryActionTypes.CHANGE_FORM: {
      let addressModel = null;

      if (action.modelForm.addressLine) {
        addressModel = {
          ...emptyAdress,
          addressStatus: AddressStatus.editing,
        };
      }

      return {
        ...state,
        ...action.modelForm,
        ...addressModel,
      };
    }
    case SubsidiaryActionTypes.EDIT: {
      const addressStatus =
        action.modelForm.addressLine && action.modelForm.addressLine !== ''
          ? AddressStatus.complete
          : AddressStatus.empty;

      return {
        ...initialModelForm,
        ...action.modelForm,
        addressStatus,
      };
    }

    case SubsidiaryActionTypes.SELECT_ADDRESS_SUGGESTION: {
      const { placeId, ...suggestion } = action.suggestion;

      const addressModel = {
        ...suggestion,
        addressStatus: AddressStatus.empty,
      };

      return {
        ...state,
        ...addressModel,
      };
    }

    case AddressActionTypes.CHANGE_FORM_ADDRESS_BLUR: {
      if (state.addressStatus === AddressStatus.editing) {
        return {
          ...state,
          addressLine: '',
          addressStatus: AddressStatus.empty,
        };
      }

      return state;
    }
    case AddressActionTypes.CLOSE_ADDRESS_MODAL:
      return {
        ...state,
        addressLine: '',
        addressStatus: AddressStatus.empty,
      };
    case AddressActionTypes.SAVE_ADDRESS_MODAL_SUCCESS: {
      const line = [
        action.modelForm.addressStreet,
        action.modelForm.addressCity,
        action.modelForm.addressState,
        action.modelForm.addressCountry,
      ];

      return {
        ...state,
        ...action.modelForm,
        addressLine: line.join(', '),
        addressStatus: AddressStatus.complete,
      };
    }
    default:
      return state;
  }
};

const validation = (state = {}, action) => {
  switch (action.type) {
    case SubsidiaryActionTypes.POST:
    case SubsidiaryActionTypes.PUT:
    case SubsidiaryActionTypes.EDIT:
    case SubsidiaryActionTypes.CLEAR_FORM:
      return {};
    case SubsidiaryActionTypes.POST_FAIL:
    case SubsidiaryActionTypes.PUT_FAIL:
    case SubsidiaryActionTypes.GET_ALL_FAIL:
      return action.validation;
    default:
      return state;
  }
};

const buildAdressLabel = model => {
  const label = [];
  if (model.addressStreet) label.push(model.addressStreet);
  if (model.addressZipCode) label.push(model.addressZipCode);
  if (model.addressCity) label.push(model.addressCity);
  if (model.addressState) label.push(model.addressState);
  if (model.addressCountry) label.push(model.addressCountry);
  return label.join(', ');
};

const subsidiaries = (state = [], action) => {
  switch (action.type) {
    case SubsidiaryActionTypes.GET_ALL_SUCCESS:
      return action.subsidiaries.map(model =>
        Object.assign({}, model, {
          addressLabel: buildAdressLabel(model),
        }),
      );
    case SubsidiaryActionTypes.GET_ALL_FAIL:
      return [];
    default:
      return state;
  }
};

export const addressSuggestions = (state = [], action) => {
  switch (action.type) {
    case SubsidiaryActionTypes.CHANGE_FORM_ADDRESS_SUGGESTIONS_SUCCESS:
      return action.suggestions;
    case SubsidiaryActionTypes.CHANGE_FORM_ADDRESS_SUGGESTIONS_FAIL:
      return [];
    default:
      return state;
  }
};

export default combineReducers({
  modelForm,
  isSaving,
  validation,
  subsidiaries,
  addressSuggestions,
});
