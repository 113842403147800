import {
  closeJobDeletionModal,
  JobDeletionActionTypes,
  openJobDeletionModal,
} from './JobDeletionAction';
import JobDeletionModal from './JobDeletionModal';
import JobDeletionReducer from './JobDeletionReducer';

const JobDeletionAction = {
  openJobDeletionModal,
  closeJobDeletionModal,
};

export {
  JobDeletionModal,
  JobDeletionAction,
  JobDeletionReducer,
  JobDeletionActionTypes,
};
