import { CareerPageVisibilityEnum } from '@gupy/enums';
import { combineReducers } from 'redux';
import rscChildAppStatusEnum from './enums/RscChildAppStatusEnum';
import { IntegrationsActionTypes } from './IntegrationsAction';

export const integrations = (state = {}, action) => {
  switch (action.type) {
    case IntegrationsActionTypes.INIT:
      return {};
    case IntegrationsActionTypes.INIT_INTEGRATIONS_SUCCESS:
    case IntegrationsActionTypes.SAVE_INTEGRATIONS:
    case IntegrationsActionTypes.UPDATE_INTEGRATIONS_FORM:
      return action.integrations;
    default:
      return state;
  }
};

export const jsonSchema = (state = {}, action) => {
  switch (action.type) {
    case IntegrationsActionTypes.INIT:
      return {};
    case IntegrationsActionTypes.INIT_INTEGRATIONS_SUCCESS:
      return action.jsonSchema;
    default:
      return state;
  }
};

export const uiSchema = (state = {}, action) => {
  switch (action.type) {
    case IntegrationsActionTypes.INIT:
      return {};
    case IntegrationsActionTypes.INIT_INTEGRATIONS_SUCCESS:
      return action.uiSchema;
    default:
      return state;
  }
};

export const isSaving = (state = false, action) => {
  switch (action.type) {
    case IntegrationsActionTypes.SAVE_INTEGRATIONS:
      return true;
    case IntegrationsActionTypes.SAVE_INTEGRATIONS_SUCCESS:
    case IntegrationsActionTypes.SAVE_INTEGRATIONS_FAIL:
      return false;
    default:
      return state;
  }
};

export const publicCareerPages = (state = [], action) => {
  switch (action.type) {
    case IntegrationsActionTypes.INIT:
      return [];
    case IntegrationsActionTypes.INIT_INTEGRATIONS_SUCCESS:
      return action.publicCareerPages;
    default:
      return state;
  }
};

export const jobBoardsCustomIds = (state = [], action) => {
  switch (action.type) {
    case IntegrationsActionTypes.INIT:
      return [];
    case IntegrationsActionTypes.INIT_INTEGRATIONS_SUCCESS:
    case IntegrationsActionTypes.SAVE_JOB_BOARDS_CUSTOM_IDS:
      return action.jobBoardsCustomIds;
    default:
      return state;
  }
};

export const initialJobBoardsCustomIds = (state = [], action) => {
  switch (action.type) {
    case IntegrationsActionTypes.INIT:
      return [];
    case IntegrationsActionTypes.INIT_INTEGRATIONS_SUCCESS:
      return action.initialJobBoardsCustomIds;
    default:
      return state;
  }
};

export const childAppCreated = (state = null, action) => {
  switch (action.type) {
    case IntegrationsActionTypes.CREATE_RSC_LINKEDIN_INTEGRATION_CHILD_APP_SUCCESS:
      return action.childAppCreated;
    case IntegrationsActionTypes.CREATE_RSC_LINKEDIN_INTEGRATION_CHILD_APP_CLEAR:
      return null;
    default:
      return state;
  }
};

export const rscLinkedinIntegrationRestartingChildApps = (
  state = [],
  action,
) => {
  switch (action.type) {
    case IntegrationsActionTypes.RESTART_SYNC_RSC_LINKEDIN_INTEGRATION_CHILD_APP:
      return [...(state || []), action.childApp.id];
    case IntegrationsActionTypes.RESTART_SYNC_RSC_LINKEDIN_INTEGRATION_CHILD_APP_FAIL:
    case IntegrationsActionTypes.RESTART_SYNC_RSC_LINKEDIN_INTEGRATION_CHILD_APP_SUCCESS:
      return state.filter(childAppId => childAppId !== action.childApp.id);
    default:
      return state;
  }
};

export const rscLinkedinIntegrationChildApps = (state = [], action) => {
  const getAdaptedChildApps = childApps =>
    childApps
      ? childApps.map(childApp => ({
          ...childApp,
          ...(childApp.status === rscChildAppStatusEnum.READY_TO_SYNC_DATA && {
            name: '',
          }),
          ...(!childApp.features && { features: [] }),
          ...(!childApp.careerPages && { careerPages: [] }),
          formValidationErrors: {},
          nameWithFormValidationErrors: '',
          careerPagesWithFormValidationErrors: [],
          hasDeleteEmail: action.rscChildAppsWithDeleteEmail
            ? action.rscChildAppsWithDeleteEmail.some(
                item => item.childAppId === childApp.id,
              )
            : false,
        }))
      : [];

  switch (action.type) {
    case IntegrationsActionTypes.INIT:
      return [];

    case IntegrationsActionTypes.GET_RSC_LINKEDIN_INTEGRATION_CHILD_APPS_SUCCESS:
    case IntegrationsActionTypes.INIT_LINKEDIN_RSC_SUCCESS: {
      return getAdaptedChildApps(action.rscLinkedinIntegrationChildApps);
    }

    case IntegrationsActionTypes.SET_RSC_LINKEDIN_INTEGRATION_CHILD_APP_FORM_VALIDATION_ERROR:
      return state.map(childApp => ({
        ...childApp,
        ...(action.childApp.id === childApp.id && {
          formValidationErrors: {
            ...childApp.formValidationErrors,
            ...action.formValidationErrors,
          },
          nameWithFormValidationErrors: action.childApp.name,
          careerPagesWithFormValidationErrors:
            action.childApp.careerPagesIds.map(careerPageId => ({
              careerPageId: Number(careerPageId),
            })),
        }),
      }));

    default:
      return state;
  }
};

export const rscLinkedinIntegrationCareerPages = (state = [], action) => {
  switch (action.type) {
    case IntegrationsActionTypes.INIT:
      return [];
    case IntegrationsActionTypes.INIT_LINKEDIN_RSC_SUCCESS: {
      const onlyAllowedCareerPages = careerPage =>
        careerPage.visibility === CareerPageVisibilityEnum.public ||
        careerPage.visibility === CareerPageVisibilityEnum.internal ||
        careerPage.visibility === CareerPageVisibilityEnum.confidential;

      const getFilteredCareerPages = careerPages =>
        careerPages ? careerPages.filter(onlyAllowedCareerPages) : [];

      return getFilteredCareerPages(action.rscLinkedinIntegrationCareerPages);
    }
    default:
      return state;
  }
};

export const rscLinkedinIntegrationServiceOnError = (state = false, action) => {
  switch (action.type) {
    case IntegrationsActionTypes.INIT_LINKEDIN_RSC_SUCCESS:
      return action.rscLinkedinIntegrationServiceOnError;
    default:
      return state;
  }
};

export default combineReducers({
  integrations,
  jsonSchema,
  uiSchema,
  isSaving,
  publicCareerPages,
  jobBoardsCustomIds,
  initialJobBoardsCustomIds,
  rscLinkedinIntegrationRestartingChildApps,
  childAppCreated,
  rscLinkedinIntegrationChildApps,
  rscLinkedinIntegrationCareerPages,
  rscLinkedinIntegrationServiceOnError,
});
