import { buildToast, ToastTypes } from '@gupy/front-commons';
import ScrollHelper from '@gupy/front-helpers/src/helpers/ScrollHelper';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  getTestProvidersSuccess,
  getTestsFromProviderFail,
  getTestsFromProviderSuccess,
} from '../../../actions/Job/JobStep/JobStepCustomAction';
import JobStepCustomActionTypes from '../../../constants/Job/JobStep/JobStepCustomActionTypes';
import JobStepTypes from '../../../constants/Job/JobStep/JobStepTypes';
import questionFormService from '../../../containers/Job/JobStep/components/JobStepQuestionForm/hooks/JobStepQuestionForm.service';
import JobStepCustomForm from '../../../forms/Job/JobStep/JobStepCustomForm';
import JobStepCustomOnlineForm from '../../../forms/Job/JobStep/JobStepCustomOnlineForm';
import history from '../../../history';
import JobService from '../../../services/Job/JobService';
import TestProvidersService from '../../../services/TestProviders/TestProvidersService';

function* getJobStepCustom(action) {
  try {
    const response = yield JobService.getJobStep(action.jobId, action.stepId);

    yield put({
      type: JobStepCustomActionTypes.GET_SUCCESS,
      modelForm: response.body,
    });
  } catch (error) {
    yield put({
      type: JobStepCustomActionTypes.GET_FAIL,
    });
  }
}

function* postJobStepCustom(action) {
  const { toastMessages, ...payload } = action.payload;
  const showToast = !!toastMessages;
  const hasQuestionForm = !!payload.questionForm;

  let form;
  if (payload.type === JobStepTypes.online) {
    form = new JobStepCustomOnlineForm();
  } else {
    form = new JobStepCustomForm();
  }

  form.populate(payload);

  const validation = form.validateModel();
  if (validation) {
    yield put({
      type: JobStepCustomActionTypes.POST_FAIL,
      validation,
    });
  } else {
    try {
      const { statusCode, body } = yield JobService.postStep(payload);
      if (hasQuestionForm && statusCode === 200 && body.id) {
        yield questionFormService.postQuestionForm(
          { jobId: payload.jobId, stepId: body.id },
          { questionForm: payload.questionForm },
        );
      }

      yield put({
        type: JobStepCustomActionTypes.POST_SUCCESS,
        modelForm: body,
        toast: showToast
          ? buildToast(toastMessages.successMessage, ToastTypes.success, {
              autoClose: 5000,
            })
          : undefined,
      });
      yield call(history.push, `/companies/jobs/edit/${payload.jobId}/steps`);
    } catch (error) {
      yield put({
        type: JobStepCustomActionTypes.POST_FAIL,
        validation: form.validateErrorResponse(error),
        toast: showToast
          ? buildToast(toastMessages.errorMessage, ToastTypes.error, {
              autoClose: 5000,
            })
          : undefined,
      });
      ScrollHelper.scrollToValidation();
    }
  }
}

function* patchJobStepCustom(action) {
  const { payload } = action;
  const hasQuestionForm = !!payload.questionForm;

  let form;
  if (payload.type === JobStepTypes.online) {
    form = new JobStepCustomOnlineForm();
  } else {
    form = new JobStepCustomForm();
  }

  form.populate(payload);

  const validation = form.validateModel();
  if (validation) {
    yield put({
      type: JobStepCustomActionTypes.PATCH_FAIL,
      validation,
    });
  } else {
    try {
      const { statusCode, body } = yield JobService.patchStep(payload);
      if (
        hasQuestionForm &&
        payload.questionForm.edited &&
        statusCode === 200
      ) {
        yield questionFormService.postQuestionForm(
          { jobId: payload.jobId, stepId: payload.stepId },
          { questionForm: payload.questionForm },
        );
      }

      yield put({
        type: JobStepCustomActionTypes.PATCH_SUCCESS,
        modelForm: body,
      });
      yield call(history.push, `/companies/jobs/edit/${payload.jobId}/steps/`);
    } catch (error) {
      yield put({
        type: JobStepCustomActionTypes.PATCH_FAIL,
        validation: form.validateErrorResponse(error),
      });

      ScrollHelper.scrollToValidation();
    }
  }
}

function* validateJobStepCustom(action) {
  const { payload, jobPath } = action;
  const form = new JobStepCustomForm();

  form.populate(payload);

  const validation = form.validateModel();
  if (validation) {
    yield put({
      type: JobStepCustomActionTypes.VALIDATE_JOB_STEP_CUSTOM_FAIL,
      validation,
    });
  } else {
    yield call(history.push, jobPath);
  }
}

function* getTestProviders() {
  try {
    const response = yield TestProvidersService.getAvailableProviders();
    yield put(getTestProvidersSuccess(response.body));
  } catch (error) {
    yield put({
      type: JobStepCustomActionTypes.GET_TEST_PROVIDERS_FAIL,
      error,
    });
  }
}

function* getAllTestsFromProvider(action) {
  const { testProvider, language } = action;
  try {
    const response = yield TestProvidersService.getAllTestsFromProvider(
      testProvider.uuid,
      language,
    );

    yield put(getTestsFromProviderSuccess(response.body.payload, testProvider));
  } catch (error) {
    yield put(getTestsFromProviderFail(error, testProvider));
  }
}

function* JobStepCustomSaga() {
  yield takeLatest(JobStepCustomActionTypes.GET, getJobStepCustom);
  yield takeLatest(JobStepCustomActionTypes.POST, postJobStepCustom);
  yield takeLatest(JobStepCustomActionTypes.PATCH, patchJobStepCustom);
  yield takeLatest(
    JobStepCustomActionTypes.VALIDATE_JOB_STEP_CUSTOM,
    validateJobStepCustom,
  );
  yield takeLatest(
    JobStepCustomActionTypes.GET_TEST_PROVIDERS,
    getTestProviders,
  );
  yield takeEvery(
    JobStepCustomActionTypes.GET_TESTS_FROM_PROVIDER,
    getAllTestsFromProvider,
  );
}

export default JobStepCustomSaga;
