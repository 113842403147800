import { combineReducers } from 'redux';
import { UserAccessProfileActionTypes } from './Actions';

const initialModelForm = {
  id: undefined,
  name: '',
  struct: {
    gt: {},
    tree: {},
  },
  groupType: [],
};

export const isSaving = (state = false, action) => {
  switch (action.type) {
    case UserAccessProfileActionTypes.GET:
    case UserAccessProfileActionTypes.GET_STRUCTURE:
    case UserAccessProfileActionTypes.POST:
    case UserAccessProfileActionTypes.PUT:
      return true;
    case UserAccessProfileActionTypes.GET_SUCCESS:
    case UserAccessProfileActionTypes.GET_STRUCTURE_SUCCESS:
    case UserAccessProfileActionTypes.GET_STRUCTURE_FAIL:
    case UserAccessProfileActionTypes.GET_FAIL:
    case UserAccessProfileActionTypes.POST_SUCCESS:
    case UserAccessProfileActionTypes.POST_FAIL:
    case UserAccessProfileActionTypes.PUT_SUCCESS:
    case UserAccessProfileActionTypes.PUT_FAIL:
      return false;
    default:
      return state;
  }
};

export const searched = (state = false, action) => {
  switch (action.type) {
    case UserAccessProfileActionTypes.SEARCH:
      return true;
    default:
      return state;
  }
};

export const types = (state = [], action) => {
  switch (action.type) {
    case UserAccessProfileActionTypes.GET_TYPES_SUCCESS:
      return action.types;
    default:
      return state;
  }
};

export const modelForm = (state = initialModelForm, action) => {
  switch (action.type) {
    case UserAccessProfileActionTypes.POST_SUCCESS:
    case UserAccessProfileActionTypes.GET_STRUCTURE:
    case UserAccessProfileActionTypes.PUT_SUCCESS:
    case UserAccessProfileActionTypes.CLEAR_FORM:
      return initialModelForm;
    case UserAccessProfileActionTypes.GET_SUCCESS:
    case UserAccessProfileActionTypes.GET_STRUCTURE_SUCCESS:
    case UserAccessProfileActionTypes.CHANGE_FORM:
      return Object.assign({}, state, action.modelForm);
    case UserAccessProfileActionTypes.EDIT:
      return Object.assign({}, initialModelForm, action.modelForm);
    default:
      return state;
  }
};

export const validation = (state = {}, action) => {
  switch (action.type) {
    case UserAccessProfileActionTypes.POST:
    case UserAccessProfileActionTypes.PUT:
    case UserAccessProfileActionTypes.EDIT:
    case UserAccessProfileActionTypes.CLEAR_FORM:
      return {};
    case UserAccessProfileActionTypes.POST_FAIL:
    case UserAccessProfileActionTypes.PUT_FAIL:
    case UserAccessProfileActionTypes.EDIT_FAIL:
      return action.validation;
    default:
      return state;
  }
};

export const isLoading = (state = false, action) => {
  switch (action.type) {
    case UserAccessProfileActionTypes.GET_ALL:
    case UserAccessProfileActionTypes.GET:
    case UserAccessProfileActionTypes.GET_STRUCTURE:
    case UserAccessProfileActionTypes.TYPE_SEARCH:
    case UserAccessProfileActionTypes.SEARCH:
      return true;
    case UserAccessProfileActionTypes.GET_ALL_SUCCESS:
    case UserAccessProfileActionTypes.GET_STRUCTURE_SUCCESS:
    case UserAccessProfileActionTypes.GET_SUCCESS:
    case UserAccessProfileActionTypes.GET_ALL_FAIL:
    case UserAccessProfileActionTypes.GET_FAIL:
    case UserAccessProfileActionTypes.SEARCH_SUCCESS:
    case UserAccessProfileActionTypes.SEARCH_FAIL:
      return false;
    default:
      return state;
  }
};

export const userAccessProfiles = (state = [], action) => {
  switch (action.type) {
    case UserAccessProfileActionTypes.GET_ALL:
    case UserAccessProfileActionTypes.SEARCH:
      return [];
    case UserAccessProfileActionTypes.GET_ALL_SUCCESS:
      return action.userAccessProfiles;
    case UserAccessProfileActionTypes.GET_ALL_FAIL:
      return [];
    case UserAccessProfileActionTypes.SEARCH_SUCCESS:
      return action.userAccessProfiles.length > 0
        ? action.userAccessProfiles
        : [];
    default:
      return state;
  }
};

export default combineReducers({
  modelForm,
  isSaving,
  isLoading,
  types,
  searched,
  validation,
  userAccessProfiles,
});
