import { DialogConfirm } from '@gupy/design-system';
import { bool, func } from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';

const messages = defineMessages({
  areYouSure: {
    defaultMessage: 'Ao confirmar você não poderá mudar sua resposta.',
    id: 'are-you-sure',
  },
  confirm: {
    defaultMessage: 'Confirmar',
    id: 'confirm',
  },
  cancel: {
    defaultMessage: 'Cancelar',
    id: 'cancel',
  },
  attention: {
    defaultMessage: 'Atenção',
    id: 'warning',
  },
});

const ModalConfirmAction = ({ isOpen, onConfirm, onCancel, intl }) => (
  <DialogConfirm
    id="confirm-indication-modal-form"
    open={isOpen}
    confirmButton={{
      label: intl.formatMessage(messages.confirm),
      action: onConfirm,
    }}
    cancelButton={{
      label: intl.formatMessage(messages.cancel),
      action: onCancel,
    }}
    title={intl.formatMessage(messages.attention)}
    description={intl.formatMessage(messages.areYouSure)}
  />
);

ModalConfirmAction.propTypes = {
  intl: intlShape.isRequired,
  onConfirm: func.isRequired,
  onCancel: func.isRequired,
  isOpen: bool,
};

ModalConfirmAction.defaultProps = {
  isOpen: false,
};

export default injectIntl(ModalConfirmAction);
