import { combineReducers } from 'redux';
import * as actionTypes from './ActionTypes';
import {
  formatCompanyBranchToTreeStruct,
  injectNodeInsideCompanyBranches,
} from './scripts/formater';

const initialCompanyBranches = {
  list: [],
};

export default combineReducers({
  companyBranches: (state = initialCompanyBranches, action) => {
    switch (action.type) {
      case actionTypes.GET_COMPANY_BRANCHES_SUCCESS: {
        const { items, level, branchLabel } = action.payload;

        return {
          ...state,
          list: injectNodeInsideCompanyBranches({
            tree: state.list,
            level,
            branchLabel,
            items,
          }),
        };
      }
      case actionTypes.PATCH_COMPANY_BRANCH_SUCCESS:
      case actionTypes.DELETE_COMPANY_BRANCH_SUCCESS:
      case actionTypes.POST_COMPANY_BRANCHES_CSV_SUCCESS:
        return {
          ...state,
          list: formatCompanyBranchToTreeStruct([...action.payload]),
        };
      default:
        return state;
    }
  },

  isDownloading: (state = false, action) => {
    switch (action.type) {
      case actionTypes.GET_COMPANY_BRANCHES_CSV_TEMPLATE:
      case actionTypes.GET_COMPANY_BRANCHES_CURRENT_CSV:
        return true;
      case actionTypes.GET_COMPANY_BRANCHES_CSV_TEMPLATE_SUCCESS:
      case actionTypes.GET_COMPANY_BRANCHES_CSV_TEMPLATE_FAIL:
      case actionTypes.GET_COMPANY_BRANCHES_CURRENT_CSV_SUCCESS:
      case actionTypes.GET_COMPANY_BRANCHES_CURRENT_CSV_FAIL:
        return false;
      default:
        return state;
    }
  },
  isNodeModalOpen: (state = false, action) => {
    switch (action.type) {
      case actionTypes.SET_NODE_MODAL_IS_OPEN:
        return action.payload;
      case actionTypes.PATCH_COMPANY_BRANCH_SUCCESS:
        return false;
      default:
        return state;
    }
  },
  apiErrorMessage: (state = '', action) => {
    switch (action.type) {
      case actionTypes.PATCH_COMPANY_BRANCH_SUCCESS:
        return '';
      case actionTypes.PATCH_COMPANY_BRANCH_FAIL:
        return action.payload ? action.payload : '';
      default:
        return state;
    }
  },
  isLoading: (state = true, action) => {
    switch (action.type) {
      case actionTypes.GET_COMPANY_BRANCHES_SUCCESS:
      case actionTypes.GET_COMPANY_BRANCHES_FAIL:
        return false;
      default:
        return state;
    }
  },
  showError500: (state = false, action) => {
    switch (action.type) {
      case actionTypes.GET_COMPANY_BRANCHES_FAIL:
        return true;
      default:
        return state;
    }
  },
  hasUploadedCompanyBranches: (state = false, action) => {
    switch (action.type) {
      case actionTypes.POST_COMPANY_BRANCHES_CSV:
        return false;
      case actionTypes.POST_COMPANY_BRANCHES_CSV_SUCCESS:
      case actionTypes.POST_COMPANY_BRANCHES_CSV_FAIL:
        return true;
      default:
        return state;
    }
  },
  isUploadingCompanyBranchesCSV: (state = false, action) => {
    switch (action.type) {
      case actionTypes.POST_COMPANY_BRANCHES_CSV:
        return true;
      case actionTypes.POST_COMPANY_BRANCHES_CSV_SUCCESS:
      case actionTypes.POST_COMPANY_BRANCHES_CSV_FAIL:
        return false;
      default:
        return state;
    }
  },
  companyBranchesCSVImportErrors: (state = [], action) => {
    switch (action.type) {
      case actionTypes.POST_COMPANY_BRANCHES_CSV:
      case actionTypes.POST_COMPANY_BRANCHES_CSV_SUCCESS:
        return [];
      case actionTypes.POST_COMPANY_BRANCHES_CSV_FAIL:
        return action.payload ? action.payload : [];
      default:
        return state;
    }
  },
  isDeleteNodeModalOpen: (state = false, action) => {
    switch (action.type) {
      case actionTypes.SET_DELETE_NODE_MODAL_IS_OPEN:
        return action.payload;
      case actionTypes.DELETE_COMPANY_BRANCH_SUCCESS:
      case actionTypes.DELETE_COMPANY_BRANCH_FAIL:
        return false;
      default:
        return state;
    }
  },
  deleteCompanyBranches: (state = [], action) => {
    switch (action.type) {
      case actionTypes.DELETE_COMPANY_BRANCH:
      case actionTypes.DELETE_COMPANY_BRANCH_SUCCESS:
        return [];
      case actionTypes.DELETE_COMPANY_BRANCH_FAIL:
        return action.payload ? action.payload : [];
      default:
        return state;
    }
  },
});
