import JobPublicationTypeActionTypes from '../../../constants/Job/JobPublicationType/JobPublicationTypeActionTypes';
import JobPermissionsManager from '../../../managers/JobPermissionsManager';

export const changeForm = modelForm => ({
  type: JobPublicationTypeActionTypes.CHANGE_FORM,
  modelForm,
});

export const getJob = payload => ({
  type: JobPublicationTypeActionTypes.GET,
  payload,
});

export const getJobSuccess = job => ({
  type: JobPublicationTypeActionTypes.GET_SUCCESS,
  modelForm: JobPermissionsManager.adaptJobPermissions(job),
});

export const clearValidationErrors = () => ({
  type: JobPublicationTypeActionTypes.CLEAR_VALIDATION_ERRORS,
});

export const publishJob = (
  payload,
  registerStepId,
  exceededQuestions,
  templateMessage,
) => ({
  type: JobPublicationTypeActionTypes.PUBLISH,
  payload,
  registerStepId,
  exceededQuestions,
  templateMessage,
});
