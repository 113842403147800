import { call, put, takeLatest } from 'redux-saga/effects';
import { ConfirmIndicationActionTypes } from './ConfirmIndicationActions';
import ConfirmIndicationService from './ConfirmIndicationService';

function isSecureToken(token) {
  const hexTokenRegex = /^[0-9A-Fa-f]+$/;

  return token.match(hexTokenRegex);
}

export function* validateToken({ payload: token }) {
  try {
    let body;

    if (isSecureToken(token)) {
      ({ body } = yield call(
        ConfirmIndicationService.getConfirmIndicationSecure,
        token,
      ));
    } else {
      ({ body } = yield call(
        ConfirmIndicationService.getConfirmIndication,
        token,
      ));
    }

    yield put({
      type: ConfirmIndicationActionTypes.VALIDATE_SUCCESS,
      payload: body,
    });
  } catch (err) {
    yield put({ type: ConfirmIndicationActionTypes.VALIDATE_FAIL });
  }
}

export function* saveFeedback({ payload: { token, ...body } }) {
  try {
    if (isSecureToken(token)) {
      yield call(
        ConfirmIndicationService.postConfirmIndicationSecure,
        token,
        body,
      );
    } else {
      yield call(ConfirmIndicationService.postConfirmIndication, token, body);
    }

    yield put({ type: ConfirmIndicationActionTypes.FEEDBACK_SUCCESS });
  } catch (err) {
    yield put({ type: ConfirmIndicationActionTypes.FEEDBACK_FAIL });
  }
}

export default function* ConfirmIndicationSaga() {
  yield takeLatest(ConfirmIndicationActionTypes.VALIDATE, validateToken);
  yield takeLatest(ConfirmIndicationActionTypes.FEEDBACK, saveFeedback);
}
