import NotificationTypes from '../enums/NotificationTypes';

export const sorter = (
  { createdAt: n1CreatedAt = '' },
  { createdAt: n2CreatedAt = '' },
) => n2CreatedAt.localeCompare(n1CreatedAt);

export const sort = notifications => {
  const userNotifications =
    notifications && notifications[NotificationTypes.userNotification]
      ? notifications[NotificationTypes.userNotification]
      : [];
  return {
    ...notifications,
    [NotificationTypes.userNotification]: userNotifications.sort(sorter),
  };
};
