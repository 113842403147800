import { EmailTemplateTypeEnum } from '@gupy/enums';
import { buildToast, ToastTypes } from '@gupy/front-commons';
import { call, put, takeLatest } from 'redux-saga/effects';
import EmailTemplateService from '../../../services/EmailTemplate/EmailTemplateService';
import JobService from '../../../services/Job/JobService';
import { JobDeletionActionTypes } from './JobDeletionAction';

export function* getTemplateEmails() {
  try {
    const response = yield call(EmailTemplateService.getAll);
    const feedbackTemplates = response.body.filter(
      t => t.type === EmailTemplateTypeEnum.discontinuedJobPosting,
    );
    yield put({
      type: JobDeletionActionTypes.GET_TEMPLATE_EMAILS_SUCCESS,
      payload: feedbackTemplates,
    });
  } catch (error) {
    yield put({
      error,
      type: JobDeletionActionTypes.GET_TEMPLATE_EMAILS_FAIL,
    });
  }
}

export function* sendEmail({ payload }) {
  try {
    const { from, jobId, messages, templateEmail } = payload;
    const response = yield call(
      JobService.getAllApplicationsWithNoFeedback,
      jobId,
    );
    const data = {
      from,
      subject: templateEmail.subject,
      emailBody: templateEmail.body,
      applicationIds: response.body.applications,
    };
    yield call(JobService.sendFeedbackEmails, jobId, data);
    yield put({
      type: JobDeletionActionTypes.SEND_EMAIL_SUCCESS,
      toast: buildToast(messages.success, ToastTypes.success),
    });
  } catch (error) {
    const { messages } = payload;
    yield put({
      error,
      type: JobDeletionActionTypes.SEND_EMAIL_FAIL,
      toast: buildToast(messages.error, ToastTypes.error),
    });
  }
}

export function* getApplicationsToSendFeedback(action) {
  try {
    const res = yield call(
      JobService.getAllApplicationsWithNoFeedback,
      action.jobId,
    );
    yield put({
      type: JobDeletionActionTypes.GET_APPLICATIONS_TO_SEND_EMAIL_SUCCESS,
      payload: res.body.applications,
    });
  } catch (error) {
    yield put({
      type: JobDeletionActionTypes.GET_APPLICATIONS_TO_SEND_EMAIL_FAIL,
      error,
    });
  }
}

function* JobDeletionSaga() {
  yield takeLatest(
    JobDeletionActionTypes.GET_TEMPLATE_EMAILS,
    getTemplateEmails,
  );
  yield takeLatest(JobDeletionActionTypes.SEND_EMAIL, sendEmail);
  yield takeLatest(
    JobDeletionActionTypes.GET_APPLICATIONS_TO_SEND_EMAIL,
    getApplicationsToSendFeedback,
  );
}

export default JobDeletionSaga;
