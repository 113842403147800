import { combineReducers } from 'redux';
import { SkillsEvaluationActionTypes } from './SkillEvaluationLegacyActions';

export const isLoading = (state = false, action) => {
  switch (action.type) {
    case SkillsEvaluationActionTypes.GET:
      return true;
    case SkillsEvaluationActionTypes.GET_SUCCESS:
    case SkillsEvaluationActionTypes.GET_FAIL:
      return false;
    default:
      return state;
  }
};

export const skillsEvaluations = (state = [], action) => {
  switch (action.type) {
    case SkillsEvaluationActionTypes.GET_SUCCESS:
      return action.skillsEvaluations;
    default:
      return state;
  }
};

export default combineReducers({
  isLoading,
  skillsEvaluations,
});
