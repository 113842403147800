import { call, put, takeLatest } from 'redux-saga/effects';
import JobService from '../../../../../services/Job/JobService';
import { TimelineActionTypes } from './TimelineAction';

export function* getTimeline({ applicationId }) {
  try {
    const response = yield call(JobService.getTimeline, applicationId);
    yield put({
      type: TimelineActionTypes.GET_TIMELINE_SUCCESS,
      applicationId,
      payload: response.body,
    });
  } catch (error) {
    yield put({
      type: TimelineActionTypes.GET_TIMELINE_FAIL,
      payload: error,
    });
  }
}

function* TimelineSaga() {
  yield takeLatest(TimelineActionTypes.GET_TIMELINE, getTimeline);
}

export default TimelineSaga;
