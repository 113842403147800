export const OPEN_HIRING_CELEBRATION_MODAL =
  'hiringCelebration/OPEN_HIRING_CELEBRATION_MODAL';
export const CLOSE_HIRING_CELEBRATION_MODAL =
  'hiringCelebration/CLOSE_HIRING_CELEBRATION_MODAL';

export const SAVE_FEEDBACK_REQUEST = 'hiringCelebration/SAVE_FEEDBACK_REQUEST';
export const SAVE_FEEDBACK_REQUEST_SUCCESS =
  'hiringCelebration/SAVE_FEEDBACK_REQUEST_SUCCESS';
export const SAVE_FEEDBACK_REQUEST_FAILURE =
  'hiringCelebration/SAVE_FEEDBACK_REQUEST_FAILURE';
