import { InterviewEventStatusEnum } from '@gupy/front-commons';
import { combineReducers } from 'redux';
import { IntegrationsActionTypes } from '../../../../Integrations/IntegrationsActionTypes';
import * as types from './InterviewEventActionTypes';

const modelForm = (state = null, action) => {
  switch (action.type) {
    case types.CHANGE_FORM:
      return {
        ...state,
        ...action.modelForm,
      };
    case types.CREATE_INTERVIEW_EVENT_SUCCESS: {
      const newState = {
        ...action.interviewEvent,
        status: InterviewEventStatusEnum.SCHEDULED,
      };

      return newState;
    }
    case types.GET_INTERVIEW_EVENT_SUCCESS:
      return action.interviewEvent;
    case types.CANCEL_INTERVIEW_EVENT_SUCCESS: {
      const newState = {
        ...state,
        status: InterviewEventStatusEnum.CANCELED,
      };

      return newState;
    }
    default:
      return state;
  }
};

const isLoading = (state = false, action) => {
  switch (action.type) {
    case types.CREATE_INTERVIEW_EVENT_REQUEST:
      return true;
    case types.CREATE_INTERVIEW_EVENT_SUCCESS:
    case types.CREATE_INTERVIEW_EVENT_FAILURE:
      return false;
    default:
      return state;
  }
};

const modelFormErrors = (state = {}, action) => {
  switch (action.type) {
    case types.GET_USERS_AVAILABILITY_INVALID:
      return action.validation;
    case types.CREATE_INTERVIEW_EVENT_INVALID:
      return action.validation;
    case types.GET_USERS_AVAILABILITY_REQUEST:
    case types.CREATE_INTERVIEW_EVENT_REQUEST:
    case types.CLOSE_INTERVIEW_EVENT_MODAL:
      return {};
    default:
      return state;
  }
};

const modalOpened = (state = false, action) => {
  switch (action.type) {
    case types.CREATE_INTERVIEW_EVENT_SUCCESS:
    case types.CREATE_INTERVIEW_EVENT_FAILURE:
    case types.CLOSE_INTERVIEW_EVENT_MODAL:
      return false;
    case types.OPEN_INTERVIEW_EVENT_MODAL:
      return true;
    default:
      return state;
  }
};

const suggestedSpots = (state = [], action) => {
  switch (action.type) {
    case types.GET_SUGGEST_SPOTS_SUCCESS:
      return action.suggestedSpots;
    case types.CHANGE_FORM:
    case types.GET_SUGGEST_SPOTS_REQUEST:
    case types.CLOSE_INTERVIEW_EVENT_MODAL:
      return [];
    default:
      return state;
  }
};

const usersAvailability = (state = {}, action) => {
  switch (action.type) {
    case types.GET_USERS_AVAILABILITY_SUCCESS:
      return action.usersAvailability;
    case types.CHANGE_FORM:
    case types.GET_USERS_AVAILABILITY_REQUEST:
    case types.CLOSE_INTERVIEW_EVENT_MODAL:
      return {};
    default:
      return state;
  }
};

const possibleParticipants = (state = [], action) => {
  switch (action.type) {
    case types.GET_POSSIBLE_PARTICIPANTS_SUCCESS:
      return action.possibleParticipants;
    case types.CLOSE_INTERVIEW_EVENT_MODAL:
    case types.CLEAN_POSSIBLE_PARTICIPANTS:
      return [];
    default:
      return state;
  }
};

const isSuggestedSpotsLoading = (state = false, action) => {
  switch (action.type) {
    case types.GET_SUGGEST_SPOTS_REQUEST:
      return true;
    case types.GET_SUGGEST_SPOTS_SUCCESS:
    case types.GET_SUGGEST_SPOTS_FAILURE:
      return false;
    default:
      return state;
  }
};

const isCalendarIntegrationEnabled = (state = false, action) => {
  switch (action.type) {
    case IntegrationsActionTypes.INIT_INTEGRATIONS_SUCCESS:
      return (
        (action.integrations &&
          action.integrations.calendarIntegration &&
          action.integrations.calendarIntegration !== 'DUMMY') ||
        false
      );
    case IntegrationsActionTypes.INIT_FAIL:
    default:
      return state;
  }
};

export const summaryModalOpened = (state = false, action) => {
  switch (action.type) {
    case types.OPEN_INTERVIEW_EVENT_SUMMARY_MODAL:
      return true;
    case types.CLOSE_INTERVIEW_EVENT_SUMMARY_MODAL:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  modelForm,
  modelFormErrors,
  modalOpened,
  suggestedSpots,
  usersAvailability,
  possibleParticipants,
  isSuggestedSpotsLoading,
  isCalendarIntegrationEnabled,
  summaryModalOpened,
  isLoading,
});
