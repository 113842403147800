import { combineReducers } from 'redux';
import { SetupEngageSurveyActionTypes } from './SetupEngageSurveyAction';
import { SCENARIOS_CEE } from './StatusCode';

export const isLoadingCareerPageOptionsList = (state = false, action) => {
  switch (action.type) {
    case SetupEngageSurveyActionTypes.GET_CAREER_PAGES_OPTIONS_LIST:
      return true;
    case SetupEngageSurveyActionTypes.GET_CAREER_PAGES_OPTIONS_LIST_SUCCESS:
    case SetupEngageSurveyActionTypes.GET_CAREER_PAGES_OPTIONS_LIST_FAIL:
      return false;
    default:
      return state;
  }
};

export const isLoadingSurveyOptionsList = (state = false, action) => {
  switch (action.type) {
    case SetupEngageSurveyActionTypes.GET_SURVEY_OPTIONS_LIST:
      return true;
    case SetupEngageSurveyActionTypes.GET_SURVEY_OPTIONS_LIST_SUCCESS:
    case SetupEngageSurveyActionTypes.GET_SURVEY_OPTIONS_LIST_FAIL:
      return false;
    default:
      return state;
  }
};

export const careerPageOptionsList = (state = [], action) => {
  switch (action.type) {
    case SetupEngageSurveyActionTypes.GET_CAREER_PAGES_OPTIONS_LIST_SUCCESS:
      return action.payload.career_pages.map(({ id, name }) => ({
        value: String(id),
        label: name,
      }));
    default:
      return state;
  }
};

export const surveyOptionsList = (state = [], action) => {
  switch (action.type) {
    case SetupEngageSurveyActionTypes.GET_SURVEY_OPTIONS_LIST_SUCCESS:
      return action.payload.surveys.map(({ id, surveyName }) => ({
        value: String(id),
        label: surveyName,
      }));
    default:
      return state;
  }
};

export const isLoadingSendBind = (state = false, action) => {
  switch (action.type) {
    case SetupEngageSurveyActionTypes.SAVE_BIND_SURVEY_CAREER_PAGES:
      return true;
    case SetupEngageSurveyActionTypes.SAVE_BIND_SURVEY_CAREER_PAGES_SUCCESS:
    case SetupEngageSurveyActionTypes.SAVE_BIND_SURVEY_CAREER_PAGES_FAIL:
      return false;
    default:
      return state;
  }
};

export const sendedBindWithSuccess = (state = false, action) => {
  switch (action.type) {
    case SetupEngageSurveyActionTypes.SAVE_BIND_SURVEY_CAREER_PAGES_SUCCESS:
      return true;
    default:
      return state;
  }
};

export const surveyCareerPageBindList = (state = [], action) => {
  switch (action.type) {
    case SetupEngageSurveyActionTypes.GET_BIND_SURVEY_CAREER_PAGES_SUCCESS:
      return action.payload.binds;
    default:
      return state;
  }
};

export const isLoadingDelete = (state = false, action) => {
  switch (action.type) {
    case SetupEngageSurveyActionTypes.DELETE_BIND_SURVEY_CAREER_PAGES:
      return action.payload.id;
    case SetupEngageSurveyActionTypes.DELETE_BIND_SURVEY_CAREER_PAGES_SUCCESS:
    case SetupEngageSurveyActionTypes.DELETE_BIND_SURVEY_CAREER_PAGES_FAIL:
      return false;
    default:
      return state;
  }
};

export const isLoadingCreateSurvey = (state = false, action) => {
  switch (action.type) {
    case SetupEngageSurveyActionTypes.CREATE_SURVEY:
      return true;
    case SetupEngageSurveyActionTypes.CREATE_SURVEY_SUCCESS:
    case SetupEngageSurveyActionTypes.CREATE_SURVEY_FAIL:
      return false;
    default:
      return state;
  }
};

export const isLoadingCreateAccount = (state = false, action) => {
  switch (action.type) {
    case SetupEngageSurveyActionTypes.BIND_ACCOUNT:
      return true;
    case SetupEngageSurveyActionTypes.BIND_ACCOUNT_SUCCESS:
    case SetupEngageSurveyActionTypes.BIND_ACCOUNT_FAIL:
      return false;
    default:
      return state;
  }
};

export const createSurveyResult = (state = null, action) => {
  switch (action.type) {
    case SetupEngageSurveyActionTypes.CREATE_SURVEY_SUCCESS:
      return 200;
    case SetupEngageSurveyActionTypes.CREATE_SURVEY_FAIL:
      return action.payload.statusCode;
    case SetupEngageSurveyActionTypes.SET_MODAL_OPEN:
      if (!action.isOpen) return null;
      return state;
    case SetupEngageSurveyActionTypes.BIND_ACCOUNT_FAIL:
      return SCENARIOS_CEE.OWNER_ACCOUNT_CREATION_ERROR;
    default:
      return state;
  }
};

export const isLoadingCreateClimateEngagementAccount = (
  state = false,
  action,
) => {
  switch (action.type) {
    case SetupEngageSurveyActionTypes.CREATE_CLIMATE_ENGAGEMENT_ACCOUNT:
      return true;
    case SetupEngageSurveyActionTypes.CREATE_CLIMATE_ENGAGEMENT_ACCOUNT_SUCCESS:
    case SetupEngageSurveyActionTypes.CREATE_CLIMATE_ENGAGEMENT_ACCOUNT_FAIL:
      return false;
    default:
      return state;
  }
};

export const createClimateEngagementAccountResult = (state = null, action) => {
  switch (action.type) {
    case SetupEngageSurveyActionTypes.CREATE_CLIMATE_ENGAGEMENT_ACCOUNT_SUCCESS:
      return {
        type: 'success',
      };
    case SetupEngageSurveyActionTypes.CREATE_CLIMATE_ENGAGEMENT_ACCOUNT_FAIL:
      return {
        type: 'error',
        message: action.message,
      };
    default:
      return state;
  }
};

export const isCreateModalOpen = (state = false, action) => {
  switch (action.type) {
    case SetupEngageSurveyActionTypes.SET_MODAL_OPEN:
      return action.isOpen;
    default:
      return state;
  }
};

export default combineReducers({
  isLoadingCareerPageOptionsList,
  isLoadingSurveyOptionsList,
  careerPageOptionsList,
  surveyOptionsList,
  surveyCareerPageBindList,
  isLoadingSendBind,
  sendedBindWithSuccess,
  isLoadingDelete,
  isLoadingCreateSurvey,
  createSurveyResult,
  isCreateModalOpen,
  isLoadingCreateAccount,
  isLoadingCreateClimateEngagementAccount,
  createClimateEngagementAccountResult,
});
