import { JobStatusEnum } from '@gupy/enums';
import JobPermissionsManager from '../../../managers/JobPermissionsManager';
import { JobSummaryActionTypes } from './ActionTypes';

const initAllApproversList = () => ({
  type: JobSummaryActionTypes.GET_ALL_APPROVERS,
});

const replaceApprover = ({
  newApprover,
  currentApprover,
  jobId,
  messages,
}) => ({
  type: JobSummaryActionTypes.REPLACE_APPROVER,
  newApprover,
  currentApprover,
  jobId,
  messages,
});

const replaceApproverBulk = args => ({
  type: JobSummaryActionTypes.REPLACE_APPROVER_BULK,
  ...args,
});

const initJobSummary = jobId => ({
  type: JobSummaryActionTypes.INIT_JOB_SUMMARY,
  jobId,
});

const initJobSummarySuccess = response => ({
  type: JobSummaryActionTypes.INIT_JOB_SUMMARY_SUCCESS,
  job: JobPermissionsManager.adaptJobPermissions(response.job),
  jobTypes: response.jobTypes,
  classifiersList: response.classifiersList,
  steps: response.steps,
});

const patchApproveJob = (
  jobId,
  payload,
  messages,
  approvalWorkflowEnabled,
) => ({
  type: JobSummaryActionTypes.PATCH_APPROVE_JOB,
  payload: {
    job: {
      ...payload,
      id: jobId,
    },
    workflow: {
      id: jobId,
      approvalStatus: JobStatusEnum.approved,
      reason: payload.approvalReason,
      salaryCurrencyType: payload.salaryCurrencyType,
      salaryFrom: payload.salaryFrom,
      salaryTo: payload.salaryTo,
      jobVacanciesCodes: payload.jobVacanciesCodes
        ? payload.jobVacanciesCodes
        : null,
    },
  },
  messages,
  approvalWorkflowEnabled,
});

const openApproveJobModal = payload => ({
  type: JobSummaryActionTypes.OPEN_APPROVE_JOB_MODAL,
  payload,
});

const closeApproveJobModal = () => ({
  type: JobSummaryActionTypes.CLOSE_APPROVE_JOB_MODAL,
});

const changeApproveJobFormModel = model => ({
  type: JobSummaryActionTypes.CHANGE_APPROVE_JOB_FORM_MODEL,
  model,
});

const openDisapproveJobModal = job => ({
  type: JobSummaryActionTypes.OPEN_DISAPPROVE_JOB_MODAL,
  job,
});

const closeDisapproveJobModal = () => ({
  type: JobSummaryActionTypes.CLOSE_DISAPPROVE_JOB_MODAL,
});

const changeDisapproveJobFormModel = model => ({
  type: JobSummaryActionTypes.CHANGE_DISAPPROVE_JOB_FORM_MODEL,
  model,
});

const patchDisapproveJob = (payload, messages, approvalWorkflowEnabled) => ({
  type: JobSummaryActionTypes.PATCH_DISAPPROVE_JOB,
  payload: {
    job: {
      ...payload,
    },
    workflow: {
      id: payload.id,
      approvalStatus: payload.status,
      reason: payload.disapprovalReason,
      disapprovalReason: payload.disapprovalReason,
      salaryCurrencyType: payload.salaryCurrencyType,
      salaryFrom: payload.salaryFrom,
      salaryTo: payload.salaryTo,
    },
  },
  messages,
  approvalWorkflowEnabled,
});

const getAllJobsByApproverId = approverId => ({
  type: JobSummaryActionTypes.GET_ALL_JOBS_BY_APPROVER_ID,
  approverId,
});

const addApprover = ({ jobId, approver, messages }) => ({
  type: JobSummaryActionTypes.ADD_APPROVER,
  jobId,
  approver,
  messages,
});

const removeApprover = ({ jobId, approver, messages }) => ({
  type: JobSummaryActionTypes.REMOVE_APPROVER,
  jobId,
  approver,
  messages,
});

const removeApproverBulk = args => ({
  type: JobSummaryActionTypes.REMOVE_APPROVER_BULK,
  ...args,
});

export {
  initAllApproversList,
  replaceApprover,
  replaceApproverBulk,
  initJobSummary,
  initJobSummarySuccess,
  patchApproveJob,
  openApproveJobModal,
  closeApproveJobModal,
  changeApproveJobFormModel,
  openDisapproveJobModal,
  closeDisapproveJobModal,
  changeDisapproveJobFormModel,
  patchDisapproveJob,
  getAllJobsByApproverId,
  addApprover,
  removeApprover,
  removeApproverBulk,
};
