import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import QuestionsCreateAnswerInput from './QuestionsCreateAnswerInput';

const propTypes = {
  correctAnswers: PropTypes.array,
  wrongAnswers: PropTypes.array,
  multipleAnswers: PropTypes.bool,
  onChangeAnswers: PropTypes.func.isRequired,
  isViewOnlyMode: PropTypes.bool,
  intl: intlShape.isRequired,
};

const QuestionsCreateAnswers = ({
  correctAnswers = [],
  wrongAnswers = [],
  multipleAnswers = false,
  onChangeAnswers,
  isViewOnlyMode = false,
  intl,
}) => {
  const allAnswers = useMemo(
    () => [...correctAnswers, ...wrongAnswers],
    [correctAnswers, wrongAnswers],
  );

  const texts = defineMessages({
    rightOption: {
      id: 'right_option',
      defaultMessage: 'Alternativa correta',
    },
    rightOptions: {
      id: 'right_options',
      defaultMessage: 'Alternativas corretas',
    },
    wrongOptions: {
      id: 'wrong_options',
      defaultMessage: 'Alternativas incorretas',
    },
  });

  return (
    <div>
      <p className="help-block">
        <FormattedMessage
          id="answers_sort_message"
          defaultMessage="Na exibição para os candidatos, as alternativas aparecerão com a ordem embaralhada.*"
        />
      </p>
      <span className="questionsCreate-answersTitle">
        {multipleAnswers
          ? intl.formatMessage(texts.rightOptions)
          : intl.formatMessage(texts.rightOption)}
      </span>
      <div className="form-group choices">
        <QuestionsCreateAnswerInput
          name="correctAnswers"
          data={correctAnswers}
          multiple={multipleAnswers}
          isCheckbox={multipleAnswers}
          onChange={onChangeAnswers}
          disabled={isViewOnlyMode}
          allAnswers={allAnswers}
        />
      </div>
      <span className="questionsCreate-answersTitle">
        {intl.formatMessage(texts.wrongOptions)}
      </span>
      <div className="form-group choices">
        <QuestionsCreateAnswerInput
          name="wrongAnswers"
          data={wrongAnswers}
          multiple
          isCheckbox={multipleAnswers}
          onChange={onChangeAnswers}
          disabled={isViewOnlyMode}
          allAnswers={allAnswers}
        />
      </div>
    </div>
  );
};

QuestionsCreateAnswers.propTypes = propTypes;

export default injectIntl(QuestionsCreateAnswers);
