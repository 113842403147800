import { MenuButton } from '@gupy/design-system';
import PropTypes from 'prop-types';
import React from 'react';
import './JobCustomFormTemplateConditionalMenuButton.scss';
import { ItemSortEnum } from '../ItemSortEnum';

const propTypes = {
  index: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired,
  messages: PropTypes.object.isRequired,
  onRemoveField: PropTypes.func.isRequired,
  onEditField: PropTypes.func.isRequired,
  fieldTitle: PropTypes.string.isRequired,
  fieldType: PropTypes.object.isRequired,
  dependentOption: PropTypes.array.isRequired,
  selectedItems: PropTypes.array,
  fieldIndex: PropTypes.number.isRequired,
  items: PropTypes.array,
  onMoveTo: PropTypes.func.isRequired,
};

const JobCustomFormTemplateConditionalMenuButton = ({
  index,
  itemsCount,
  messages,
  onRemoveField,
  onEditField,
  fieldTitle,
  fieldType,
  dependentOption,
  selectedItems,
  fieldIndex,
  items,
  onMoveTo,
}) => {
  const reorderMenuOptions = [];

  const isFirstPosition = index === 0;
  const isLastPosition = index + 1 === itemsCount;
  const option = dependentOption[0];

  if (!isFirstPosition) {
    reorderMenuOptions.push(
      {
        action: () => onMoveTo(ItemSortEnum.MOVE_TO_TOP, index, option),
        title: messages.moveToTop,
      },
      {
        action: () => onMoveTo(ItemSortEnum.MOVE_UP, index, option),
        title: messages.moveUp,
      },
    );
  }

  if (!isLastPosition) {
    reorderMenuOptions.push(
      {
        action: () => onMoveTo(ItemSortEnum.MOVE_DOWN, index, option),
        title: messages.moveDown,
      },
      {
        action: () => onMoveTo(ItemSortEnum.MOVE_TO_END, index, option),
        title: messages.moveToEnd,
      },
    );
  }

  return (
    <div className="job-custom-form-template-conditional-menu-button">
      {itemsCount > 1 && (
        <div className="job-custom-form-template-conditional-menu-button__btn">
          <MenuButton
            id={`form-template-reorder-${index}`}
            data-testid={`form-template-reorder-${index}`}
            icon="SwapVert"
            options={reorderMenuOptions}
            closeOnClick
          />
        </div>
      )}
      <div className={'job-custom-form-template-conditional-menu-button__btn'}>
        <MenuButton
          id={`form-template-menu-${index}`}
          data-testid={`form-template-menu-${index}`}
          options={[
            {
              action: () =>
                onEditField({
                  fieldTitle,
                  fieldType,
                  dependentOption,
                  selectedItems,
                  fieldIndex,
                  items,
                }),
              title: messages.edit,
            },
            {
              action: () =>
                onRemoveField({
                  fieldIndex,
                  fieldTitle,
                  fieldType,
                  dependentOption,
                }),
              title: messages.exclude,
            },
          ]}
          closeOnClick
        />
      </div>
    </div>
  );
};

JobCustomFormTemplateConditionalMenuButton.propTypes = propTypes;

export default JobCustomFormTemplateConditionalMenuButton;
