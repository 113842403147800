import { buildToast, ToastTypes } from '@gupy/front-commons';
import { call, put, takeLatest } from 'redux-saga/effects';
import EmailDomainActionTypes from './ActionTypes';
import EmailDomainModalValidator from './components/EmailDomainModalValidator';
import EmailDomainsService from './EmailDomainsService';

function* getEmailDomains() {
  try {
    const response = yield EmailDomainsService.getEmailDomains();
    yield put({
      type: EmailDomainActionTypes.LIST_EMAIL_DOMAINS_SUCCESS,
      emailDomains: response.body,
    });
  } catch (error) {
    yield put({
      type: EmailDomainActionTypes.LIST_EMAIL_DOMAINS_FAIL,
      error,
    });
  }
}

function* createEmailDomain(action) {
  const { payload, messages } = action;
  const form = new EmailDomainModalValidator();
  form.populate(payload);
  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: EmailDomainActionTypes.CREATE_EMAIL_DOMAIN_FAIL,
      validation,
    });
  } else {
    try {
      const response = yield call(
        EmailDomainsService.createEmailDomain,
        payload,
      );
      yield put({
        type: EmailDomainActionTypes.CREATE_EMAIL_DOMAIN_SUCCESS,
        newEmailDomain: response.body,
        toast: buildToast(messages.success, ToastTypes.success, {
          autoClose: 5000,
        }),
      });
    } catch (error) {
      yield put({
        type: EmailDomainActionTypes.CREATE_EMAIL_DOMAIN_FAIL,
        error,
      });
    }
  }
}

function* deleteEmailDomain(action) {
  const { payload } = action;

  try {
    yield call(EmailDomainsService.deleteEmailDomain, payload);
    yield put({
      type: EmailDomainActionTypes.DELETE_EMAIL_DOMAIN_SUCCESS,
      emailDomainId: payload,
    });
  } catch (error) {
    yield put({
      type: EmailDomainActionTypes.DELETE_EMAIL_DOMAIN_FAIL,
      error,
    });
  }
}

function* EmailDomainsSaga() {
  yield takeLatest(EmailDomainActionTypes.LIST_EMAIL_DOMAINS, getEmailDomains);
  yield takeLatest(
    EmailDomainActionTypes.CREATE_EMAIL_DOMAIN,
    createEmailDomain,
  );
  yield takeLatest(
    EmailDomainActionTypes.DELETE_EMAIL_DOMAIN,
    deleteEmailDomain,
  );
}

export default EmailDomainsSaga;
