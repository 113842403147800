import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  saveButton: {
    id: 'save',
    defaultMessage: 'Salvar',
  },
  cancelButton: {
    id: 'cancel',
    defaultMessage: 'Cancelar',
  },
  newUserProfile: {
    id: 'new_user_profile',
    defaultMessage: 'Novo perfil de usuário',
  },
  userProfile: {
    id: 'user_profile',
    defaultMessage: 'Perfil de usuário',
  },
  userAccessProfileName: {
    id: 'user_access_profile_name',
    defaultMessage: 'Nome do perfil',
  },
  success: {
    id: 'user_access_profile_saved_success',
    defaultMessage: 'Perfil salvo com sucesso',
  },
  noAccessProfileFound: {
    id: 'no_user_access_profile_found',
    defaultMessage: 'Nenhum perfil de acesso disponível',
  },
  noAccessProfileFoundDescription: {
    id: 'no_user_access_profile_found_description',
    defaultMessage:
      'Os perfis de acesso permitem você definir as permissões de acesso de cada usuário(a) da sua empresa.',
  },
  confirmDialogTitle: {
    id: 'user_access_profile_delete_confirmation_title',
    defaultMessage: 'Excluir perfil de usuário',
  },
  confirmDialogMessage: {
    id: 'user_access_profile_delete_confirmation_message',
    defaultMessage: 'Você confirma a exclusão do perfil de usuário?',
  },
  confirmButtonText: {
    id: 'delete',
    defaultMessage: 'Excluir',
  },
  cancelButtonText: {
    id: 'cancel',
    defaultMessage: 'Cancelar',
  },
  confirmDialogSuccess: {
    id: 'user_access_profile_delete_success_message',
    defaultMessage: 'Perfil de usuário excluído com sucesso.',
  },
  searchUserProfileAccess: {
    id: 'search_user_profile_access',
    defaultMessage: 'Buscar perfis de acesso criados',
  },
  gridContentTitle: {
    id: 'user_access_profile_page_grid_content_title',
    defaultMessage: 'Perfil de usuário',
  },
  createNewProfile: {
    id: 'create_new_job_user_profile',
    defaultMessage: 'Novo perfil de acesso',
  },
  createAccessProfile: {
    id: 'create_access_profile',
    defaultMessage: 'Criar perfil de acesso',
  },
  groupType: {
    id: 'group_type',
    defaultMessage: 'Tipo de grupo',
  },
  selectGroupType: {
    id: 'select_group_type',
    defaultMessage: 'Selecione um tipo de grupo para este perfil',
  },
  selectOption: {
    id: 'select_option',
    defaultMessage: 'Selecione uma opção',
  },
  managementGroup: {
    id: 'management-group',
    defaultMessage: 'Grupo do tipo Gestão',
  },
  recruitmentGroup: {
    id: 'recruitment_group',
    defaultMessage: 'Grupo do tipo Recrutamento',
  },
  groupTypeHint: {
    id: 'group-type-hint',
    defaultMessage:
      'Este perfil será para pessoas gestoras ou recrutadoras? Associe-o a um tipo de grupo na plataforma. Saiba mais sobre os',
  },
  groupTypeHintBtn: {
    id: 'group-type-hint-btn',
    defaultMessage: 'benefícios dos tipos de grupos no perfil de acesso.',
  },
  knowMoreTitle: {
    id: 'know-more-title',
    defaultMessage: 'Entenda os tipos de grupo em perfis de acesso',
  },
  knowMoreDescription: {
    id: 'know-more-description',
    defaultMessage:
      'Os grupos presentes em perfil de acesso ajudam a plataforma a ser mais inteligente, entendendo que grupo de pessoas exibir em um determinado campo da plataforma ou qual funcionalidade apresentar para determinado público. Por exemplo:',
  },
  okButton: {
    id: 'ok-button',
    defaultMessage: 'Ok',
  },
  newDialogTitle: {
    id: 'new-dialog-tittle',
    defaultMessage: 'Os grupos de perfis de acesso chegaram!',
  },
  newDialogDescription: {
    id: 'new-dialog-description',
    defaultMessage:
      'Os grupos definidos nos perfis de acesso são associados a determinados campos e funcionalidades da plataforma, tais como:',
  },
  newDialogManagementGroup: {
    id: 'new-dialog-management-group',
    defaultMessage: 'Grupo de Gestão',
  },
  newDialogManagementDescription: {
    id: 'new-dialog-management-description',
    defaultMessage:
      'é associado ao campo Gestor responsável pela vaga, presente na criação da vaga;',
  },
  newDialogRecruitmentGroup: {
    id: 'new-dialog-recruitment-group',
    defaultMessage: 'Grupo de Recrutamento',
  },
  newDialogRecruitmentDescription: {
    id: 'new-dialog-recruitment-description',
    defaultMessage:
      'é associado ao campo Recrutador(a) da vaga, presente na criação da vaga.',
  },
  allGroups: {
    id: 'all-groups',
    defaultMessage: 'Exibir todos os grupos',
  },
  delete: {
    id: 'delete',
    defaultMessage: 'Excluir',
  },
  edit: {
    id: 'edit',
    defaultMessage: 'Editar',
  },
  groupTypeNotDefined: {
    id: 'group-type-not-defined',
    defaultMessage: 'Sem grupo definido',
  },
  management: {
    id: 'management',
    defaultMessage: 'Gestão',
  },
  recruitment: {
    id: 'recruitment',
    defaultMessage: 'Recrutamento',
  },
  diversityPermissionDialogConfirmButton: {
    id: 'diversity_permission_dialog_confirm_button',
    defaultMessage: 'Confirmar',
  },
  diversityPermissionDialogBackButton: {
    id: 'diversity_permission_dialog_back_button',
    defaultMessage: 'Voltar',
  },
  diversityPermissionDialogTitle: {
    id: 'diversity_permission_dialog_title',
    defaultMessage: 'Confirmar permissão de diversidade',
  },
  diversityPermissionDialogWarningTip: {
    id: 'diversity_permission_dialog_warning_tip',
    defaultMessage:
      'Essa permissão concede acesso a dados sensíveis sobre candidatos e pode impactar os processos seletivos. A conscientização e o treinamento sobre diversidade são essenciais para um impacto positivo nas contratações.',
  },
  diversityPermissionDialogParagraphOne: {
    id: 'diversity_permission_dialog_paragraph_one',
    defaultMessage:
      'Todas as pessoas vinculadas a esse perfil, novas e já existentes, terão acesso aos recursos de diversidade que você selecionou.',
  },
  title: {
    id: 'diversity-title',
    defaultMessage: 'Gupy Diversidade',
  },
  tip: {
    id: 'diversity-tip',
    defaultMessage:
      'Esta seção está visível apenas para você. Defina quais perfis podem ter acesso à funcionalidade Diversidade.',
  },
  diversityTrainingButton: {
    id: 'diversity-training-button',
    defaultMessage: 'Treinamento em Diversidade',
  },
  diversityTrainingTitle: {
    id: 'diversity-training-title',
    defaultMessage: 'Treinamento de capacitação em D&I',
  },
  diversityTrainingDescription: {
    id: 'diversity-training-description',
    defaultMessage:
      'Faça o treinamento em Diversidade e Redução de Vieses no Recrutamento e Seleção para um melhor uso da funcionalidade.',
  },
  diversityTrainingLinkTooltip: {
    id: 'diversity-training-link-tooltip',
    defaultMessage: 'Clique aqui para copiar o link de treinamento!',
  },
  diversityTrainingLinkCopied: {
    id: 'diversity-training-link-copied',
    defaultMessage: 'Você copiou o link de treinamento!',
  },
  panelTip: {
    id: 'diversity_panel_tip',
    defaultMessage:
      'Dados de diversidade disponíveis no Recrutamento & Seleção. Essas permissões impactam dados sensíveis de: Cor/Raça; Orientação Sexual; Identidade de Gênero; Pronome e Naturalidade.',
  },
  diversity: {
    id: 'diversity',
    defaultMessage: 'Diversidade',
  },
  tokenManagementWithoutOtherAccessPermissionsTitle: {
    id: 'token_management_with_insufficient_permissions',
    defaultMessage: 'Gerenciamento de tokens sem outras permissões de acesso',
  },
  tokenManagementWithoutOtherAccessPermissionsConfirmButton: {
    id: 'token_management_with_insufficient_permissions_confirm_button',
    defaultMessage: 'Ok, entendi',
  },
  tokenManagementWithoutOtherAccessPermissionsParagraph: {
    id: 'token_management_with_insufficient_permissions_paragraph',
    defaultMessage:
      'Para criar um perfil de acesso com o gerenciamento de tokens da API pública, você deve, minimamente, habilitar outras permissões de acesso que se relacionem com os endpoints a serem gerenciados. ',
  },
  tokenManagementWithoutOtherAccessPermissionsParagraphFinal: {
    id: 'token_management_with_insufficient_permissions_paragraph_final',
    defaultMessage: 'Volte e revise as permissões antes de continuar.',
  },
  new: {
    id: 'new',
    defaultMessage: 'Novo',
  },
  admissionGroup: {
    id: 'admission-group',
    defaultMessage: 'Grupo do tipo Admissão',
  },
  atsTabTitle: {
    id: 'ats-tab-title',
    defaultMessage: 'Recrutamento e Seleção',
  },
  admissionTabTitle: {
    id: 'admission-tab-title',
    defaultMessage: 'Admissão',
  },
  admission: {
    id: 'admission',
    defaultMessage: 'Admissão',
  },
  tipMessageAdmissionViewOnly: {
    id: 'tip_message_admission_view_only',
    defaultMessage:
      'Ao ativar o acesso de visualizador, as demais permissões dessa seção serão desativadas.',
  },
});

export const getMessages = intl => formatMessages(intl, messages);
