import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getProfileTestStatisticsFail,
  getProfileTestStatisticsSuccess,
} from '../../actions/ProfileTest/ProfileTestActions';
import ProfileTestActionTypes from '../../constants/ProfileTest/ProfileTestActionTypes';
import { postSigninSuccess } from '../../containers/Authentication/Actions';
import history from '../../history';
import AuthenticationService from '../../services/Authentication/AuthenticationService';
import ProfileTestService from '../../services/ProfileTest/ProfileTestService';

function* getProfileTest() {
  try {
    const response = yield call(ProfileTestService.getProfileTest);
    const profileTest = response.body;

    yield put({
      type: ProfileTestActionTypes.GET_SUCCESS,
      profileTest,
    });
  } catch (error) {
    yield put({
      type: ProfileTestActionTypes.GET_FAIL,
      error,
    });
  }
}

function* getProfileTestAverage({ payload }) {
  const { departmentFilterId, roleFilterId } = payload;
  try {
    const response = yield call(
      ProfileTestService.getProfileTestAverage,
      departmentFilterId,
      roleFilterId,
    );
    const profileTest = response.body;

    yield put({
      type: ProfileTestActionTypes.GET_AVERAGE_SUCCESS,
      profileTest,
    });
  } catch (error) {
    yield put({
      type: ProfileTestActionTypes.GET_AVERAGE_FAIL,
      error,
    });
  }
}

function* getCandidateMatching(action) {
  try {
    const response = yield call(
      ProfileTestService.getCandidateMatching,
      action.payload,
    );
    yield put({
      type: ProfileTestActionTypes.GET_MATCHING_SUCCESS,
      payload: response.body,
    });
  } catch (error) {
    yield put({
      type: ProfileTestActionTypes.GET_MATCHING_FAIL,
      error,
    });
  }
}

function* getCompanyUserProfileTest() {
  try {
    const response = yield call(ProfileTestService.getCompanyUserProfileTest);
    const profileTest = response.body;
    yield put({
      type: ProfileTestActionTypes.GET_USER_TEST_SUCCESS,
      profileTest,
    });
  } catch (error) {
    yield put({
      type: ProfileTestActionTypes.GET_USER_TEST_FAIL,
      error,
    });
  }
}

export function* getProfileTestDiagnostic({ payload }) {
  try {
    const response = yield call(
      ProfileTestService.getProfileTestDiagnostic,
      payload,
    );
    const diagnostic = response.body;
    yield put({
      type: ProfileTestActionTypes.GET_DIAGNOSTIC_SUCCESS,
      payload: diagnostic,
    });
  } catch (error) {
    yield put({
      type: ProfileTestActionTypes.GET_DIAGNOSTIC_FAIL,
      error,
    });
  }
}

function* patchProfileTest(action) {
  try {
    yield ProfileTestService.patchProfileTest(action.payload);

    yield put({
      type: ProfileTestActionTypes.SAVE_QUESTION_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: ProfileTestActionTypes.SAVE_QUESTION_FAIL,
      error,
    });
  }
}

function* finishProfileTest() {
  try {
    yield call(ProfileTestService.finishProfileTest);
    const currentUserResponse = yield call(
      AuthenticationService.getCurrentUser,
    );

    yield put({
      type: ProfileTestActionTypes.FINISH_TEST_SUCCESS,
      currentUser: currentUserResponse.body,
    });

    yield call(history.push, '/companies');
  } catch (error) {
    yield put({
      type: ProfileTestActionTypes.FINISH_TEST_FAIL,
      error,
    });
  }
}

export function* closeProfileTest({
  payload: { testProviderId, testCode, groupName, groupKey },
}) {
  try {
    yield call(ProfileTestService.closeProfileTest, {
      testProviderId,
      testCode,
      groupName,
      groupKey,
    });
    yield put({ type: ProfileTestActionTypes.CLOSE_TEST_SUCCESS });
  } catch (error) {
    yield put({
      type: ProfileTestActionTypes.CLOSE_TEST_FAIL,
      error,
    });
  }
}

export function* getProfileTestStatistics() {
  try {
    const response = yield call(ProfileTestService.getProfileTestStatistics);
    const statistics = response.body || {};
    yield put(getProfileTestStatisticsSuccess({ ...statistics }));
  } catch (error) {
    yield put(getProfileTestStatisticsFail({ error }));
  }
}

export function* switchProfileTestCompatibilityStatus({
  payload: { enabled },
}) {
  try {
    yield call(ProfileTestService.switchProfileTestCompatibility, enabled);
    const currentUserResponse = yield call(
      AuthenticationService.getCurrentUser,
    );
    const updatedUser = currentUserResponse.body;

    yield put(postSigninSuccess(updatedUser));
    yield put({
      type: ProfileTestActionTypes.SWITCH_COMPATIBILITY_STATUS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: ProfileTestActionTypes.SWITCH_COMPATIBILITY_STATUS_FAIL,
      error,
    });
  }
}

function* profileTestSaga() {
  yield takeLatest(ProfileTestActionTypes.GET, getProfileTest);
  yield takeLatest(ProfileTestActionTypes.GET_AVERAGE, getProfileTestAverage);
  yield takeLatest(ProfileTestActionTypes.GET_MATCHING, getCandidateMatching);
  yield takeLatest(
    ProfileTestActionTypes.GET_USER_TEST,
    getCompanyUserProfileTest,
  );
  yield takeLatest(
    ProfileTestActionTypes.GET_DIAGNOSTIC,
    getProfileTestDiagnostic,
  );
  yield takeLatest(ProfileTestActionTypes.SAVE_QUESTION, patchProfileTest);
  yield takeLatest(ProfileTestActionTypes.FINISH_TEST, finishProfileTest);
  yield takeLatest(ProfileTestActionTypes.FINISH_TEST_SUCCESS, getProfileTest);
  yield takeLatest(ProfileTestActionTypes.CLOSE_TEST, closeProfileTest);
  yield takeLatest(
    ProfileTestActionTypes.GET_PROFILE_TEST_STATISTICS,
    getProfileTestStatistics,
  );
  yield takeLatest(
    ProfileTestActionTypes.SWITCH_COMPATIBILITY_STATUS,
    switchProfileTestCompatibilityStatus,
  );
}

export default profileTestSaga;
