import { combineReducers } from 'redux';
import { WhatsappBusinessTemplateActionTypes } from './Actions';

export const isSaveLoading = (state = false, action) => {
  switch (action.type) {
    case WhatsappBusinessTemplateActionTypes.SAVE_TEMPLATE:
      return true;
    case WhatsappBusinessTemplateActionTypes.SAVE_TEMPLATE_SUCCESS:
    case WhatsappBusinessTemplateActionTypes.SAVE_TEMPLATE_FAIL:
      return false;
    default:
      return state;
  }
};

const getTemplatesInitialState = {
  isLoadingTemplates: false,
  templates: [],
};

export const getTemplates = (state = getTemplatesInitialState, action) => {
  switch (action.type) {
    case WhatsappBusinessTemplateActionTypes.GET_TEMPLATES:
      return {
        ...state,
        isLoadingTemplates: true,
      };
    case WhatsappBusinessTemplateActionTypes.GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        isLoadingTemplates: false,
        templates: action.templates,
      };
    case WhatsappBusinessTemplateActionTypes.GET_TEMPLATES_FAIL:
      return {
        ...state,
        isLoadingTemplates: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  isSaveLoading,
  getTemplates,
});
