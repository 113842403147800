import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getCountriesSuccess } from './Action';
import UserProfileActionTypes from './ActionTypes';
import * as CompanyUserInfoActionTypes from './ActionTypesCompanyUser';
import { convertErrorPasswordObject } from './convertErrorMessage';
import UserProfileCountryForm from './form/UserProfileCountryForm';
import UserProfileForm from './form/UserProfileForm';
import UserProfileLanguageForm from './form/UserProfileLanguageForm';
import UserProfilePasswordForm from './form/UserProfilePasswordForm';
import UserProfileTaxpayerRegistryForm from './form/UserProfileTaxpayerRegistryForm';
import UserProfileService from './Service';

export function* getUserProfile() {
  const form = new UserProfileForm();

  try {
    const service = new UserProfileService();
    const [countryResponse, getResponse] = yield all([
      call(service.getCountries),
      call(service.get),
    ]);
    yield put({
      type: UserProfileActionTypes.GET_SUCCESS,
      countries: countryResponse.body,
      modelForm: getResponse.body,
    });
    yield put(getCountriesSuccess(countryResponse.body));
  } catch (error) {
    yield put({
      type: UserProfileActionTypes.GET_FAIL,
      validation: form.validateErrorResponse(error),
    });
  }
}

export function* patchUserProfile(action) {
  const { payload } = action;
  const formUserProfile = new UserProfileForm();
  const formUserProfilePassword = new UserProfilePasswordForm(payload);
  const formUserProfileLanguage = new UserProfileLanguageForm();
  const formUserProfileCountry = new UserProfileCountryForm();
  const formUserProfileTaxpayerRegistry = new UserProfileTaxpayerRegistryForm();
  let validationUserProfile;
  let validationUserProfilePassword;
  let validationUserProfileLanguage;
  let validationUserProfileCountry;
  let validationUserProfileTaxpayerRegistry;

  if ('name' in payload) {
    formUserProfile.populate(payload);
    validationUserProfile = formUserProfile.validateModel();
  }

  if ('currentPassword' in payload || 'newPassword' in payload) {
    formUserProfilePassword.populate(payload);
    validationUserProfilePassword = formUserProfilePassword.validateModel();
  }

  if ('language' in payload) {
    formUserProfileLanguage.populate(payload);
    validationUserProfileLanguage = formUserProfileLanguage.validateModel();
  }

  if ('selectedCountry' in payload) {
    formUserProfileCountry.populate({
      selectedCountry: payload.selectedCountry.label,
    });
    validationUserProfileCountry = formUserProfileCountry.validateModel();
  }

  if ('taxpayerRegistry' in payload && 'selectedCountry' in payload) {
    formUserProfileTaxpayerRegistry.populate({
      selectedCountry: payload.selectedCountry.value,
      taxpayerRegistry: payload.taxpayerRegistry,
    });
    validationUserProfileTaxpayerRegistry =
      formUserProfileTaxpayerRegistry.customValidateModel();
  }

  let validation;
  if (
    validationUserProfile ||
    validationUserProfilePassword ||
    validationUserProfileLanguage ||
    validationUserProfileCountry ||
    validationUserProfileTaxpayerRegistry
  ) {
    validation = {};

    if (validationUserProfile) {
      validation = Object.assign({}, validation, validationUserProfile);
    }

    if (validationUserProfilePassword) {
      validation = Object.assign({}, validation, validationUserProfilePassword);
    }

    if (validationUserProfileLanguage) {
      validation = Object.assign({}, validation, validationUserProfileLanguage);
    }
    if (validationUserProfileCountry) {
      validation = Object.assign({}, validation, validationUserProfileCountry);
    }
    if (validationUserProfileTaxpayerRegistry) {
      validation = Object.assign(
        {},
        validation,
        validationUserProfileTaxpayerRegistry,
      );
    }
  }

  if (validation) {
    yield put({
      type: UserProfileActionTypes.PATCH_FAIL,
      validation,
    });
  } else {
    try {
      const service = new UserProfileService();
      if ('selectedCountry' in payload) {
        payload.countryOfOrigin = payload.selectedCountry.value;
      }
      const [countryResponse, patchResponse] = yield all([
        call(service.getCountries),
        call(service.patch, payload),
      ]);

      yield put({
        type: UserProfileActionTypes.PATCH_SUCCESS,
        modelForm: patchResponse.body,
        countries: countryResponse.body,
        payload,
      });

      if ('selectedCountry' in payload) {
        yield put({
          type: CompanyUserInfoActionTypes.PATCH_COUNTRIES_SUCCESS,
          payload,
        });
      }
      if ('taxpayerRegistry' in payload) {
        yield put({
          type: CompanyUserInfoActionTypes.TAXPAYER_REGISTRY_SUCCESS,
          payload,
        });
      }
    } catch (error) {
      yield put({
        type: UserProfileActionTypes.PATCH_FAIL,
        validation: convertErrorPasswordObject(
          formUserProfile.validateErrorResponse(error),
        ),
      });
    }
  }
}

function* userProfileSaga() {
  yield takeLatest(UserProfileActionTypes.GET, getUserProfile);
  yield takeLatest(UserProfileActionTypes.CANCEL_EDIT, getUserProfile);
  yield takeLatest(UserProfileActionTypes.PATCH, patchUserProfile);
}

export default userProfileSaga;
