import { combineReducers } from 'redux';
import { OnboardingGaiaActionTypes } from './OnboardingGaiaAction';

export const onboardingGaiaStatus = (state = null, action) => {
  switch (action.type) {
    case OnboardingGaiaActionTypes.GET_ONBOARDING_GAIA_STATUS_SUCCESS:
    case OnboardingGaiaActionTypes.CHANGE_ONBOARDING_GAIA_STATUS_SUCCESS:
      return action.payload.statusGaiaOnboarding;
    case OnboardingGaiaActionTypes.GET_ONBOARDING_GAIA_STATUS_FAIL:
      return null;
    default:
      return state;
  }
};

export const getRefAffinityGaia = (state = {}, action) => {
  const { payload, type } = action;
  switch (type) {
    case OnboardingGaiaActionTypes.SET_REF_POSITION_GAIA:
      return payload.ref;
    default:
      return state;
  }
};

export const getRefMoveApplicationBtn = (state = {}, action) => {
  const { payload, type } = action;
  switch (type) {
    case OnboardingGaiaActionTypes.SET_REF_MOVE_APPLICATION_BTN:
      return payload.ref === undefined ? null : payload.ref;
    default:
      return state;
  }
};

export const hoverRefAffinityGaia = (state = false, action) => {
  const { payload, type } = action;
  switch (type) {
    case OnboardingGaiaActionTypes.HOVER_REF_POSITION_GAIA:
      return payload.isHover;
    default:
      return state;
  }
};

export default combineReducers({
  onboardingGaiaStatus,
  getRefAffinityGaia,
  getRefMoveApplicationBtn,
  hoverRefAffinityGaia,
});
