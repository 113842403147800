import { Card, Divisor, IconButton, IconEnum } from '@gupy/design-system';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import LinesEllipsis from 'react-lines-ellipsis';

const propTypes = {
  jobOfferTemplate: PropTypes.object.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  canEditPublic: PropTypes.bool,
  canEditPrivate: PropTypes.bool,
  canDeletePublic: PropTypes.bool,
  canDeletePrivate: PropTypes.bool,
  messages: PropTypes.object.isRequired,
};

const defaultProps = {
  canEditPublic: false,
  canEditPrivate: false,
  canDeletePublic: false,
  canDeletePrivate: false,
};

const JobOfferTemplateCard = ({
  jobOfferTemplate,
  onDeleteClick,
  onEditClick,
  canEditPublic,
  canEditPrivate,
  canDeletePublic,
  canDeletePrivate,
  messages,
}) => {
  const cardClassNames = classNames(
    'col-xs-12',
    'col-sm-6',
    'col-md-4',
    'col-lg-4',
    'card-list__item',
  );

  const isTemplatePublic = jobOfferTemplate.public;

  const onEditClickProp = {};
  let editCardDisabledClass = '';
  if (
    (canEditPublic && isTemplatePublic) ||
    (canEditPrivate && !isTemplatePublic)
  ) {
    onEditClickProp.onClick = () => {
      onEditClick(jobOfferTemplate.id);
    };
  }

  if (
    (!canEditPublic && isTemplatePublic) ||
    (!canEditPrivate && !isTemplatePublic)
  ) {
    editCardDisabledClass = 'card-list__card--edit-disabled';
  }

  return (
    <div
      {...onEditClickProp}
      className={cardClassNames}
      tabIndex="0"
      role="button"
    >
      <Card
        className={classNames(`card-list__card ${editCardDisabledClass}`)}
        role="button"
        tabIndex={-1}
        padding="zero"
      >
        <div className="card-list__card__content">
          <h3>
            <LinesEllipsis
              text={jobOfferTemplate.name}
              maxLine="2"
              basedOn="letters"
              trimRight
            />
          </h3>
          <p>
            {jobOfferTemplate.public ? (
              <span>
                <i className="fa fa-eye" />
                <FormattedMessage id="public" defaultMessage="Público" />
              </span>
            ) : (
              <span>
                <i className="fa fa-eye-slash" />
                <FormattedMessage id="private" defaultMessage="Privado" />
              </span>
            )}
          </p>
          <p>
            <FormattedHTMLMessage
              id="created_by_job_offer_template_card"
              defaultMessage="Criado por <b>{name}</b> em <b>{when}</b>"
              values={{
                name: jobOfferTemplate.user.name,
                when: moment
                  .utc(jobOfferTemplate.createdAt)
                  .format('DD/MM/YYYY'),
              }}
            />
          </p>
        </div>
        <Divisor />
        {(canDeletePublic && isTemplatePublic) ||
        (canDeletePrivate && !isTemplatePublic) ? (
          <div className="card-list__card__actions">
            <IconButton
              id="bt-delete-template-tooltip"
              icon={IconEnum.DeleteOutline}
              className="action"
              variant="text"
              infotip={{
                id: 'delete-template-infotip',
                message: messages.delete,
              }}
              onClick={event => {
                onDeleteClick(event, jobOfferTemplate.id);
              }}
            />
          </div>
        ) : (
          <div className="card-list__card__actions" />
        )}
      </Card>
    </div>
  );
};

JobOfferTemplateCard.propTypes = propTypes;
JobOfferTemplateCard.defaultProps = defaultProps;

export default JobOfferTemplateCard;
