import { Tip } from '@gupy/design-system';
import PropTypes from 'prop-types';
import React from 'react';

const buildArrayMessage = errors => {
  if (errors.length === 1) {
    return errors[0];
  }

  return (
    <ul className="list-unstyled">
      {errors.map(message => (
        <li key={`${message}-${Math.random()}`}>{message}</li>
      ))}
    </ul>
  );
};

const propTypes = {
  validation: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.array,
    ]),
  }),
  showIcon: PropTypes.bool,
};

const defaultProps = {
  validation: undefined,
  showIcon: false,
};

const FormAlert = ({ validation, showIcon }) => {
  if (!validation) {
    return null;
  }

  let message;

  if (validation.message.constructor === Array) {
    message = buildArrayMessage(validation.message);
  } else if (typeof validation.message === 'string') {
    message = buildArrayMessage(validation.message.split('\n'));
  } else {
    ({ message } = validation);
  }

  return (
    <Tip
      text={message}
      type="danger"
      showIcon={showIcon}
      className="alert--error"
    />
  );
};

FormAlert.propTypes = propTypes;
FormAlert.defaultProps = defaultProps;

export default FormAlert;
