import { buildToast, ToastTypes } from '@gupy/front-commons';
import { call, put, takeLatest } from 'redux-saga/effects';
import CareerPageConfidentialForm from '../../forms/Career/CareerPageConfidentialForm';
import CareerPageInternalForm from '../../forms/Career/CareerPageInternalForm';
import CareerPagePublicForm from '../../forms/Career/CareerPagePublicForm';
import TestimonialForm from '../../forms/Career/TestimonialForm';
import history from '../../history';
import * as ActionTypes from './ActionTypes';
import { CareerPageVisibility } from './Constants';
import Service from './Service';

const getFormValidator = visibility => {
  if (visibility === CareerPageVisibility.confidential) {
    return new CareerPageConfidentialForm();
  }
  if (visibility === CareerPageVisibility.internal) {
    return new CareerPageInternalForm();
  }
  return new CareerPagePublicForm();
};

const removeNulls = obj =>
  Object.keys(obj)
    .filter(k => obj[`${k}`] != null)
    .reduce((newObj, k) => ({ ...newObj, [k]: obj[`${k}`] }), {});

export function* getCareerPages() {
  try {
    const response = yield call(Service.getCareerPages);
    yield put({
      type: ActionTypes.GET_CAREER_PAGES_SUCCESS,
      payload: { careerPages: response.body },
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_CAREER_PAGES_FAIL,
      error,
    });
  }
}

export function* postCareerPage({ payload: { careerPage, message } }) {
  const formValidator = getFormValidator(careerPage.visibility);
  formValidator.populate(careerPage);
  const validation = formValidator.validateModel();

  const employeeTestimonialValidation = TestimonialForm.validate(
    careerPage.userTestimonials,
  );
  const candidateTestimonialValidation = TestimonialForm.validate(
    careerPage.candidateTestimonials,
  );

  if (
    validation ||
    employeeTestimonialValidation.length ||
    candidateTestimonialValidation.length
  ) {
    yield put({
      type: ActionTypes.POST_FAIL,
      error: {
        validation: {
          ...(validation || {}),
          userTestimonials: employeeTestimonialValidation,
          candidateTestimonials: candidateTestimonialValidation,
        },
      },
    });
  } else {
    try {
      yield call(Service.postCareerPage, careerPage);

      yield put({
        type: ActionTypes.POST_SUCCESS,
        toast: buildToast(message, ToastTypes.success),
      });

      yield call(history.push, '/companies/setup/career-pages');
    } catch (error) {
      yield put({
        type: ActionTypes.POST_FAIL,
        error: { validation: formValidator.validateErrorResponse(error) },
      });
    }
  }
}

export function* patchCareerPage({ payload: { careerPage, message } }) {
  const payloadWithoutNull = removeNulls(careerPage);

  const formValidator = getFormValidator(careerPage.visibility);
  formValidator.populate(payloadWithoutNull);
  const validation = formValidator.validateModel();

  if (validation) {
    yield put({
      type: ActionTypes.PATCH_FAIL,
      error: { validation },
    });
  } else {
    try {
      yield call(Service.patchCareerPage, payloadWithoutNull);

      yield put({
        type: ActionTypes.PATCH_SUCCESS,
        toast: buildToast(message, ToastTypes.success),
      });

      yield call(history.push, '/companies/setup/career-pages');
    } catch (error) {
      yield put({
        type: ActionTypes.PATCH_FAIL,
        error: { validation: formValidator.validateErrorResponse(error) },
      });
    }
  }
}

export function* postCareerPageV2({ payload: { careerPage } }) {
  const formValidator = getFormValidator(careerPage.visibility);
  formValidator.populate(careerPage);
  const validation = formValidator.validateModel();

  const employeeTestimonialValidation = TestimonialForm.validate(
    careerPage.userTestimonials,
  );
  const candidateTestimonialValidation = TestimonialForm.validate(
    careerPage.candidateTestimonials,
  );

  if (
    validation ||
    employeeTestimonialValidation.length ||
    candidateTestimonialValidation.length
  ) {
    yield put({
      type: ActionTypes.POST_FAIL,
      error: {
        validation: {
          ...(validation || {}),
          userTestimonials: employeeTestimonialValidation,
          candidateTestimonials: candidateTestimonialValidation,
        },
      },
    });
  } else {
    try {
      yield call(Service.postCareerPage, careerPage);

      yield put({
        type: ActionTypes.POST_SUCCESS,
      });
    } catch (error) {
      yield put({
        type: ActionTypes.POST_FAIL,
        error: { validation: formValidator.validateErrorResponse(error) },
      });
    }
  }
}

export function* patchCareerPageV2({ payload: { careerPage } }) {
  const payloadWithoutNull = removeNulls(careerPage);

  const formValidator = getFormValidator(careerPage.visibility);
  formValidator.populate(payloadWithoutNull);
  const validation = formValidator.validateModel();

  if (validation) {
    yield put({
      type: ActionTypes.PATCH_FAIL,
      error: { validation },
    });
  } else {
    try {
      yield call(Service.patchCareerPage, payloadWithoutNull);

      yield put({
        type: ActionTypes.PATCH_SUCCESS,
      });
    } catch (error) {
      yield put({
        type: ActionTypes.PATCH_FAIL,
        error: { validation: formValidator.validateErrorResponse(error) },
      });
    }
  }
}

function* CareerPageSaga() {
  yield takeLatest(ActionTypes.GET_CAREER_PAGES_REQUEST, getCareerPages);
  yield takeLatest(ActionTypes.POST, postCareerPage);
  yield takeLatest(ActionTypes.PATCH, patchCareerPage);
  yield takeLatest(ActionTypes.POST_V2, postCareerPageV2);
  yield takeLatest(ActionTypes.PATCH_V2, patchCareerPageV2);
}

export default CareerPageSaga;
