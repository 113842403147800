import { createActionTypes } from '@gupy/front-commons';

const JobDeletionActionTypes = createActionTypes(
  'JobDeletion',
  'OPEN_JOB_DELETION_MODAL',
  'CLOSE_JOB_DELETION_MODAL',
  'GET_TEMPLATE_EMAILS',
  'GET_TEMPLATE_EMAILS_SUCCESS',
  'GET_TEMPLATE_EMAILS_FAIL',
  'SEND_EMAIL',
  'SEND_EMAIL_SUCCESS',
  'SEND_EMAIL_FAIL',
  'GET_APPLICATIONS_TO_SEND_EMAIL',
  'GET_APPLICATIONS_TO_SEND_EMAIL_SUCCESS',
  'GET_APPLICATIONS_TO_SEND_EMAIL_FAIL',
);

const openJobDeletionModal = job => ({
  type: JobDeletionActionTypes.OPEN_JOB_DELETION_MODAL,
  job,
});

const closeJobDeletionModal = () => ({
  type: JobDeletionActionTypes.CLOSE_JOB_DELETION_MODAL,
});

const getTemplateEmails = () => ({
  type: JobDeletionActionTypes.GET_TEMPLATE_EMAILS,
});

const sendEmail = payload => ({
  payload,
  type: JobDeletionActionTypes.SEND_EMAIL,
});

const getApplicationsToSendFeedback = jobId => ({
  jobId,
  type: JobDeletionActionTypes.GET_APPLICATIONS_TO_SEND_EMAIL,
});

export {
  openJobDeletionModal,
  closeJobDeletionModal,
  JobDeletionActionTypes,
  getTemplateEmails,
  getApplicationsToSendFeedback,
  sendEmail,
};
