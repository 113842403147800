import { IconButton, IconEnum, InfotipTypes } from '@gupy/design-system';
import {
  getMenuMessages,
  LinksBuilder,
  Navbar,
  NavbarItem,
} from '@gupy/front-commons';
import { RouteHelper } from '@gupy/front-helpers/src/helpers/RouteHelper';
import { useKeycloak } from '@react-keycloak/web';
import { useFlag, useUnleashClient } from '@unleash/proxy-client-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FeaturesFlagEnum from '../../../Authentication/FeaturesFlagsEnum';
import {
  getFormDataFromSchema,
  hasInMemoryUpdates,
} from '../../../JobCustomFormTemplate/JobCustomFormTemplate';
import { getMessages } from '../../../JobCustomFormTemplate/Messages';
import UserNotificationsContainerV2 from '../../../Notifications/UserNotificationsContainerV2';
import getTermsAndPrivacy from '../../../TermsAndPrivacy/getTermsAndPrivacy';
import { getTermsAndPrivacyMessages } from '../../../TermsAndPrivacy/TermsAndPrivacy.messages';

const propTypes = {
  currentUser: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  openConfirmDialog: PropTypes.func,
  schema: PropTypes.object,
  schemaReset: PropTypes.bool,
  items: PropTypes.array,
};

const AppMenu = ({
  currentUser,
  currentUser: {
    permissions,
    featureFlags,
    shouldTakeProfileTest,
    language,
    userType,
  },
  intl,
  location,
  history,
  openConfirmDialog,
  schema,
  schemaReset,
  items,
}) => {
  const isLegacySignOn = useFlag(FeaturesFlagEnum.legacySignOn);
  const isSSOEnabled = !isLegacySignOn;
  const { initialized, keycloak } = isSSOEnabled
    ? // TODO: FRONT-75 - Regra de hook quebrada
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useKeycloak()
    : { initialized: false, keycloak: {} };
  const messages = { ...getMenuMessages(intl), ...getMessages(intl) };
  const termsAndPrivacyMessages = getTermsAndPrivacyMessages(intl);
  const { terms, privacy } = getTermsAndPrivacy({
    messages: termsAndPrivacyMessages,
    openConfirmDialog,
  });

  const [originalItems, setOriginalItems] = useState([]);
  const [originalSchema, setOriginalSchema] = useState(null);
  const [hasNiduuAccess, setHasNiduuAccess] = useState(false);

  const flagsClient = useUnleashClient();
  const enabledFlags = flagsClient
    .getAllToggles()
    .filter(({ name }) => flagsClient.isEnabled(name))
    .map(({ name }) => name);
  const allEnabledFlags = [...featureFlags, ...enabledFlags];

  const { primaryLinks, secondaryLinks, customLinks } = LinksBuilder.build({
    isSSOEnabled,
    permissions,
    userFeatureFlags: allEnabledFlags,
    language,
    messages,
    footerLinks: [terms, privacy],
    userType,
  });

  const router = new RouteHelper({
    externalRoutePattern: /\/(admission|analytics)\/.*/,
    redirectInternally: link => history.push(link),
  });

  const isAdmissionOnlyEnabled =
    featureFlags &&
    featureFlags.length &&
    featureFlags.includes(FeaturesFlagEnum.admissionOnly);

  const favicons = [
    {
      search: "link[href*='apple-touch-icon.png']",
      href: `${process.env.REACT_APP_ASSETS_URL}/new-favicon/apple-touch-icon.png?v=2`,
    },
    {
      search: "link[href*='favicon-32x32.png']",
      href: `${process.env.REACT_APP_ASSETS_URL}/new-favicon/favicon-32x32.png?v=2`,
    },
    {
      search: "link[href*='favicon-16x16.png']",
      href: `${process.env.REACT_APP_ASSETS_URL}/new-favicon/favicon-16x16.png?v=2`,
    },
    {
      search: "link[href*='safari-pinned-tab.svg']",
      href: `${process.env.REACT_APP_ASSETS_URL}/new-favicon/safari-pinned-tab.png?v=2`,
    },
  ];

  const addFaviconToPage = favicon => {
    const link = document.querySelector(favicon.search);
    if (link) link.setAttribute('href', favicon.href);
  };

  favicons.forEach(favicon => addFaviconToPage(favicon));

  const userShouldDoProfileTest = shouldTakeProfileTest;
  const routeIsProfileTest =
    location && location.pathname === '/companies/profile-test';
  const shouldDisplayProfileTest =
    userShouldDoProfileTest && !routeIsProfileTest;

  const createItemsSorted = () => {
    const itemsUnsorted = getFormDataFromSchema(schema, messages);
    const schemaOrder = schema.uiSchema['ui:order'];
    const itemsSorted = schemaOrder.map(key =>
      itemsUnsorted.find(itemUns => itemUns.key === key),
    );

    return itemsSorted;
  };

  useEffect(() => {
    if (!schemaReset) return;
    if (schema) {
      const itemsSorted = createItemsSorted();
      setOriginalSchema(schema);
      setOriginalItems(itemsSorted);
    } else {
      setOriginalSchema(schema);
      setOriginalItems([]);
    }
  }, [schemaReset]);

  const customFormTemplateUnsavedChangesInterceptor = link => {
    if (
      hasInMemoryUpdates({
        schema,
        originalSchema,
        items,
        originalItems,
      })
    ) {
      const conditionalLinks = [
        '/analytics/dashboards',
        '/admission/companies/',
        '/analytics/reports',
      ];
      if (conditionalLinks.includes(link)) {
        if (confirm(messages.leavingWithoutSavingInMemoryUpdates)) {
          router.redirect(link);
        }
      } else {
        router.redirect(link);
      }
    } else {
      router.redirect(link);
    }
  };

  const handleMenuLink = (link, event) => {
    if (event && (event.ctrlKey || event.metaKey)) {
      window.open(link);
    } else if (
      window.location.pathname === '/companies/setup/custom-form-template'
    ) {
      customFormTemplateUnsavedChangesInterceptor(link);
    } else {
      router.redirect(link);
    }
  };

  useEffect(() => {
    if (isSSOEnabled && initialized) {
      setHasNiduuAccess(!!keycloak.tokenParsed.niduuId);
    }
  }, [isSSOEnabled, initialized]);

  return (
    <div className="app-menu">
      <Navbar
        logoUrl={`${process.env.REACT_APP_ASSETS_URL}/logo-new.svg`}
        logoTitle={messages.homeLinkLabel}
        logoClickUrl={
          isAdmissionOnlyEnabled ? '/admission/companies' : '/companies'
        }
        primaryLinks={primaryLinks}
        secondaryLinks={secondaryLinks}
        currentRoute={location.pathname}
        currentUser={currentUser}
        location={location}
        messages={messages}
        isSSOEnabled={isSSOEnabled}
        extraItems={[
          <React.Fragment key="app-menu-navbar">
            <NavbarItem>
              {shouldDisplayProfileTest && (
                <IconButton
                  className="root"
                  id="header-profile-test-btn"
                  icon={IconEnum.Assignment}
                  infotip={{
                    alignment: InfotipTypes.BOTTOM_RIGHT,
                    id: 'header-profile-test-btn-tooltip',
                    message: messages.profileTestRequested,
                  }}
                  label={messages.profileTestRequestedLabel}
                  onClick={event =>
                    handleMenuLink('/companies/profile-test', event)
                  }
                  variant="text"
                />
              )}

              <UserNotificationsContainerV2 />
            </NavbarItem>
          </React.Fragment>,
        ]}
        customLinks={customLinks}
        onMenuClick={handleMenuLink}
        hasNiduuAccess={hasNiduuAccess}
      />
    </div>
  );
};

AppMenu.propTypes = propTypes;

const mapStateToProps = ({ reducers }) => ({
  schema: reducers.JobCustomFormTemplate.schema,
  schemaReset: reducers.JobCustomFormTemplate.schemaReset,
  items: reducers.JobCustomFormTemplate.items,
  Signin: reducers.Signin,
});

export default withRouter(injectIntl(connect(mapStateToProps)(AppMenu)));
