export const selectFormValues = state => {
  const { modelForm } = state.reducers.HiringInformationReducer;
  return { ...modelForm.data };
};

export const selectIsLoading = state => {
  const { modelForm, hiringTypes, vacancyCodes, isReloadingApplications } =
    state.reducers.HiringInformationReducer;

  return (
    modelForm.isLoading ||
    hiringTypes.isLoading ||
    vacancyCodes.isLoading ||
    isReloadingApplications
  );
};

export const selectIsSaving = state => {
  const { isSaving } = state.reducers.HiringInformationReducer;
  return isSaving;
};

export const selectIsModalOpen = state => {
  const { isHiringInformationModalOpen } =
    state.reducers.HiringInformationReducer;
  return isHiringInformationModalOpen;
};

export const selectHiringTypes = state => {
  const { hiringTypes } = state.reducers.HiringInformationReducer;
  return hiringTypes.data;
};

export const selectVacancyCodes = state => {
  const { vacancyCodes } = state.reducers.HiringInformationReducer;
  return vacancyCodes.data;
};

export const selectJob = state => {
  const { job } = state.reducers.JobApplication;
  return {
    id: job.id,
  };
};

export const selectCandidate = state => {
  const { candidate } = state.reducers.HiringInformationReducer.modelForm.data;
  return {
    id: candidate.id || '',
  };
};
