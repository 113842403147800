import { NetworkAlert } from '@gupy/front-commons';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  updateGlobalization,
  updateNetworkStatus,
} from '../../../../actions/App/AppActions';
import DefaultAppFooter from '../AppFooter';
import { isInsideIframe } from '../helpers/isInsideIframe';
import SystemAlert from '../SystemAlert';

const propTypes = {
  Authentication: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};

const SecondaryLayout = ({ children, Authentication: { currentUser } }) => {
  const shouldRenderHeaderAndFooter = !isInsideIframe();

  const AppFooter = () => shouldRenderHeaderAndFooter && <DefaultAppFooter />;

  return (
    <Fragment>
      <NetworkAlert onChange={() => {}} />
      <SystemAlert currentUser={currentUser} />
      <div>{children}</div>
      <AppFooter />
    </Fragment>
  );
};

SecondaryLayout.propTypes = propTypes;

const mapStateToProps = state => ({
  App: state.reducers.App,
  Authentication: state.reducers.Authentication,
});

export default withRouter(
  connect(mapStateToProps, {
    updateGlobalization,
    updateNetworkStatus,
  })(SecondaryLayout),
);
