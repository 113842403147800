import { asyncActionType, createActionTypes } from '@gupy/front-commons';

export default createActionTypes(
  'JobRatingCriterias',
  'CHANGE_FORM',
  'CLEAR_FORM',
  'EDIT',
  asyncActionType('POST'),
  asyncActionType('PATCH'),
  asyncActionType('DELETE'),
  asyncActionType('INIT'),
  asyncActionType('GENERATE_SKILLS_FROM_GAIA'),
  asyncActionType('CLOSE_GENERATE_SKILLS_ERROR_MODAL'),
  asyncActionType('CLOSE_GENERATE_SKILLS_SUCCESS_TOAST'),
  asyncActionType('SEARCH'),
);
