import JobClosureActionTypes from './JobClosureActionTypes';

const openJobClosureModal = jobId => ({
  type: JobClosureActionTypes.OPEN_JOB_CLOSURE_MODAL,
  jobId,
});

const closeJobClosureModal = () => ({
  type: JobClosureActionTypes.CLOSE_JOB_CLOSURE_MODAL,
});

const setJobClosureModalStep = step => ({
  type: JobClosureActionTypes.SET_JOB_CLOSURE_MODAL_STEP,
  step,
});

const getFinalJobStepId = jobId => ({
  type: JobClosureActionTypes.GET_FINAL_JOB_STEP_ID,
  jobId,
});

const getBindSurveyCareerPages = () => ({
  type: JobClosureActionTypes.GET_BIND_SURVEY_CAREER_PAGES,
});

const doNoSendFeedbackEmail = () => ({
  type: JobClosureActionTypes.DO_NOT_SEND_FEEDBACK_EMAIL,
});

const getAllTemplateEmails = () => ({
  type: JobClosureActionTypes.GET_ALL_TEMPLATE_EMAILS,
});

const sendFeedbackEmails = (
  applicationIds,
  template,
  jobId,
  from,
  messages,
  engageSurveyId,
) => ({
  type: JobClosureActionTypes.SEND_FEEDBACK_EMAILS,
  applicationIds,
  template,
  jobId,
  from,
  messages,
  engageSurveyId,
});

const closeJob = (jobId, isFeedbackSent, successToastMessage) => ({
  type: JobClosureActionTypes.CLOSE_JOB,
  jobId,
  isFeedbackSent,
  successToastMessage,
});

export {
  openJobClosureModal,
  closeJobClosureModal,
  setJobClosureModalStep,
  getFinalJobStepId,
  getBindSurveyCareerPages,
  doNoSendFeedbackEmail,
  getAllTemplateEmails,
  sendFeedbackEmails,
  closeJob,
};
